import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchQboContentAsync,
  setLoading,
  resetState,
} from '@features/qboObjectContent/qboObjectContentSlice';
import { QboTypography } from '@ui/Components';
import { pascalToTitleCase, asyncFlattenAttributes } from '@utilities';

const attributesColumn = 'attributes';
const valueColumn = 'value';

const createHeader = (id, label, columnName, orderable, size, align, withColor) => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: id === 2,
    align,
    withColor,
    component: id === 1 ? 'th' : 'td',
  };
};

const useFetchObjectContent = ({ isDrawerReady, qboRealmId, objectName, objectId, versionId }) => {
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { QboObjectContent, fetchingQboContent } = useSelector((state) => state.qboObjectContent);
  const [contentDisplay, setContentDisplay] = useState([]);

  const parseRawContent = async (attributes) => {
    let content = await asyncFlattenAttributes(attributes);
    content = Object.entries(content);

    dispatch(setLoading(false));
    setContentDisplay(content);

    return content;
  };

  const createData = (rowId, attributes, value) => {
    return {
      id: rowId,
      isLink: false,
      attributes,
      value,
      heightSize: 'small',
    };
  };

  const tableHeaders = [
    createHeader(1, 'Attributes', attributesColumn, false, 'md', 'left', true),
    createHeader(2, 'Value', valueColumn, false, null, 'left', false),
  ];

  const tableRows = contentDisplay.map((content, index) =>
    createData(
      index,
      <QboTypography withTitle>{pascalToTitleCase(content[0])}</QboTypography>,
      <QboTypography>{content[1]}</QboTypography>
    )
  );

  useEffect(() => {
    const keyRaws = Object.keys(QboObjectContent.raw);

    if (keyRaws.length > 0) {
      dispatch(setLoading(true));
      parseRawContent(QboObjectContent.raw);
    }
  }, [QboObjectContent.raw]);

  useEffect(() => {
    if (isApplicationReady && isDrawerReady && versionId && versionId !== '') {
      dispatch(
        fetchQboContentAsync({
          qboRealmId,
          objectName,
          objectId,
          versionId,
        })
      );
    }

    return () => {
      dispatch(resetState());
    };
  }, [isApplicationReady, isDrawerReady, versionId]);

  return {
    QboObjectContent,
    tableHeaders,
    tableRows,
    loading: fetchingQboContent,
  };
};

export default useFetchObjectContent;
