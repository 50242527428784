import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLocale } from '@features/application/applicationSlice';
import { QboAvatar, QboDropdown, QboTypography } from '@ui/Components';
import I18n from '@config/I18n';
import BaseSetting from '@config/BaseSetting';
import { Box } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import useAuthParam from '@pages/sharedHooks/useAuthParam';
import useAccountMenu from '@pages/sharedHooks/useAccountMenu';

const qboI18n = new I18n(BaseSetting.defaultLanguage, BaseSetting.resourcesLocales);

export default function MenuBlock() {
  const dispatch = useDispatch();
  const { displayNameInitial } = useAuthParam();
  const { locale } = useSelector((state) => state.application);
  const { selectedMenuAccount, accountMenuItems } = useAccountMenu();

  const localeOptions = qboI18n.localeOptions
    .filter((i) => qboI18n.activeLocales.includes(i[0]))
    .map((item) => {
      return [
        item[0],
        <Box className="Dropdown__flag-item">
          <QboTypography>{item[1]}</QboTypography>
        </Box>,
      ];
    });

  return (
    <>
      <QboDropdown
        className="QboDropdown__locale-dropdown"
        menuItems={localeOptions}
        onClickItem={(selected) => {
          dispatch(setLocale(selected));
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <TranslateIcon />
        {qboI18n.getActiveLocaleLabel(locale)}
      </QboDropdown>
      <QboDropdown
        dropdownClassName="QboDropdown__account-menu"
        menuItems={accountMenuItems}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClickItem={(selected) => {
          selectedMenuAccount(selected);
        }}>
        <QboAvatar>{displayNameInitial()}</QboAvatar>
      </QboDropdown>
    </>
  );
}
