import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { qboDeactivateRealmAsync } from '@features/qboRealms/qboRealmsSlice';
import { useAlertMessage, usePageAlert } from '@hooks';

const useRealmDeactivation = () => {
  const dispatch = useDispatch();
  const { QboRealms } = useSelector((state) => state.qboRealms);
  const { submitting, success, error } = useSelector((state) => state.qboRealms.deactivateRealm);
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();

  const [seletedId, setSeletedId] = useState('');
  const [showModalDeactivate, setShowModalDeactivate] = useState(false);
  const [deactivateReason, setDeactivateReason] = useState('');

  const QboRealm = QboRealms.value.find((r) => r.id === seletedId);

  const setDeactivateStatus = () => {
    const payload = {
      realmId: seletedId,
      reason: deactivateReason,
    };
    if (seletedId) {
      dispatch(qboDeactivateRealmAsync(payload));
    }
    setShowModalDeactivate(false);
    setDeactivateReason('');
  };

  const onOpenDeactivateModal = (value) => {
    setSeletedId('');
    setShowModalDeactivate(true);
    setSeletedId(value);
  };

  const onCloseDeactivateModal = () => {
    setDeactivateReason('');
    setShowModalDeactivate(false);
  };

  useEffect(() => {
    if (success) {
      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_DEACTIVATE, {
          company_name: QboRealm?.companyName,
        }),
      };

      updateSuccessAlert(alertPayload);
    } else if (error) {
      const alertPayload = {
        ...getErrorMessage(konstMessage.ERROR_DEACTIVATE, { company_name: QboRealm?.companyName }),
      };

      updateErrorAlert(alertPayload);
    }
  }, [submitting]);

  return {
    onOpenDeactivateModal,
    onCloseDeactivateModal,
    setDeactivateStatus,
    showModalDeactivate,
    deactivateReason,
    setDeactivateReason,
    companyName: QboRealm?.companyName,
  };
};

export default useRealmDeactivation;
