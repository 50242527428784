import React from 'react';
import { QboDrawerListItem, QboTypography, QboLinearProgress } from '@ui/Components';
import PropTypes from 'prop-types';
import { useSystemStatus } from '@hooks';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { t } from 'i18next';

export default function DownloadStatusItemBlock({
  status,
  percentage,
  expireAt,
  label,
  onClickDownload,
}) {
  const { listDownloadStatus, badgeDownloadStatusRender } = useSystemStatus({
    status,
    withIcon: true,
    withInfoIcon: false,
    withStatusAndButton: true,
    onClickDownload,
  });

  const sideBadgeTextRender = () => {
    if (listDownloadStatus.RUNNING === status && percentage) {
      return (
        <QboTypography>
          {t('system_status.completed.percentage_text_all', { number: percentage })}
        </QboTypography>
      );
    }

    return null;
  };

  return (
    <div className="BackupStatusItemBlock__wrapper">
      <QboDrawerListItem
        className="BackupStatusItemBlock__badge-wrapper"
        icon={<CloudDownloadOutlinedIcon />}
        label={label}>
        <div className="BackupStatusItemBlock__content">
          {badgeDownloadStatusRender()}
          {sideBadgeTextRender()}
        </div>

        {listDownloadStatus.RUNNING === status && <QboLinearProgress progress={percentage} />}
        {listDownloadStatus.NO_DATA === status && (
          <QboTypography variant="caption">
            {t('status_download_page.drawer_download_info_section.item_info_no_data')}
          </QboTypography>
        )}
        {listDownloadStatus.SUCCESS === status && (
          <QboTypography variant="caption" color="secondary" withTitle>
            {t('status_download_page.drawer_download_info_section.item_info_will_expire', {
              date: expireAt,
            })}
          </QboTypography>
        )}
      </QboDrawerListItem>
    </div>
  );
}

DownloadStatusItemBlock.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  expireAt: PropTypes.string,
  percentage: PropTypes.number,
  onClickDownload: PropTypes.func,
};

DownloadStatusItemBlock.defaultProps = {
  label: '',
  status: '',
  expireAt: '',
  percentage: 0,
  onClickDownload: () => {},
};
