//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchQboContentAsync,
  fetchQboContentExtraReducers,
} from './serviceActions/fetchQboContentAsync';

const initialState = {
  QboObjectContent: {
    raw: {},
  },
  fetchingQboContent: true,
  error: null,
};

const qboObjectContentSlice = createSlice({
  name: 'QboObjectContent',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    setLoading(state, action) {
      state.fetchingQboContent = action.payload;
    },
  },
  extraReducers: {
    ...fetchQboContentExtraReducers,
  },
});

export const { resetState, setLoading } = qboObjectContentSlice.actions;
export { fetchQboContentAsync };
export default qboObjectContentSlice.reducer;
