import BaseSetting, { constant } from '@config/BaseSetting';
import { useLocalStorage } from '@hooks';

const { ssoCache } = constant;

export const IMPERSONATE = 'impersonate';
export const SOURCE = 'source';
export const CALLBACK_URL = 'callbackUrl';

export default function useAuthParam() {
  const [storageCallbackUrl, setStorageCallbackUrl] = useLocalStorage(ssoCache.CALLBACK_URL);
  const [storageSource, setStorageSource] = useLocalStorage(ssoCache.SOURCE);
  const [storageImpersonate, setStorageImpersonate] = useLocalStorage(ssoCache.IMPERSONATE);
  const [storageEmailAddress, setStorageEmailAddress] = useLocalStorage(ssoCache.EMAIL_ADDRESS);
  const [storageNameInitial, setStorageNameInitial] = useLocalStorage(ssoCache.NAME_INITIAL);
  const [storageFeatures, setStorageFeatures] = useLocalStorage(ssoCache.FEATURES);
  const [storageFavicon, setStorageFavicon] = useLocalStorage(ssoCache.FAVICON);
  const [storageGeneralLogo, setStorageGeneralLogo] = useLocalStorage(ssoCache.GENERAL_LOGO);

  const currentLocationUrl = new URL(window.location.href);

  const impersonateParam = currentLocationUrl.searchParams.get(IMPERSONATE);
  const sourceParam = currentLocationUrl.searchParams.get(SOURCE);
  const callbackUrlParam = currentLocationUrl.searchParams.get(CALLBACK_URL);

  const callbackUrl = callbackUrlParam || storageCallbackUrl || BaseSetting.defaultSSOCallbackUrl;
  const source = sourceParam || storageSource;
  const impersonate = ['True', 'true'].includes(impersonateParam || storageImpersonate);
  const emailAddress = storageEmailAddress;
  const nameInitial = storageNameInitial || BaseSetting.defaultNameInitial;
  const features = JSON.parse(storageFeatures);
  const favicon = storageFavicon;
  const generalLogo = storageGeneralLogo;

  const setSSOLoginParam = () => {
    setStorageImpersonate(impersonateParam === 'True');
    setStorageSource(sourceParam);
    setStorageCallbackUrl(callbackUrl);
  };

  const displayNameInitial = () => {
    try {
      return nameInitial.slice(0, 2).toUpperCase();
    } catch (err) {
      return 'A';
    }
  };

  return {
    setSSOLoginParam,
    callbackUrl,
    impersonate,
    source,
    emailAddress,
    features,
    displayNameInitial,
    favicon,
    generalLogo,
    setEmailAddress: setStorageEmailAddress,
    setNameInitial: setStorageNameInitial,
    setFeatures: setStorageFeatures,
    setFavicon: setStorageFavicon,
    setGeneralLogo: setStorageGeneralLogo,
  };
}
