import React from 'react';
import { QboCard, QboCardCaption } from '@ui/Components';
import NoRestoreStatus from '@images/generals/NoDownloadStatus.svg';
import { useTranslation } from 'react-i18next';

export default function NoRestoreSection() {
  const { t } = useTranslation();

  return (
    <QboCard noPadding isMainWrapper>
      <QboCardCaption
        imageUrl={NoRestoreStatus}
        heading={t('status_restore_page.no_restore_section.heading_text')}
        caption={t('status_restore_page.no_restore_section.caption_text')}
      />
    </QboCard>
  );
}
