/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboTypography, QboTipsBox } from '@ui/Components';
import ReplicateGuide from '@images/generals/ReplicateGuide.svg';

function CreateParagraph({ header, footer, title, subtitle, description, description2, list }) {
  return (
    <div className="ReplicateGuideSection__paragraph-wrapper">
      {title ? (
        <QboTypography className="ReplicateGuideSection__paragraph-title" color="primary" wrap>
          {title}
        </QboTypography>
      ) : null}
      {subtitle ? (
        <QboTypography className="ReplicateGuideSection__paragraph-sub-title" color="primary" wrap>
          {subtitle}
        </QboTypography>
      ) : null}
      {header}
      <div>
        <div
          className="ReplicateGuideSection__paragraph-description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {list ? (
          <div
            className="ReplicateGuideSection__paragraph-list"
            dangerouslySetInnerHTML={{
              __html: list,
            }}
          />
        ) : null}
        {description2 ? (
          <div
            className="ReplicateGuideSection__paragraph-description"
            dangerouslySetInnerHTML={{
              __html: description2,
            }}
          />
        ) : null}
      </div>
      {footer}
    </div>
  );
}
CreateParagraph.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  list: PropTypes.string,
};

CreateParagraph.defaultProps = {
  header: null,
  footer: null,
  title: '',
  subtitle: '',
  description: '',
  description2: '',
  list: '',
};

export default function ReplicateGuideSection() {
  const { t } = useTranslation();
  const replicateTextConfig = 'dashboard_page.drawer_replicate_guide_section';

  return (
    <div className="ReplicateGuideSection__content">
      <div className="ReplicateGuideSection__image-wrapper">
        <img src={ReplicateGuide} alt="" className="Img__fluid" />
      </div>
      <CreateParagraph description={t(`${replicateTextConfig}.helper_text.paragraph_1`)} />
      <CreateParagraph
        description={t(`${replicateTextConfig}.helper_text.paragraph_2`)}
        list={t(`${replicateTextConfig}.helper_text.paragraph_2_list_html`)}
        description2={t(`${replicateTextConfig}.helper_text.paragraph_3`)}
      />
      <QboTypography className="ReplicateGuideSection__content-title" color="primary">
        {t('dashboard_page.drawer_replicate_guide_section.content_1.title_text')}
      </QboTypography>
      <QboTipsBox
        className="ReplicateGuideSection__tips-box"
        __html={t('dashboard_page.drawer_replicate_guide_section.description_text')}
        variant="primary"
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_1.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_1.description`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_2.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_2.description_html`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_2.list_html`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_3.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_3.description_html`)}
      />
      <CreateParagraph
        subtitle={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_1.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_1.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_1.list_html`)}
        footer={
          <QboTipsBox
            className="ReplicateGuideSection__tips-box"
            __html={t('dashboard_page.drawer_replicate_guide_section.description_text')}
            variant="primary"
          />
        }
      />
      <CreateParagraph
        description={t(
          `${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_1.description_2`
        )}
        list={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_1.list_2_html`)}
      />
      <CreateParagraph
        subtitle={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.list_html`)}
      />
      <CreateParagraph
        description={t(
          `${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.description_2_html`
        )}
        list={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.list_2_html`)}
        description2={t(
          `${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.description_3_html`
        )}
        footer={
          <QboTipsBox
            className="ReplicateGuideSection__tips-box"
            __html={t(`${replicateTextConfig}.content_1.paragraph_3.sub_paragraph_2.warning_html`)}
            variant="warning"
          />
        }
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_4.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_4.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_4.list_html`)}
        header={
          <QboTipsBox
            className="ReplicateGuideSection__tips-box"
            __html={t(`${replicateTextConfig}.content_1.paragraph_4.notes_html`)}
            variant="primary"
          />
        }
        footer={
          <QboTipsBox
            className="ReplicateGuideSection__tips-box"
            __html={t(`${replicateTextConfig}.content_1.paragraph_4.notes_2_html`)}
            variant="primary"
          />
        }
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_5.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_5.description_html`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_5.list_html`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_6.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_6.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_6.list_html`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_7.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_7.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_7.list_html`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_8.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_8.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_8.list_html`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_1.paragraph_9.title`)}
        description={t(`${replicateTextConfig}.content_1.paragraph_9.description`)}
        list={t(`${replicateTextConfig}.content_1.paragraph_9.list_html`)}
      />
      <QboTypography className="ReplicateGuideSection__content-title" color="primary">
        {t('dashboard_page.drawer_replicate_guide_section.content_2.title_text')}
      </QboTypography>
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_2.paragraph_1.title`)}
        description={t(`${replicateTextConfig}.content_2.paragraph_1.description`)}
        list={t(`${replicateTextConfig}.content_2.paragraph_1.list_html`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_2.paragraph_2.title`)}
        description={t(`${replicateTextConfig}.content_2.paragraph_2.description`)}
        list={t(`${replicateTextConfig}.content_2.paragraph_2.list_html`)}
        footer={
          <QboTipsBox
            className="ReplicateGuideSection__tips-box"
            __html={t(`${replicateTextConfig}.content_2.paragraph_2.warning_html`)}
            variant="warning"
          />
        }
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_2.paragraph_3.title`)}
        description={t(`${replicateTextConfig}.content_2.paragraph_3.description`)}
      />
      <CreateParagraph
        title={t(`${replicateTextConfig}.content_2.paragraph_3_note.title`)}
        description={t(`${replicateTextConfig}.content_2.paragraph_3_note.description`)}
        list={t(`${replicateTextConfig}.content_2.paragraph_3_note.list_html`)}
        footer={
          <>
            <QboTipsBox
              className="ReplicateGuideSection__tips-box"
              __html={t(`${replicateTextConfig}.content_2.paragraph_3_note.notes`)}
              variant="primary"
            />
            <CreateParagraph
              description={t(`${replicateTextConfig}.content_2.paragraph_3_note.description_2`)}
            />
          </>
        }
      />
    </div>
  );
}
