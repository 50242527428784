import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

// params
// layout: layout type for wrapper of card
//   - single: single layout
//   - double: double layout

const DOUBLE_LAYOUT = 'double';

export default function QboCardWrapper({ children, layout, leftContent }) {
  switch (layout) {
    case DOUBLE_LAYOUT:
      return (
        <Box className={`QboCardWrapper__wrapper QoCardWrapper__${layout}-layout`}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              {leftContent}
            </Grid>
            <Grid item xs={12} md={9}>
              {children}
            </Grid>
          </Grid>
        </Box>
      );
    default:
      return (
        <Box className={`QboCardWrapper__wrapper QoCardWrapper__${layout}-layout`}>{children}</Box>
      );
  }
}

QboCardWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  layout: PropTypes.string,
  leftContent: PropTypes.node,
};

QboCardWrapper.defaultProps = {
  layout: 'single',
  leftContent: null,
};
