/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboRightDrawer, QboTypography, QboTipsBox } from '@ui/Components';
import RestoreGuide from '@images/generals/RestoreGuide.svg';
import GearIcon from '@images/generals/RestoreGuide-GearIcon.svg';
import PencilIcon from '@images/generals/RestoreGuide-PencilIcon.svg';
import AutoAddIcon from '@images/generals/RestoreGuide-AutoAddIcon.svg';

function CreateParagraph({ title, description, list }) {
  return (
    <div className="DrawerRestoreGuideSection__paragraph-wrapper">
      <QboTypography className="DrawerRestoreGuideSection__paragraph-title" color="primary">
        {title}
      </QboTypography>
      <div>
        <div
          className="DrawerRestoreGuideSection__paragraph-description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {list ? (
          <div
            className="DrawerRestoreGuideSection__paragraph-list"
            dangerouslySetInnerHTML={{
              __html: list,
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
CreateParagraph.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.string,
};

CreateParagraph.defaultProps = {
  title: '',
  description: '',
  list: '',
};

export default function DrawerRestoreGuideSection({ onClose, open }) {
  const { t } = useTranslation();
  const restoreTextConfig = 'dashboard_page.drawer_restore_guide_section';

  return (
    <QboRightDrawer
      className="DrawerRestoreGuideSection__wrapper"
      title={t('dashboard_page.drawer_restore_guide_section.heading_text')}
      onClose={onClose}
      open={open}
      showOnTop
      variant="persistent"
      hideBackdrop>
      <div className="DrawerRestoreGuideSection__content">
        <div className="DrawerRestoreGuideSection__image-wrapper">
          <img src={RestoreGuide} alt="" className="Img__fluid" />
        </div>
        <QboTypography variant="caption" className="DrawerRestoreGuideSection__content-helper">
          {t('dashboard_page.drawer_restore_guide_section.helper_text')}
        </QboTypography>

        <QboTypography className="DrawerRestoreGuideSection__content-title" color="primary">
          {t('dashboard_page.drawer_restore_guide_section.content_1.title_text')}
        </QboTypography>
        <QboTipsBox
          className="DrawerRestoreGuideSection__tips-box"
          __html={t('dashboard_page.drawer_restore_guide_section.description_text')}
          variant="primary"
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_1.paragraph_1.title`)}
          description={t(`${restoreTextConfig}.content_1.paragraph_1.description`)}
          list={t(`${restoreTextConfig}.content_1.paragraph_1.list_html`)}
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_1.paragraph_2.title`)}
          description={t(`${restoreTextConfig}.content_1.paragraph_2.description_html`)}
          list={t(`${restoreTextConfig}.content_1.paragraph_2.list_html`, {
            gear_icon: `<img src="${GearIcon}" alt="" />`,
            pencil_icon: `<img src="${PencilIcon}" alt="" />`,
          })}
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_1.paragraph_3.title`)}
          description={t(`${restoreTextConfig}.content_1.paragraph_3.description_html`)}
          list={t(`${restoreTextConfig}.content_1.paragraph_3.list_html`, {
            auto_add_icon: `<img src="${AutoAddIcon}" alt="" />`,
          })}
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_1.paragraph_4.title`)}
          description={t(`${restoreTextConfig}.content_1.paragraph_4.description_html`)}
          list={t(`${restoreTextConfig}.content_1.paragraph_4.list_html`)}
        />

        <QboTypography className="DrawerRestoreGuideSection__content-title" color="primary">
          {t('dashboard_page.drawer_restore_guide_section.content_2.title_text')}
        </QboTypography>
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_2.paragraph_1.title`)}
          description={t(`${restoreTextConfig}.content_2.paragraph_1.description`)}
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_2.paragraph_2.title`)}
          description={t(`${restoreTextConfig}.content_2.paragraph_2.description_html`)}
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_2.paragraph_3.title`)}
          description={t(`${restoreTextConfig}.content_2.paragraph_3.description`)}
        />
        <CreateParagraph
          title={t(`${restoreTextConfig}.content_2.paragraph_4.title`)}
          description={t(`${restoreTextConfig}.content_2.paragraph_4.description`)}
        />
        <QboTipsBox
          className="DrawerRestoreGuideSection__tips-box"
          __html={t('dashboard_page.drawer_restore_guide_section.notes_text')}
          variant="primary"
        />
      </div>
    </QboRightDrawer>
  );
}

DrawerRestoreGuideSection.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

DrawerRestoreGuideSection.defaultProps = {
  onClose: () => {},
  open: false,
};
