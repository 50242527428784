import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { replaceUrl as replaceUrlUtil } from '@utilities';

export default function useDrawerContent() {
  const paramVersionId = useParams().versionId;

  const [qboRealmId] = useState(useParams().id);
  const [objectName, setObjectName] = useState(useParams().objectName);
  const [objectId, setObjectId] = useState(useParams().objectId);
  const [versionId, setVersionId] = useState(paramVersionId || '');
  const [openDrawer, setOpenDrawer] = useState(Boolean(paramVersionId));
  const [replaceUrl, setReplaceUrl] = useState(Boolean(paramVersionId));
  const [navigateObjectName, setNavigateObjectName] = useState(false);

  const onOpenDrawer = (
    sourceVersionId,
    sourceObjectName,
    sourceObjectId,
    sourceReplaceUrl,
    sourceNavigate
  ) => {
    setOpenDrawer(true);
    setReplaceUrl(sourceReplaceUrl);
    setVersionId(sourceVersionId);
    setObjectName(sourceObjectName);
    setObjectId(sourceObjectId);
    setNavigateObjectName(sourceNavigate);
  };

  // replace url currently only
  // for realm item page
  useEffect(() => {
    if (openDrawer && replaceUrl) {
      replaceUrlUtil(
        ClientRoutes.showRealmObjectContentPath({
          id: qboRealmId,
          objectName,
          objectId,
          versionId,
        })
      );
    } else if (!openDrawer && replaceUrl) {
      replaceUrlUtil(
        ClientRoutes.showRealmObjectPath({
          id: qboRealmId,
          objectName,
        })
      );
    }
  }, [openDrawer]);

  return {
    objectName,
    objectId,
    versionId,
    openDrawer,
    qboRealmId,
    replaceUrl,
    navigateObjectName,
    onOpenDrawer,
    setOpenDrawer,
  };
}
