import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQboRestoreStatusAPI } from '@services/WebApiService';

const getStatusRestoreAsync = createAsyncThunk(
  'QboStatusRestore/getQboRestoreStatus',
  async ({ qboRestoreId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await getQboRestoreStatusAPI(accessToken, qboRestoreId);
    return data;
  }
);

const { fulfilled } = getStatusRestoreAsync;

const getQboRestoreStatusExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.id) {
      const {
        id,
        companyName,
        status,
        startTime,
        endTime,
        expireTime,
        pointInTime,
        type,
        totalNumObject,
        percentage,
        urls,
      } = action.payload;

      // replace the value from array
      const value = state.QboStatusRestore.value.find((v) => v.id === id);

      if (value) {
        const index = state.QboStatusRestore.value.indexOf(value);
        const currentValueState = state.QboStatusRestore.value[index];

        state.QboStatusRestore.value[index] = {
          ...currentValueState,
          companyName,
          status,
          startTime,
          endTime,
          expireTime,
          pointInTime,
          type,
          totalNumObject,
          percentage,
          urls,
        };
      }
    }
  },
};

export { getStatusRestoreAsync, getQboRestoreStatusExtraReducers };
