//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboFetchObjectItemsAsync,
  qboFetchObjectItemsExtraReducers,
} from './serviceActions/fetchQboObjectItemsAsync';
import {
  qboBackupNowRealmAsync,
  qboBackupNowRealmExtraReducers,
} from './serviceActions/backupNowAsync';

const initialState = {
  QboObjectItems: {
    showPagination: false,
    companyName: null,
    backupStatus: null,
    lastBackupAt: null,
    lastCompletedBackupAt: null,
    initialBackupAt: null,
    value: [],
    nextLink: null,
    prevLink: null,
  },
  fetchingQboObjectItems: true,
  error: null,
};

const qboObjectItemsSlice = createSlice({
  name: 'QboObjectItems',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    ...qboFetchObjectItemsExtraReducers,
    ...qboBackupNowRealmExtraReducers,
  },
});

export const { resetState } = qboObjectItemsSlice.actions;
export { qboFetchObjectItemsAsync, qboBackupNowRealmAsync };
export default qboObjectItemsSlice.reducer;
