import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQboBackupStatusAPI } from '@services/WebApiService';

const getStatusBackupAsync = createAsyncThunk(
  'QboStatusBackup/getQboBackupStatus',
  async ({ qboBackupId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await getQboBackupStatusAPI(accessToken, qboBackupId);
    return data;
  }
);

const { fulfilled } = getStatusBackupAsync;

/* eslint-disable prettier/prettier */
const getQboBackupStatusExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.id) {
      const { id, companyName, status, startTime, endTime, numOfBackupObjects, currentNumOfBackedUpObjects, percentage } = action.payload;

      // replace the value from array
      const value = state.QboStatusBackups.value.find((v) => v.id === id);

      if (value) {
        const index = state.QboStatusBackups.value.indexOf(value);
        const currentValueState = state.QboStatusBackups.value[index];

        state.QboStatusBackups.value[index] = {
          ...currentValueState,
          companyName,
          status,
          startTime,
          endTime,
          numOfBackupObjects,
          currentNumOfBackedUpObjects,
          percentage,
        };
      }
    }
  },
};

export { getStatusBackupAsync, getQboBackupStatusExtraReducers };
