//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboFetchStatusBackupsAsync,
  qboFetchStatusBackupsExtraReducers,
} from './serviceActions/fetchStatusBackupsAsync';
import {
  getStatusBackupAsync,
  getQboBackupStatusExtraReducers,
} from './serviceActions/getStatusBackupAsync';

const initialState = {
  QboStatusBackups: {
    value: [],
    nextLink: null,
    prevLink: null,
    showPagination: false,
  },
  fetchingQboStatusBackups: true,
  error: null,
};

const qboStatusBackupsSlice = createSlice({
  name: 'QboStatusBackups',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    updateFetching(state, action) {
      state.fetchingQboStatusBackups = action.payload;
    },
  },
  extraReducers: {
    ...qboFetchStatusBackupsExtraReducers,
    ...getQboBackupStatusExtraReducers,
  },
});

export const { resetState, updateFetching } = qboStatusBackupsSlice.actions;
export { qboFetchStatusBackupsAsync, getStatusBackupAsync };

export default qboStatusBackupsSlice.reducer;
