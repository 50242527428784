//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  changePasswordAsync,
  changePasswordExtraReducers,
} from './serviceActions/changePasswordAsync';

import {
  fetchStatus2FAAsync,
  fetchStatus2FAExtraReducers,
} from './serviceActions/fetchStatus2FAAsync';

import { fetchKey2FAAsync, fetchKey2FAExtraReducers } from './serviceActions/fetchKey2FAAsync';

import {
  validateAuthForm2FAAsync,
  validateAuthForm2FAExtraReducers,
} from './serviceActions/validateAuthForm2FAAsync';

const initialState = {
  changePassword: {
    submitting: false,
    success: false,
    error: null,
  },
  status2FA: {
    submitting: false,
    hasSetup2FA: false,
    hasEnable2FA: false,
    verifiedDate: '',
  },
  authForm2FA: {
    submitting: false,
    success: false,
    error: null,
  },
  authKey2FA: {
    submitting: false,
    qrKey: '',
    secretKey: '',
  },
  disable2FA: {
    submitting: false,
    success: false,
  },
  reEnable2FA: {
    submitting: false,
    success: false,
  },
};

const accountSlice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    resetChangePassword(state) {
      state.changePassword = initialState.changePassword;
    },
    resetStatus2FA(state) {
      return { ...initialState, status2FA: state.status2FA };
    },
    resetKey2FA(state) {
      return { ...initialState, status2FA: state.status2FA };
    },
    resetAuthform2FA(state) {
      return { ...initialState, status2FA: state.status2FA };
    },
  },
  extraReducers: {
    ...changePasswordExtraReducers,
    ...fetchStatus2FAExtraReducers,
    ...fetchKey2FAExtraReducers,
    ...validateAuthForm2FAExtraReducers,
  },
});

export { changePasswordAsync, fetchStatus2FAAsync, fetchKey2FAAsync, validateAuthForm2FAAsync };
export const { resetChangePassword, resetStatus2FA, resetKey2FA, resetAuthform2FA } =
  accountSlice.actions;
export default accountSlice.reducer;
