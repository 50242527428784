import { useTranslation } from 'react-i18next';

export default function useFormErrorMessage() {
  const { t } = useTranslation();

  const getErrorMessage = (localeKey, payload) => {
    const localeKeys = localeKey.split('.');
    const validateKey = localeKeys[localeKeys.length - 1];

    let translate = t(localeKey, payload);

    // there is no translation handle
    // by fallback to general translation
    if (translate === localeKey) translate = t(`form_validations.general.${validateKey}`, payload);

    // if still no translation
    // will return no validation
    if (translate === localeKey) translate = '';

    return translate;
  };

  return { getErrorMessage };
}
