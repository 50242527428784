import React from 'react';
import PropTypes from 'prop-types';
import { QboAccordion, QboButton, QboRightDrawer } from '@ui/Components';
import { useTranslation } from 'react-i18next';

export default function DrawerFilterSection({
  showDrawer,
  setShowDrawer,
  handleFilter,
  ButtonFilter,
  filterData,
}) {
  const { t } = useTranslation();

  return (
    <QboRightDrawer
      open={showDrawer}
      onClose={() => setShowDrawer(false)}
      title={t('dashboard_page.search_block.filter_text_button')}
      className="DrawerRealmFilterSection__wrapper"
      titleBarClassName="DrawerRealmFilterSection__heading"
      bottomBar={
        <>
          <QboButton
            variant="outlined"
            onClick={() => handleFilter(ButtonFilter.ButtonFilterRemove)}>
            {t('dashboard_page.search_block.filter_text_button_remove_filter')}
          </QboButton>
          <QboButton
            variant="contained"
            onClick={() => handleFilter(ButtonFilter.ButtonFilterApply)}>
            {t('dashboard_page.search_block.filter_text_button_apply_filter')}
          </QboButton>
        </>
      }
      bottomBarClassName="DrawerRealmFilterSection__footer">
      <QboAccordion
        defaultExpanded
        collections={filterData()}
        className="DrawerRealmFilterSection__accordion-filter"
      />
    </QboRightDrawer>
  );
}

DrawerFilterSection.propTypes = {
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.func,
  handleFilter: PropTypes.func,
  ButtonFilter: PropTypes.object,
  filterData: PropTypes.func,
};

DrawerFilterSection.defaultProps = {
  showDrawer: false,
  setShowDrawer: () => {},
  handleFilter: () => {},
  ButtonFilter: {},
  filterData: () => {},
};
