import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQboReplicateStatusAPI } from '@services/WebApiService';

const getStatusReplicateAsync = createAsyncThunk(
  'QboStatusReplicate/getQboReplicateStatus',
  async ({ qboReplicateId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await getQboReplicateStatusAPI(accessToken, qboReplicateId);
    return data;
  }
);

const { fulfilled } = getStatusReplicateAsync;

const getQboReplicateStatusExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.id) {
      const {
        id,
        companyName,
        status,
        startTime,
        endTime,
        expireTime,
        pointInTime,
        type,
        totalNumObject,
        percentage,
        urls,
        hasErrorUrl,
        attachmentFileName,
      } = action.payload;

      // replace the value from array
      const value = state.QboStatusReplicate.value.find((v) => v.id === id);

      if (value) {
        const index = state.QboStatusReplicate.value.indexOf(value);
        const currentValueState = state.QboStatusReplicate.value[index];

        state.QboStatusReplicate.value[index] = {
          ...currentValueState,
          companyName,
          status,
          startTime,
          endTime,
          expireTime,
          pointInTime,
          type,
          totalNumObject,
          percentage,
          urls,
          hasErrorUrl,
          attachmentFileName,
        };
      }
    }
  },
};

export { getStatusReplicateAsync, getQboReplicateStatusExtraReducers };
