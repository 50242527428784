import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { fontActiveColor } from '@config/themes/default/variables';

export default function QboAvatar({ sx, children, size, ...rest }) {
  const sxSize = () => {
    return {
      height: '2.4rem',
      width: '2.4rem',
      backgroundColor: fontActiveColor,
    };
  };

  const styles = {
    ...sxSize(),
  };

  return (
    <Avatar
      className="QboAvatar__wrapper"
      sx={{
        ...styles,
        ...sx,
      }}
      {...rest}>
      {children}
    </Avatar>
  );
}

QboAvatar.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  size: PropTypes.string,
};

QboAvatar.defaultProps = {
  sx: {},
  children: '',
  size: 'small',
};
