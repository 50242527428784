import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { QboCard, QboCardCaption, QboCardListItem } from '@ui/Components';
import SearchCardBlock from '@ui/Blocks/Shared/CardBlocks/SearchCardBlock';
import { useTranslation } from 'react-i18next';
import PaginationCardBlock from '@ui/Blocks/Shared/CardBlocks/PaginationCardBlock';
import FilterCardBlock from '@ui/Blocks/Shared/CardBlocks/FilterCardBlock';
import RealmCardItemBlock from '@ui/Blocks/DashboardPageBlocks/RealmCardItemBlock';
import { foldersMd } from '@images/generals/folders-svg';
import useRealmsOperation, {
  ButtonFilter,
} from '@pages/DashboardPage/pageHooks/useRealmsOperation';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import useRealmDeactivation from '@pages/DashboardPage/pageHooks/useRealmDeactivation';
import ModalDeactivateCardBlock from '@ui/Blocks/DashboardPageBlocks/ModalDeactivateCardBlock';

export default function BackupsQboSection({
  loading,
  collection,
  hasNextLink,
  hasPrevLink,
  onPrevPage,
  onNextPage,
  onChangePageNumber,
  pageNumber,
  showPagination,
  onSort,
  sort,
  onFilter,
  onClickDownload,
  onClickRestore,
  onClickReplicate,
  onClickMoreInfo,
  onClickBackupNow,
  searchKeyword,
  displaySearchKeyword,
  setSearchKeyword,
}) {
  const { t } = useTranslation();
  const loadingRows = collection.length > 0 ? collection : [...Array(5).keys()];
  const {
    listSort,
    listSortOptions,
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useRealmsOperation(onFilter);

  const [selectedSort, setSelectedSort] = useState(listSort[1]);

  const {
    onOpenDeactivateModal,
    onCloseDeactivateModal,
    setDeactivateStatus,
    showModalDeactivate,
    deactivateReason,
    setDeactivateReason,
    companyName,
  } = useRealmDeactivation();

  const renderContent = () => {
    if (loading) {
      return loadingRows.map((row, index) => (
        <QboCardListItem key={row?.id || index} leftContent="loading" loading />
      ));
    }

    if (!loading && !collection.length) {
      return (
        <QboCardCaption
          className="BackupListItemNotFound__border-top"
          imageUrl={foldersMd}
          heading={t('caption_card.not_found_block.title_text')}
          caption={t('caption_card.not_found_block.caption_text')}
        />
      );
    }

    return (
      <>
        {collection.map((QboRealm) => (
          <RealmCardItemBlock
            QboRealm={QboRealm}
            key={QboRealm.id}
            searchKeyword={displaySearchKeyword}
            onClickMoreInfo={onClickMoreInfo}
            onClickBackupNow={onClickBackupNow}
            onClickDownload={onClickDownload}
            onClickRestore={onClickRestore}
            onClickReplicate={onClickReplicate}
            onOpenDeactivateModal={onOpenDeactivateModal}
          />
        ))}

        <PaginationCardBlock
          hasNextLink={hasNextLink}
          hasPrevLink={hasPrevLink}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          showPagination={showPagination}
        />
      </>
    );
  };

  return (
    <div className="ListBackupSection__wrapper">
      <QboCard noPadding isMainWrapper title={t('dashboard_page.list_backup_card.title')}>
        <SearchCardBlock
          searchKeyword={searchKeyword}
          setSearchKeyword={(searchValue) => {
            setSearchKeyword(searchValue);
          }}
          selectedSort={selectedSort}
          setSelectedSort={(selectedValue) => {
            onSort(selectedValue);
            setSelectedSort(selectedValue);
          }}
          placeholder={t('dashboard_page.search_block.search_placeholder')}
          withSortButton
          sort={sort}
          listSortOptions={listSortOptions}
          setShowDrawer={handleOpenDrawer}
          showBadgeFilter={listBadges.length > 0}
        />
        <FilterCardBlock filterData={listBadges} handleDelete={handleDelete} />
        {renderContent()}
        <DrawerFilterSection
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          handleFilter={handleFilter}
          ButtonFilter={ButtonFilter}
          filterData={filterData}
        />

        <ModalDeactivateCardBlock
          showModal={showModalDeactivate}
          onClose={onCloseDeactivateModal}
          reason={deactivateReason}
          setReason={setDeactivateReason}
          companyName={companyName}
          onClick={setDeactivateStatus}
        />
      </QboCard>
    </div>
  );
}

BackupsQboSection.propTypes = {
  loading: PropTypes.bool,
  collection: PropTypes.array,
  hasNextLink: PropTypes.string,
  hasPrevLink: PropTypes.string,
  onPrevPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onChangePageNumber: PropTypes.func,
  pageNumber: PropTypes.string,
  showPagination: PropTypes.bool,
  onSort: PropTypes.func,
  sort: PropTypes.object,
  onFilter: PropTypes.func,
  onClickDownload: PropTypes.func,
  onClickRestore: PropTypes.func,
  onClickReplicate: PropTypes.func,
  onClickMoreInfo: PropTypes.func,
  searchKeyword: PropTypes.string,
  setSearchKeyword: PropTypes.func,
  displaySearchKeyword: PropTypes.string,
  onClickBackupNow: PropTypes.func,
};

BackupsQboSection.defaultProps = {
  loading: false,
  collection: [],
  hasNextLink: null,
  hasPrevLink: null,
  onPrevPage: () => {},
  onNextPage: () => {},
  onChangePageNumber: () => {},
  pageNumber: null,
  showPagination: false,
  onSort: () => {},
  sort: {},
  onFilter: () => {},
  onClickDownload: () => {},
  onClickRestore: () => {},
  onClickReplicate: () => {},
  onClickMoreInfo: () => {},
  searchKeyword: '',
  displaySearchKeyword: '',
  setSearchKeyword: () => {},
  onClickBackupNow: () => {},
};
