import React from 'react';
import PropTypes from 'prop-types';
import { QboCardListItem, QboTypography } from '@ui/Components';

export default function QboDrawerListItem({ icon, label, children, className }) {
  return (
    <QboCardListItem leftContent={icon} className={`QboDrawerListItem__wrapper ${className}`}>
      {label && <QboTypography variant="caption">{label}</QboTypography>}
      {children}
    </QboCardListItem>
  );
}

QboDrawerListItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

QboDrawerListItem.defaultProps = {
  icon: null,
  label: null,
  children: null,
  className: '',
};
