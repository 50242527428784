import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authReducer from '@features/auth/authSlice';
import accountReducer from '@features/account/accountSlice';
import applicationReducer from '@features/application/applicationSlice';
import qboObjectsReducer from '@features/qboObjects/qboObjectsSlice';
import qboObjectItemsReducer from '@features/qboObjectItems/qboObjectItemsSlice';
import qboSearchItemsReducer from '@features/qboSearchItems/qboSearchItemsSlice';
import qboRealmsReducer from '@features/qboRealms/qboRealmsSlice';
import qboOauthReducer from '@features/qboOauth/qboOauthSlice';
import organizationReducer from '@features/organization/organizationSlice';
import qboObjectContentReducer from '@features/qboObjectContent/qboObjectContentSlice';
import qboContentVersionsReducer from '@features/qboContentVersions/qboContentVersionsSlice';
import qboStatusBackupsReducer from '@features/qboStatusBackups/qboStatusBackupsSlice';
import qboRealmToolsReducer from '@features/QboRealmTools/QboRealmToolsSlice';
import qboStatusDowloadReducer from '@features/qboStatusDowload/qboStatusDownloadSlice';
import qboStatusRestoreReducer from '@features/qboStatusRestore/qboStatusRestoreSlice';
import qboStatusReplicateReducer from '@features/qboStatusReplicate/qboStatusReplicateSlice';

const reducer = {
  auth: authReducer,
  account: accountReducer,
  application: applicationReducer,
  qboRealms: qboRealmsReducer,
  qboOauth: qboOauthReducer,
  qboObjects: qboObjectsReducer,
  qboObjectItems: qboObjectItemsReducer,
  qboSearchItems: qboSearchItemsReducer,
  organization: organizationReducer,
  qboObjectContent: qboObjectContentReducer,
  qboContentVersions: qboContentVersionsReducer,
  qboStatusBackups: qboStatusBackupsReducer,
  qboRealmsTools: qboRealmToolsReducer,
  qboStatusDownload: qboStatusDowloadReducer,
  qboStatusRestore: qboStatusRestoreReducer,
  qboStatusReplicate: qboStatusReplicateReducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    if (['production', 'test'].includes(process.env.NODE_ENV)) {
      return getDefaultMiddleware();
    }

    return getDefaultMiddleware().concat(logger);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
