import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QboTypography, QboDrawerListItem } from '@ui/Components';
import EventIcon from '@mui/icons-material/Event';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useDateTime, useSystemStatus } from '@hooks';
import useRealmDownload from '@pages/sharedHooks/useRealmDownload';

const defaultNull = '-';

const createCardItem = (icon, label, value) => {
  return (
    <QboDrawerListItem icon={icon} label={label}>
      <QboTypography className="DrawerRealmInfoSection__card-list-value">{value}</QboTypography>
    </QboDrawerListItem>
  );
};

export default function useDrawerDownloadInfo(QboStatusDownloadSelectedItem) {
  const { t } = useTranslation();
  const { dateFormat, dateToString, dateTimeNow, dateDistance } = useDateTime();
  const { ConstantQBOPayloadType } = useRealmDownload();
  const { listDownloadStatus } = useSystemStatus();
  const [selectedDownloadId, setSelectedDownloadId] = useState(null);
  const { QboStatusDownload } = useSelector((state) => state.qboStatusDownload);

  const drawerIsOpen = selectedDownloadId !== null;
  const QboStatusDownloadItem = QboStatusDownload.value.find((v) => v.id === selectedDownloadId);

  let elapsedTime = defaultNull;

  if (QboStatusDownloadSelectedItem?.startTime) {
    const endTime = QboStatusDownloadSelectedItem?.endTime || dateTimeNow();
    elapsedTime =
      dateDistance(QboStatusDownloadSelectedItem?.startTime, endTime, {
        separator: ' ',
      }) || '1s';
  }

  const generalInformationPanel = [
    {
      headingText: t('dashboard_page.drawer_realm_info_section.general_info_caption'),
      isFullContent: true,
      children: (
        <>
          {createCardItem(
            <EventIcon />,
            t('status_download_page.drawer_download_info_section.started_at_label'),
            dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME,
              QboStatusDownloadSelectedItem?.startTime
            ) || defaultNull
          )}
          {createCardItem(
            <HistoryToggleOffIcon />,
            t('status_download_page.drawer_download_info_section.elapsed_time_label'),
            elapsedTime
          )}
          {createCardItem(
            <EventAvailableIcon />,
            t('status_download_page.drawer_download_info_section.generated_at_label'),
            dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME,
              QboStatusDownloadSelectedItem?.endTime
            ) || defaultNull
          )}
        </>
      ),
    },
  ];

  const labelInfoItem = () => {
    let label = '';

    if (QboStatusDownloadSelectedItem?.status === listDownloadStatus.RUNNING) {
      label = t('status_download_page.drawer_download_info_section.item_info_calculating');
    } else if (ConstantQBOPayloadType?.TypeRealm === QboStatusDownloadSelectedItem?.type) {
      label = t('status_download_page.drawer_download_info_section.item_info_realm_label', {
        company_name: QboStatusDownloadSelectedItem?.displayName,
      });
    } else if (ConstantQBOPayloadType?.TypeObject === QboStatusDownloadSelectedItem?.type) {
      label = t('status_download_page.drawer_download_info_section.item_info_object_label', {
        row_selected: QboStatusDownloadSelectedItem?.totalObjects,
      });
    } else if (ConstantQBOPayloadType?.TypeItems === QboStatusDownloadSelectedItem?.type) {
      if (QboStatusDownloadSelectedItem?.displayName) {
        label = t('status_download_page.drawer_download_info_section.item_info_items_label', {
          object_name: QboStatusDownloadSelectedItem?.displayName,
        });
      } else {
        label = t('status_download_page.drawer_download_info_section.item_info_items_search_label');
      }
    } else {
      label = QboStatusDownloadSelectedItem?.displayName;
    }

    return label;
  };

  return {
    drawerIsOpen,
    setOpenDrawer: (id) => {
      setSelectedDownloadId(id);
    },
    closeDrawer: () => {
      setSelectedDownloadId(null);
    },
    QboStatusDownloadItem,
    generalInformationPanel,
    labelInfoItem: labelInfoItem(),
  };
}
