import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlertMessage, usePageAlert, useDateTime, usePageSnackbar } from '@hooks';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import { pushSpliceEntry } from '@utilities';
import { qboRestoreRealmToolsAsync } from '@features/QboRealmTools/QboRealmToolsSlice';
import ErrorResponse from '@libs/ErrorResponse';

const restoreTypeRealm = 'realm';
const restoreTypeObject = 'object';
const restoreTypeItems = 'items';
const restoreTypeItemVersion = 'itemVersions';

const restoreType = {
  restoreTypeRealm,
  restoreTypeObject,
  restoreTypeItems,
  restoreTypeItemVersion,
};

const ConstantQBOPayloadType = {
  TypeRealm: 'Realm',
  TypeObject: 'Object',
  TypeItems: 'Item',
  TypeItemVersion: 'ItemVersion',
};

const typeNotifSnackbar = 'snackbar';

const useRealmRestore = (
  props = {
    selectedListId: [],
    objectName: '',
    searchKeyword: '',
  }
) => {
  const dispatch = useDispatch();
  const { selectedListId, objectName, searchKeyword, companyName } = props;
  const { dateToString, dateFormat, mergeDateAndTime, toISOFormat } = useDateTime();
  const { QboRealms } = useSelector((state) => state.qboRealms);
  const {
    error: restoreError,
    submitting,
    success: restoreSuccess,
  } = useSelector((state) => state.qboRealmsTools.restore);

  const { selectAllConst } = useCheckboxTable();
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const { updateSuccessSnackbar, updateErrorSnackbar } = usePageSnackbar();

  const [selectedRealmId, setSelectedRealmId] = useState(null);
  const [showModalRestore, setShowModalRestore] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [showModalConfirmationRestore, setShowModalConfirmationRestore] = useState(false);
  const selectedRestoreData = QboRealms.value.find((r) => r.id === selectedRealmId);
  const selectedCompanyName = companyName || selectedRestoreData?.companyName;
  const [notes, setNotes] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [typeNotif, setTypeNotif] = useState('');
  const [selectedItemVersion, setSelectedItemVersion] = useState({});

  const getTime = (date = dateValue, time = timeValue) => {
    if (date && time) {
      const dateTime = mergeDateAndTime(date, time);
      setSelectedTime(toISOFormat(dateTime));
      return dateTime;
    }
    return '';
  };

  const resetState = () => {
    setSelectedRealmId(null);
    setShowModalRestore(false);
    setShowModalConfirmationRestore(false);
    setSelectedRealmId({});
    setDateValue(null);
    setTimeValue(null);
    setSelectedTime(null);
    setNotes('');
  };

  const actionRestoreRealm = async () => {
    const payload = {
      realmId: [selectedRealmId],
      pointInTime: selectedTime,
      notes,
    };
    dispatch(qboRestoreRealmToolsAsync(payload));
    setRequesting(true);
    resetState();
  };

  const actionRestoreObject = async () => {
    const isSelectAll = selectedListId.includes(selectAllConst);
    const objectId = isSelectAll ? pushSpliceEntry(selectedListId, selectAllConst) : selectedListId;
    const payload = {
      type: restoreTypeObject,
      realmId: selectedRealmId,
      select_all: isSelectAll,
      objectId,
      pointInTime: selectedTime,
      notes,
    };
    dispatch(qboRestoreRealmToolsAsync(payload));
    setRequesting(true);
    resetState();
  };

  const actionRestoreItems = async () => {
    const isSelectAll = selectedListId.includes(selectAllConst);
    const itemId = isSelectAll ? pushSpliceEntry(selectedListId, selectAllConst) : selectedListId;
    const payload = {
      type: restoreTypeItems,
      realmId: selectedRealmId,
      select_all: true,
      itemId,
      pointInTime: selectedTime,
      notes,
      searchKeyword,
    };
    dispatch(qboRestoreRealmToolsAsync(payload));
    setRequesting(true);
    resetState();
  };

  const actionRestoreItemVersion = async () => {
    const payload = {
      realmId: selectedRealmId,
      type: restoreTypeItemVersion,
      maskedIds: [selectedItemVersion?.id],
      objectName,
      pointInTime: selectedTime,
      notes,
    };
    dispatch(qboRestoreRealmToolsAsync(payload));
    setRequesting(true);
    setTypeNotif(typeNotifSnackbar);
    resetState();
  };

  const onGenerateLink = (type = restoreTypeRealm) => {
    switch (type) {
      case restoreTypeRealm:
        actionRestoreRealm();
        break;
      case restoreTypeObject:
        actionRestoreObject();
        break;
      case restoreTypeItems:
        actionRestoreItems();
        break;
      case restoreTypeItemVersion:
        actionRestoreItemVersion();
        break;
      default:
        actionRestoreRealm();
    }
    return '';
  };

  useEffect(() => {
    if (restoreSuccess && requesting) {
      setRequesting(false);

      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_RESTORE, {
          path: ClientRoutes.backupStatusPath(),
        }),
        html: true,
      };

      const bannerPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_BANNER_RESTORE, {
          path: ClientRoutes.backupStatusPath(),
        }),
        html: true,
      };

      if (typeNotif === typeNotifSnackbar) {
        updateSuccessSnackbar(bannerPayload);
      } else {
        updateSuccessAlert(alertPayload);
      }
    } else if (restoreError && requesting) {
      setRequesting(false);

      let alertPayload = {};
      let bannerPayload = {};

      switch (restoreError) {
        case ErrorResponse.RestoreRequestAlreadyExists:
          alertPayload = {
            ...getErrorMessage(konstMessage.ERROR_RESTORE_ALREADY_EXISTS, {
              path: ClientRoutes.downloadStatusPath({ keyword: selectedCompanyName }),
            }),
            html: true,
          };
          bannerPayload = {
            ...getErrorMessage(konstMessage.ERROR_BANNER_RESTORE_ALREADY_EXISTS, {}),
          };
          break;
        case ErrorResponse.MaxRestoreLimitReached:
          alertPayload = { ...getErrorMessage(konstMessage.ERROR_RESTORE_LIMITED_REACHED, {}) };
          bannerPayload = {
            ...getErrorMessage(konstMessage.ERROR_BANNER_RESTORE_LIMITED_REACHED, {}),
          };
          break;
        default:
          alertPayload = { ...getErrorMessage(konstMessage.ERROR_RESTORE_GENERAL, {}) };
          bannerPayload = { ...getErrorMessage(konstMessage.ERROR_BANNER_RESTORE_GENERAL, {}) };
      }

      if (typeNotif === typeNotifSnackbar) {
        updateErrorSnackbar(bannerPayload);
      } else {
        updateErrorAlert(alertPayload);
      }
    }
  }, [submitting]);

  return {
    showModalRestore,
    onOpen: (realmId) => {
      setSelectedRealmId(realmId);
      setShowModalRestore(true);
    },
    onOpenItemVersion: (realmId, selectedVersion) => {
      setSelectedRealmId(realmId);
      setSelectedItemVersion(selectedVersion);
      setShowModalConfirmationRestore(true);
      setSelectedTime(selectedVersion?.backupAt);
    },
    onClose: () => {
      resetState();
    },
    timeValue,
    setTimeValue: (value) => {
      setTimeValue(value);
      getTime(...Array(1), value);
    },
    dateValue,
    setDateValue: (value) => {
      setDateValue(value);
      getTime(value);
    },
    selectedTimeRestore: dateToString(
      dateFormat.DEFAULT_FULL_DATE_TIME_WITHOUT_DAY_NAME,
      selectedTime
    ),
    onNextButtonRestore: () => {
      setShowModalConfirmationRestore(true);
      setShowModalRestore(false);
    },
    showModalConfirmationRestore,
    onBackButtonRestore: () => {
      setShowModalRestore(true);
      setShowModalConfirmationRestore(false);
    },
    selectedRestoreData,
    restoreNotes: notes,
    setRestoreNotes: setNotes,
    restoreType,
    onGenerateRestore: onGenerateLink,
    ConstantQBOPayloadType,
  };
};

export default useRealmRestore;
