import { createAsyncThunk } from '@reduxjs/toolkit';
import { authenticateAPI } from '@services/WebApiService';

const authRequestAsync = createAsyncThunk('Auth/auth', async (ssoToken) => {
  const data = await authenticateAPI(ssoToken);
  return data;
});

const { pending, rejected, fulfilled } = authRequestAsync;

const authExtraReducers = {
  [pending]: (state) => {
    state.isAuthenticating = true;
  },
  [rejected]: (state) => {
    state.isAuthenticating = false;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.accessToken) {
      state.accessToken = action.payload.accessToken;
      state.emailAddress = action.payload.emailAddress;
      state.nameInitial = action.payload.nameInitial;
      state.fullName = action.payload.fullName;
      state.organizationName = action.payload.organizationName;
      state.isAuthenticating = false;
      state.isUserSignedIn = true;
      state.features = action.payload.features;
      state.favicon = action.payload.favicon;
      state.generalLogo = action.payload.generalLogo;
    }
  },
};

export { authRequestAsync, authExtraReducers };
