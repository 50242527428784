import React from 'react';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';

export default function QboHighlighter({ searchWord, sentences }) {
  return (
    <Highlighter
      highlightClassName="QboTypography__mark-label"
      searchWords={[searchWord]}
      autoEscape={false}
      textToHighlight={sentences}
    />
  );
}

QboHighlighter.propTypes = {
  searchWord: PropTypes.string,
  sentences: PropTypes.string,
};

QboHighlighter.defaultProps = {
  searchWord: '',
  sentences: '',
};
