import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlertMessage, usePageAlert } from '@hooks';
import { listBackupStatus } from '@hooks/useSystemStatus';
import ClientRoutes from '@config/Routes/WebClientRoutes';

export default function useBackupNow(dispatchAction, DefaultSelectedQboRealm) {
  const dispatch = useDispatch();
  const { getSuccessMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert } = usePageAlert();
  const { QboRealms } = useSelector((state) => state.qboRealms);

  const [requesting, setRequesting] = useState(false);
  const [selectedQboRealmId, setSelectedQboRealmId] = useState();
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const QboRealm =
    DefaultSelectedQboRealm || QboRealms.value.find((r) => r.id === selectedQboRealmId);

  const updateSelectedQboRealm = (qboRealmId = null) => {
    if (qboRealmId && QboRealms.value.length > 0) setSelectedQboRealmId(qboRealmId);
    setOpenDialogConfirm(true);
  };

  const closeDialog = () => {
    setOpenDialogConfirm(false);
    setRequesting(false);
  };

  const onConfirmBackupNow = () => {
    setRequesting(true);
    if (QboRealm?.id) dispatch(dispatchAction({ qboRealmId: QboRealm.id }));
  };

  const openDialogBackupNow = () => {
    setOpenDialogConfirm(true);
  };

  const onSelectForBackup = (qboRealmId) => {
    updateSelectedQboRealm(qboRealmId);
  };

  useEffect(() => {
    if (QboRealm?.status === listBackupStatus.RUNNING && requesting) {
      closeDialog();

      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_BACKUP_NOW, {
          company_name: QboRealm.companyName,
          path: ClientRoutes.backupStatusPath({ keyword: QboRealm.companyName }),
        }),
        html: true,
      };

      updateSuccessAlert(alertPayload);
    }
  }, [QboRealm]);

  return {
    onSelectForBackup,
    onConfirmBackupNow,
    openDialogConfirm,
    companyNameLabel: QboRealm?.companyName,
    onCloseConfirmBackupNow: closeDialog,
    requestingBackupNow: requesting,
    openDialogBackupNow,
  };
}
