import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authLogoutAsync } from '@features/auth/authSlice';
import useAuthParam from '@pages/sharedHooks/useAuthParam';
import { clearStorage } from '@hooks/useLocalStorage';

export default function useLogout() {
  const dispatch = useDispatch();
  const { callbackUrl } = useAuthParam();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { isUserSignedIn } = useSelector((state) => state.auth);

  const logoutAction = () => {
    dispatch(authLogoutAsync());
  };

  useEffect(() => {
    if (isApplicationReady && !isUserSignedIn) {
      clearStorage();
      window.location.href = callbackUrl;
    }
  }, [isUserSignedIn]);

  return { logoutAction };
}
