import React from 'react';
import { QboSnackbar } from '@ui/Components';
import { usePageSnackbar } from '@hooks';

/* eslint-disable react/no-danger */
export default function AlertPageSection() {
  const { isSnackbarOpen, snackbar, closeSnackbar } = usePageSnackbar();

  if (!isSnackbarOpen) return null;

  return (
    <QboSnackbar
      open={isSnackbarOpen}
      message={snackbar.message}
      type={snackbar.type}
      onClose={() => closeSnackbar(false)}
    />
  );
}
