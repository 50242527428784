import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboTypography, QboLink } from '@ui/Components';
import { Error as ErrorIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import useAuthParam from '@pages/sharedHooks/useAuthParam';
import useLogout from '@pages/sharedHooks/useLogout';

export default function SecondBarSection({ drawerWidth, drawerOpen }) {
  const { t } = useTranslation();
  const { emailAddress } = useAuthParam();
  const { logoutAction } = useLogout();

  return (
    <Box
      className="SecondBar__default"
      sx={{
        ...(drawerOpen ? { marginLeft: `${drawerWidth}px` } : { marginLeft: '5.5rem' }),
        ...(drawerOpen
          ? { width: `calc(100% - ${drawerWidth}px)` }
          : { width: `calc(100% - 5.5rem)` }),
      }}>
      <Box className="SecondBar__default-flex">
        <ErrorIcon fontSize="medium" sx={{ marginRight: '1rem' }} />
        <QboTypography>
          {t('second_bar.logged_status', { email: emailAddress })}&nbsp;
          <QboLink
            underline="always"
            onClick={() => {
              logoutAction();
            }}>
            {t('second_bar.logout_text')}
          </QboLink>
        </QboTypography>
      </Box>
    </Box>
  );
}

SecondBarSection.propTypes = {
  drawerWidth: PropTypes.number,
  drawerOpen: PropTypes.bool,
};

SecondBarSection.defaultProps = {
  drawerWidth: 240,
  drawerOpen: true,
};
