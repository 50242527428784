import React from 'react';
import PropTypes from 'prop-types';
import { QboCardWrapper } from '@ui/Components';
import ListingBackupsSection from '@ui/Sections/SystemStatusPage/ListingBackupsSection';
import useFetchStatusBackups from '@pages/SystemStatusPage/pageHooks/useFetchStatusBackups';
import useStatusBackupFilter, {
  ButtonFilter,
} from '@pages/SystemStatusPage/pageHooks/useStatusBackupFilter';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import NoBackupSection from '@ui/Sections/SystemStatusPage/NoBackupSection';
import DrawerBackupInfoSection from '@ui/Sections/SystemStatusPage/DrawerBackupInfoSection';
import useDrawerBackupInfo from '@pages/SystemStatusPage/pageHooks/useDrawerBackupInfo';

export default function StatusBackupSection({ setSnackbarOpen }) {
  const {
    hasBackupStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    QboStatusBackups,
    fetchingQboStatusBackups,
  } = useFetchStatusBackups();

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusBackupFilter(onFilter);

  const { drawerIsOpen, setOpenDrawer, closeDrawer, QboStatusBackup } = useDrawerBackupInfo();

  return (
    <>
      {!fetchingQboStatusBackups && !hasBackupStatuses && <NoBackupSection />}

      {(fetchingQboStatusBackups || hasBackupStatuses) && (
        <QboCardWrapper>
          <ListingBackupsSection
            onClickRow={setOpenDrawer}
            loading={fetchingQboStatusBackups}
            collection={QboStatusBackups.value}
            hasNextLink={QboStatusBackups.nextLink}
            hasPrevLink={QboStatusBackups.prevLink}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onChangePageNumber={onChangePageNumber}
            pageNumber={pageNumber}
            showPagination={QboStatusBackups.showPagination}
            onFilter={onFilter}
            searchKeyword={keyword}
            setSearchKeyword={setKeyword}
            displaySearchKeyword={displayKeyword}
            handleOpenDrawer={handleOpenDrawer}
            listBadges={listBadges}
            handleDelete={handleDelete}
          />
        </QboCardWrapper>
      )}

      <DrawerFilterSection
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleFilter={handleFilter}
        ButtonFilter={ButtonFilter}
        filterData={filterData}
      />

      <DrawerBackupInfoSection
        onClose={closeDrawer}
        QboStatusBackup={QboStatusBackup}
        open={drawerIsOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
}

StatusBackupSection.propTypes = {
  setSnackbarOpen: PropTypes.func,
};

StatusBackupSection.defaultProps = {
  setSnackbarOpen: () => {},
};
