import React from 'react';
import PropTypes from 'prop-types';
import { QboCardListItem, QboLink, QboTypography } from '@ui/Components';
import { Close as CloseIcon } from '@mui/icons-material';
import { Skeleton, Drawer } from '@mui/material';

function TitleBar({ className, title, loading, onClose }) {
  return (
    <QboCardListItem
      className={`QboRightDrawer__heading ${className}`}
      rightContent={
        <QboLink
          className="QboRightDrawer__close-icon QboLink__gray-primary-hover"
          onClick={onClose}>
          <CloseIcon />
        </QboLink>
      }>
      <QboTypography variant="h5">
        {loading && <Skeleton width="30%" height={22} />}
        {!loading && title}
      </QboTypography>
    </QboCardListItem>
  );
}

TitleBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

TitleBar.defaultProps = {
  title: null,
  loading: false,
  onClose: () => {},
  className: '',
};

export default function QboRightDrawer({
  topBar,
  bottomBar,
  loading,
  children,
  title,
  onClose,
  open,
  className,
  titleBarClassName,
  bottomBarClassName,
  size,
  showOnTop,
  ...rest
}) {
  return (
    <Drawer
      {...rest}
      anchor="right"
      className={`QboRightDrawer__wrapper QboRightDrawer__${size}-size ${className} ${
        bottomBar ? 'QboRightDrawer__with-bottom-bar' : ''
      } ${showOnTop ? 'QboRightDrawer__show-on-top' : ''}`}
      open={open}>
      {topBar}

      <TitleBar className={titleBarClassName} title={title} loading={loading} onClose={onClose} />

      {children}

      {bottomBar && (
        <div className={`QboRightDrawer__footer-bar ${bottomBarClassName}`}>{bottomBar}</div>
      )}
    </Drawer>
  );
}

QboRightDrawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  topBar: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  bottomBar: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
  className: PropTypes.string,
  titleBarClassName: PropTypes.string,
  bottomBarClassName: PropTypes.string,
  size: PropTypes.string,
  showOnTop: PropTypes.bool,
};

QboRightDrawer.defaultProps = {
  topBar: null,
  bottomBar: null,
  open: false,
  loading: false,
  title: null,
  onClose: PropTypes.func,
  className: '',
  titleBarClassName: '',
  bottomBarClassName: '',
  size: 'medium',
  showOnTop: false,
};
