import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboTypography, QboDrawerListItem } from '@ui/Components';
import { useDateTime } from '@hooks';
import BusinessIcon from '@mui/icons-material/Business';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const createCardItem = (icon, label, value) => {
  return (
    <QboDrawerListItem icon={icon} label={label}>
      <QboTypography className="DrawerRealmInfoSection__card-list-value">{value}</QboTypography>
    </QboDrawerListItem>
  );
};

export default function useRealmInfo({ QboRealm }) {
  const { t } = useTranslation();
  const { dateFormat, dateToString } = useDateTime();

  const generalInformationPanel = [
    {
      headingText: t('dashboard_page.drawer_realm_info_section.general_info_caption'),
      isFullContent: true,
      children: (
        <>
          {createCardItem(
            <BusinessIcon />,
            t('dashboard_page.drawer_realm_info_section.realm_info.company_name_label'),
            QboRealm?.companyName || '-'
          )}
          {createCardItem(
            <MailOutlineIcon />,
            t('dashboard_page.drawer_realm_info_section.realm_info.company_email_label'),
            QboRealm?.emailAddress || '-'
          )}
          {createCardItem(
            <EmojiFlagsIcon />,
            t('dashboard_page.drawer_realm_info_section.realm_info.country_label'),
            QboRealm?.country || '-'
          )}
          {createCardItem(
            <PlaylistAddIcon />,
            t('dashboard_page.drawer_realm_info_section.realm_info.added_at_label'),
            dateToString(dateFormat.DEFAULT_DATE_TIME, QboRealm?.createdAt) || '-'
          )}
        </>
      ),
    },
  ];

  const backupInformationPanel = [
    {
      headingText: t('dashboard_page.drawer_realm_info_section.backup_info_caption'),
      isFullContent: true,
      children: (
        <>
          {createCardItem(
            <FolderOpenIcon />,
            t('dashboard_page.drawer_realm_info_section.realm_info.backup_object_label'),
            t('dashboard_page.drawer_realm_info_section.number_backup_objects', {
              count: QboRealm?.numOfBackupObjects,
            })
          )}
          {createCardItem(
            <HourglassEmptyIcon />,
            t('dashboard_page.drawer_realm_info_section.realm_info.backup_frequency_label'),
            QboRealm?.backupFrequency || '-'
          )}
        </>
      ),
    },
  ];

  return { generalInformationPanel, backupInformationPanel };
}
