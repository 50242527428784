import { useSelector } from 'react-redux';
import {
  qboDownloadErrorMessageURLToolsAsync,
  resetState,
} from '@features/QboRealmTools/QboRealmToolsSlice';
import useDownload from '@pages/sharedHooks/useDownload';

const RestoreErrorMessage = 'qbo-restores';
const ReplicateErrorMessage = 'qbo-replicates';

export const TypeDownload = {
  RestoreErrorMessage,
  ReplicateErrorMessage,
};

const useDownloadErrorMessage = (type) => {
  const { downloadErrorMessageUrl } = useSelector((state) => state.qboRealmsTools);
  const { handleDownload } = useDownload(
    downloadErrorMessageUrl,
    qboDownloadErrorMessageURLToolsAsync,
    resetState
  );

  const onClickDownload = (maskedQboId) => {
    const payload = {
      maskedQboId,
      type,
    };
    handleDownload(payload);
  };

  return {
    onClickDownload,
  };
};

export default useDownloadErrorMessage;
