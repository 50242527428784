import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadErrorMessageUrlCallbackAPI } from '@services/WebApiService';

const qboDownloadErrorMessageURLToolsAsync = createAsyncThunk(
  'QboRealms/downloadErrorMessage',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await downloadErrorMessageUrlCallbackAPI(accessToken, payload);

    return data;
  }
);

const { pending, fulfilled, rejected } = qboDownloadErrorMessageURLToolsAsync;

const qboDownloadErrorMessageURLToolsExtraReducers = {
  [pending]: (state) => {
    state.downloadErrorMessageUrl.submitting = true;
  },
  [rejected]: (state) => {
    state.downloadErrorMessageUrl.submitting = false;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.url) {
      state.downloadErrorMessageUrl.url = action.payload.url;
    }
    state.downloadErrorMessageUrl.submitting = false;
  },
};

export { qboDownloadErrorMessageURLToolsAsync, qboDownloadErrorMessageURLToolsExtraReducers };
