import React from 'react';
import { Box } from '@mui/material';
import { QboLink } from '@ui/Components';
import { useTranslation } from 'react-i18next';

const date = new Date();

export default function FooterSection() {
  const { t } = useTranslation();

  return (
    <Box component="footer" className="Box__main-with-padding">
      <div className="footer__flex-wraper">
        <p>{t('footer.copyright', { year: date.getFullYear() })}</p>
        <QboLink href="#top">{t('footer.link_to_top')}</QboLink>
      </div>
    </Box>
  );
}
