import { useMemo } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import { authenticationPath, refreshTokenPath } from '@config/Routes/WebApiRoutes';
import BaseSetting from '@config/BaseSetting';
import { sleep } from '@utilities';
import useAuthParam from '@pages/sharedHooks/useAuthParam';

const ERROR_UNAUTHORIZED = 'Unauthorized';
const MISSING_PARAMETER = 'MissingParameter';
const USED_MORE_THAN_ONCE = 'UsedMoreThanOnce';
const refreshTokenExcludedUrl = [authenticationPath];

const returnResponse = (response) => {
  // directly return the data
  if (response.data) return response.data;

  // return response if no data
  return response;
};

window.throtleRequest = BaseSetting.refresTokenRetryThrotle;

/* eslint-disable no-underscore-dangle */
export default function ServiceClientInterceptors({ children }) {
  const { callbackUrl } = useAuthParam();

  useMemo(() => {
    axios.interceptors.response.use(
      (response) => {
        if (response.data === '') return { data: response.data, status: response.status };
        // directly return the data
        if (response.data) return response.data;
        // return response if no data
        return response;
      },
      async (error) => {
        const { response, config } = error;
        const originalConfig = config;

        if (!refreshTokenExcludedUrl.includes(originalConfig.url) && response) {
          // handle if
          // Access Token is expired
          const { data, status } = response;

          if (
            status === 401 &&
            data?.error?.code === ERROR_UNAUTHORIZED &&
            !originalConfig._retry
          ) {
            originalConfig._retry = true;

            // add throttle to
            // solve race condition request refresh token
            // on the same time
            window.throtleRequest += BaseSetting.refresTokenRetryThrotle;
            await sleep(window.throtleRequest);

            try {
              const responseRefreshToken = await axios.post(refreshTokenPath);
              const newAccessToken = responseRefreshToken.accessToken;

              if (newAccessToken) {
                axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
                originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;

                window.throtleRequest = 0;
                // retry process will be done directly on each request
                // by retrying the process
                return axios(originalConfig);
              }
            } catch (err) {
              if (response.data) return returnResponse(response);
              return Promise.reject(err);
            }
          } else if (
            status === 400 &&
            originalConfig.url === refreshTokenPath &&
            (data?.error?.code === MISSING_PARAMETER ||
              data?.error?.code === USED_MORE_THAN_ONCE) &&
            callbackUrl
          ) {
            window.location.href = callbackUrl;
          }
        }

        if (response.data) return returnResponse(response);
        return Promise.reject(error);
      }
    );
  }, []);

  return children;
}

ServiceClientInterceptors.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
