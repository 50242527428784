import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@mui/material';
import { QboTypography } from '@ui/Components';

export default function QboLinearProgress({ progress, label, className, processing }) {
  const props = {};
  if (!processing) props.value = parseInt(progress, 10);
  if (!processing) props.variant = 'determinate';

  return (
    <div className={`QboLinearProgress__wrapper ${className}`}>
      {label && <QboTypography>{label}</QboTypography>}
      <LinearProgress className={`QboLinearProgress__component ${className}`} {...props} />
    </div>
  );
}

QboLinearProgress.propTypes = {
  progress: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  processing: PropTypes.bool,
};

QboLinearProgress.defaultProps = {
  progress: 0,
  label: null,
  className: '',
  processing: false,
};
