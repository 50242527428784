import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboContentVersionsAPI } from '@services/WebApiService';

const fetchQboContentVersionsAsync = createAsyncThunk(
  'QboContentVersions/fetchQboContentVersions',
  async (
    { qboRealmId, objectName, objectId, pageLink, top, skip, orderBy, order },
    { getState }
  ) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboContentVersionsAPI(
      accessToken,
      qboRealmId,
      objectName,
      objectId,
      pageLink,
      {
        top,
        skip,
        order,
        orderBy,
      }
    );
    return data;
  }
);

const { pending, fulfilled, rejected } = fetchQboContentVersionsAsync;

const fetchQboContentVersionsExtraReducers = {
  [pending]: (state, { meta }) => {
    if (!meta.arg.pageLink) state.fetchingQboContentVersions = true;
    state.fetchingMoreVersions = true;
  },
  [rejected]: (state) => {
    state.fetchingQboContentVersions = false;
    state.fetchingMoreVersions = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboContentVersions = false;
    state.fetchingMoreVersions = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.value && action.payload.value.length > 0) {
      const { companyName, value, nextLink, isRestorable, isDeletedByIntuit } = action.payload;

      if (!action.meta.arg.pageLink) {
        state.QboContentVersions = {
          companyName,
          value,
          nextLink,
          isRestorable,
          isDeletedByIntuit,
        };
      } else {
        state.QboContentVersions.value = [...state.QboContentVersions.value, ...value];
        state.QboContentVersions.nextLink = nextLink;
      }
    }
  },
};

export { fetchQboContentVersionsAsync, fetchQboContentVersionsExtraReducers };
