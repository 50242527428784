import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { stringRandom } from '@utilities';
import { fontActiveColor } from '@config/themes/default/variables';
import { QboTypography } from '@ui/Components';
import Badge from '@mui/material/Badge';

/* eslint-disable no-unused-vars */
const optionLabel = (item) => {
  let label = item;
  let value;
  let attributes = {};

  if (typeof item === 'object') [value, label, attributes] = item;
  if (typeof label === 'string') {
    return <QboTypography color={attributes.color || 'primary'}>{label}</QboTypography>;
  }

  return label;
};

const optionValue = (item) => {
  if (typeof item === 'object') {
    return item[0];
  }

  return item;
};

// to add rest attributes for menu items
// eg props:
// [ [ITEM, ITEM_LABEL, { divider: true }] ]
//
const menuItemAttributes = (item) => {
  if (typeof item === 'object') {
    return item[2] || {};
  }

  return {};
};

const iconStyles = {
  color: fontActiveColor,
};

const iconButtonStyles = {
  gap: '0.5rem',
  color: fontActiveColor,
};

export default function QboDropdown({
  withArrowIcon,
  menuItems,
  onClickItem,
  children,
  anchorOrigin,
  transformOrigin,
  className,
  dropdownClassName,
  buttonClassName,
  leftIcon,
  variant,
  showBadge,
  headerMenu,
  disabled,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const menuId = `QboDropdown--${stringRandom(7)}`;

  const onDropdownOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (selected) => {
    setAnchorEl(null);
    if (selected) onClickItem(selected);
  };

  const renderLeftIcon = () => {
    if (!leftIcon) return null;
    if (showBadge) {
      return (
        <Badge
          className="QboButton__badge"
          color="primary"
          variant="dot"
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          {leftIcon}
        </Badge>
      );
    }
    return leftIcon;
  };

  return (
    <Box
      className={`QboDropdown__wrapper ${className} ${
        disabled ? 'QboDropdown__disable-button' : ''
      }`}>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <IconButton
          size="large"
          edge="end"
          aria-label={menuId}
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={disabled ? () => {} : onDropdownOpen}
          color="inherit"
          sx={iconButtonStyles}
          className={`IconButton__default ${buttonClassName}`}
          disableRipple>
          {renderLeftIcon()}
          {children}
          {withArrowIcon && <KeyboardArrowDownIcon sx={iconStyles} />}
        </IconButton>
      </Box>

      <Menu
        className={`QboDropdown__menu ${dropdownClassName}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          ...{
            vertical: 'bottom',
            horizontal: 'left',
          },
          ...anchorOrigin,
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          ...{
            vertical: 'top',
            horizontal: 'left',
          },
          ...transformOrigin,
        }}
        open={isOpen}
        onClose={() => {
          handleMenuClose();
        }}>
        {headerMenu}

        {menuItems.length > 0 &&
          menuItems.map((item, index) => {
            const attributes = {};
            const mItemAttributes = menuItemAttributes(item);

            if (mItemAttributes) {
              const { divider, component, to, onClick } = mItemAttributes;

              if (divider) attributes.divider = divider;
              if (component) attributes.component = component;
              if (to) attributes.to = to;
              if (onClick) attributes.onClick = onClick;
            }

            /* eslint-disable react/no-array-index-key */
            return (
              <MenuItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  // eslint-disable-next-line no-unused-expressions
                  !(variant === 'sort' && index === 0) && handleMenuClose(optionValue(item));
                }}
                {...attributes}>
                {optionLabel(item)}
              </MenuItem>
            );
          })}
      </Menu>
    </Box>
  );
}

QboDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  headerMenu: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  withArrowIcon: PropTypes.bool,
  menuItems: PropTypes.array,
  onClickItem: PropTypes.func,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  leftIcon: PropTypes.node,
  variant: PropTypes.string,
  showBadge: PropTypes.bool,
  disabled: PropTypes.bool,
};

QboDropdown.defaultProps = {
  headerMenu: null,
  children: '',
  withArrowIcon: true,
  menuItems: [],
  onClickItem: () => {},
  anchorOrigin: {},
  transformOrigin: {},
  className: '',
  dropdownClassName: '',
  buttonClassName: '',
  leftIcon: null,
  variant: '',
  showBadge: false,
  disabled: false,
};
