import { useTranslation } from 'react-i18next';
import SystemStatus from '@libs/SystemStatus';
import useStatusFilter, {
  ButtonFilter as ButtonFilterConst,
  FilterType as FilterTypeConst,
  SelectAllStatus as SelectAllStatusConst,
} from '@pages/sharedHooks/useStatusFilter';

export const ButtonFilter = ButtonFilterConst;

const useStatusItemsFilter = (onFilter) => {
  const { t } = useTranslation();
  const { listItemStatus } = SystemStatus;

  const listFilter = [
    {
      label: 'backup_realm_object_page.status.all_items',
      status: SelectAllStatusConst,
    },
    {
      label: 'backup_realm_object_page.status.available_items',
      status: listItemStatus.AVAILABLE,
      labelPrefix: 'backup_realm_object_page.status.badge.prefix',
    },
    {
      label: 'backup_realm_object_page.status.deleted_items',
      status: listItemStatus.DELETED,
      labelPrefix: 'backup_realm_object_page.status.badge.prefix',
    },
  ];

  const headingText = t('search_realm_object_item_page.search_block.filter_item_status_text');

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusFilter({
    onFilter: (statuses) => onFilter(statuses),
    listFilter,
    headingText,
    type: FilterTypeConst.FilterTypeSelect,
  });

  return {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  };
};

export default useStatusItemsFilter;
