import { useTranslation } from 'react-i18next';

const konstObjectName = {
  ACCOUNT: 'Account',
  ATTACHABLE: 'Attachable',
  BILL: 'Bill',
  CUSTOMER: 'Customer',
  DEPOSIT: 'Deposit',
  ESTIMATE: 'Estimate',
  INVOICE: 'Invoice',
  ITEM: 'Item',
  PAYMENT: 'Payment',
  PURCHASE: 'Purchase',
  TRANSFER: 'Transfer',
  VENDOR: 'Vendor',
};

export default function useRelatedObjectRules(props = {}) {
  const { t } = useTranslation();
  const { objectName } = props;
  let message = '';

  switch (objectName) {
    case konstObjectName.ACCOUNT:
      message = t('rewind_related_object.account_html');
      break;
    case konstObjectName.ATTACHABLE:
      message = t('rewind_related_object.attachable_html');
      break;
    case konstObjectName.BILL:
      message = t('rewind_related_object.bill_html');
      break;
    case konstObjectName.CUSTOMER:
      message = t('rewind_related_object.customer_html');
      break;
    case konstObjectName.DEPOSIT:
      message = t('rewind_related_object.deposit_html');
      break;
    case konstObjectName.ESTIMATE:
      message = t('rewind_related_object.estimate_html');
      break;
    case konstObjectName.INVOICE:
      message = t('rewind_related_object.invoice_html');
      break;
    case konstObjectName.ITEM:
      message = t('rewind_related_object.item_html');
      break;
    case konstObjectName.PAYMENT:
      message = t('rewind_related_object.payment_html');
      break;
    case konstObjectName.PURCHASE:
      message = t('rewind_related_object.purchase_html');
      break;
    case konstObjectName.TRANSFER:
      message = t('rewind_related_object.transfer_html');
      break;
    case konstObjectName.VENDOR:
      message = t('rewind_related_object.vendor_html');
      break;
    default:
      break;
  }

  return {
    message,
  };
}
