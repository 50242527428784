import React from 'react';
import PropTypes from 'prop-types';
import { QboButton, QboRefreshingIcon } from '@ui/Components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTranslation } from 'react-i18next';
import useBackupNow from '@pages/sharedHooks/useBackupNow';
import { listBackupStatus } from '@hooks/useSystemStatus';
import BackupNowConfirmationBlock from '@ui/Blocks/Shared/BackupNowConfirmationBlock';

export default function BackupNowButtonBlock({ id, status, companyName, action, loading }) {
  const { t } = useTranslation();

  const {
    onConfirmBackupNow,
    openDialogConfirm,
    onCloseConfirmBackupNow,
    companyNameLabel,
    requestingBackupNow,
    openDialogBackupNow,
  } = useBackupNow(action, { id, status, companyName });

  const disabled = status !== listBackupStatus.SUCCESS;
  const label =
    status === listBackupStatus.RUNNING
      ? t('dashboard_page.backup_now_block.button.in_progress_text')
      : t('dashboard_page.backup_now_block.button.backup_now_text');
  const icon = status === listBackupStatus.RUNNING ? <QboRefreshingIcon /> : <CloudUploadIcon />;

  return (
    <>
      <QboButton
        variant="contained"
        startIcon={icon}
        disabled={disabled}
        onClick={openDialogBackupNow}
        loading={loading}>
        {label}
      </QboButton>

      <BackupNowConfirmationBlock
        onClose={onCloseConfirmBackupNow}
        onConfirm={onConfirmBackupNow}
        open={openDialogConfirm}
        companyName={companyNameLabel}
        submitting={requestingBackupNow}
      />
    </>
  );
}

BackupNowButtonBlock.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  action: PropTypes.func,
  companyName: PropTypes.string,
  loading: PropTypes.bool,
};

BackupNowButtonBlock.defaultProps = {
  id: null,
  status: null,
  action: null,
  companyName: null,
  loading: false,
};
