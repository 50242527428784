import * as React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';

export default function QboQRCode({ value, size }) {
  return (
    <QRCode
      size={size}
      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
      value={value}
      viewBox="0 0 256 256"
    />
  );
}

QboQRCode.propTypes = {
  value: PropTypes.string,
  size: PropTypes.number,
};

QboQRCode.defaultProps = {
  value: '',
  size: 256,
};
