//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboFetchStatusReplicateAsync,
  qboFetchStatusReplicateExtraReducers,
} from './serviceActions/fetchStatusReplicateAsync';
import {
  getStatusReplicateAsync,
  getQboReplicateStatusExtraReducers,
} from './serviceActions/getStatusReplicateAsync';

const initialState = {
  QboStatusReplicate: {
    value: [],
    nextLink: null,
    prevLink: null,
    showPagination: false,
  },
  fetchingQboStatusReplicate: true,
  error: null,
};

const qboStatusReplicateSlice = createSlice({
  name: 'QboStatusReplicate',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    updateFetching(state, action) {
      state.fetchingQboStatusReplicate = action.payload;
    },
  },
  extraReducers: {
    ...qboFetchStatusReplicateExtraReducers,
    ...getQboReplicateStatusExtraReducers,
  },
});

export const { resetState, updateFetching } = qboStatusReplicateSlice.actions;
export { qboFetchStatusReplicateAsync, getStatusReplicateAsync };

export default qboStatusReplicateSlice.reducer;
