import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import {
  CalendarMonth as CalendarMonthIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@mui/icons-material';
import {
  QboLink,
  QboTypography,
  QboCardListItem,
  QboSimpleDataTable,
  QboSelectField,
  QboRightDrawer,
  QboButton,
} from '@ui/Components';
import { pascalToTitleCase } from '@utilities';
import DashboardBreadcrumbsBlocks from '@ui/Blocks/Shared/DashboardBreadcrumbsBlock';
import useFetchObjectContent from '@pages/sharedHooks/useFetchObjectContent';
import useFetchContentVersions from '@pages/sharedHooks/useFetchContentVersions';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import useRealmDownload from '@pages/sharedHooks/useRealmDownload';
import ModalDownloadCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalDownloadCardBlock';
import { useNavigate } from 'react-router-dom';
import SnackbarPageSection from '@ui/Sections/Shared/SnackbarPageSection';
import useRealmRestore from '@pages/sharedHooks/useRealmRestore';
import ModalRestoreCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalRestoreCardBlock';
import useDrawerRestoreGuide from '@pages/DashboardPage/pageHooks/useDrawerRestoreGuide';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import DrawerRestoreGuideSection from '@ui/Sections/DashboardPage/DrawerRestoreGuideSection';
import BaseSetting from '@config/BaseSetting';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import useRelatedObjectRules from '@pages/sharedHooks/useRelatedObjectRules';

export default function DrawerObjectContentSection({
  open,
  closeDrawer,
  qboRealmId,
  objectName,
  objectId,
  versionId,
  isNavigateObjectName,
}) {
  const navigate = useNavigate();
  const {
    QboContentVersions,
    versionOptions,
    loadingVersions,
    onLoadMoreVersion,
    loadingMoreVersions,
    selectedVersion,
    setSelectedVersionId,
  } = useFetchContentVersions({ isDrawerReady: open, qboRealmId, objectName, objectId, versionId });
  const { t } = useTranslation();
  const { tableHeaders, tableRows, loading } = useFetchObjectContent({
    isDrawerReady: open,
    qboRealmId,
    objectName,
    objectId,
    versionId: selectedVersion?.id,
  });

  const {
    showModalDateTime,
    showModalConfirmation,
    onClose,
    onNextButton,
    onBackButton,
    setSelectedRealmId,
    timeValue,
    setTimeValue,
    dateValue,
    setDateValue,
    notes,
    setNotes,
    selectedTime,
    onGenerateLink,
    QboObjects: QboObjectData,
    downloadType,
    setSelectedItemVersion,
  } = useRealmDownload({ objectName, companyName: QboContentVersions?.companyName });

  const { drawerRestoreIsOpen, closeDrawerRestore, openDrawerRestore } = useDrawerRestoreGuide();

  const {
    onClose: onCloseModalRestore,
    showModalConfirmationRestore,
    restoreNotes,
    setRestoreNotes,
    restoreType,
    onGenerateRestore,
    onOpenItemVersion,
    selectedTimeRestore,
  } = useRealmRestore({ objectName, companyName: QboContentVersions?.companyName });

  const { message } = useRelatedObjectRules({ objectName });

  return (
    <QboRightDrawer
      className="DrawerObjectContentSection__wrapper"
      titleBarClassName="DrawerObjectContentSection__title"
      size="large"
      topBar={
        <DashboardBreadcrumbsBlocks loading={loading}>
          <QboLink withTitle navigate={ClientRoutes.showRealmPath({ id: qboRealmId })}>
            {QboContentVersions?.companyName}
          </QboLink>
          <QboLink
            withTitle
            onClick={() => {
              if (isNavigateObjectName)
                navigate(ClientRoutes.showRealmObjectPath({ id: qboRealmId, objectName }));
              closeDrawer();
            }}>
            {pascalToTitleCase(objectName)}
          </QboLink>
          <QboTypography withTitle>{pascalToTitleCase(selectedVersion?.itemName)}</QboTypography>
        </DashboardBreadcrumbsBlocks>
      }
      title={
        <>
          {QboContentVersions?.isDeletedByIntuit ? (
            <InsertPageBreakIcon />
          ) : (
            <InsertDriveFileOutlinedIcon />
          )}
          <span title={pascalToTitleCase(selectedVersion?.itemName)}>
            {pascalToTitleCase(selectedVersion?.itemName)}
          </span>
        </>
      }
      open={open}
      loading={loading}
      onClose={() => {
        closeDrawer(false, objectName);
      }}>
      {QboContentVersions?.isDeletedByIntuit ? (
        <QboCardListItem className="DrawerObjectContentSection__banner-info-deleted">
          <QboTypography variant="caption" color="secondary">
            {t('dashboard_page.drawer_content.banner_info_deleted_item')}
          </QboTypography>
        </QboCardListItem>
      ) : null}
      <QboCardListItem
        className="DrawerObjectContentSection__select-version"
        rightContent={
          <div className="DrawerObjectContentSection__right-button-search">
            <QboButton
              onClick={() => {
                setSelectedRealmId(qboRealmId);
                setSelectedItemVersion(selectedVersion);
                onNextButton();
              }}
              size="large"
              startIcon={<CloudDownloadOutlinedIcon />}
              variant="outlined"
              color="primary">
              {t('button.download')}
            </QboButton>
            <QboButton
              onClick={() => {
                onOpenItemVersion(qboRealmId, selectedVersion);
              }}
              size="large"
              startIcon={<CloudSyncOutlinedIcon />}
              variant="outlined"
              color="primary"
              disabled={!QboContentVersions?.isRestorable}
              withTitle={!QboContentVersions?.isRestorable}
              title={t('dashboard_page.drawer_content.unable_to_restore_item_label')}
              hidden={!BaseSetting.qboEnableRestoreFeature}>
              {t('button.restore')}
            </QboButton>
          </div>
        }>
        <QboSelectField
          horizontal
          disabled={loadingVersions || loading}
          loading={loadingMoreVersions}
          onChange={(e) => setSelectedVersionId(e.target.value)}
          value={selectedVersion?.id || ''}
          options={versionOptions}
          label={t('dashboard_page.drawer_content.version_label')}
          startIcon={<CalendarMonthIcon />}
          onScroll={(event) => {
            if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
              onLoadMoreVersion();
            }
          }}
        />
      </QboCardListItem>

      <QboSimpleDataTable
        stickyHeader
        rows={tableRows}
        header={tableHeaders}
        loading={loading}
        fullBorder
      />

      <ModalDownloadCardBlock
        onClose={() => onClose()}
        onNextButton={() => onNextButton()}
        onBackButton={() => onBackButton()}
        onGenerateLink={() => onGenerateLink(downloadType.downloadTypeItemVersion)}
        showModalDateTime={showModalDateTime}
        showModalConfirmation={showModalConfirmation}
        selectedTime={selectedTime}
        minDate={QboObjectData?.lastCompletedBackupAt}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        dateValue={dateValue}
        setDateValue={setDateValue}
        notes={notes}
        setNotes={setNotes}
        itemSelectedText={`${objectName} - ${selectedVersion?.itemName}`}
        withoutSelectDate
      />

      <SnackbarPageSection />

      <ModalRestoreCardBlock
        onClose={() => {
          closeDrawerRestore();
          onCloseModalRestore();
        }}
        showModalConfirmation={showModalConfirmationRestore}
        itemSelectedText={`${objectName} - ${selectedVersion?.itemName}`}
        notes={restoreNotes}
        setNotes={setRestoreNotes}
        onGenerateLink={() => {
          closeDrawerRestore();
          onGenerateRestore(restoreType.restoreTypeItemVersion);
        }}
        withoutSelectDate
        selectedTime={selectedTimeRestore}
        onOpenGuide={openDrawerRestore}
        infoTips={message}>
        <DrawerRestoreGuideSection onClose={closeDrawerRestore} open={drawerRestoreIsOpen} />
      </ModalRestoreCardBlock>
    </QboRightDrawer>
  );
}

DrawerObjectContentSection.propTypes = {
  open: PropTypes.bool,
  closeDrawer: PropTypes.func,
  qboRealmId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectName: PropTypes.string,
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  versionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isNavigateObjectName: PropTypes.bool,
};

DrawerObjectContentSection.defaultProps = {
  open: false,
  closeDrawer: () => {},
  qboRealmId: '',
  objectName: '',
  objectId: '',
  versionId: null,
  isNavigateObjectName: false,
};
