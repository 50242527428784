import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboStatusBackupsAPI } from '@services/WebApiService';
import { canShowPagination } from '@utilities';

const qboFetchStatusBackupsAsync = createAsyncThunk(
  'QboStatusBackups/fetchStatusBackups',
  async ({ pageLink, query, top, skip, statuses }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboStatusBackupsAPI(accessToken, pageLink, {
      query,
      top,
      skip,
      statuses,
    });
    return data;
  }
);

const { pending, fulfilled, rejected } = qboFetchStatusBackupsAsync;

const qboFetchStatusBackupsExtraReducers = {
  [pending]: (state) => {
    state.fetchingQboStatusBackups = true;
  },
  [rejected]: (state) => {
    state.fetchingQboStatusBackups = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboStatusBackups = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.value) {
      const showPagination = canShowPagination(state.QboStatusBackups.showPagination, action);

      state.QboStatusBackups = {
        value: action.payload.value,
        nextLink: action.payload.nextLink,
        prevLink: action.payload.prevLink,
        showPagination,
      };
    }
  },
};

export { qboFetchStatusBackupsAsync, qboFetchStatusBackupsExtraReducers };
