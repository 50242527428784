import { t } from 'i18next';

const useAlertMessage = () => {
  const konstMessage = {
    // authentication alert message
    SUCCESS_QBO_REAUTHENTICATED: 'SUCCESS_QBO_REAUTHENTICATED',
    SUCCESS_QBO_ADDED: 'SUCCESS_QBO_ADDED_MESSAGE',

    ERROR_CONFIRM_QBO_ADDED_DIFFERENT_ORG: 'ERROR_CONFIRM_QBO_ADDED_DIFFERENT_ORG',
    ERROR_NEW_QBO_ADDED_DIFFERENT_ORG: 'ERROR_NEW_QBO_ADDED_DIFFERENT_ORG',
    ERROR_NEW_QBO_DEFAULT: 'ERROR_NEW_QBO_DEFAULT',
    ERROR_CONFIRM_QBO_DEFAULT: 'ERROR_CONFIRM_QBO_DEFAULT',
    ERROR_REAUTHORIZED_WRONG_REALM: 'ERROR_REAUTHORIZED_WRONG_REALM',
    ERROR_REAUTHORIZED_GENERAL_ERROR: 'ERROR_REAUTHORIZED_GENERAL_ERROR',
    ERROR_GENERAL: 'ERROR_GENERAL',

    // backup now alert
    SUCCESS_BACKUP_NOW: 'SUCCESS_BACKUP_NOW',

    // change password alert
    SUCCESS_CHANGE_PASSWORD: 'SUCCESS_CHANGE_PASSWORD',

    ERROR_CHANGE_PASSWORD_INCORRECT: 'ERROR_CHANGE_PASSWORD_INCORRECT',
    ERROR_CHANGE_PASSWORD_INVALID: 'ERROR_CHANGE_PASSWORD_INVALID',
    ERROR_CHANGE_PASSWORD_GENERAL: 'ERROR_CHANGE_PASSWORD_GENERAL',

    // download alert
    SUCCESS_DOWNLOAD: 'SUCCESS_DOWNLOAD',
    SUCCESS_BANNER_DOWNLOAD: 'SUCCESS_BANNER_DOWNLOAD',
    ERROR_DOWNLOAD_ALREADY_EXISTS: 'ERROR_DOWNLOAD_ALREADY_EXISTS',
    ERROR_DOWNLOAD_LIMITED_REACHED: 'ERROR_DOWNLOAD_LIMITED_REACHED',
    ERROR_DOWNLOAD_GENERAL: 'ERROR_DOWNLOAD_GENERAL',
    ERROR_BANNER_DOWNLOAD_ALREADY_EXISTS: 'ERROR_BANNER_DOWNLOAD_ALREADY_EXISTS',
    ERROR_BANNER_DOWNLOAD_LIMITED_REACHED: 'ERROR_BANNER_DOWNLOAD_LIMITED_REACHED',
    ERROR_BANNER_DOWNLOAD_GENERAL: 'ERROR_BANNER_DOWNLOAD_GENERAL',

    // restore alert
    SUCCESS_RESTORE: 'SUCCESS_RESTORE',
    SUCCESS_BANNER_RESTORE: 'SUCCESS_BANNER_RESTORE',
    ERROR_RESTORE_ALREADY_EXISTS: 'ERROR_RESTORE_ALREADY_EXISTS',
    ERROR_RESTORE_LIMITED_REACHED: 'ERROR_RESTORE_LIMITED_REACHED',
    ERROR_RESTORE_GENERAL: 'ERROR_RESTORE_GENERAL',
    ERROR_BANNER_RESTORE_ALREADY_EXISTS: 'ERROR_BANNER_RESTORE_ALREADY_EXISTS',
    ERROR_BANNER_RESTORE_LIMITED_REACHED: 'ERROR_BANNER_RESTORE_LIMITED_REACHED',
    ERROR_BANNER_RESTORE_GENERAL: 'ERROR_BANNER_RESTORE_GENERAL',

    // deactivate alert
    SUCCESS_DEACTIVATE: 'SUCCESS_DEACTIVATE',
    ERROR_DEACTIVATE: 'ERROR_DEACTIVATE',

    // reactivate alert
    SUCCESS_REACTIVATE: 'SUCCESS_REACTIVATE',
    ERROR_REACTIVATE: 'ERROR_REACTIVATE',
    ERROR_REACTIVATE_INCORRECT_COMPANY: 'ERROR_REACTIVATE_INCORRECT_COMPANY',

    // 2fa alert
    SUCCESS_SETUP_2FA: 'SUCCESS_SETUP_2FA',
    SUCCESS_DISABLE_2FA: 'SUCCESS_DISABLE_2FA',
    SUCCESS_RE_ENABLE_2FA: 'SUCCESS_RE_ENABLE_2FA',
    SUCCESS_RESET_2FA: 'SUCCESS_RESET_2FA',
    ERROR_SETUP_2FA: 'ERROR_SETUP_2FA',
    ERROR_DISABLE_2FA: 'ERROR_DISABLE_2FA',
    ERROR_RE_ENABLE_2FA: 'ERROR_RE_ENABLE_2FA',
    ERROR_RESET_2FA: 'ERROR_RESET_2FA',

    // NFR
    ERROR_NFR: 'ERROR_NFR',

    // replicate alert
    SUCCESS_REPLICATE: 'SUCCESS_REPLICATE',
    ERROR_REPLICATE: 'ERROR_REPLICATE',
    ERROR_REPLICATE_SAME_COMPANY: 'ERROR_REPLICATE_SAME_COMPANY',
    ERROR_REPLICATE_ON_PROGRESS: 'ERROR_REPLICATE_ON_PROGRESS',
    ERROR_REPLICATE_SUBMISSION_INCOMPLETE: 'ERROR_REPLICATE_SUBMISSION_INCOMPLETE',
    SUCCESS_REACTIVATE_REPLICATE: 'SUCCESS_REACTIVATE_REPLICATE',
    ERROR_REACTIVATE_REPLICATE: 'ERROR_REACTIVATE_REPLICATE',
  };

  const success = 'success';
  const error = 'error';

  const getSuccessMessage = (konst, payload, fallbackMessage) => {
    const defaultPayload = { type: success };

    switch (konst) {
      case konstMessage.SUCCESS_QBO_REAUTHENTICATED:
        return {
          ...defaultPayload,
          message: t('new_quickbooks_page.alert.success.reauthenticated_message', payload),
          heading: t('new_quickbooks_page.alert.success.reauthenticated_heading', payload),
        };
      case konstMessage.SUCCESS_QBO_ADDED_MESSAGE:
        return {
          ...defaultPayload,
          message: t('quickbook_backup_confirm_page.alert.success.added_message_html', payload),
        };
      case konstMessage.SUCCESS_BACKUP_NOW:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.backup_now_html', payload),
        };
      case konstMessage.SUCCESS_CHANGE_PASSWORD:
        return {
          ...defaultPayload,
          message: t('account_settings_change_password_page.alert.success_message', payload),
        };
      case konstMessage.SUCCESS_DOWNLOAD:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.download_html', payload),
        };
      case konstMessage.SUCCESS_BANNER_DOWNLOAD:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.banner_download', payload),
        };
      case konstMessage.SUCCESS_RESTORE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.restore_html', payload),
        };
      case konstMessage.SUCCESS_BANNER_RESTORE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.banner_restore', payload),
        };
      case konstMessage.SUCCESS_DEACTIVATE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.banner_deactivate', payload),
        };
      case konstMessage.SUCCESS_REACTIVATE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.banner_reactivate', payload),
        };
      case konstMessage.SUCCESS_SETUP_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.success.setup_2fa', payload),
        };
      case konstMessage.SUCCESS_DISABLE_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.success.disable_2fa', payload),
        };
      case konstMessage.SUCCESS_RE_ENABLE_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.success.re_enable_2fa', payload),
        };
      case konstMessage.SUCCESS_RESET_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.success.reset_2fa', payload),
        };
      case konstMessage.SUCCESS_REPLICATE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.success.replicate_html', payload),
        };
      case konstMessage.SUCCESS_REACTIVATE_REPLICATE:
        return {
          ...defaultPayload,
          message: t('status_replicate_page.alert.success.reactivate_replicate', payload),
        };
      default:
        return {
          ...defaultPayload,
          message: fallbackMessage,
          heading: `${konst}: ${t('alert.success_heading')}`,
        };
    }
  };

  const getErrorMessage = (konst, payload, fallbackMessage) => {
    const defaultPayload = { type: error };

    switch (konst) {
      case konstMessage.ERROR_CONFIRM_QBO_ADDED_DIFFERENT_ORG:
        return {
          ...defaultPayload,
          message: t('quickbook_backup_confirm_page.alert.error.failed_diff_org_message', payload),
          heading: t('quickbook_backup_confirm_page.alert.error.failed_heading', payload),
        };
      case konstMessage.ERROR_NEW_QBO_ADDED_DIFFERENT_ORG:
        return {
          ...defaultPayload,
          message: t('quickbook_backup_confirm_page.alert.error.failed_diff_org_message', payload),
          heading: t('new_quickbooks_page.alert.error.unable_heading_text', payload),
        };
      case konstMessage.ERROR_NEW_QBO_DEFAULT:
        return {
          ...defaultPayload,
          message: t('new_quickbooks_page.alert.error.unable_heading_text', payload),
          heading: t('alert.error_message', payload),
        };
      case konstMessage.ERROR_CONFIRM_QBO_DEFAULT:
        return {
          ...defaultPayload,
          message: t('quickbook_backup_confirm_page.alert.error.failed_heading'),
          heading: t('alert.error_message'),
        };
      case konstMessage.ERROR_REAUTHORIZED_WRONG_REALM:
        return {
          ...defaultPayload,
          message: t('new_quickbooks_page.alert.error.unable_reauthorized_message', payload),
          heading: t('new_quickbooks_page.alert.error.unable_reauthorized_heading_text', payload),
        };
      case konstMessage.ERROR_REAUTHORIZED_GENERAL_ERROR:
        return {
          ...defaultPayload,
          message: t('alert.error_message', payload),
          heading: t('new_quickbooks_page.alert.error.unable_reauthorized_heading_text', payload),
        };
      case konstMessage.ERROR_CHANGE_PASSWORD_INCORRECT:
        return {
          ...defaultPayload,
          message: t(
            'account_settings_change_password_page.alert.error.incorrect_message',
            payload
          ),
        };
      case konstMessage.ERROR_CHANGE_PASSWORD_INVALID:
        return {
          ...defaultPayload,
          message: t(
            'account_settings_change_password_page.alert.error.invalid_password_message',
            payload
          ),
        };
      case konstMessage.ERROR_CHANGE_PASSWORD_GENERAL:
        return {
          ...defaultPayload,
          message: t('account_settings_change_password_page.alert.error.general_message', payload),
        };
      case konstMessage.ERROR_GENERAL:
        return {
          ...defaultPayload,
          message: t('alert.error_message'),
        };
      case konstMessage.ERROR_DOWNLOAD_ALREADY_EXISTS:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.download_already_exists_html', payload),
        };
      case konstMessage.ERROR_DOWNLOAD_LIMITED_REACHED:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.download_limit_reached', payload),
        };
      case konstMessage.ERROR_DOWNLOAD_GENERAL:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.download_general_error', payload),
        };
      case konstMessage.ERROR_BANNER_DOWNLOAD_ALREADY_EXISTS:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_download_already_exists', payload),
        };
      case konstMessage.ERROR_BANNER_DOWNLOAD_LIMITED_REACHED:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_download_limit_reached', payload),
        };
      case konstMessage.ERROR_BANNER_DOWNLOAD_GENERAL:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_download_general_error', payload),
        };
      case konstMessage.ERROR_RESTORE_ALREADY_EXISTS:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.restore_already_exists', payload),
        };
      case konstMessage.ERROR_RESTORE_LIMITED_REACHED:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.restore_limit_reached', payload),
        };
      case konstMessage.ERROR_RESTORE_GENERAL:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.restore_general_error', payload),
        };
      case konstMessage.ERROR_BANNER_RESTORE_ALREADY_EXISTS:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_restore_already_exists', payload),
        };
      case konstMessage.ERROR_BANNER_RESTORE_LIMITED_REACHED:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_restore_limit_reached', payload),
        };
      case konstMessage.ERROR_BANNER_RESTORE_GENERAL:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_restore_general_error', payload),
        };
      case konstMessage.ERROR_DEACTIVATE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_deactivate', payload),
        };
      case konstMessage.ERROR_REACTIVATE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_reactivate', payload),
        };
      case konstMessage.ERROR_REACTIVATE_INCORRECT_COMPANY:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.banner_reactivate_incorrect_company', payload),
        };
      case konstMessage.ERROR_SETUP_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.error.setup_2fa', payload),
        };
      case konstMessage.ERROR_DISABLE_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.error.disable_2fa', payload),
        };
      case konstMessage.ERROR_RE_ENABLE_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.error.re_enable_2fa', payload),
        };
      case konstMessage.ERROR_RESET_2FA:
        return {
          ...defaultPayload,
          message: t('account_settings_setup_2fa_page.alert.error.reset_2fa', payload),
        };
      case konstMessage.ERROR_NFR:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.nfr', payload),
        };
      case konstMessage.ERROR_REPLICATE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.replicate', payload),
        };
      case konstMessage.ERROR_REPLICATE_SAME_COMPANY:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.replicate_same_company', payload),
        };
      case konstMessage.ERROR_REPLICATE_ON_PROGRESS:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.replicate_on_progress', payload),
        };
      case konstMessage.ERROR_REPLICATE_SUBMISSION_INCOMPLETE:
        return {
          ...defaultPayload,
          message: t('dashboard_page.alert.error.replicate_submission_incomplete', payload),
        };
      case konstMessage.ERROR_REACTIVATE_REPLICATE:
        return {
          ...defaultPayload,
          message: t('status_replicate_page.alert.error.reactivate_replicate', payload),
        };
      default:
        return {
          ...defaultPayload,
          message: fallbackMessage,
          heading: `${konst}: ${t('alert.error_heading')}`,
        };
    }
  };

  return { getSuccessMessage, getErrorMessage, konstMessage };
};

export default useAlertMessage;
