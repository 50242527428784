import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDateTime } from '@hooks';
import BaseSetting from '@config/BaseSetting';
import {
  fetchQboContentVersionsAsync,
  resetState,
} from '@features/qboContentVersions/qboContentVersionsSlice';

const defaultOrderColumn = 'BackupAt';
const defaultOrderMethod = 'desc';

const useFetchContentVersions = ({
  isDrawerReady,
  qboRealmId,
  objectName,
  objectId,
  versionId,
}) => {
  const { dateToString, dateFormat } = useDateTime();
  const dispatch = useDispatch();
  const [selectedVersionId, setSelectedVersionId] = useState('');
  const { isApplicationReady } = useSelector((state) => state.application);
  const { QboContentVersions, fetchingQboContentVersions, fetchingMoreVersions } = useSelector(
    (state) => state.qboContentVersions
  );

  const returnVersionId = selectedVersionId || versionId;
  const selectedVersion =
    QboContentVersions.value.find((v) => v.id === returnVersionId) || QboContentVersions.value[0];

  const versionOptions =
    QboContentVersions?.value && QboContentVersions.value.length > 0
      ? QboContentVersions.value.map((v) => [
          v.id,
          dateToString(dateFormat.DEFAULT_DATE_TIME, v.backupAt),
        ])
      : [];

  const onLoadMoreVersion = () => {
    if (QboContentVersions.nextLink && !fetchingMoreVersions) {
      dispatch(fetchQboContentVersionsAsync({ pageLink: QboContentVersions.nextLink }));
    }
  };

  useEffect(() => {
    if (isApplicationReady && isDrawerReady) {
      dispatch(
        fetchQboContentVersionsAsync({
          qboRealmId,
          objectName,
          objectId,
          top: BaseSetting.paginationPerPageDefault,
          orderBy: defaultOrderColumn,
          order: defaultOrderMethod,
        })
      );
    }

    return () => {
      dispatch(resetState());
    };
  }, [isApplicationReady, isDrawerReady]);

  return {
    QboContentVersions,
    versionOptions,
    loadingVersions: fetchingQboContentVersions,
    loadingMoreVersions: fetchingMoreVersions,
    objectId,
    onLoadMoreVersion,
    selectedVersion,
    setSelectedVersionId,
  };
};

export default useFetchContentVersions;
