import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDateTime } from '@hooks';
import ClientRoutes, { dashboardPath } from '@config/Routes/WebClientRoutes';
import {
  QboCardCaption,
  QboLink,
  QboTypography,
  QboCard,
  QboSimpleDataTable,
  QboButton,
} from '@ui/Components';
import DrawerObjectContentSection from '@ui/Sections/Shared/DrawerObjectContentSection';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import DashboardBreadcrumbsBlocks from '@ui/Blocks/Shared/DashboardBreadcrumbsBlock';
import SearchCardBlock from '@ui/Blocks/Shared/CardBlocks/SearchCardBlock';
import PaginationCardBlock from '@ui/Blocks/Shared/CardBlocks/PaginationCardBlock';
import useDrawerContent from '@pages/sharedHooks/useDrawerContent';
import NoMatchKeywordSVG from '@images/generals/NoMatchKeyword.svg';
import useSearchItemSuggestion from '@pages/sharedHooks/useSearchItemSuggestion';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import useRealmDownload from '@pages/sharedHooks/useRealmDownload';
import ModalDownloadCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalDownloadCardBlock';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import useRealmRestore from '@pages/sharedHooks/useRealmRestore';
import ModalRestoreCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalRestoreCardBlock';
import useDrawerRestoreGuide from '@pages/DashboardPage/pageHooks/useDrawerRestoreGuide';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import DrawerRestoreGuideSection from '@ui/Sections/DashboardPage/DrawerRestoreGuideSection';
import BackupNowButtonBlock from '@ui/Blocks/Shared/BackupNowButtonBlock';
import { qboBackupNowRealmAsync } from '@features/qboObjectItems/qboObjectItemsSlice';
import useStatusItemsSearchFilter, {
  ButtonFilter,
} from '@pages/SearchRealmItemPage/pageHooks/useStatusItemsSearchFilter';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import FilterCardBlock from '@ui/Blocks/Shared/CardBlocks/FilterCardBlock';
import useSearchObjectItems from './pageHooks/useSearchObjectItems';

export default function SearchRealmItemPage() {
  const { t } = useTranslation();
  const { dateFormat, dateToString } = useDateTime();

  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.HOME_MENU);

  const {
    objectName,
    objectId,
    versionId,
    openDrawer,
    setOpenDrawer,
    onOpenDrawer,
    qboRealmId,
    navigateObjectName,
  } = useDrawerContent();

  const {
    QboObjectItems,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableHeaders,
    tableRows,
    loading,
    searchResultLabel,
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    pageNumber,
    setSearchKeyword,
    open,
    setOpen,
    replaceSearchParams,
    listIdOnPage,
    onFilter,
  } = useSearchObjectItems();

  const {
    loadingSuggestion,
    searchKeyword: searchKeywordSuggestion,
    keyGroupBy,
    keyLabelOption,
    suggestions,
    setSearchKeyword: setSearchKeywordSuggestion,
    onSelectItem,
  } = useSearchItemSuggestion(onOpenDrawer);

  const { selected, setSelected, listSortOptions, selectedDropdown, onDropdownSelect } =
    useCheckboxTable({ listIdOnPage });

  const {
    showModalDateTime,
    showModalConfirmation,
    onOpen,
    onClose,
    onNextButton,
    onBackButton,
    setSelectedRealmId,
    timeValue,
    setTimeValue,
    dateValue,
    setDateValue,
    notes,
    setNotes,
    selectedTime,
    onGenerateLink,
    downloadType,
    getMinDate,
  } = useRealmDownload({
    selectedListId: selected,
    objectName,
    searchKeyword: searchKeywordSuggestion || searchResultLabel,
    companyName: QboObjectItems?.companyName,
  });

  const { drawerRestoreIsOpen, openDrawerRestore, closeDrawerRestore } = useDrawerRestoreGuide();

  const {
    showModalRestore,
    onOpen: onOpenModalRestore,
    onClose: onCloseModalRestore,
    timeValue: timeValueRestore,
    setTimeValue: setTimeValueRestore,
    dateValue: dateValueRestore,
    setDateValue: setDateValueRestore,
    selectedTimeRestore,
    onNextButtonRestore,
    showModalConfirmationRestore,
    onBackButtonRestore,
    selectedRestoreData,
    restoreNotes,
    setRestoreNotes,
    restoreType,
    onGenerateRestore,
  } = useRealmRestore({
    selectedListId: selected,
    objectName,
    searchKeyword: searchKeywordSuggestion || searchResultLabel,
    companyName: QboObjectItems?.companyName,
  });

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusItemsSearchFilter(onFilter);

  const renderContent = () => {
    if (!loading && searchResultLabel.length > 0 && QboObjectItems.value.length === 0) {
      return (
        <QboCardCaption
          className="SearchRealmItemPage__no-result"
          imageUrl={NoMatchKeywordSVG}
          heading={t('search_realm_object_item_page.card_not_found.no_match_heading')}
          caption={t('search_realm_object_item_page.card_not_found.no_match_caption')}
        />
      );
    }

    return (
      <>
        <QboSimpleDataTable
          rows={tableRows}
          header={tableHeaders}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          loading={loading}
          onClickRow={(row) => {
            if (row?.lastVersionId) {
              onOpenDrawer(row.lastVersionId, row.objectName, row.maskedId, false, true);
            }
          }}
          selected={selected}
          setSelected={setSelected}
          listSortOptions={listSortOptions}
          selectedDropdown={selectedDropdown}
          onDropdownSelect={onDropdownSelect}
          withCheckboxSelection
        />

        <PaginationCardBlock
          className="SearchRealmItemPage__pagination"
          hasNextLink={QboObjectItems.nextLink}
          hasPrevLink={QboObjectItems.prevLink}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          showPagination={QboObjectItems.showPagination}
          paginationLabel={t('pagination.lists_per_page_label')}
        />
      </>
    );
  };

  return (
    <div className="SearchRealmItemPage__wrapper">
      <div className="SearchRealmItemPage__header-block">
        <HeaderPageBlock
          loading={loading}
          navigate={dashboardPath}
          linkNavigateText={t('navigate.back_to_dashboard')}
          caption={
            QboObjectItems?.lastCompletedBackupAt
              ? t('search_realm_object_item_page.header.last_backup_label', {
                  date: dateToString(
                    dateFormat.DEFAULT_DATE_TIME,
                    QboObjectItems?.lastCompletedBackupAt
                  ),
                })
              : ' '
          }>
          {QboObjectItems?.companyName}
        </HeaderPageBlock>

        <BackupNowButtonBlock
          id={qboRealmId}
          status={QboObjectItems?.backupStatus}
          action={qboBackupNowRealmAsync}
          companyName={QboObjectItems?.companyName}
          loading={loading}
        />
      </div>

      <AlertPageSection />

      <QboCard noPadding isMainWrapper>
        <DashboardBreadcrumbsBlocks loading={loading} anyChildren={QboObjectItems?.companyName}>
          <QboLink withTitle navigate={ClientRoutes.showRealmPath({ id: qboRealmId })}>
            {QboObjectItems?.companyName}
          </QboLink>
          <QboTypography withTitle>
            {t('search_realm_object_item_page.breadcrumbs.search_result_label')}
          </QboTypography>
        </DashboardBreadcrumbsBlocks>

        <SearchCardBlock
          className="SearchRealmItemPage__search-wrapper"
          loading={loadingSuggestion}
          defaultSearchValue={searchResultLabel}
          searchKeyword={searchKeywordSuggestion}
          setSearchKeyword={setSearchKeywordSuggestion}
          keyGroupBy={keyGroupBy}
          keyLabelOption={keyLabelOption}
          options={suggestions}
          compact
          autocomplete
          withFilterButton
          placeholder={t('search_realm_object_item_page.search_block.search_placeholder')}
          onSelectItem={onSelectItem}
          onClickAllResult={() => {
            setSearchKeyword(searchKeywordSuggestion);
            setOpen(false);
            replaceSearchParams(searchKeywordSuggestion);
            handleFilter('');
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          clearOnEscape={false}
          clearOnBlur={false}
          forceShowClearIcon
          rightContent={
            <div className="SearchRealmItemPage__right-button-search">
              <QboButton
                onClick={() => {
                  setSelectedRealmId(qboRealmId);
                  onOpen();
                }}
                size="large"
                startIcon={<CloudDownloadOutlinedIcon />}
                variant="outlined"
                color="primary"
                disabled={selected.length < 1}>
                {t('button.download')}
              </QboButton>
              <QboButton
                onClick={() => {
                  onOpenModalRestore(qboRealmId);
                }}
                size="large"
                startIcon={<CloudSyncOutlinedIcon />}
                variant="outlined"
                color="primary"
                disabled={selected.length < 1}
                hidden>
                {t('button.restore')}
              </QboButton>
            </div>
          }
          sortFilterPosition="left"
          setShowDrawer={handleOpenDrawer}
          showBadgeFilter={listBadges.length > 0}
        />

        <FilterCardBlock filterData={listBadges} handleDelete={handleDelete} />

        {renderContent()}
      </QboCard>

      <DrawerObjectContentSection
        versionId={versionId}
        objectName={objectName}
        objectId={objectId}
        qboRealmId={qboRealmId}
        open={openDrawer}
        closeDrawer={() => {
          setOpenDrawer(false);
        }}
        isNavigateObjectName={navigateObjectName}
      />

      <ModalDownloadCardBlock
        onClose={() => onClose()}
        onNextButton={() => onNextButton()}
        onBackButton={() => onBackButton()}
        onGenerateLink={() => onGenerateLink(downloadType.downloadTypeItems)}
        showModalDateTime={showModalDateTime}
        showModalConfirmation={showModalConfirmation}
        selectedTime={selectedTime}
        minDate={getMinDate(QboObjectItems?.value, QboObjectItems?.initialBackupAt)}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        dateValue={dateValue}
        setDateValue={setDateValue}
        notes={notes}
        setNotes={setNotes}
        itemSelectedText={t('dashboard_page.modal_download.item_value_text_items_search')}
      />

      <ModalRestoreCardBlock
        showModalRestore={showModalRestore}
        onClose={onCloseModalRestore}
        timeValue={timeValueRestore}
        setTimeValue={setTimeValueRestore}
        dateValue={dateValueRestore}
        setDateValue={setDateValueRestore}
        selectedTime={selectedTimeRestore}
        onOpenGuide={openDrawerRestore}
        onNextButton={onNextButtonRestore}
        showModalConfirmation={showModalConfirmationRestore}
        onBackButton={onBackButtonRestore}
        minDate={selectedRestoreData?.createdAt}
        itemSelectedText={t('dashboard_page.modal_restore.item_value_text_items', {
          row_selected: selected?.length,
        })}
        notes={restoreNotes}
        setNotes={setRestoreNotes}
        onGenerateLink={() => onGenerateRestore(restoreType.restoreTypeItems)}
      />

      <DrawerFilterSection
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleFilter={handleFilter}
        ButtonFilter={ButtonFilter}
        filterData={filterData}
      />

      <DrawerRestoreGuideSection onClose={closeDrawerRestore} open={drawerRestoreIsOpen} />
    </div>
  );
}
