import React from 'react';
import QboLogo from '@ui/Components/QboLogo';
import PropTypes from 'prop-types';

export default function MenuLeftBlock({ logo }) {
  return <QboLogo logo={logo} />;
}

MenuLeftBlock.propTypes = {
  logo: PropTypes.string,
};
MenuLeftBlock.defaultProps = {
  logo: null,
};
