import BaseSetting from '@config/BaseSetting';
import Routes from './Routes';

/**
 * Contains web api urls
 * */

class WebApiRoutes extends Routes {
  //
  // routes
  // endpoint defined here
  // format: [url_name]Path
  //
  authenticationPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/authenticate', params, format);
  }

  refreshTokenPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/refresh-token', params, format);
  }

  logoutPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/logout', params, format);
  }

  qboGenerateOauthPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-auth/generate-oauth', params, format);
  }

  qboOauthCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-auth/confirm', params, format);
  }

  qboReactivateCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms/:qboRealmId/reactivate', params, format);
  }

  qboDeactivateCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms/:realmId/deactivate', params, format);
  }

  qboStartBackupPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms', params, format);
  }

  qboFetchRealmsPath(params = {}, format = '') {
    // alias return
    return this.qboStartBackupPath(params, format);
  }

  qboFetchObjectsPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms/:qboRealmId/objects', params, format);
  }

  qboFetchObjectItemsPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms/:qboRealmId/items', params, format);
  }

  qboSearchObjectItemsPath(params = {}, format = '') {
    // alias return
    return this.qboFetchObjectItemsPath(params, format);
  }

  orgSeatUsagePath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/usages', params, format);
  }

  qboRealmBackupStatusPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms/:qboRealmId/backup-status', params, format);
  }

  qboFetchObjectVersionPath(params = {}, format = '') {
    return this.createUrl(
      '/public/api/v1/qbo-realms/:qboRealmId/items/:objectName/:objectId/:versionId',
      params,
      format
    );
  }

  qboFetchObjectVersionsPath(params = {}, format = '') {
    return this.createUrl(
      '/public/api/v1/qbo-realms/:qboRealmId/items/:objectName/:objectId',
      params,
      format
    );
  }

  qboFetchStatusBackupsPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-backups', params, format);
  }

  qboBackupStatusPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-backups/:qboBackupId', params, format);
  }

  qboBackupNowPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-realms/:qboRealmId/backup-now', params, format);
  }

  passwordAccountPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/password', params, format);
  }

  qboFetchStatus2FAPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/2fa', params, format);
  }

  qboFetchKey2FAPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/2fa/generate-key', params, format);
  }

  qboValidateAuthForm2FAPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/accounts/2fa/:type', params, format);
  }

  qboDownloadCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-downloads/:qboRealmId', params, format);
  }

  qboDownloadDetailtCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-downloads/:qboRealmId/:type', params, format);
  }

  qboFetchStatusDownloadPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-downloads', params, format);
  }

  qboDownloadStatusPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-downloads/:qboDownloadId', params, format);
  }

  qboDownloadUrlPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-downloads/:qboDownloadId/download', params, format);
  }

  qboRestoreCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-restores/:qboRealmId', params, format);
  }

  qboRestoreDetailtCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-restores/:qboRealmId/:type', params, format);
  }

  qboFetchStatusRestorePath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-restores', params, format);
  }

  qboRestoreStatusPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-restores/:qboRestoreId', params, format);
  }

  qboDownloadErrorMessageUrlPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/:type/:maskedQboId/download-error', params, format);
  }

  qboOauthReplicateCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-auth/replicate-auth', params, format);
  }

  qboOauthReplicateReAuthenticationCallbackPath(params = {}, format = '') {
    return this.createUrl(
      '/public/api/v1/qbo-replicates/:qboReplicateId/reactivate',
      params,
      format
    );
  }

  qboReplicateCallbackPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-replicates', params, format);
  }

  qboFetchStatusReplicatePath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-replicates', params, format);
  }

  qboReplicateStatusPath(params = {}, format = '') {
    return this.createUrl('/public/api/v1/qbo-replicates/:qboReplicateId', params, format);
  }
}

const ApiRoutes = new WebApiRoutes(BaseSetting.qboWebappApiHost);

export const authenticationPath = ApiRoutes.authenticationPath();
export const logoutPath = ApiRoutes.logoutPath();
export const orgSeatUsagePath = ApiRoutes.orgSeatUsagePath();
export const passwordAccountPath = ApiRoutes.passwordAccountPath();
export const qboFetchStatus2FAPath = ApiRoutes.qboFetchStatus2FAPath();
export const qboFetchKey2FAPath = ApiRoutes.qboFetchKey2FAPath();
export const qboValidateAuthForm2FAPath = ApiRoutes.qboValidateAuthForm2FAPath();
export const qboGenerateOauthPath = ApiRoutes.qboGenerateOauthPath();
export const qboStartBackupPath = ApiRoutes.qboStartBackupPath();
export const qboFetchRealmsPath = ApiRoutes.qboFetchRealmsPath();
export const qboFetchObjectsPath = ApiRoutes.qboFetchObjectsPath();
export const qboFetchObjectItemsPath = ApiRoutes.qboFetchObjectItemsPath();
export const qboSearchObjectItemsPath = ApiRoutes.qboSearchObjectItemsPath();
export const qboFetchObjectVersionPath = ApiRoutes.qboFetchObjectVersionPath();
export const qboFetchObjectVersionsPath = ApiRoutes.qboFetchObjectVersionsPath();
export const qboFetchStatusBackupsPath = ApiRoutes.qboFetchStatusBackupsPath();
export const qboFetchStatusDownloadPath = ApiRoutes.qboFetchStatusDownloadPath();
export const qboBackupStatusPath = ApiRoutes.qboBackupStatusPath();
export const qboDownloadStatusPath = ApiRoutes.qboDownloadStatusPath();
export const qboBackupNowPath = ApiRoutes.qboBackupNowPath();
export const refreshTokenPath = ApiRoutes.refreshTokenPath();
export const qboDownloadCallbackPath = ApiRoutes.qboDownloadCallbackPath();
export const qboDownloadDetailtCallbackPath = ApiRoutes.qboDownloadDetailtCallbackPath();
export const qboRestoreCallbackPath = ApiRoutes.qboRestoreCallbackPath();
export const qboRestoreDetailtCallbackPath = ApiRoutes.qboRestoreDetailtCallbackPath();
export const qboDownloadUrlPath = ApiRoutes.qboDownloadUrlPath();
export const qboDownloadErrorMessageUrlPath = ApiRoutes.qboDownloadErrorMessageUrlPath();
export const qboReplicateCallbackPath = ApiRoutes.qboReplicateCallbackPath();
export const qboFetchStatusReplicatePath = ApiRoutes.qboFetchStatusReplicatePath();
export const qboReplicateStatusPath = ApiRoutes.qboReplicateStatusPath();

export default ApiRoutes;
