import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDateTime } from '@hooks';
import { QboTypography, QboCard, QboSimpleDataTable, QboButton } from '@ui/Components';
import ClientRoutes, { dashboardPath } from '@config/Routes/WebClientRoutes';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import DrawerObjectContentSection from '@ui/Sections/Shared/DrawerObjectContentSection';
import DashboardBreadcrumbsBlocks from '@ui/Blocks/Shared/DashboardBreadcrumbsBlock';
import SearchCardBlock from '@ui/Blocks/Shared/CardBlocks/SearchCardBlock';
import { noSpace } from '@utilities';
import useSearchItemSuggestion from '@pages/sharedHooks/useSearchItemSuggestion';
import useDrawerContent from '@pages/sharedHooks/useDrawerContent';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import BackupNowButtonBlock from '@ui/Blocks/Shared/BackupNowButtonBlock';
import { qboBackupNowRealmAsync } from '@features/qboObjects/qboObjectsSlice';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import useRealmDownload from '@pages/sharedHooks/useRealmDownload';
import ModalDownloadCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalDownloadCardBlock';
import useRealmRestore from '@pages/sharedHooks/useRealmRestore';
import ModalRestoreCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalRestoreCardBlock';
import useDrawerRestoreGuide from '@pages/DashboardPage/pageHooks/useDrawerRestoreGuide';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import DrawerRestoreGuideSection from '@ui/Sections/DashboardPage/DrawerRestoreGuideSection';
import useFetchObjects from './pageHooks/useFetchObjects';

export default function BackupRealmObjectPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { dateFormat, dateToString } = useDateTime();
  const {
    QboObjects,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableHeaders,
    tableRows,
    loading,
    listIdOnPage,
  } = useFetchObjects();

  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.HOME_MENU);

  const {
    objectName,
    objectId,
    versionId,
    openDrawer,
    setOpenDrawer,
    onOpenDrawer,
    qboRealmId,
    navigateObjectName,
  } = useDrawerContent();

  const {
    loadingSuggestion,
    searchKeyword,
    keyGroupBy,
    keyLabelOption,
    suggestions,
    setSearchKeyword,
    onSelectItem,
  } = useSearchItemSuggestion(onOpenDrawer);

  const {
    selected,
    setSelected,
    listSortOptions,
    selectedDropdown,
    onDropdownSelect,
    totalSelected,
  } = useCheckboxTable({
    listIdOnPage,
    totalRow: QboObjects?.value?.length,
  });

  const {
    showModalDateTime,
    showModalConfirmation,
    onOpen,
    onClose,
    onNextButton,
    onBackButton,
    setSelectedRealmId,
    timeValue,
    setTimeValue,
    dateValue,
    setDateValue,
    notes,
    setNotes,
    selectedTime,
    onGenerateLink,
    QboObjects: QboObjectData,
    downloadType,
    getMinDate,
  } = useRealmDownload({ selectedListId: selected, companyName: QboObjects?.companyName });

  const { drawerRestoreIsOpen, openDrawerRestore, closeDrawerRestore } = useDrawerRestoreGuide();

  const {
    showModalRestore,
    onOpen: onOpenModalRestore,
    onClose: onCloseModalRestore,
    timeValue: timeValueRestore,
    setTimeValue: setTimeValueRestore,
    dateValue: dateValueRestore,
    setDateValue: setDateValueRestore,
    selectedTimeRestore,
    onNextButtonRestore,
    showModalConfirmationRestore,
    onBackButtonRestore,
    selectedRestoreData,
    restoreNotes,
    setRestoreNotes,
    restoreType,
    onGenerateRestore,
  } = useRealmRestore({ selectedListId: selected, companyName: QboObjects?.companyName });

  return (
    <div className="BackupRealmObjectPage__wrapper">
      <div className="BackupRealmObjectPage__header-block">
        <HeaderPageBlock
          loading={loading}
          navigate={dashboardPath}
          linkNavigateText={t('navigate.back_to_dashboard')}
          caption={
            QboObjects?.lastCompletedBackupAt
              ? t('backup_realm_page.header.last_backup_label', {
                  date: dateToString(
                    dateFormat.DEFAULT_DATE_TIME,
                    QboObjects?.lastCompletedBackupAt
                  ),
                })
              : ' '
          }>
          {QboObjects.companyName}
        </HeaderPageBlock>

        <BackupNowButtonBlock
          id={qboRealmId}
          status={QboObjects?.backupStatus}
          action={qboBackupNowRealmAsync}
          companyName={QboObjects?.companyName}
          loading={loading}
        />
      </div>

      <AlertPageSection />

      <QboCard noPadding isMainWrapper>
        <DashboardBreadcrumbsBlocks loading={loading} anyChildren={QboObjects?.companyName}>
          <QboTypography withTitle>{QboObjects?.companyName}</QboTypography>
        </DashboardBreadcrumbsBlocks>

        <SearchCardBlock
          loading={loadingSuggestion}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          keyGroupBy={keyGroupBy}
          keyLabelOption={keyLabelOption}
          options={suggestions}
          compact
          autocomplete
          withFilterButton={false}
          placeholder={t('backup_realm_page.search_block.search_placeholder')}
          onSelectItem={onSelectItem}
          onClickAllResult={() => {
            navigate(
              ClientRoutes.searchRealmObjectItemPath({ id: qboRealmId, keyword: searchKeyword })
            );
          }}
          clearOnEscape={false}
          clearOnBlur={false}
          forceShowClearIcon
          rightContent={
            <div className="BackupRealmObjectPage__right-button-search">
              <QboButton
                onClick={() => {
                  setSelectedRealmId(id);
                  onOpen();
                }}
                size="large"
                startIcon={<CloudDownloadOutlinedIcon />}
                variant="outlined"
                color="primary"
                disabled={selected.length < 1}>
                {t('button.download')}
              </QboButton>
              <QboButton
                onClick={() => {
                  onOpenModalRestore(id);
                }}
                size="large"
                startIcon={<CloudSyncOutlinedIcon />}
                variant="outlined"
                color="primary"
                disabled={selected.length < 1}
                hidden>
                {t('button.restore')}
              </QboButton>
            </div>
          }
        />

        <QboSimpleDataTable
          rows={tableRows}
          header={tableHeaders}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          loading={loading}
          onClickRow={(row) => {
            navigate(
              ClientRoutes.showRealmObjectPath({
                id,
                objectName: noSpace(row?.objectName),
              })
            );
          }}
          selected={selected}
          setSelected={setSelected}
          listSortOptions={listSortOptions}
          selectedDropdown={selectedDropdown}
          onDropdownSelect={onDropdownSelect}
          withCheckboxSelection
        />
      </QboCard>

      <DrawerObjectContentSection
        versionId={versionId}
        objectName={objectName}
        objectId={objectId}
        qboRealmId={qboRealmId}
        open={openDrawer}
        isNavigateObjectName={navigateObjectName}
        closeDrawer={() => {
          setOpenDrawer(false);
        }}
      />

      <ModalDownloadCardBlock
        onClose={() => onClose()}
        onNextButton={() => onNextButton()}
        onBackButton={() => onBackButton()}
        onGenerateLink={() => onGenerateLink(downloadType.downloadTypeObject)}
        showModalDateTime={showModalDateTime}
        showModalConfirmation={showModalConfirmation}
        selectedTime={selectedTime}
        minDate={getMinDate(QboObjectData?.value)}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        dateValue={dateValue}
        setDateValue={setDateValue}
        notes={notes}
        setNotes={setNotes}
        itemSelectedText={t('dashboard_page.modal_download.item_value_text_object', {
          row_selected: totalSelected,
        })}
      />

      <ModalRestoreCardBlock
        showModalRestore={showModalRestore}
        onClose={onCloseModalRestore}
        timeValue={timeValueRestore}
        setTimeValue={setTimeValueRestore}
        dateValue={dateValueRestore}
        setDateValue={setDateValueRestore}
        selectedTime={selectedTimeRestore}
        onOpenGuide={openDrawerRestore}
        onNextButton={onNextButtonRestore}
        showModalConfirmation={showModalConfirmationRestore}
        onBackButton={onBackButtonRestore}
        minDate={selectedRestoreData?.createdAt}
        itemSelectedText={t('dashboard_page.modal_restore.item_value_text_object', {
          row_selected: selected?.length,
        })}
        notes={restoreNotes}
        setNotes={setRestoreNotes}
        onGenerateLink={() => onGenerateRestore(restoreType.restoreTypeObject)}
      />

      <DrawerRestoreGuideSection onClose={closeDrawerRestore} open={drawerRestoreIsOpen} />
    </div>
  );
}
