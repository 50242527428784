import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export default function QboBadge({
  sx,
  variant,
  withInfoIcon,
  color,
  label,
  size,
  deleteIcon,
  onDelete,
  className,
  ...rest
}) {
  const styles = {
    textTransform: 'inherit',
  };

  return (
    <Box className={`QboBadge__default ${className}`}>
      <Chip
        sx={{
          ...styles,
          ...sx,
        }}
        {...rest}
        color={color || 'default'}
        variant={variant}
        deleteIcon={deleteIcon}
        label={label}
        size={size}
        onDelete={onDelete()}
        className={color ? `QboBadge__wrapper QboBadge__${color}-variant` : ''}
      />

      {withInfoIcon && <InfoIcon className="QboBadge__info-icon" />}
    </Box>
  );
}

QboBadge.propTypes = {
  sx: PropTypes.object,
  withInfoIcon: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  deleteIcon: PropTypes.node,
  onDelete: PropTypes.func,
  className: PropTypes.string,
};

QboBadge.defaultProps = {
  sx: {},
  withInfoIcon: false,
  color: 'default',
  label: '',
  variant: 'filled',
  size: 'small',
  deleteIcon: null,
  onDelete: () => {},
  className: '',
};
