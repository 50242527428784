import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboModal, QboTypography, QboButton } from '@ui/Components';
import Box from '@mui/material/Box';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import BaseSetting from '@config/BaseSetting';
import { convertSize } from '@utilities';

export default function ModalStatusDownloadCardBlock({
  onClose,
  onClickLoadMore,
  onClickDownload,
  showModal,
  filenames,
  totalFiles,
}) {
  const { t } = useTranslation();
  const listDownloadElements = [];

  const renderBodyModal = () => {
    return (
      <Box className="ModalDownloadStatus__wrapper">
        <QboTypography>
          {t('status_download_page.modal_download.helper_modal', {
            size: convertSize(BaseSetting.qboSizeDownloadLimit, 'GB'),
          })}
        </QboTypography>
        <Box className="ModalDownloadStatus__download-list-wrapper">
          {filenames.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Box className="ModalDownloadStatus__content" key={index}>
                <Box className="ModalDownloadStatus__content-left">
                  <QboTypography>
                    {t('status_download_page.modal_download.part_label', {
                      number: index + 1,
                    })}
                  </QboTypography>
                  <QboTypography withTitle>{item}</QboTypography>
                </Box>
                <QboButton
                  startIcon={<CloudDownloadOutlinedIcon />}
                  variant="contained"
                  onClick={() => onClickDownload(index)}>
                  {t('button.download')}
                </QboButton>
              </Box>
            );
          })}
          {listDownloadElements}
        </Box>
      </Box>
    );
  };

  return (
    <QboModal
      onClose={() => onClose()}
      open={showModal}
      title={t('status_download_page.modal_download.title_modal')}
      button={
        filenames?.length >= 10 && filenames?.length !== totalFiles
          ? {
              text: t('status_download_page.modal_download.load_more_button'),
              onClick: () => onClickLoadMore(),
              variant: 'text',
              position: 'left',
              noPadding: true,
            }
          : ''
      }
      preventOutsideClick>
      {renderBodyModal('confirmation')}
    </QboModal>
  );
}

ModalStatusDownloadCardBlock.propTypes = {
  onClose: PropTypes.func,
  onClickLoadMore: PropTypes.func,
  onClickDownload: PropTypes.func,
  showModal: PropTypes.bool,
  filenames: PropTypes.array,
  totalFiles: PropTypes.number,
};

ModalStatusDownloadCardBlock.defaultProps = {
  onClose: () => {},
  onClickLoadMore: () => {},
  onClickDownload: () => {},
  showModal: false,
  filenames: [],
  totalFiles: 0,
};
