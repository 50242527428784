import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function QboCheckbox({ className, checked, onChange, label, ...rest }) {
  return (
    <FormGroup
      className={`QboCheckbox__wrapper ${
        label === '' && 'QboCheckbox__without-label'
      } ${className}`}>
      <FormControlLabel
        control={<Checkbox {...rest} checked={checked} onChange={onChange} />}
        label={label}
      />
    </FormGroup>
  );
}

QboCheckbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

QboCheckbox.defaultProps = {
  className: '',
  onChange: () => {},
  checked: false,
  label: '',
};
