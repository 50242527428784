//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboStartBackupAsync,
  qboStartBackupExtraReducers,
} from './serviceActions/startBackupAsync';

const initialState = {
  error: null,
  QboAuthRequestId: null,
  QboRequestCommit: null,
  isExist: true,
  isSameOrganization: false,
  confirmation: {
    companyName: '',
    backupInterval: 0,
    backupObjects: [],
  },
};

const qboOauthSlice = createSlice({
  name: 'QboOauth',
  initialState,
  reducers: {
    setQboOauthResponse(state, action) {
      const { QboAuthRequestId, isExist, isSameOrganization, confirmation } = action.payload;

      state.QboAuthRequestId = QboAuthRequestId;
      state.isExist = isExist;
      state.isSameOrganization = isSameOrganization;
      state.confirmation = confirmation;
    },
    resetQboRequestCommit(state) {
      state.QboRequestCommit = null;
      state.QboAuthRequestId = null;
      state.error = null;
    },
    resetQboConfirmation(state) {
      state.isExist = true;
      state.isSameOrganization = false;
      state.confirmation = initialState.confirmation;
    },
  },
  extraReducers: {
    ...qboStartBackupExtraReducers,
  },
});

export const { setQboOauthResponse, resetQboConfirmation, resetQboRequestCommit } =
  qboOauthSlice.actions;
export { qboStartBackupAsync };

export default qboOauthSlice.reducer;
