import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboObjectsAPI } from '@services/WebApiService';

const qboFetchObjectsAsync = createAsyncThunk(
  'QboObjects/fetchQboObjects',
  async ({ qboRealmId, pageLink, order, orderBy, top, skip }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboObjectsAPI(accessToken, qboRealmId, pageLink, {
      top,
      skip,
      order,
      orderBy,
    });
    return data;
  }
);

const { pending, fulfilled, rejected } = qboFetchObjectsAsync;

const qboFetchObjectsExtraReducers = {
  [pending]: (state) => {
    state.fetchingQboObjects = true;
  },
  [rejected]: (state) => {
    state.fetchingQboObjects = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboObjects = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.value && action.payload.value.length > 0) {
      const { companyName, backupStatus, lastCompletedBackupAt, value, nextLink, prevLink } =
        action.payload;

      state.QboObjects = {
        companyName,
        backupStatus,
        lastCompletedBackupAt,
        value,
        nextLink,
        prevLink,
      };
    }
  },
};

export { qboFetchObjectsAsync, qboFetchObjectsExtraReducers };
