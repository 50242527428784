import React from 'react';
import PropTypes from 'prop-types';
import { QboTypography } from '@ui/Components';
import { Box } from '@mui/material';

export default function QboCardCaption({ children, sx, className, imageUrl, heading, caption }) {
  return (
    <Box className={`QboCardCaption__wrapper ${className}`} sx={sx}>
      {imageUrl && <img src={imageUrl} alt="" className="Img__fluid QboCardCaption__img" />}
      {children}
      {heading && <QboTypography variant="h4">{heading}</QboTypography>}
      {caption && <QboTypography>{caption}</QboTypography>}
    </Box>
  );
}

QboCardCaption.propTypes = {
  sx: PropTypes.object,
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  heading: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

QboCardCaption.defaultProps = {
  sx: null,
  className: '',
  imageUrl: null,
  heading: null,
  caption: null,
  children: '',
};
