import { useState } from 'react';
import { useSelector } from 'react-redux';
import { qboDownloadURLToolsAsync, resetState } from '@features/QboRealmTools/QboRealmToolsSlice';
import { loadMoreArray } from '@utilities/entryUtils';
import useDownload from '@pages/sharedHooks/useDownload';

const minArray = 10;

const useModalStatusDownload = () => {
  const { QboStatusDownload } = useSelector((state) => state.qboStatusDownload);
  const { downloadUrl } = useSelector((state) => state.qboRealmsTools);
  const [selectedDownloadId, setSelectedDownloadId] = useState(null);
  const [indexLoadMore, setIndexLoadMore] = useState(0);
  const { handleDownload } = useDownload(downloadUrl, qboDownloadURLToolsAsync, resetState);

  const showModal = selectedDownloadId !== null;
  const QboStatusDownloadItem = QboStatusDownload.value.find(
    (v) => v.maskedId === selectedDownloadId
  );

  const onOpen = (maskedId) => {
    if (QboStatusDownload.value.find((v) => v.maskedId === maskedId).filenames?.length > 1) {
      setSelectedDownloadId(maskedId);
    } else {
      handleDownload({ qboDownloadId: maskedId, indexNumber: '0' });
    }
  };

  const onClose = () => {
    setSelectedDownloadId(null);
    setIndexLoadMore(0);
  };

  const onClickDownload = (indexNumber) => {
    const payload = {
      qboDownloadId: selectedDownloadId,
      indexNumber: `${indexNumber}`,
    };
    handleDownload(payload);
  };

  const filenames =
    QboStatusDownloadItem?.filenames?.length > 0
      ? loadMoreArray(QboStatusDownloadItem?.filenames, minArray, indexLoadMore)
      : [];

  return {
    onOpen,
    showModal,
    onClose,
    onClickDownload,
    filenames,
    onClickLoadMore: () => {
      const newIndex = indexLoadMore + 1;
      setIndexLoadMore(newIndex);
    },
    totalFiles: QboStatusDownloadItem?.filenames?.length,
  };
};

export default useModalStatusDownload;
