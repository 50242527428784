import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboBadge, QboCardListItem, QboTypography } from '@ui/Components';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function FilterCardBlock({ filterData, handleDelete }) {
  const { t } = useTranslation();

  if (filterData.length < 1) return null;

  return (
    <QboCardListItem
      key="filter-badges"
      className="FilterToolbar__wrapper"
      justifyContent="center"
      alignItems="center"
      leftContent={
        <div className="FilterToolbar__left-content">
          <QboTypography>
            {t('dashboard_page.search_block.filter_text_active_filters_badge')}
          </QboTypography>
          <Box className="FilterToolbar__chip-wrapper">
            {filterData.map((item, index) => {
              return (
                <QboBadge
                  className="QboBadge__chip-filter"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}-${item?.status}`}
                  deleteIcon={<CloseIcon />}
                  label={
                    item?.labelPrefix
                      ? `${t(item?.labelPrefix)} ${t(item?.label)}`
                      : `${t('system_status.badge.prefix')} ${t(item?.label)}`
                  }
                  onDelete={() => handleDelete(item.status)}
                />
              );
            })}
          </Box>
        </div>
      }
    />
  );
}

FilterCardBlock.propTypes = {
  filterData: PropTypes.array,
  handleDelete: PropTypes.func,
};

FilterCardBlock.defaultProps = {
  filterData: [],
  handleDelete: () => {},
};
