import BaseSetting from '@config/BaseSetting';

export default function canShowPagination(showPaginationState, action) {
  if (!action?.payload?.value?.length) return false;
  if (
    action?.payload?.value?.length < BaseSetting.minimumShowPagination &&
    !action?.payload?.prevLink
  )
    return false;
  let showPagination = showPaginationState;

  if (
    !action?.meta?.arg?.pageLink &&
    action?.payload?.value?.length >= BaseSetting.minimumShowPagination
  ) {
    showPagination = true;
  }

  return showPagination;
}
