import React from 'react';
import PropTypes from 'prop-types';
import { Box, Skeleton } from '@mui/material';
import { QboTypography, QboLink } from '@ui/Components';
import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';

function LinkNavigate({ text, ...rest }) {
  return (
    <QboLink {...rest}>
      <ArrowBackIosNewIcon />
      <span>{text}</span>
    </QboLink>
  );
}

LinkNavigate.propTypes = {
  text: PropTypes.string.isRequired,
};

export default function HeaderPageBlock({ loading, children, linkNavigateText, caption, ...rest }) {
  const renderChildren = () => {
    if (loading && !children) return <Skeleton width={320} height={35} />;

    return (
      <QboTypography className="HeaderPageBlock__heading" variant="h3">
        {children}
      </QboTypography>
    );
  };

  return (
    <Box
      className="HeaderPageBlock__wrapper"
      sx={{ marginBottom: linkNavigateText ? '2rem' : '2.4rem' }}>
      {linkNavigateText && (
        <LinkNavigate {...rest} className="HeaderPageBlock__navigate" text={linkNavigateText} />
      )}

      {/* Heading section */}
      {renderChildren()}

      {/* caption section */}
      {loading && !children && caption ? (
        <Skeleton width={220} />
      ) : (
        <QboTypography variant="caption">{caption}</QboTypography>
      )}
    </Box>
  );
}

HeaderPageBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  linkNavigateText: PropTypes.string,
  caption: PropTypes.string,
  loading: PropTypes.bool,
};

HeaderPageBlock.defaultProps = {
  children: '',
  linkNavigateText: null,
  caption: null,
  loading: false,
};
