import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormValidation, {
  REQUIRED,
  MAX_CHAR_LENGTH,
  MIN_CHAR_LENGTH,
  HAS_UPCASE,
  HAS_LOWCASE,
  HAS_NUMBER,
  HAS_SYMBOL,
  MATCH_VALUE,
} from '@libs/FormValidation';
import useFormErrorMessage from '@pages/sharedHooks/useFormErrorMessage';
import BaseSetting from '@config/BaseSetting';
import { pascalToTitleCase } from '@utilities';

const currentPasswordName = 'currentPassword';
const newPasswordName = 'newPassword';
const confirmationPasswordName = 'confirmationPassword';
const { passwordSetupSetting } = BaseSetting;

export default function useChangePasswordValidation() {
  // initialize
  const { t } = useTranslation();
  const { getErrorMessage } = useFormErrorMessage();

  const [isValid, setIsValid] = useState(true);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmationPassword, setConfirmationPassword] = useState('');

  const currentPasswordValidation = useMemo(
    () => new FormValidation(currentPasswordName, { validations: [REQUIRED] }),
    []
  );

  const newPasswordValidation = useMemo(() => {
    const validations = [REQUIRED];

    if (passwordSetupSetting.hasUpperCase) validations.push(HAS_UPCASE);
    if (passwordSetupSetting.hasLowerCase) validations.push(HAS_LOWCASE);
    if (passwordSetupSetting.hasNumber) validations.push(HAS_NUMBER);
    if (passwordSetupSetting.hasSymbol) validations.push(HAS_SYMBOL);
    if (passwordSetupSetting.maxCharLength) validations.push(MAX_CHAR_LENGTH);
    if (passwordSetupSetting.minCharLength) validations.push(MIN_CHAR_LENGTH);

    return new FormValidation(newPasswordName, {
      validations,
      minCharLength: passwordSetupSetting.minCharLength,
      maxCharLength: passwordSetupSetting.maxCharLength,
    });
  }, []);

  const confirmationPasswordValidation = useMemo(() => {
    const validations = [REQUIRED, MATCH_VALUE];
    return new FormValidation(confirmationPasswordName, { validations });
  }, []);

  // get error message
  const errorMessage = (name, error) => {
    if (!name) return null;
    if (!error) return null;

    const nameKey = pascalToTitleCase(name).toLowerCase().replaceAll(' ', '_');

    return getErrorMessage(`form_validations.change_password.${nameKey}.${error.toLowerCase()}`);
  };

  const newPasswordErrorMessage = (error) => {
    if (newPasswordValidation.errors.length > 0 && !newPasswordValidation.errors.includes(REQUIRED))
      return t('form_validations.change_password.new_password.not_meet_requirement');

    return errorMessage(newPasswordName, error);
  };

  //
  // functions
  //
  const checkFormIsValid = () => {
    const valid =
      currentPasswordValidation.isValid() &&
      confirmationPasswordValidation.isValid() &&
      newPasswordValidation.isValid();

    setIsValid(valid);

    return valid;
  };

  // validate execution
  const validateCurrentPassword = (value = currentPassword) => {
    currentPasswordValidation.value = value;
    currentPasswordValidation.validate();
    checkFormIsValid();
  };

  const validateConfirmationPassword = (
    value = confirmationPassword,
    secondValue = newPassword
  ) => {
    confirmationPasswordValidation.value = value;
    confirmationPasswordValidation.secondValue = secondValue;
    confirmationPasswordValidation.validate();
    checkFormIsValid();
  };

  const validateNewPassword = (value = newPassword) => {
    newPasswordValidation.value = value;
    newPasswordValidation.validate();

    if (confirmationPassword.length > 0) validateConfirmationPassword(confirmationPassword, value);
    checkFormIsValid();
  };

  const validateAll = () => {
    validateCurrentPassword();
    validateNewPassword();
    validateConfirmationPassword();

    return checkFormIsValid();
  };

  //
  // construct the attributes
  //

  const currentPasswordAttributes = {
    handleChange: (value) => {
      setCurrentPassword(value);
      validateCurrentPassword(value);
    },
    errorMessage: errorMessage(currentPasswordName, currentPasswordValidation.errors[0]),
    ...currentPasswordValidation.formAttributes(),
  };

  const newPasswordAttributes = {
    handleChange: (value) => {
      setNewPassword(value);
      validateNewPassword(value, confirmationPasswordValidation.value);
    },
    errorMessage: newPasswordErrorMessage(newPasswordValidation.errors[0]),
    ...newPasswordValidation.formAttributes(),
  };

  const confirmationPasswordAttributes = {
    handleChange: (value) => {
      setConfirmationPassword(value);
      validateConfirmationPassword(value, newPasswordValidation.value);
    },
    errorMessage: errorMessage(confirmationPasswordName, confirmationPasswordValidation.errors[0]),
    ...confirmationPasswordValidation.formAttributes(),
  };

  const resetForm = () => {
    currentPasswordValidation.resetValue();
    newPasswordValidation.resetValue();
    confirmationPasswordValidation.resetValue();

    setCurrentPassword('');
    setConfirmationPassword('');
    setNewPassword('');
  };

  return {
    currentPasswordAttributes,
    newPasswordAttributes,
    confirmationPasswordAttributes,
    isValid,
    validateAll,
    resetForm,
  };
}
