import React from 'react';
import { QboCard, QboCardCaption } from '@ui/Components';
import NoBackupStatusSVG from '@images/generals/NoBackupStatus.svg';
import { useTranslation } from 'react-i18next';

export default function NoBackupSection() {
  const { t } = useTranslation();

  return (
    <QboCard noPadding isMainWrapper>
      <QboCardCaption
        imageUrl={NoBackupStatusSVG}
        heading={t('status_backup_page.no_backup_section.heading_text')}
        caption={t('status_backup_page.no_backup_section.caption_text')}
      />
    </QboCard>
  );
}
