import React from 'react';
import PropTypes from 'prop-types';
import { QboButton, QboCard, QboTypography, QboLink, QboSwitch } from '@ui/Components';
import { Box, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Authentication from '@images/generals/Authentication.svg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import { useDateTime } from '@hooks';

export default function Setup2FASection({
  onOpenModalSetup2FA,
  hasSetup2FA,
  hasEnable2FA,
  verifiedDate,
  onOpenModalDisable2FA,
  onOpenModalReEnable2FA,
  onOpenModalReset2FA,
  loading,
}) {
  const { t } = useTranslation();
  const { dateToString, dateFormat } = useDateTime();

  const renderDescription = () => {
    if (hasSetup2FA) {
      return (
        <>
          <QboTypography noWrap={false} color="primary">
            {t('account_settings_setup_2fa_page.content.paragraph_after_setup')}
          </QboTypography>
          <Box className="SettingSetup2FAPage__toggle-info-box">
            <Box className="SettingSetup2FAPage__toggle-info-text">
              <QboTypography noWrap={false} variant="subtitle2">
                {t('account_settings_setup_2fa_page.content.toggle_info_title')}
              </QboTypography>
              <QboTypography noWrap={false} color="primary">
                {t('account_settings_setup_2fa_page.content.toggle_info_detail', {
                  date: dateToString(dateFormat.DEFAULT_DATE_TIME, verifiedDate),
                })}
              </QboTypography>
            </Box>
            <QboSwitch
              type="IOS"
              checked={hasEnable2FA}
              value={hasEnable2FA}
              onChange={() => (hasEnable2FA ? onOpenModalDisable2FA() : onOpenModalReEnable2FA())}
            />
          </Box>
          <QboLink
            className="SettingSetup2FAPage__text-button-reset"
            onClick={() => onOpenModalReset2FA()}>
            {t('account_settings_setup_2fa_page.content.text_button_reset_auth')}
          </QboLink>
        </>
      );
    }

    return (
      <>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.content.paragraph_1')}
        </QboTypography>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.content.paragraph_2')}
        </QboTypography>
        <Box className="SettingSetup2FAPage__link-list">
          <QboLink
            className="SettingSetup2FAPage__app-link"
            href="https://www.apple.com/us/search/authenticator?sel=explore&src=serp"
            target="_blank">
            {t('account_settings_setup_2fa_page.content.app_link_ios')}
            <ArrowUpwardIcon />
          </QboLink>
          <QboLink
            className="SettingSetup2FAPage__app-link"
            href="https://play.google.com/store/search?q=authenticator&pli=1&c=apps"
            target="_blank">
            {t('account_settings_setup_2fa_page.content.app_link_android')}
            <ArrowUpwardIcon />
          </QboLink>
        </Box>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.content.paragraph_3')}
        </QboTypography>
        <Box className="SettingSetup2FAPage__setup_button">
          <QboButton
            variant="contained"
            startIcon={<ShieldOutlinedIcon />}
            onClick={() => onOpenModalSetup2FA(true)}>
            {t('account_settings_setup_2fa_page.content.button')}
          </QboButton>
        </Box>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <div className="SettingSetup2FAPage__skeleton">
        <Skeleton width="100%" height={60} />
        <Skeleton width="100%" height={60} />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="40%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton width="50%" height={60} />
      </div>
    );
  };

  return (
    <QboCard
      titleCard={t('account_settings_setup_2fa_page.card.title')}
      className="SettingSetup2FAPage__wrapper">
      <Box container className="SettingSetup2FAPage__card">
        <Box container className="SettingSetup2FAPage__main-content">
          {loading ? renderLoading() : renderDescription()}
        </Box>
        <img src={Authentication} alt="" className="Img__fluid" />
      </Box>
    </QboCard>
  );
}

Setup2FASection.propTypes = {
  onOpenModalSetup2FA: PropTypes.func,
  hasSetup2FA: PropTypes.bool,
  hasEnable2FA: PropTypes.bool,
  verifiedDate: PropTypes.string,
  onOpenModalDisable2FA: PropTypes.func,
  onOpenModalReEnable2FA: PropTypes.func,
  onOpenModalReset2FA: PropTypes.func,
  loading: PropTypes.bool,
};

Setup2FASection.defaultProps = {
  onOpenModalSetup2FA: () => {},
  hasSetup2FA: false,
  hasEnable2FA: false,
  verifiedDate: '',
  onOpenModalDisable2FA: () => {},
  onOpenModalReEnable2FA: () => {},
  onOpenModalReset2FA: () => {},
  loading: true,
};
