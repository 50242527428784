import { useDispatch, useSelector } from 'react-redux';
import {
  fetchStatus2FAAsync,
  resetStatus2FA,
  fetchKey2FAAsync,
  resetKey2FA,
} from '@features/account/accountSlice';
import { useState, useEffect } from 'react';
import { useAlertMessage, usePageAlert } from '@hooks';
import useAuthForm2FA from '@pages/SettingSetup2FAPage/pageHooks/useAuthForm2FA';

export default function useSetup2FA() {
  const { isApplicationReady } = useSelector((state) => state.application);
  const { status2FA, authKey2FA } = useSelector((state) => state.account);
  const { submitting: loading, hasSetup2FA, hasEnable2FA, verifiedDate } = status2FA;
  const { submitting: loadingQRKey, qrKey, secretKey } = authKey2FA;
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const {
    submittingForm,
    successValidation,
    errorValidation,
    handleSubmitAuthForm,
    resetAuthform2FA,
    type,
    ConstantActionTypes,
    resetType,
  } = useAuthForm2FA();

  const [verificationType, setVerificationType] = useState('');
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [showAuthFormModal, setShowAuthFormModal] = useState(false);

  const dispatch = useDispatch();

  const resetState = () => {
    setShowQRCodeModal(false);
    setShowAuthFormModal(false);
    setVerificationType('');
    dispatch(resetKey2FA());
    dispatch(resetAuthform2FA());
    resetType();
  };

  const onSubmitAuthForm = (params) => {
    const payload = {
      authCode: params.authCode,
      type: ConstantActionTypes.ActionTypeSetup,
      otpSecret: secretKey,
    };

    handleSubmitAuthForm(payload);
  };

  useEffect(() => {
    if (isApplicationReady) {
      dispatch(fetchStatus2FAAsync());
    }
    return () => {
      dispatch(resetStatus2FA());
    };
  }, [isApplicationReady, successValidation]);

  useEffect(() => {
    if (showQRCodeModal) {
      dispatch(fetchKey2FAAsync());
    }
  }, [showQRCodeModal]);

  useEffect(() => {
    if (!submittingForm && type === ConstantActionTypes.ActionTypeSetup) {
      if (successValidation === true) {
        updateSuccessAlert(getSuccessMessage(konstMessage.SUCCESS_SETUP_2FA));
        resetState();
      } else if (errorValidation.banner) {
        updateErrorAlert(getErrorMessage(konstMessage.ERROR_SETUP_2FA));
        resetState();
      }
    }
  }, [submittingForm]);

  return {
    verificationType,
    changeVerificationType: setVerificationType,
    showQRCodeModal,
    showAuthFormModal,
    onOpenModalSetup2FA: () => {
      setShowQRCodeModal(true);
    },
    onCloseModal: () => resetState(),
    onNextButton: (finish, payload) => {
      if (finish) {
        onSubmitAuthForm(payload);
      } else {
        setShowQRCodeModal(false);
        setShowAuthFormModal(true);
      }
    },
    onBackButton: () => {
      setShowQRCodeModal(true);
      setShowAuthFormModal(false);
      dispatch(resetAuthform2FA());
    },
    hasSetup2FA,
    hasEnable2FA,
    verifiedDate,
    loading,
    loadingQRKey,
    qrKey,
    secretKey,
    errorValidation,
    loadingSetup: submittingForm,
  };
}
