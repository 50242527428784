import * as React from 'react';

import PropTypes from 'prop-types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useDateTime } from '@hooks';
import { QboTextField } from '@ui/Components';

export default function QboTimePicker({
  placeholder,
  value,
  onChange,
  minTime,
  valueFromDate,
  ...rest
}) {
  const { dateTimeNowObject, equalDateValidation } = useDateTime();

  const now = dateTimeNowObject();
  const hasMinTime = minTime && Object.keys(minTime).length > 0;
  const hasValueFromDate = valueFromDate && Object.keys(valueFromDate).length > 0;
  const isMaxDate = hasValueFromDate && now ? equalDateValidation(valueFromDate, now) : false;
  const isMinDate =
    hasValueFromDate && hasMinTime ? equalDateValidation(valueFromDate, minTime) : false;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        {...rest}
        className={`QboTimePicker__default ${value ? 'QboDatePicker__with-value' : ''}`}
        value={value}
        maxTime={isMaxDate ? now : ''}
        minTime={isMinDate ? minTime : ''}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          const { InputProps } = params;
          const attr = params;
          delete attr.InputProps;
          return <QboTextField {...attr} endAdornment={InputProps.endAdornment} />;
        }}
        inputProps={{
          placeholder,
          readOnly: true,
        }}
      />
    </LocalizationProvider>
  );
}

QboTimePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  minTime: PropTypes.object,
  valueFromDate: PropTypes.object,
};

QboTimePicker.defaultProps = {
  placeholder: '',
  value: {},
  onChange: () => {},
  minTime: {},
  valueFromDate: {},
};
