import React from 'react';
import PropTypes from 'prop-types';
import { QboModal, QboTypography } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ModalFormAuthenticationCodeBlock from './ModalFormAuthenticationCodeBlock';

export default function ModalDisable2FABlock({
  showDisableConfirmationModal,
  showDisableAuthFormModal,
  onCloseDisableModal,
  onNextDisable2FAButton,
  onBackDisable2FAButton,
  errorValidation,
  loadingForm,
}) {
  const { t } = useTranslation();

  const renderBodyModal = () => {
    return (
      <Box className="ModalDisable2FABlock__wrapper">
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.disable_confirmation.description_1')}
        </QboTypography>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.disable_confirmation.description_2')}
        </QboTypography>
      </Box>
    );
  };

  return (
    <>
      <QboModal
        onClose={() => onCloseDisableModal()}
        open={showDisableConfirmationModal}
        title={t('account_settings_setup_2fa_page.modal.disable_confirmation.title')}
        button={{
          text: t('account_settings_setup_2fa_page.modal.disable_confirmation.confirmation_button'),
          onClick: () => onNextDisable2FAButton(),
          position: 'right',
        }}
        preventOutsideClick>
        {renderBodyModal()}
      </QboModal>
      <ModalFormAuthenticationCodeBlock
        showAuthForm={showDisableAuthFormModal}
        onBackButton={onBackDisable2FAButton}
        onClose={onCloseDisableModal}
        onNextButton={onNextDisable2FAButton}
        validationError={errorValidation}
        loading={loadingForm}
      />
    </>
  );
}

ModalDisable2FABlock.propTypes = {
  showDisableConfirmationModal: PropTypes.bool,
  showDisableAuthFormModal: PropTypes.bool,
  onCloseDisableModal: PropTypes.func,
  onNextDisable2FAButton: PropTypes.func,
  onBackDisable2FAButton: PropTypes.func,
  errorValidation: PropTypes.object,
  loadingForm: PropTypes.bool,
};

ModalDisable2FABlock.defaultProps = {
  showDisableConfirmationModal: false,
  showDisableAuthFormModal: false,
  onCloseDisableModal: () => {},
  onNextDisable2FAButton: () => {},
  onBackDisable2FAButton: () => {},
  errorValidation: {},
  loadingForm: false,
};
