import React from 'react';
import DropsuiteLogoSvg from '@images/logos/dropsuite-logo.svg';
import PropTypes from 'prop-types';

export default function QboLogo({ logo }) {
  return (
    <div className="QboLogo__wrapper">
      <img
        src={logo || DropsuiteLogoSvg}
        alt="dropsuite-logo"
        className="Img__fluid QboLogo__main"
      />
    </div>
  );
}

QboLogo.propTypes = {
  logo: PropTypes.string,
};
QboLogo.defaultProps = {
  logo: null,
};
