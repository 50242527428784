import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboModal, QboTypography, QboTextField } from '@ui/Components';
import Box from '@mui/material/Box';

export default function ModalDeactivateCardBlock({
  showModal,
  onClose,
  onClick,
  reason,
  setReason,
  companyName,
}) {
  const { t } = useTranslation();

  const renderBodyModal = () => {
    return (
      <Box className="ModalDeactivate__wrapper">
        <QboTypography variant="subtitle2">
          {t('dashboard_page.modal_deactivate.label_text_field_reason')}
        </QboTypography>
        <QboTextField
          className="ModalDeactivate__text-field"
          placeholder={t('dashboard_page.modal_deactivate.placeholder_reason')}
          value={reason}
          onChange={(e) => {
            setReason(e.currentTarget.value);
          }}
          multiline
          rows={4}
          inputProps={{ maxLength: 255 }}
        />
        <div
          className="ModalDeactivate__box-warning"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: t('dashboard_page.modal_deactivate.deactivate_warning_text_html', {
              company_name: companyName,
            }),
          }}
        />
        <QboTypography color="primary">
          {t('dashboard_page.modal_deactivate.modal_confirmation_text')}
        </QboTypography>
      </Box>
    );
  };

  return (
    <QboModal
      onClose={() => onClose()}
      open={showModal}
      title={t('dashboard_page.modal_deactivate.title_modal_deactivate')}
      button={{
        text: t('dashboard_page.modal_deactivate.button_deactivate_confirmation'),
        onClick: () => onClick(),
        position: 'right',
        disabled: !(reason?.length > 0),
      }}
      preventOutsideClick>
      {renderBodyModal('confirmation')}
    </QboModal>
  );
}

ModalDeactivateCardBlock.propTypes = {
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  showModal: PropTypes.bool,
  reason: PropTypes.string,
  setReason: PropTypes.func,
  companyName: PropTypes.string,
};

ModalDeactivateCardBlock.defaultProps = {
  onClose: () => {},
  onClick: () => {},
  showModal: false,
  reason: '',
  setReason: () => {},
  companyName: '',
};
