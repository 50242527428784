import React from 'react';
import PropTypes from 'prop-types';
import { QboButton, QboCard, QboTextField, QboFormGroup } from '@ui/Components';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ChangePasswordFormRequirementBlock from '@ui/Blocks/AccountSettingsPageBlocks/ChangePasswordFormRequirementBlock';

export default function ChangePasswordFormSection({
  currentPassword,
  newPassword,
  confirmationPassword,
  listRequirement,
  onSubmit,
  disabled,
  submitting,
}) {
  const { t } = useTranslation();

  return (
    <QboCard
      titleCard={t('account_settings_change_password_page.card.title')}
      className="SettingChangePasswordPage__main-card">
      <form onSubmit={onSubmit}>
        <Grid container spacing={4} className="SettingChangePasswordPage__main-content">
          {/* Form Section */}
          <Grid item xs={12} md={7} className="SettingChangePasswordPage__form">
            <QboFormGroup>
              <QboTextField
                id="current-password"
                isPasswordField
                placeholder={t('account_settings_change_password_page.form.current_password_label')}
                label={t('account_settings_change_password_page.form.current_password_label')}
                fullWidth
                value={currentPassword.value}
                name={currentPassword.name}
                onChange={(e) => {
                  currentPassword.handleChange(e.currentTarget.value);
                }}
                errorMessage={currentPassword.errorMessage}
                withoutErrorIcon
                inputProps={{ maxLength: 225 }}
              />

              <QboTextField
                id="new-password"
                isPasswordField
                placeholder={t('account_settings_change_password_page.form.new_password_label')}
                label={t('account_settings_change_password_page.form.new_password_label')}
                fullWidth
                withoutErrorIcon
                value={newPassword.value}
                name={newPassword.name}
                onChange={(e) => {
                  newPassword.handleChange(e.currentTarget.value);
                }}
                errorMessage={newPassword.errorMessage}
                inputProps={{ maxLength: 225 }}
              />

              <QboTextField
                id="confirm-password"
                isPasswordField
                placeholder={t('account_settings_change_password_page.form.confirm_password_label')}
                label={t('account_settings_change_password_page.form.confirm_password_label')}
                fullWidth
                withoutErrorIcon
                value={confirmationPassword.value}
                name={confirmationPassword.name}
                onChange={(e) => {
                  confirmationPassword.handleChange(e.currentTarget.value);
                }}
                errorMessage={confirmationPassword.errorMessage}
                inputProps={{ maxLength: 225 }}
              />
            </QboFormGroup>
          </Grid>

          {/* Form Requirment Section */}
          <Grid item xs={12} md={5} className="SettingChangePasswordPage__form-requirement">
            <ChangePasswordFormRequirementBlock
              listRequirement={listRequirement}
              className="SettingChangePasswordPage__form-requirement-wrapper"
            />
          </Grid>
          <Grid item md={12}>
            <div className="SettingChangePasswordPage__submit-button">
              <QboButton variant="contained" type="submit" disabled={disabled} loading={submitting}>
                {t('account_settings_change_password_page.form.button_label')}
              </QboButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </QboCard>
  );
}

ChangePasswordFormSection.propTypes = {
  currentPassword: PropTypes.object,
  newPassword: PropTypes.object,
  confirmationPassword: PropTypes.object,
  listRequirement: PropTypes.array,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
};

ChangePasswordFormSection.defaultProps = {
  currentPassword: {
    errors: [],
    hasError: false,
    value: '',
    name: '',
    handleChange: () => {},
  },
  newPassword: {
    errors: [],
    hasError: false,
    value: '',
    name: '',
    handleChange: () => {},
  },
  confirmationPassword: {
    errors: [],
    hasError: false,
    value: '',
    name: '',
    handleChange: () => {},
  },
  listRequirement: [],
  onSubmit: () => {},
  disabled: false,
  submitting: false,
};
