//
// Backend Backup Status:
//
// Running = 1,
// Failed = 5,
// OnQueue = 22,
// Success = 7,
// SuccessWithoutNewData = 10,
// AuthenticationError = 11
//

const RUNNING = 'Running'; // 5, 1, 22
const SUCCESS = 'Completed'; // 7, 10
const AUTHENTICATION_ERROR = 'AuthenticationError'; // 11
const DEACTIVATED = 'Deactivated';
const EXPIRED = 'Expired';
const NO_DATA = 'NoData';
const FAILED = 'Failed';
const AVAILABLE = 'Available';
const DELETED = 'Deleted';
const PARTIALLY_COMPLETED = 'PartiallyCompleted';

export default class SystemStatus {
  static BACKUP_STATUSES = [RUNNING, SUCCESS, AUTHENTICATION_ERROR, DEACTIVATED];

  static DOWNLOAD_STATUSES = [RUNNING, SUCCESS, EXPIRED, NO_DATA];

  static RESTORE_STATUSES = [RUNNING, SUCCESS, AUTHENTICATION_ERROR, FAILED];

  static REPLICATE_STATUSES = [RUNNING, SUCCESS, AUTHENTICATION_ERROR, FAILED, PARTIALLY_COMPLETED];

  static listBackupStatus = {
    RUNNING,
    SUCCESS,
    AUTHENTICATION_ERROR,
    DEACTIVATED,
  };

  static listDownloadStatus = {
    RUNNING,
    SUCCESS,
    EXPIRED,
    NO_DATA,
  };

  static listRestoreStatus = {
    RUNNING,
    SUCCESS,
    AUTHENTICATION_ERROR,
    FAILED,
  };

  static listReplicateStatus = {
    RUNNING,
    SUCCESS,
    AUTHENTICATION_ERROR,
    FAILED,
    PARTIALLY_COMPLETED,
  };

  static listItemStatus = {
    AVAILABLE,
    DELETED,
  };
}
