import React from 'react';
import PropTypes from 'prop-types';
import { QboCard, QboCardCaption, QboCardListItem } from '@ui/Components';
import SearchCardBlock from '@ui/Blocks/Shared/CardBlocks/SearchCardBlock';
import { useTranslation } from 'react-i18next';
import PaginationCardBlock from '@ui/Blocks/Shared/CardBlocks/PaginationCardBlock';
import StatusRestoreCardItemBlock from '@ui/Blocks/StatusPageBlocks/StatusRestoreCardItemBlock';
import FilterCardBlock from '@ui/Blocks/Shared/CardBlocks/FilterCardBlock';
import { foldersMd } from '@images/generals/folders-svg';

export default function ListingRestoreSection({
  loading,
  collection,
  hasNextLink,
  hasPrevLink,
  onPrevPage,
  onNextPage,
  onChangePageNumber,
  pageNumber,
  showPagination,
  searchKeyword,
  displaySearchKeyword,
  setSearchKeyword,
  handleOpenDrawer,
  listBadges,
  handleDelete,
  onClickRow,
}) {
  const { t } = useTranslation();
  const loadingRows = collection.length > 0 ? collection : [...Array(5).keys()];

  const renderContent = () => {
    if (loading) {
      return loadingRows.map((row, index) => (
        <QboCardListItem key={row?.id || index} leftContent="loading" loading />
      ));
    }

    if (collection.length === 0) {
      return (
        <QboCardCaption
          className="BackupListItemNotFound__border-top"
          imageUrl={foldersMd}
          heading={t('caption_card.not_found_block.title_text')}
          caption={t('caption_card.not_found_block.caption_text')}
        />
      );
    }

    return (
      <>
        {collection.map((QboStatusRestore) => (
          <StatusRestoreCardItemBlock
            QboStatusRestore={QboStatusRestore}
            key={QboStatusRestore.id}
            searchKeyword={displaySearchKeyword}
            onClickRow={onClickRow}
          />
        ))}

        <PaginationCardBlock
          hasNextLink={hasNextLink}
          hasPrevLink={hasPrevLink}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          showPagination={showPagination}
        />
      </>
    );
  };

  return (
    <div className="ListingDownloadSection__wrapper">
      <QboCard className="ListingDownloadSection__card" noPadding isMainWrapper>
        <SearchCardBlock
          searchKeyword={searchKeyword}
          setSearchKeyword={(searchValue) => {
            setSearchKeyword(searchValue);
          }}
          placeholder={t('status_restore_page.search_block.search_placeholder')}
          setShowDrawer={handleOpenDrawer}
          showBadgeFilter={listBadges.length > 0}
        />
        <FilterCardBlock filterData={listBadges} handleDelete={handleDelete} />

        {renderContent()}
      </QboCard>
    </div>
  );
}

ListingRestoreSection.propTypes = {
  loading: PropTypes.bool,
  collection: PropTypes.array,
  hasNextLink: PropTypes.string,
  hasPrevLink: PropTypes.string,
  onPrevPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onChangePageNumber: PropTypes.func,
  pageNumber: PropTypes.string,
  showPagination: PropTypes.bool,
  searchKeyword: PropTypes.string,
  setSearchKeyword: PropTypes.func,
  displaySearchKeyword: PropTypes.string,
  handleOpenDrawer: PropTypes.func,
  listBadges: PropTypes.array,
  handleDelete: PropTypes.func,
  onClickRow: PropTypes.func,
};

ListingRestoreSection.defaultProps = {
  loading: false,
  collection: [],
  hasNextLink: null,
  hasPrevLink: null,
  onPrevPage: () => {},
  onNextPage: () => {},
  onChangePageNumber: () => {},
  pageNumber: null,
  showPagination: false,
  searchKeyword: '',
  displaySearchKeyword: '',
  setSearchKeyword: () => {},
  handleOpenDrawer: () => {},
  listBadges: [],
  handleDelete: () => {},
  onClickRow: () => {},
};
