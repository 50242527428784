import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadUrlCallbackAPI } from '@services/WebApiService';

const qboDownloadURLToolsAsync = createAsyncThunk(
  'QboRealms/downloadURL',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await downloadUrlCallbackAPI(accessToken, payload);

    return data;
  }
);

const { pending, fulfilled, rejected } = qboDownloadURLToolsAsync;

const qboDownloadURLToolsExtraReducers = {
  [pending]: (state) => {
    state.downloadUrl.submitting = true;
  },
  [rejected]: (state) => {
    state.downloadUrl.submitting = false;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.url) {
      state.downloadUrl.url = action.payload.url;
    }
    state.downloadUrl.submitting = false;
  },
};

export { qboDownloadURLToolsAsync, qboDownloadURLToolsExtraReducers };
