import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useAlertMessage, usePageAlert } from '@hooks';
import useAuthForm2FA from '@pages/SettingSetup2FAPage/pageHooks/useAuthForm2FA';

export default function useReEnable2FA() {
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const {
    submittingForm,
    successValidation,
    errorValidation,
    handleSubmitAuthForm,
    resetAuthform2FA,
    type,
    ConstantActionTypes,
    resetType,
  } = useAuthForm2FA();

  const [showReEnableConfirmationModal, setShowReEnableConfirmationModal] = useState(false);

  const dispatch = useDispatch();

  const resetState = () => {
    setShowReEnableConfirmationModal(false);
    dispatch(resetAuthform2FA());
    resetType();
  };

  const onSubmitAuthForm = () => {
    const payload = {
      type: ConstantActionTypes.ActionTypeReEnable,
    };

    handleSubmitAuthForm(payload);
  };

  useEffect(() => {
    if (!submittingForm && type === ConstantActionTypes.ActionTypeReEnable) {
      if (successValidation === true) {
        updateSuccessAlert(getSuccessMessage(konstMessage.SUCCESS_RE_ENABLE_2FA));
        resetState();
      } else if (errorValidation.banner) {
        updateErrorAlert(getErrorMessage(konstMessage.ERROR_RE_ENABLE_2FA));
        resetState();
      }
    }
  }, [submittingForm]);

  return {
    showReEnableConfirmationModal,
    onOpenModalReEnable2FA: () => {
      setShowReEnableConfirmationModal(true);
    },
    onCloseReEnableModal: () => {
      resetState();
    },
    onNextReEnable2FAButton: () => onSubmitAuthForm(),
    loadingReEnable: submittingForm,
  };
}
