import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List, ListItem, ListSubheader } from '@mui/material';
import {
  HomeOutlined as HomeOutlinedIcon,
  RestoreOutlined as RestoreOutlinedIcon,
} from '@mui/icons-material';
import { dashboardPath, backupStatusPath } from '@config/Routes/WebClientRoutes';
import { QboDrawerMenuItem } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import usePageMenu from '@pages/sharedHooks/usePageMenu';

export default function MenuBlock({ drawerOpen }) {
  const { requireInitialSetup, isInitializing } = useSelector((state) => state.application);
  const { t } = useTranslation();
  const { isActiveMenu, listMenu, setActivePageMenu } = usePageMenu();

  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="main-menus"
        subheader={
          drawerOpen && (
            <ListSubheader className="DrawerSubHeader__default" component="div">
              {t('drawer.main_menu.text')}
            </ListSubheader>
          )
        }>
        <ListItem disablePadding sx={{ display: 'block' }}>
          {isInitializing && (
            <>
              <QboDrawerMenuItem loading />
              <QboDrawerMenuItem loading />
            </>
          )}

          {!isInitializing && (
            <QboDrawerMenuItem
              icon={<HomeOutlinedIcon className="Icon__default" />}
              text={t('drawer.main_menu.homepage')}
              isWithLabel={drawerOpen}
              path={dashboardPath}
              forceActive={isActiveMenu(listMenu.HOME_MENU)}
              onClick={() => {
                setActivePageMenu(listMenu.HOME_MENU);
              }}
            />
          )}

          {!isInitializing && !requireInitialSetup && (
            <>
              {/* <QboDrawerMenuItem
                icon={<SearchOutlinedIcon className="Icon__default" />}
                text={t('drawer.main_menu.advance_search')}
                isWithLabel={drawerOpen}
                forceActive={isActiveMenu(listMenu.ADVANCED_SEARCH_MENU)}
              /> */}
              <QboDrawerMenuItem
                icon={<RestoreOutlinedIcon className="Icon__default" />}
                text={t('drawer.main_menu.system_status')}
                isWithLabel={drawerOpen}
                forceActive={isActiveMenu(listMenu.SYSTEM_STATUS_MENU)}
                path={backupStatusPath}
                onClick={() => {
                  setActivePageMenu(listMenu.SYSTEM_STATUS_MENU);
                }}
              />
            </>
          )}
        </ListItem>
      </List>
      {/* {!isInitializing && !requireInitialSetup && (
        <>
          <Divider />
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="others"
            subheader={
              drawerOpen && (
                <ListSubheader className="DrawerSubHeader__default" component="div">
                  {t('drawer.others_menu.text')}
                </ListSubheader>
              )
            }>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <QboDrawerMenuItem
                forceActive={isActiveMenu(listMenu.SETTING_MENU)}
                SYSTEM_STATUS_MENU
                icon={<SettingsOutlinedIcon className="Icon__default" />}
                text={t('drawer.others_menu.settings')}
                isWithLabel={drawerOpen}
              />
            </ListItem>
          </List>
        </>
      )} */}
    </>
  );
}

MenuBlock.propTypes = {
  drawerOpen: PropTypes.bool,
};

MenuBlock.defaultProps = {
  drawerOpen: true,
};
