import React from 'react';
import PropTypes from 'prop-types';
import {
  QboCardListItemStatus,
  QboTypography,
  QboHighlighter,
  QboLinearProgress,
} from '@ui/Components';
import RestoreAva from '@images/generals/RestoreAva.svg';
import { useDateTime } from '@hooks';
import useSystemStatus, { listDownloadStatus } from '@hooks/useSystemStatus';
import { t } from 'i18next';

const defaultNull = '-';

/* eslint-disable react/no-danger */
export default function StatusRestoreCardItemBlock({
  QboStatusRestore,
  searchKeyword,
  onClickRow,
}) {
  const { dateToString, dateFormat, dateDistance, dateTimeNow } = useDateTime();
  const { badgeRestoreStatusRender } = useSystemStatus({
    status: QboStatusRestore.status,
    withIcon: true,
    withInfoIcon: false,
  });

  let elapsedTime = defaultNull;

  if (QboStatusRestore?.startTime) {
    const endTime = QboStatusRestore?.endTime || dateTimeNow();
    elapsedTime =
      dateDistance(QboStatusRestore?.startTime, endTime, {
        separator: ' ',
      }) || '1s';
  }

  const restoreInfoHTML = () => {
    const info = [
      t('status_restore_page.item_card.caption.status_id_label', {
        id: QboStatusRestore?.id,
      }),
      t('status_restore_page.item_card.caption.started_at_label', {
        date:
          dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusRestore?.startTime) || defaultNull,
      }),
      t('status_restore_page.item_card.caption.elapsed_at_label', { date: elapsedTime }),
    ];

    if (QboStatusRestore?.status === listDownloadStatus.RUNNING && !QboStatusRestore?.startTime) {
      // calculating
      info.push(`${t('system_status.calculating_text')}...`);
    } else if (QboStatusRestore?.status === listDownloadStatus.RUNNING) {
      // running info
      info.push(
        t('system_status.completed.percentage_text_all', {
          number: QboStatusRestore?.percentage,
        })
      );
    } else {
      // fallback
      info.push(
        t('status_restore_page.item_card.caption.completed_at_label', {
          date:
            dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusRestore?.endTime) || defaultNull,
        })
      );
    }

    return info.join(' | ');
  };

  return (
    <QboCardListItemStatus
      onClick={() => {
        if (onClickRow) onClickRow(QboStatusRestore.id);
      }}
      className="StatusCardItemBlock__wrapper StatusCardItemBlock__active"
      justifyContent="center"
      alignItems="center"
      leftContent={<img src={RestoreAva} alt="DownloadAva" className="Img__fluid" />}
      rightContent={
        <div className="StatusCardItemBlock__right-content">{badgeRestoreStatusRender()}</div>
      }
      buttonRightContent={
        QboStatusRestore?.status === listDownloadStatus.RUNNING && (
          <QboLinearProgress
            progress={parseInt(QboStatusRestore?.percentage, 10)}
            className="StatusCardItemBlock__running-progress"
            processing={Boolean(!QboStatusRestore?.startTime)}
          />
        )
      }>
      <div className="StatusCardItemBlock__title">
        <QboTypography variant="subtitle2">
          {searchKeyword !== '' ? (
            <QboHighlighter searchWord={searchKeyword} sentences={QboStatusRestore?.companyName} />
          ) : (
            QboStatusRestore?.companyName
          )}
        </QboTypography>
      </div>

      <div
        className="StatusCardItemBlock__info QboCardListItem__info"
        dangerouslySetInnerHTML={{ __html: restoreInfoHTML() }}
      />
    </QboCardListItemStatus>
  );
}

StatusRestoreCardItemBlock.propTypes = {
  QboStatusRestore: PropTypes.object.isRequired,
  searchKeyword: PropTypes.string,
  onClickRow: PropTypes.func,
};

StatusRestoreCardItemBlock.defaultProps = {
  searchKeyword: '',
  onClickRow: () => {},
};
