import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { authRequestAsync } from '@features/auth/authSlice';
import { dashboardPath } from '@config/Routes/WebClientRoutes';
import { setLocale } from '@features/application/applicationSlice';
import useAuthParam from '@pages/sharedHooks/useAuthParam';

//
// eg: SSO URL
// https://localhost:3000/en/qbo_sso/202209140740345321/4af29957-6027-4045-b5ab-df7eb6c5cea9/bgaVyNhEpMKMgjArOVy+A5alEabc+Z2BNs3r4XJN6nA=
// ?impersonate=False&source=npp&callbackUrl=https%3A%2F%2Fusw-prerelease.dropmyemail.com
//

export default function useUserAuthenticate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    setSSOLoginParam,
    callbackUrl,
    setEmailAddress,
    setNameInitial,
    setFeatures,
    setFavicon,
    setGeneralLogo,
  } = useAuthParam();

  const { isUserSignedIn, error, emailAddress, nameInitial, features, favicon, generalLogo } =
    useSelector((state) => state.auth);
  const { requireInitialSetup } = useSelector((state) => state.application);
  const { locale, signature } = useParams();

  useEffect(() => {
    if (signature) dispatch(authRequestAsync(signature));
  }, [signature]);

  useEffect(() => {
    if (error && callbackUrl) {
      let redirectUrl = callbackUrl;
      if (error?.payload?.callbackParams) {
        redirectUrl = `${redirectUrl}${error?.payload?.callbackParams}`;
      }

      window.location.href = redirectUrl;
    } else if (isUserSignedIn) {
      if (locale) dispatch(setLocale(locale));
      if (emailAddress) setEmailAddress(emailAddress);
      if (nameInitial) setNameInitial(nameInitial);
      if (features) setFeatures(JSON.stringify(features));
      if (favicon) setFavicon(favicon);
      if (generalLogo) setGeneralLogo(generalLogo);

      setSSOLoginParam();
    }
  }, [error, isUserSignedIn]);

  useEffect(() => {
    if (requireInitialSetup !== null) navigate(dashboardPath, { replace: true });
  }, [requireInitialSetup]);

  return [isUserSignedIn];
}
