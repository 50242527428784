import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QboSimpleTable } from '@ui/Components';
import { dashboardPath, newQuickbooksPath } from '@config/Routes/WebClientRoutes';
import {
  Business as BusinessIcon,
  HourglassEmpty as HourglassEmptyIcon,
  FolderOutlined as FolderOutlinedIcon,
} from '@mui/icons-material';
import { arrayListToObject } from '@utilities';
import { resetQboRequestCommit } from '@features/qboOauth/qboOauthSlice';
import { setRequireInitialSetup } from '@features/application/applicationSlice';
import { usePageAlert, useAlertMessage } from '@hooks';
import ErrorResponse from '@libs/ErrorResponse';

const ErrorNFRQuotaExceeded = 'nfr_quota_exceeded';

export default function useConfirmBackup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { isAlertOpen, alert, closeAlert, updateSuccessAlert, updateErrorAlert } = usePageAlert();

  const { QboAuthRequestId, confirmation, error, QboRequestCommit, isRequestingBackup } =
    useSelector((state) => state.qboOauth);

  const contructBackupObjects = (objects) => {
    return arrayListToObject(['number', 'objectName'], objects);
  };

  const confirmBack = (back, cancel) => {
    const endProgress = window.confirm(t('new_quickbooks_page.dialog.prevent_close_text'));

    if (endProgress && back) {
      back();
    } else if (cancel) {
      cancel();
    }
  };

  useEffect(() => {
    if (!QboRequestCommit?.qboRealmId && !QboAuthRequestId) {
      navigate(newQuickbooksPath, { replace: true });
    } else {
      window.onbeforeunload = () => {
        return t('alert.dont_close');
      };
    }

    return () => {
      window.onbeforeunload = null;
      dispatch(resetQboRequestCommit());
    };
  }, []);

  useEffect(() => {
    // Start Backup Request Success
    if (QboRequestCommit?.qboRealmId) {
      dispatch(setRequireInitialSetup(false));
      navigate(dashboardPath, { replace: true });

      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_QBO_ADDED_MESSAGE, {
          company_name: confirmation?.companyName,
        }),
        html: true,
      };

      setTimeout(() => {
        updateSuccessAlert(alertPayload);
      }, 100);
    }
    // Start due to exist in different org
    else if (error?.code === ErrorResponse.RecordAlreadyExists) {
      updateErrorAlert(
        getErrorMessage(konstMessage.ERROR_CONFIRM_QBO_ADDED_DIFFERENT_ORG, {
          company_name: confirmation?.companyName,
        })
      );
    }
    // NFR when limit reached
    else if (error?.code === ErrorResponse.UnprocessableFormat) {
      if (error?.payload?.errors.find((e) => e.error_code === ErrorNFRQuotaExceeded)) {
        navigate(newQuickbooksPath, { replace: true });

        setTimeout(() => {
          updateErrorAlert(getErrorMessage(konstMessage.ERROR_NFR));
        }, 100);
      }
    }

    // fallback error message
    else if (error) {
      updateErrorAlert(getErrorMessage(konstMessage.ERROR_CONFIRM_QBO_DEFAULT));
    }
  }, [error, QboRequestCommit]);

  const confirmData = [
    {
      headingText: t('quickbook_backup_confirm_page.accordion_dialog.company_name_label'),
      headingSubtitle: confirmation?.companyName || '',
      icon: <BusinessIcon />,
    },
    {
      headingText: t('quickbook_backup_confirm_page.accordion_dialog.backup_frequency_label'),
      headingSubtitle: confirmation?.backupFrequency,
      icon: <HourglassEmptyIcon />,
    },
    {
      headingText: t('quickbook_backup_confirm_page.accordion_dialog.backup_objects'),
      headingSubtitle: t('quickbook_backup_confirm_page.accordion_dialog.number_object', {
        count: confirmation?.backupObjects?.length || 0,
      }),
      isFullContent: true,
      icon: <FolderOutlinedIcon />,
      children: (
        <QboSimpleTable
          header={[
            {
              text: '#',
              align: 'right',
              size: 'xs',
              columnName: 'number',
            },
            {
              text: t('quickbook_backup_confirm_page.table.object_name_label'),
              columnName: 'objectName',
            },
          ]}
          contents={contructBackupObjects(confirmation?.backupObjects)}
        />
      ),
    },
  ];

  return {
    confirmData,
    QboAuthRequestId,
    isRequestingBackup,
    isAlertOpen,
    alert,
    closeAlert,
    confirmBack,
  };
}
