//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboFetchStatusRestoreAsync,
  qboFetchStatusRestoreExtraReducers,
} from './serviceActions/fetchStatusRestoreAsync';
import {
  getStatusRestoreAsync,
  getQboRestoreStatusExtraReducers,
} from './serviceActions/getStatusRestoreAsync';

const initialState = {
  QboStatusRestore: {
    value: [],
    nextLink: null,
    prevLink: null,
    showPagination: false,
  },
  fetchingQboStatusRestore: true,
  error: null,
};

const qboStatusRestoreSlice = createSlice({
  name: 'QboStatusRestore',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    updateFetching(state, action) {
      state.fetchingQboStatusRestore = action.payload;
    },
  },
  extraReducers: {
    ...qboFetchStatusRestoreExtraReducers,
    ...getQboRestoreStatusExtraReducers,
  },
});

export const { resetState, updateFetching } = qboStatusRestoreSlice.actions;
export { qboFetchStatusRestoreAsync, getStatusRestoreAsync };

export default qboStatusRestoreSlice.reducer;
