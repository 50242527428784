import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { qboFetchObjectsAsync, resetState } from '@features/qboObjects/qboObjectsSlice';
import { pascalToTitleCase } from '@utilities';
import { QboLink, QboTypography } from '@ui/Components';
import {
  Folder as FolderIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';

const defaultOrder = 'asc';
const defaultOrderColumn = 'ObjectName';
const defaultRowPerPage = 200;

const folderIconColumn = 'folderIcon';
const displayObjectNameColumn = 'ObjectName';
const recordBackupColumn = 'TotalRecords';
const arrowIconColumn = 'arrowIcon';

const createHeader = (id, label, columnName, orderable, size, align) => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: id === 1,
    align,
    noPadding: columnName === folderIconColumn,
  };
};

const createData = (id, objectName, totalRecords) => {
  return {
    id: objectName,
    folderIcon: <FolderIcon className="QboTable__icon" />,
    isLink: true,
    ObjectName: (
      <QboTypography variant="subtitle2" color="secondary" withTitle>
        {pascalToTitleCase(objectName)}
      </QboTypography>
    ),
    objectName,
    TotalRecords: (
      <QboTypography color="primary" withTitle>
        {totalRecords}
      </QboTypography>
    ),
    arrowIcon: (
      <QboLink className="QboTableCell__icon">
        <KeyboardArrowRightIcon />
      </QboLink>
    ),
  };
};

const useFetchObjects = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderColumn);

  const { QboObjects, fetchingQboObjects } = useSelector((state) => state.qboObjects);

  const tableHeaders = [
    createHeader(1, null, folderIconColumn, false, 'xxs', 'center'),
    createHeader(
      2,
      t('backup_realm_page.table.object_name_column'),
      displayObjectNameColumn,
      true,
      'md',
      'left'
    ),
    createHeader(
      3,
      t('backup_realm_page.table.record_number'),
      recordBackupColumn,
      true,
      'auto',
      'left'
    ),
    createHeader(4, null, arrowIconColumn, false, 'icon', 'right'),
  ];

  const tableRows = QboObjects.value.map((item) =>
    createData(item.id, item.objectName, item.totalRecords)
  );

  useEffect(() => {
    dispatch(
      qboFetchObjectsAsync({
        qboRealmId: id,
        order,
        orderBy,
        defaultRowPerPage,
      })
    );

    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (QboObjects.value && QboObjects.value.length > 0) {
      dispatch(
        qboFetchObjectsAsync({
          qboRealmId: id,
          order,
          orderBy,
          defaultRowPerPage,
        })
      );
    }
  }, [order, orderBy]);

  return {
    QboObjects,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableHeaders,
    tableRows,
    loading: fetchingQboObjects,
    listIdOnPage: tableRows.map((x) => x.id).filter((x) => x !== undefined),
  };
};

export default useFetchObjects;
