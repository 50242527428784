import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  QboModal,
  QboTypography,
  QboDatePicker,
  QboTimePicker,
  QboTextField,
  QboTipsBox,
  QboDropdown,
  QboConnectButton,
  QboCheckbox,
  QboButton,
  QboLink,
} from '@ui/Components';
import Box from '@mui/material/Box';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InfoIcon from '@mui/icons-material/Info';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DomainIcon from '@mui/icons-material/Domain';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useDateTime } from '@hooks';
import { intuitQboLogo } from '@images/quickbooks';
import useQboOauth, { REPLICATE } from '@pages/sharedHooks/useQboOauth';
import { listEnvironment } from '@pages/DashboardPage/pageHooks/useRealmReplicate';
import { QboModalListItem, QboModalCardList } from '@ui/Components/QboModal';
import ReplicateGuideSection from '@ui/Sections/DashboardPage/ReplicateGuideSection';

const TypeModalConfirmation = 'confirmation';
const TypeModalValidation = 'validation';
const TextValidationConfirmation = 'I UNDERSTAND';

function CreateDescription({ children, labelButton, onClick }) {
  return (
    <div className="ModalConfirmationValidationReplicate__description-wrapper">
      <span
        className="ModalConfirmationValidationReplicate__description-text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      />
      {labelButton ? (
        <QboLink
          className="ModalConfirmationValidationReplicate__button-guide"
          onClick={() => onClick()}>
          {labelButton}
          <ArrowUpwardIcon />
        </QboLink>
      ) : null}
    </div>
  );
}
CreateDescription.propTypes = {
  children: PropTypes.string,
  labelButton: PropTypes.string,
  onClick: PropTypes.func,
};
CreateDescription.defaultProps = {
  children: '',
  labelButton: '',
  onClick: () => {},
};

function CreateListField({ children, title }) {
  return (
    <div className="ModalReplicate__list-field-wrapper">
      <QboTypography noWrap={false} variant="subtitle2">
        {title}
      </QboTypography>
      {children}
    </div>
  );
}
CreateListField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  title: PropTypes.string,
};
CreateListField.defaultProps = {
  children: null,
  title: '',
};

export default function ModalReplicateCardBlock({
  onClose,
  onNextButton,
  onBackButton,
  onGenerateLink,
  onOpenGuide,
  showModalReplicate,
  showModalConfirmation,
  showModalConfirmationValidation,
  selectedTime,
  timeValue,
  setTimeValue,
  dateValue,
  setDateValue,
  notes,
  setNotes,
  minDate,
  itemSelectedText,
  children,
  environment,
  setEnvironment,
  checkboxConfirmation,
  setCheckboxConfirmation,
  checkboxFollowGuide,
  setCheckboxFollowGuide,
  checkFormIsValid,
  textConfirmation,
  setTextConfirmation,
  realmId,
  responseOAuth,
  setResponseOAuth,
  getErrorMessages,
}) {
  const { t } = useTranslation();
  const { stringToDate, addDateTime } = useDateTime();
  const minDateFormatted = minDate ? stringToDate(minDate) : {};
  const addedMinDate = minDateFormatted ? addDateTime(minDateFormatted, 2, 'minutes') : {};
  addedMinDate?.second(0);
  const listEnvironmentOptions = listEnvironment.map((item) => {
    return [
      item,
      <Box>
        <QboTypography>{t(item.label)}</QboTypography>
      </Box>,
    ];
  });
  const { openOauthWindow, isButtonActive } = useQboOauth(REPLICATE, {
    environment: environment?.value,
    qboRealmId: realmId,
    successCallback: setResponseOAuth,
    errorCallback: setResponseOAuth,
  });

  const renderBodyModal = (type = '') => {
    if (type === TypeModalConfirmation) {
      return (
        <Box className="ModalConfirmationReplicate__wrapper">
          <Box className="ModalConfirmationReplicate__preview-title">
            <QboTypography variant="subtitle2">
              {t('dashboard_page.modal_replicate.helper_modal_confirmation')}
            </QboTypography>
          </Box>
          <Box className="ModalReplicate__preview-wrapper">
            <QboModalCardList>
              <QboModalListItem
                icon={<InsertDriveFileOutlinedIcon />}
                name={t('dashboard_page.modal_replicate.item_text_label')}
                description1={itemSelectedText}
              />
              <QboModalListItem
                icon={<Inventory2OutlinedIcon />}
                name={t('dashboard_page.modal_replicate.point_in_time_text_label')}
                description1={selectedTime}
              />
            </QboModalCardList>
            <QboModalCardList>
              <QboModalListItem
                icon={<DomainIcon />}
                name={t('dashboard_page.modal_replicate.replicate_to_text_label')}
                description1={responseOAuth?.companyName}
                description2={responseOAuth?.emailAddress}
              />
              <QboModalListItem
                icon={<StorageRoundedIcon />}
                name={t('dashboard_page.modal_replicate.environment_to_text_label')}
                description1={t(environment?.label)}
              />
            </QboModalCardList>
          </Box>
          <Box className="ModalConfirmationReplicate__notes">
            <div className="ModalConfirmationReplicate__notes-label">
              <QboTypography>{t('dashboard_page.modal_replicate.notes_text_label')}</QboTypography>
              <QboTypography>
                {t('dashboard_page.modal_replicate.optional_text_label')}
              </QboTypography>
            </div>
            <QboTextField
              className="ModalConfirmationReplicate__text-field"
              placeholder={t('dashboard_page.modal_replicate.placeholder_notes')}
              value={notes}
              onChange={(e) => {
                setNotes(e.currentTarget.value);
              }}
              multiline
              rows={4}
              inputProps={{ maxLength: 255 }}
            />
          </Box>

          <QboTipsBox
            className="ModalConfirmationReplicate_tips-box-guides"
            __html={t('dashboard_page.modal_replicate.tips_box_modal_confirmation_html')}
            variant="info"
            icon={<InfoIcon />}
          />
        </Box>
      );
    }

    if (type === TypeModalValidation) {
      return (
        <Box className="ModalConfirmationValidationReplicate__wrapper">
          <CreateDescription
            onClick={() => onOpenGuide()}
            labelButton={t('dashboard_page.modal_replicate.tips_box_button_guide')}>
            {t('dashboard_page.modal_replicate.validation_description_1_html')}
          </CreateDescription>
          <CreateDescription>
            {t('dashboard_page.modal_replicate.validation_description_2_html')}
          </CreateDescription>
          <QboTextField
            className="ModalConfirmationValidationReplicate__text-field"
            placeholder={t('dashboard_page.modal_replicate.placeholder_confirmation')}
            value={textConfirmation}
            onChange={(e) => {
              setTextConfirmation(e.currentTarget.value);
            }}
            inputProps={{ maxLength: 255 }}
            fullWidth
          />
        </Box>
      );
    }

    return (
      <Box className="ModalReplicate__wrapper">
        <CreateListField title={t('dashboard_page.modal_replicate.label_date_time')}>
          <Box className="ModalReplicate__date-time">
            <QboDatePicker
              value={dateValue}
              onChange={setDateValue}
              placeholder="DD/MM/YYYY"
              minDate={addedMinDate}
            />
            <QboTimePicker
              value={timeValue}
              onChange={setTimeValue}
              placeholder="HH:MM"
              minTime={addedMinDate}
              valueFromDate={dateValue}
            />
          </Box>
          <QboTypography variant="caption">
            {t('dashboard_page.modal_replicate.helper_modal_date_time')}
          </QboTypography>
        </CreateListField>
        <CreateListField title={t('dashboard_page.modal_replicate.label_environment')}>
          <QboDropdown
            menuItems={listEnvironmentOptions}
            onClickItem={setEnvironment}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            disabled={responseOAuth?.success}
            className="ModalReplicate__dropdown-environment">
            <QboTypography>{t(environment?.label)}</QboTypography>
          </QboDropdown>
        </CreateListField>
        <CreateListField title={t('dashboard_page.modal_replicate.label_replicate_to')}>
          {!responseOAuth?.success ? (
            <Box
              className={`ModalReplicate__quickbooks_auth ${
                responseOAuth.error ? 'ModalReplicate__quickbooks_auth_error' : ''
              }`}>
              <img src={intuitQboLogo} alt="" className="Img__fluid" />
              <QboTypography>{t('new_quickbooks_page.main.caption')}</QboTypography>
              <QboConnectButton disabled={!isButtonActive} onClick={openOauthWindow} />
            </Box>
          ) : null}
          {responseOAuth?.error ? (
            <Box className="ModalReplicate__error_message">
              <InfoRoundedIcon />
              <QboTypography noWrap={false} variant="caption">
                {t(getErrorMessages(responseOAuth?.error?.code))}
              </QboTypography>
            </Box>
          ) : null}
          {responseOAuth?.success ? (
            <Box className="ModalReplicate__preview-wrapper">
              <QboModalCardList className="ModalReplicate__preview-box">
                <QboModalListItem
                  icon={<DomainIcon />}
                  description1={responseOAuth?.companyName}
                  description2={responseOAuth?.emailAddress}
                />
                <QboButton variant="text" onClick={() => setResponseOAuth({})}>
                  {t('button.remove')}
                </QboButton>
              </QboModalCardList>
            </Box>
          ) : null}
          <QboTipsBox
            className="ModalReplicate__disclaimer-wrapper"
            __html={t('dashboard_page.modal_replicate.disclaimer_html')}
          />
        </CreateListField>
        <QboCheckbox
          className="ModalReplicate__checkbox_confirmation"
          checked={checkboxConfirmation}
          onChange={setCheckboxConfirmation}
          label={t('dashboard_page.modal_replicate.checkbox_confirmation_text')}
        />
        <QboCheckbox
          className="ModalReplicate__checkbox_confirmation"
          checked={checkboxFollowGuide}
          onChange={setCheckboxFollowGuide}
          label={t('dashboard_page.modal_replicate.checkbox_follow_guide_text')}
        />
      </Box>
    );
  };

  return (
    <>
      <QboModal
        className="ModalReplicate__default"
        onClose={() => onClose()}
        open={showModalReplicate}
        title={t('dashboard_page.modal_replicate.title_modal')}
        button={{
          text: t('dashboard_page.modal_replicate.next_text_button'),
          onClick: () => onNextButton(),
          disabled: !checkFormIsValid,
        }}
        preventOutsideClick
        sideContent={<ReplicateGuideSection />}
        sideContentTitle={t('dashboard_page.modal_replicate.side_content_title_modal')}>
        {renderBodyModal()}
      </QboModal>
      <QboModal
        className="ModalReplicate__default"
        onClose={() => onClose()}
        open={showModalConfirmation}
        title={t('dashboard_page.modal_replicate.title_modal_confirmation')}
        button={[
          {
            text: t('dashboard_page.modal_replicate.back_text_button'),
            onClick: () => onBackButton(),
          },
          {
            text: t('dashboard_page.modal_replicate.replicate_text_button'),
            onClick: () => onNextButton(true),
            disabled: !(notes?.length >= 1) || !checkFormIsValid,
          },
        ]}
        preventOutsideClick
        sideContent={<ReplicateGuideSection />}
        sideContentTitle={t('dashboard_page.modal_replicate.side_content_title_modal')}>
        {renderBodyModal(TypeModalConfirmation)}
        {children}
      </QboModal>
      <QboModal
        onClose={() => onClose()}
        open={showModalConfirmationValidation}
        title={t('dashboard_page.modal_replicate.title_modal_confirmation')}
        button={[
          {
            text: t('dashboard_page.modal_replicate.back_text_button'),
            onClick: () => onBackButton(true),
          },
          {
            text: t('dashboard_page.modal_replicate.replicate_text_button'),
            onClick: () => onGenerateLink(),
            disabled:
              !(notes?.length >= 1) ||
              !checkFormIsValid ||
              !(textConfirmation === TextValidationConfirmation),
          },
        ]}
        preventOutsideClick>
        {renderBodyModal(TypeModalValidation)}
        {children}
      </QboModal>
    </>
  );
}

ModalReplicateCardBlock.propTypes = {
  onClose: PropTypes.func,
  onNextButton: PropTypes.func,
  onBackButton: PropTypes.func,
  onGenerateLink: PropTypes.func,
  onOpenGuide: PropTypes.func,
  showModalReplicate: PropTypes.bool,
  showModalConfirmation: PropTypes.bool,
  showModalConfirmationValidation: PropTypes.bool,
  selectedTime: PropTypes.string,
  minDate: PropTypes.string,
  dateValue: PropTypes.object,
  setDateValue: PropTypes.func,
  timeValue: PropTypes.object,
  setTimeValue: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  itemSelectedText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  environment: PropTypes.object,
  setEnvironment: PropTypes.func,
  checkboxConfirmation: PropTypes.bool,
  setCheckboxConfirmation: PropTypes.func,
  checkboxFollowGuide: PropTypes.bool,
  setCheckboxFollowGuide: PropTypes.func,
  checkFormIsValid: PropTypes.bool,
  textConfirmation: PropTypes.string,
  setTextConfirmation: PropTypes.func,
  realmId: PropTypes.string,
  responseOAuth: PropTypes.object,
  setResponseOAuth: PropTypes.func,
  getErrorMessages: PropTypes.func,
};

ModalReplicateCardBlock.defaultProps = {
  onClose: () => {},
  onNextButton: () => {},
  onBackButton: () => {},
  onGenerateLink: () => {},
  onOpenGuide: () => {},
  showModalReplicate: false,
  showModalConfirmation: false,
  showModalConfirmationValidation: false,
  selectedTime: '',
  minDate: '',
  dateValue: {},
  setDateValue: () => {},
  timeValue: {},
  setTimeValue: () => {},
  notes: '',
  setNotes: () => {},
  itemSelectedText: '',
  children: null,
  environment: {},
  setEnvironment: () => {},
  checkboxConfirmation: false,
  setCheckboxConfirmation: () => {},
  checkboxFollowGuide: false,
  setCheckboxFollowGuide: () => {},
  checkFormIsValid: false,
  textConfirmation: '',
  setTextConfirmation: () => {},
  realmId: '',
  responseOAuth: {},
  setResponseOAuth: () => {},
  getErrorMessages: () => {},
};
