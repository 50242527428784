import React from 'react';
import PropTypes from 'prop-types';
import { QboLink, QboTypography } from '@ui/Components';

/* eslint-disable react/no-danger */
export default function QboTipsBox({
  __html,
  children,
  variant,
  className,
  icon,
  showButton,
  classNameButton,
  textButton,
  endIconButton,
  onClick,
  withTitle,
}) {
  const renderIcon = () => {
    if (!icon) return null;
    return <div className="QboTipsBox__corner-icon">{icon}</div>;
  };
  return (
    <div className={`QboTipsBox__wrapper QboTipsBox__${variant}-variant ${className}`}>
      <div dangerouslySetInnerHTML={{ __html }} className="QboTipsBox__text" />
      {children}
      {showButton && (
        <QboLink
          className={`QboTipsBox__action-button ${classNameButton}`}
          onClick={() => onClick()}>
          <QboTypography withTitle={withTitle}>{textButton}</QboTypography>
          {endIconButton}
        </QboLink>
      )}
      {renderIcon()}
    </div>
  );
}

QboTipsBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  variant: PropTypes.string,
  className: PropTypes.string,
  __html: PropTypes.string,
  icon: PropTypes.node,
  showButton: PropTypes.bool,
  classNameButton: PropTypes.string,
  textButton: PropTypes.string,
  onClick: PropTypes.func,
  endIconButton: PropTypes.node,
  withTitle: PropTypes.bool,
};

QboTipsBox.defaultProps = {
  variant: 'primary',
  className: '',
  children: null,
  __html: '',
  icon: null,
  showButton: false,
  classNameButton: '',
  textButton: '',
  onClick: () => {},
  endIconButton: null,
  withTitle: false,
};
