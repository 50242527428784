import { constant } from '@config/BaseSetting';
import { useLocalStorage } from '@hooks';

export default function usePageMenu() {
  const [activeMenu, setActivePageMenu] = useLocalStorage(constant.MENU_STORAGE);
  const isActiveMenu = (menu) => menu === activeMenu;

  return {
    setActivePageMenu,
    isActiveMenu,
    listMenu: constant.MENUS,
  };
}
