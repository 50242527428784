import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboCardWrapper } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import AccountSettingsMenuSection from '@ui/Sections/AccountSettingsPage/AccountSettingsMenuSection';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import Setup2FASection from '@ui/Sections/AccountSettingsPage/Setup2FASection';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import ModalQRCode2FABlock from '@ui/Blocks/AccountSettingsPageBlocks/ModalQRCode2FABlock';
import useSetup2FA from '@pages/SettingSetup2FAPage/pageHooks/useSetup2FA';
import ModalDisable2FABlock from '@ui/Blocks/AccountSettingsPageBlocks/ModalDisable2FABlock';
import useDisable2FA from '@pages/SettingSetup2FAPage/pageHooks/useDisable2FA';
import ModalReEnable2FABlock from '@ui/Blocks/AccountSettingsPageBlocks/ModalReEnable2FABlock';
import useReEnable2FA from '@pages/SettingSetup2FAPage/pageHooks/useReEnable2FA';
import ModalReset2FABlock from '@ui/Blocks/AccountSettingsPageBlocks/ModalReset2FABlock';
import useReset2FA from '@pages/SettingSetup2FAPage/pageHooks/useReset2FA';

export default function SettingChangePasswordPage() {
  const { t } = useTranslation();

  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.ACCOUNT_SETUP_2FA_MENU);

  const {
    verificationType,
    changeVerificationType,
    showQRCodeModal,
    showAuthFormModal,
    onOpenModalSetup2FA,
    onCloseModal,
    onNextButton,
    onBackButton,
    hasSetup2FA,
    hasEnable2FA,
    verifiedDate,
    loading,
    qrKey,
    secretKey,
    loadingQRKey,
    errorValidation,
    loadingSetup,
  } = useSetup2FA();

  const {
    showDisableConfirmationModal,
    showDisableAuthFormModal,
    onOpenModalDisable2FA,
    onCloseDisableModal,
    onNextDisable2FAButton,
    onBackDisable2FAButton,
    loadingDisable,
  } = useDisable2FA();

  const {
    showReEnableConfirmationModal,
    onOpenModalReEnable2FA,
    onCloseReEnableModal,
    onNextReEnable2FAButton,
    loadingReEnable,
  } = useReEnable2FA();

  const {
    showResetConfirmationModal,
    showResetAuthFormModal,
    onOpenModalReset2FA,
    onCloseResetModal,
    onNextReset2FAButton,
    onBackReset2FAButton,
    loadingReset,
  } = useReset2FA();

  return (
    <div className="SettingChangePasswordPage__wrapper">
      <HeaderPageBlock>{t('account_settings_change_password_page.header.title')}</HeaderPageBlock>

      <AlertPageSection />

      <QboCardWrapper layout="double" leftContent={<AccountSettingsMenuSection />}>
        <Setup2FASection
          onOpenModalSetup2FA={onOpenModalSetup2FA}
          hasSetup2FA={hasSetup2FA}
          hasEnable2FA={hasEnable2FA}
          verifiedDate={verifiedDate}
          onOpenModalDisable2FA={onOpenModalDisable2FA}
          onOpenModalReEnable2FA={onOpenModalReEnable2FA}
          onOpenModalReset2FA={onOpenModalReset2FA}
          loading={loading}
        />
      </QboCardWrapper>

      <ModalQRCode2FABlock
        showQRCode={showQRCodeModal}
        showAuthForm={showAuthFormModal}
        type={verificationType}
        changeTypeAuth={changeVerificationType}
        onClose={() => onCloseModal()}
        onNextButton={onNextButton}
        onBackButton={() => onBackButton()}
        qrKey={qrKey}
        secretKey={secretKey}
        loading={loadingQRKey}
        errorValidation={errorValidation}
        loadingForm={loadingSetup}
      />

      <ModalDisable2FABlock
        showDisableConfirmationModal={showDisableConfirmationModal}
        showDisableAuthFormModal={showDisableAuthFormModal}
        onCloseDisableModal={onCloseDisableModal}
        onNextDisable2FAButton={onNextDisable2FAButton}
        onBackDisable2FAButton={onBackDisable2FAButton}
        errorValidation={errorValidation}
        loadingForm={loadingDisable}
      />

      <ModalReEnable2FABlock
        showReEnableConfirmationModal={showReEnableConfirmationModal}
        onCloseReEnableModal={onCloseReEnableModal}
        onNextReEnable2FAButton={onNextReEnable2FAButton}
        loading={loadingReEnable}
      />

      <ModalReset2FABlock
        showResetConfirmationModal={showResetConfirmationModal}
        showResetAuthFormModal={showResetAuthFormModal}
        onCloseResetModal={onCloseResetModal}
        onNextReset2FAButton={onNextReset2FAButton}
        onBackReset2FAButton={onBackReset2FAButton}
        errorValidation={errorValidation}
        loadingForm={loadingReset}
      />
    </div>
  );
}
