import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { childrenToString, pascalToTitleCase } from '@utilities';

export default function QboTypography({ sx, className, children, withTitle, wrap, ...rest }) {
  const styles = {
    fontFamily: 'Inter',
    ...(Object.keys(rest).includes('variant') ? {} : { fontSize: '1.4rem' }),
  };

  return (
    <Typography
      title={withTitle ? childrenToString(children) : null}
      noWrap={!wrap}
      className={`${className} ${
        Object.keys(rest).includes('color')
          ? `MuiTypography-color${pascalToTitleCase(rest.color)}`
          : ''
      }`}
      sx={{
        ...styles,
        ...sx,
      }}
      {...rest}>
      {children}
    </Typography>
  );
}

QboTypography.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  withTitle: PropTypes.bool,
  className: PropTypes.string,
  wrap: PropTypes.bool,
};

QboTypography.defaultProps = {
  sx: {},
  children: '',
  withTitle: false,
  className: '',
  wrap: false,
};
