import StorageError from './StorageError';
import LocalStorage from './LocalStorage';

class StorageFactory {
  constructor(options, storageType) {
    this.storageType = storageType;
    this.options = options;
  }

  create() {
    if (this.storageType === 'localStorage') {
      return new LocalStorage(this.options);
    }

    throw new StorageError('storageType is not defined.');
  }
}

export default StorageFactory;
