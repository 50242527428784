import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useDrawerRealmInfo() {
  const [selectedRealmId, setSelectedRealmId] = useState(null);
  const { QboRealms } = useSelector((state) => state.qboRealms);

  const drawerIsOpen = selectedRealmId !== null;
  const QboRealm = QboRealms.value.find((v) => v.id === selectedRealmId);

  return {
    drawerIsOpen,
    setOpenDrawer: (id) => {
      setSelectedRealmId(id);
    },
    closeDrawer: () => {
      setSelectedRealmId(null);
    },
    QboRealm,
  };
}
