import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  dashboardPath,
  loginSSOPath,
  newQuickbooksPath,
  oauthQuickbooksCallbackPath,
  confirmQuickbooksPath,
  showRealmPath,
  showRealmObjectPath,
  showRealmObjectContentPath,
  searchRealmObjectItemPath,
  settingChangePasswordPath,
  settingSetup2FAPath,
  backupStatusPath,
  downloadStatusPath,
  restoreStatusPath,
  replicateStatusPath,
  notFoundPath,
  errorPath,
} from '@config/Routes/WebClientRoutes';
import {
  BackupRealmObjectPage,
  BackupRealmItemPage,
  DashboardPage,
  SearchRealmItemPage,
  SSOPage,
  SystemStatusPage,
  SettingChangePasswordPage,
  SettingSetup2FAPage,
  NewQuickbooksPage,
  OauthCallbackQuickbooksPage,
  QuickbookConfirmBackupPage,
  NotFoundPage,
  ErrorPage,
} from '@pages';

export default function Navigation() {
  return (
    <Routes>
      <Route path={loginSSOPath} element={<SSOPage />} />
      <Route path={dashboardPath} element={<DashboardPage />} />
      <Route path={backupStatusPath} element={<SystemStatusPage />} />
      <Route path={newQuickbooksPath} element={<NewQuickbooksPage />} />
      <Route path={oauthQuickbooksCallbackPath} element={<OauthCallbackQuickbooksPage />} />
      <Route path={confirmQuickbooksPath} element={<QuickbookConfirmBackupPage />} />
      <Route path={showRealmPath} element={<BackupRealmObjectPage />} />
      <Route path={showRealmObjectPath} element={<BackupRealmItemPage />} />
      <Route path={showRealmObjectContentPath} element={<BackupRealmItemPage />} />
      <Route path={searchRealmObjectItemPath} element={<SearchRealmItemPage />} />
      <Route path={settingChangePasswordPath} element={<SettingChangePasswordPage />} />
      <Route path={downloadStatusPath} element={<SystemStatusPage />} />
      <Route path={restoreStatusPath} element={<SystemStatusPage />} />
      <Route path={replicateStatusPath} element={<SystemStatusPage />} />
      <Route path={settingSetup2FAPath} element={<SettingSetup2FAPage />} />
      <Route path={notFoundPath} element={<NotFoundPage />} />
      <Route path={errorPath} element={<ErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
