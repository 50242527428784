import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboItemsAPI } from '@services/WebApiService';
import { canShowPagination } from '@utilities';

const qboFetchObjectItemsAsync = createAsyncThunk(
  'QboObjectItems/fetchQboObjectItems',
  async (
    { qboRealmId, objectName, pageLink, order, orderBy, top, skip, query, statuses },
    { getState }
  ) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboItemsAPI(accessToken, qboRealmId, objectName, pageLink, {
      top,
      skip,
      order,
      orderBy,
      query,
      intuitDeletedStatus: statuses,
    });
    return data;
  }
);

const { pending, fulfilled, rejected } = qboFetchObjectItemsAsync;

const qboFetchObjectItemsExtraReducers = {
  [pending]: (state) => {
    state.fetchingQboObjectItems = true;
  },
  [rejected]: (state) => {
    state.fetchingQboObjectItems = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboObjectItems = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action?.payload?.companyName) {
      const showPagination = canShowPagination(state.QboObjectItems.showPagination, action);

      const {
        companyName,
        backupStatus,
        lastCompletedBackupAt,
        lastBackupAt,
        totalRecords,
        initialBackupAt,
        value,
        nextLink,
        prevLink,
      } = action.payload;

      state.QboObjectItems = {
        companyName,
        backupStatus,
        lastCompletedBackupAt,
        lastBackupAt,
        totalRecords,
        initialBackupAt,
        value,
        nextLink,
        prevLink,
        showPagination,
      };
    }
  },
};

export { qboFetchObjectItemsAsync, qboFetchObjectItemsExtraReducers };
