import { useState } from 'react';
import BaseSetting from '@config/BaseSetting';
import StorageFactory from '@libs/Storage';

const storageFactory = new StorageFactory(
  { keyStoragePrefix: 'QBO_USE_PAGE_NUMBER' },
  'localStorage'
);

const storageClient = storageFactory.create();

const usePageNumber = (key) => {
  const [number, setNumber] = useState(
    storageClient.get(key) || BaseSetting.paginationPerPageDefault
  );

  const setPageNumber = (value) => {
    setNumber(value);
    storageClient.save(key, value);
  };

  return [number, setPageNumber];
};

export default usePageNumber;
