import { useDispatch, useSelector } from 'react-redux';
import { validateAuthForm2FAAsync, resetAuthform2FA } from '@features/account/accountSlice';
import { useState } from 'react';
import ErrorResponse from '@libs/ErrorResponse';

const ActionTypeSetup = 'setup';
const ActionTypeDisable = 'disable';
const ActionTypeReEnable = 're-enable';
const ActionTypeReset = 'reset';

const ConstantActionTypes = {
  ActionTypeSetup,
  ActionTypeDisable,
  ActionTypeReEnable,
  ActionTypeReset,
};

const ErrorInvalidPassword = 'invalid_password';
const ErrorInvalidAuthCode = 'invalid_code';

export default function useAuthForm2FA() {
  const { authForm2FA } = useSelector((state) => state.account);
  const { submitting, success, error } = authForm2FA;

  const [authCode, setAuthCode] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('');

  const dispatch = useDispatch();

  const handleSubmitAuthForm = (payload = {}) => {
    dispatch(validateAuthForm2FAAsync(payload));
  };

  const formValidation = (withoutPassword = false, withoutAuth = false) => {
    let passValidation = false;
    if (withoutPassword) {
      passValidation = authCode?.length >= 1;
    } else if (withoutAuth) {
      passValidation = password?.length >= 1;
    } else {
      passValidation = password?.length >= 1 && authCode?.length >= 1;
    }

    return passValidation;
  };

  const getErrorResponse = () => {
    const errorResponse = {
      banner: false,
      authCode: false,
      password: false,
    };
    const errors = error?.payload?.errors;

    if (error?.code === ErrorResponse.UnauthorizedRecords) {
      errorResponse.authCode = !!errors?.find((e) => e.error_code === ErrorInvalidAuthCode);
      errorResponse.password = !!errors?.find((e) => e.error_code === ErrorInvalidPassword);
    } else {
      errorResponse.banner = true;
    }

    return errorResponse;
  };

  return {
    authCode,
    setAuthCode: (value) => {
      const regex = /^[0-9\b]+$/;
      if (regex.test(value)) {
        setAuthCode(value);
      } else {
        setAuthCode('');
      }
    },
    password,
    setPassword,
    handleSubmitAuthForm: (payload) => {
      setType(payload?.type);
      handleSubmitAuthForm(payload);
    },
    submittingForm: submitting,
    successValidation: success,
    errorValidation: getErrorResponse(),
    resetAuthform2FA,
    type,
    ConstantActionTypes,
    formValidation,
    resetType: () => {
      setType('');
    },
  };
}
