import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboCardWrapper } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import AccountSettingsMenuSection from '@ui/Sections/AccountSettingsPage/AccountSettingsMenuSection';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import ChangePasswordFormSection from '@ui/Sections/AccountSettingsPage/ChangePasswordFormSection';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import useChangePasswordForm from './pageHooks/useChangePasswordForm';

export default function SettingChangePasswordPage() {
  const { t } = useTranslation();

  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.ACCOUNT_CHANGE_PASSWORD_MENU);

  const {
    currentPasswordAttributes,
    newPasswordAttributes,
    confirmationPasswordAttributes,
    listRequirement,
    isValid,
    onSubmit,
    submitting,
  } = useChangePasswordForm();

  return (
    <div className="SettingChangePasswordPage__wrapper">
      <HeaderPageBlock>{t('account_settings_change_password_page.header.title')}</HeaderPageBlock>

      <AlertPageSection />

      <QboCardWrapper layout="double" leftContent={<AccountSettingsMenuSection />}>
        <ChangePasswordFormSection
          listRequirement={listRequirement}
          currentPassword={currentPasswordAttributes}
          newPassword={newPasswordAttributes}
          confirmationPassword={confirmationPasswordAttributes}
          onSubmit={onSubmit}
          disabled={!isValid}
          submitting={submitting}
        />
      </QboCardWrapper>
    </div>
  );
}
