import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useAlertMessage, usePageAlert } from '@hooks';
import useAuthForm2FA from '@pages/SettingSetup2FAPage/pageHooks/useAuthForm2FA';

export default function useReset2FA() {
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const {
    submittingForm,
    successValidation,
    errorValidation,
    handleSubmitAuthForm,
    resetAuthform2FA,
    type,
    ConstantActionTypes,
    resetType,
  } = useAuthForm2FA();

  const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
  const [showResetAuthFormModal, setResetShowAuthFormModal] = useState(false);

  const dispatch = useDispatch();

  const resetState = () => {
    setShowResetConfirmationModal(false);
    setResetShowAuthFormModal(false);
    dispatch(resetAuthform2FA());
    resetType();
  };

  const onSubmitAuthForm = (params) => {
    const payload = {
      password: params.password,
      type: ConstantActionTypes.ActionTypeReset,
    };

    handleSubmitAuthForm(payload);
  };

  useEffect(() => {
    if (!submittingForm && type === ConstantActionTypes.ActionTypeReset) {
      if (successValidation) {
        updateSuccessAlert(getSuccessMessage(konstMessage.SUCCESS_RESET_2FA));
        resetState();
      } else if (errorValidation.banner) {
        updateErrorAlert(getErrorMessage(konstMessage.ERROR_RESET_2FA));
        resetState();
      }
    }
  }, [submittingForm]);

  return {
    showResetConfirmationModal,
    showResetAuthFormModal,
    onOpenModalReset2FA: () => {
      setShowResetConfirmationModal(true);
    },
    onCloseResetModal: () => {
      resetState();
    },
    onNextReset2FAButton: (finish, payload) => {
      if (finish) {
        onSubmitAuthForm(payload);
      } else {
        setShowResetConfirmationModal(false);
        setResetShowAuthFormModal(true);
      }
    },
    onBackReset2FAButton: () => {
      setShowResetConfirmationModal(true);
      setResetShowAuthFormModal(false);
    },
    errorValidation,
    loadingReset: submittingForm,
  };
}
