import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generateOauthUrlAPI } from '@services/WebApiService';
import WindowOpener from '@libs/WindowOpener';
import BaseSetting from '@config/BaseSetting';
import { dashboardPath, confirmQuickbooksPath } from '@config/Routes/WebClientRoutes';
import { setQboOauthResponse, resetQboConfirmation } from '@features/qboOauth/qboOauthSlice';
import { usePageAlert, useAlertMessage } from '@hooks';
import { secondsToHour } from '@utilities';

const windowOpener = new WindowOpener(BaseSetting.qboWindowOpenerName);

export const REACTIVATE = 'Reactivate';
export const ADD_BACKUP = 'Create';
export const REPLICATE = 'Replicate';
export const REACTIVATE_REPLICATE = 'ReactivateReplicate';

export default function useQboOauth(action = ADD_BACKUP, payloadOauth = {}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { accessToken } = useSelector((state) => state.auth);
  const { isApplicationReady } = useSelector((state) => state.application);
  const { updateAlert } = usePageAlert();
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { successCallback, errorCallback, qboRealmId, environment, qboReplicateId } = payloadOauth;

  const isButtonActive = isApplicationReady && !isPopupOpen;

  useEffect(() => {
    dispatch(resetQboConfirmation());

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleOauthResponse = ({ isExist, isSameOrganization, confirmation }) => {
    if (!isExist) {
      navigate(confirmQuickbooksPath);
    } else if (isExist && isSameOrganization) {
      navigate(dashboardPath, { replace: true });

      // add timeout for race condition
      // during switching navigation
      setTimeout(() => {
        const backupIntervalHourText = t('date.hour.with_count', {
          count: secondsToHour(confirmation.backupInterval),
        });

        updateAlert(
          getSuccessMessage(konstMessage.SUCCESS_QBO_REAUTHENTICATED, {
            company_name: confirmation?.companyName,
            backup_interval: backupIntervalHourText,
          })
        );
      }, 200);
    } else if (isExist && !isSameOrganization) {
      updateAlert(
        getErrorMessage(konstMessage.ERROR_NEW_QBO_ADDED_DIFFERENT_ORG, {
          company_name: confirmation?.companyName,
        })
      );
    }
  };

  const successOauthReactivate = (data) => {
    const { success, error } = data;

    if (success) {
      if (successCallback) successCallback(data);
    } else if (error) {
      if (errorCallback) errorCallback(error);
    }
  };

  const successOauth = (data) => {
    const { success, error, confirmResponse } = data;

    if (success && confirmResponse) {
      const { qboAuthRequestId, isExist, isSameOrganization } = confirmResponse;
      const rebuildResponse = {
        ...{ confirmation: confirmResponse },
        error,
        isExist,
        isSameOrganization,
        QboAuthRequestId: qboAuthRequestId,
      };

      if (successCallback) successCallback(data);
      dispatch(setQboOauthResponse(rebuildResponse));
      handleOauthResponse(rebuildResponse);
    } else if (success && error) {
      if (errorCallback) errorCallback(data);
      updateAlert(getErrorMessage(konstMessage.ERROR_NEW_QBO_DEFAULT));
    }
  };

  const successOauthReplicate = (data) => {
    const { success, error, confirmResponse } = data;
    const rebuildResponse = {
      companyName: confirmResponse?.companyName,
      emailAddress: confirmResponse?.emailAddress,
      maskedQboAuthRequestId: confirmResponse?.maskedQboAuthRequestId,
      success,
    };

    if (success) {
      if (successCallback) successCallback(rebuildResponse);
    } else if (errorCallback) errorCallback({ error });
  };

  const successOauthReactivateReplicate = (data) => {
    const { success, error } = data;

    if (success) {
      if (successCallback) successCallback(data);
    } else if (errorCallback) errorCallback({ error });
  };

  const onSubscribePopup = ({ isTrusted, data }) => {
    if (isTrusted && data.windowName === windowOpener.windowName) {
      switch (action) {
        case REACTIVATE:
          successOauthReactivate(data);
          break;
        case ADD_BACKUP:
          successOauth(data);
          break;
        case REPLICATE:
          successOauthReplicate(data);
          break;
        case REACTIVATE_REPLICATE:
          successOauthReactivateReplicate(data);
          break;
        default:
          if (data.error) {
            if (errorCallback) errorCallback();
            updateAlert(getErrorMessage(konstMessage.ERROR_NEW_QBO_DEFAULT));
          }
          break;
      }

      setIsPopupOpen(false);
    }
  };

  const openOauthWindow = async () => {
    const onClose = () => {
      setIsPopupOpen(false);
    };

    try {
      setIsPopupOpen(true);
      const payload = {
        reason: action,
      };

      if (action === REACTIVATE) {
        payload.qboRealmId = qboRealmId;
      } else if (action === REPLICATE) {
        payload.environment = environment;
        payload.qboRealmId = qboRealmId;
      } else if (action === REACTIVATE_REPLICATE) {
        payload.environment = environment;
        payload.qboReplicateId = qboReplicateId;
      }

      const data = await generateOauthUrlAPI(accessToken, payload);
      let generateUrl = data.oAuthUrl;
      generateUrl = new URL(generateUrl);
      generateUrl = generateUrl.href;

      windowOpener.open(
        generateUrl,
        onClose,
        onSubscribePopup,
        t('new_quickbooks_page.dialog.prevent_close_text')
      );
    } catch (err) {
      setIsPopupOpen(false);
    }
  };

  return { openOauthWindow, isButtonActive };
}
