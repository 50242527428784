import React from 'react';
import { useTranslation } from 'react-i18next';
import SystemStatus from '@libs/SystemStatus';
import { QboBadge, QboRefreshingIcon, QboButton } from '@ui/Components';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';

export const { listBackupStatus, listDownloadStatus, listRestoreStatus, listReplicateStatus } =
  SystemStatus;

const useSystemStatus = (badgeProps = { onClickDownload: () => {} }) => {
  const { t } = useTranslation();

  const handliClickDownload = (e) => {
    const { onClickDownload } = badgeProps;
    e.stopPropagation();
    onClickDownload();
  };

  const badgeBackupStatusRender = () => {
    const { size, status, withIcon, withInfoIcon } = badgeProps;
    const sizeProp = size || 'small';

    switch (status) {
      case listBackupStatus.RUNNING:
        return (
          <QboBadge
            withInfoIcon={withInfoIcon}
            label={t('system_status.running_text')}
            color="primary"
            size={sizeProp}
            icon={withIcon && <QboRefreshingIcon />}
          />
        );
      case listBackupStatus.DEACTIVATED:
        return (
          <QboBadge
            label={t('system_status.deactivated_text')}
            withInfoIcon={withInfoIcon}
            color="warning"
            size={sizeProp}
            icon={withIcon && <WarningOutlinedIcon />}
          />
        );
      case listBackupStatus.AUTHENTICATION_ERROR:
        return (
          <QboBadge
            label={t('system_status.authentication_error_text')}
            withInfoIcon={withInfoIcon}
            color="error"
            size={sizeProp}
            icon={withIcon && <ErrorOutlinedIcon />}
          />
        );
      case listBackupStatus.SUCCESS:
        return (
          <QboBadge
            label={t('system_status.completed_text')}
            withInfoIcon={withInfoIcon}
            color="success"
            size={sizeProp}
            icon={withIcon && <CheckCircleIcon />}
          />
        );
      default:
        return null;
    }
  };

  const badgeDownloadStatusRender = () => {
    const { size, status, withIcon, withInfoIcon, withStatusAndButton } = badgeProps;
    const sizeProp = size || 'small';

    switch (status) {
      case listDownloadStatus.RUNNING:
        return (
          <QboBadge
            withInfoIcon={withInfoIcon}
            label={t('system_status.generating_text')}
            color="primary"
            size={sizeProp}
            icon={withIcon && <QboRefreshingIcon />}
          />
        );
      case listDownloadStatus.EXPIRED:
        return (
          <QboBadge
            withInfoIcon={withInfoIcon}
            label={t('system_status.expired_text')}
            color="info"
            size={sizeProp}
            icon={withIcon && <TimerOffOutlinedIcon />}
          />
        );
      case listDownloadStatus.NO_DATA:
        return (
          <QboBadge
            withInfoIcon={withInfoIcon}
            label={t('system_status.no_data_text')}
            color="info"
            size={sizeProp}
            icon={withIcon && <InfoIcon />}
          />
        );
      case listDownloadStatus.SUCCESS:
        if (withStatusAndButton) {
          return (
            <>
              <QboBadge
                label={t('system_status.completed_text')}
                withInfoIcon={withInfoIcon}
                color="success"
                size={sizeProp}
                icon={withIcon && <CheckCircleIcon />}
              />
              <QboButton
                variant="text"
                className="QboButton__close-padding"
                onClick={(e) => {
                  handliClickDownload(e);
                }}
                startIcon={<CloudDownloadOutlinedIcon />}>
                {t('system_status.completed_download_text')}
              </QboButton>
            </>
          );
        }
        return (
          <QboButton
            variant="contained"
            onClick={(e) => {
              handliClickDownload(e);
            }}
            startIcon={<CloudDownloadOutlinedIcon />}>
            {t('system_status.completed_download_text')}
          </QboButton>
        );
      default:
        return null;
    }
  };

  const badgeRestoreStatusRender = () => {
    const { size, status, withIcon, withInfoIcon } = badgeProps;
    const sizeProp = size || 'small';

    switch (status) {
      case listRestoreStatus.RUNNING:
        return (
          <QboBadge
            withInfoIcon={withInfoIcon}
            label={t('system_status.running_text')}
            color="primary"
            size={sizeProp}
            icon={withIcon && <QboRefreshingIcon />}
          />
        );
      case listRestoreStatus.AUTHENTICATION_ERROR:
        return (
          <QboBadge
            label={t('system_status.authentication_error_text')}
            withInfoIcon={withInfoIcon}
            color="error"
            size={sizeProp}
            icon={withIcon && <ErrorOutlinedIcon />}
          />
        );
      case listRestoreStatus.FAILED:
        return (
          <QboBadge
            label={t('system_status.failed_text')}
            withInfoIcon={withInfoIcon}
            color="error"
            size={sizeProp}
            icon={withIcon && <CheckCircleIcon />}
          />
        );
      case listRestoreStatus.SUCCESS:
        return (
          <QboBadge
            label={t('system_status.completed_text')}
            withInfoIcon={withInfoIcon}
            color="success"
            size={sizeProp}
            icon={withIcon && <CheckCircleIcon />}
          />
        );
      default:
        return null;
    }
  };

  const badgeReplicateStatusRender = () => {
    const { size, status, withIcon, withInfoIcon } = badgeProps;
    const sizeProp = size || 'small';

    switch (status) {
      case listReplicateStatus.RUNNING:
        return (
          <QboBadge
            withInfoIcon={withInfoIcon}
            label={t('system_status.running_text')}
            color="primary"
            size={sizeProp}
            icon={withIcon && <QboRefreshingIcon />}
          />
        );
      case listReplicateStatus.AUTHENTICATION_ERROR:
        return (
          <QboBadge
            label={t('system_status.authentication_error_text')}
            withInfoIcon={withInfoIcon}
            color="error"
            size={sizeProp}
            icon={withIcon && <ErrorOutlinedIcon />}
          />
        );
      case listReplicateStatus.FAILED:
        return (
          <QboBadge
            label={t('system_status.failed_text')}
            withInfoIcon={withInfoIcon}
            color="error"
            size={sizeProp}
            icon={withIcon && <CheckCircleIcon />}
          />
        );
      case listReplicateStatus.SUCCESS:
        return (
          <QboBadge
            label={t('system_status.completed_text')}
            withInfoIcon={withInfoIcon}
            color="success"
            size={sizeProp}
            icon={withIcon && <CheckCircleIcon />}
          />
        );
      case listReplicateStatus.PARTIALLY_COMPLETED:
        return (
          <QboBadge
            label={t('system_status.completed_text')}
            withInfoIcon={withInfoIcon}
            color="secondary"
            size={sizeProp}
            icon={withIcon && <ContrastOutlinedIcon />}
          />
        );
      default:
        return null;
    }
  };

  const isRequireRauth = (status) => {
    return [listBackupStatus.AUTHENTICATION_ERROR, listBackupStatus.DEACTIVATED].includes(status);
  };

  return {
    listBackupStatus,
    listDownloadStatus,
    listRestoreStatus,
    listReplicateStatus,
    isRequireRauth,
    badgeBackupStatusRender,
    badgeDownloadStatusRender,
    badgeRestoreStatusRender,
    badgeReplicateStatusRender,
  };
};

export default useSystemStatus;
