import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';
import { stringToPascalCase } from '@utilities';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const QboObjectsService = {
  fetchQboObjectsAPI: (accessToken, qboRealmId, pageLink, payload) => {
    const { order, orderBy } = payload;
    const url =
      pageLink ||
      WebApiRoutes.qboFetchObjectsPath({
        qboRealmId,
        order,
        orderBy,
      });
    return webClientApi(accessToken).getRequest({ url });
  },
  fetchQboItemsAPI: (accessToken, qboRealmId, objectName, pageLink, payload) => {
    const { top, skip, order, orderBy, query, intuitDeletedStatus } = payload;
    const url =
      pageLink ||
      WebApiRoutes.qboFetchObjectItemsPath({
        qboRealmId,
        objectName,
        top,
        skip,
        order,
        orderBy: stringToPascalCase(orderBy),
        query,
        intuitDeletedStatus,
      });
    return webClientApi(accessToken).getRequest({ url });
  },
  fetchQboObjectContentAPI: (accessToken, qboRealmId, objectName, objectId, versionId) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboFetchObjectVersionPath({
        qboRealmId,
        objectName,
        objectId,
        versionId,
      }),
    });
  },
  fetchQboContentVersionsAPI: (
    accessToken,
    qboRealmId,
    objectName,
    objectId,
    pageLink,
    payload
  ) => {
    const { top, skip, order, orderBy } = payload;
    const url =
      pageLink ||
      WebApiRoutes.qboFetchObjectVersionsPath({
        qboRealmId,
        objectId,
        objectName,
        top,
        skip,
        orderBy,
        order,
      });
    return webClientApi(accessToken).getRequest({ url });
  },
};

export default QboObjectsService;
