import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboCheckbox, QboButton } from '@ui/Components';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { FilterType as FilterTypeConst } from '@pages/sharedHooks/useStatusFilter';

export default function FilterBlock({ collections, handleChecked, filterType }) {
  const { t } = useTranslation();

  const { header, filterList, checked, setChecked, ableToReset } = collections;
  const iconCheckCircle =
    FilterTypeConst.FilterTypeSelect === filterType
      ? {
          icon: <RadioButtonUncheckedIcon />,
          checkedIcon: <RadioButtonCheckedIcon />,
        }
      : {};
  return (
    <>
      {filterList.map((item, indexItem) => {
        return (
          <QboCheckbox
            // eslint-disable-next-line react/no-array-index-key
            key={indexItem}
            checked={checked.includes(item.status)}
            onChange={() => handleChecked(checked, setChecked, item.status, header)}
            label={t(item.label)}
            {...iconCheckCircle}
          />
        );
      })}
      {ableToReset && (
        <QboButton
          onClick={() => {
            setChecked([]);
          }}
          variant="text">
          {t('dashboard_page.search_block.filter_text_button_reset')}
        </QboButton>
      )}
    </>
  );
}

FilterBlock.propTypes = {
  collections: PropTypes.object,
  handleChecked: PropTypes.func,
  filterType: PropTypes.string,
};

FilterBlock.defaultProps = {
  collections: [],
  handleChecked: () => {},
  filterType: 'MultiSelect',
};
