import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQboRealmStatusAPI } from '@services/WebApiService';

const getQboRealmStatusAsync = createAsyncThunk(
  'QboRealms/getQboRealmStatus',
  async ({ qboRealmId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await getQboRealmStatusAPI(accessToken, qboRealmId);
    return data;
  }
);

const { fulfilled } = getQboRealmStatusAsync;

/* eslint-disable prettier/prettier */
const getQboRealmStatusExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.id) {
      const {
        id,
        percentage,
        status,
        lastBackupAt,
        lastCompletedBackupAt,
        initialCompletedBackupAt,
      } = action.payload;

      // replace the value from array
      const value = state.QboRealms.value.find((v) => v.id === id);

      if (value) {
        const index = state.QboRealms.value.indexOf(value);
        const currentValueState = state.QboRealms.value[index];
        state.QboRealms.value[index] = {
          ...currentValueState,
          percentage,
          status,
          lastBackupAt,
          lastCompletedBackupAt,
          initialCompletedBackupAt,
        };
      }
    }
  },
};

export { getQboRealmStatusAsync, getQboRealmStatusExtraReducers };
