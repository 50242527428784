import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MobileStepper } from '@mui/material';
import { t } from 'i18next';
import { ArrowForward as ArrowForwardIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { QboButton } from '@ui/Components';

export default function BottomStepperBlock({
  maxSteps,
  onBack,
  onNext,
  backText,
  nextText,
  noDots,
  sx,
  isNextLoading,
  isBackLoading,
  isNextDisabled,
  isBackDisabled,
}) {
  const bottomStepperBlockRef = useRef(null);
  const [activeStep, setActiveStep] = useState(1);
  const application = useSelector((state) => state.application);

  const handleNext = () => {
    if (typeof onNext === 'function') {
      onNext();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (typeof onBack === 'function') {
      onBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  /* eslint-disable no-unused-expressions */
  /* eslint-disable prefer-destructuring */
  const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const fixedPosition = document.body.scrollHeight - 24;

    if (scrollPosition >= fixedPosition) {
      bottomStepperBlockRef.current.style.position = 'absolute';
      bottomStepperBlockRef.current.style.bottom = '2.4rem';
      bottomStepperBlockRef.current.style.paddingLeft = '6rem';
    } else {
      bottomStepperBlockRef.current.style.position = 'fixed';
      bottomStepperBlockRef.current.style.bottom = '0';
      bottomStepperBlockRef.current.style.paddingLeft = application.isDrawerOpen
        ? '30rem'
        : '11.5rem';
    }
  };

  useEffect(() => {
    handleScroll();

    // create an Observer instance
    // for listener agaist height doc
    const resizeObserver = new ResizeObserver(() => handleScroll());
    // listener to window scroll
    window.addEventListener('scroll', handleScroll);

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      resizeObserver.unobserve(document.body);
    };
  }, [application.isDrawerOpen]);

  const styles = {
    ...(application.isDrawerOpen ? { paddingLeft: '30rem' } : {}),
  };

  return (
    <MobileStepper
      sx={{ ...styles, ...sx }}
      ref={bottomStepperBlockRef}
      className={`BottomStepperBlock__wrapper BottomStepperBlock${noDots ? '__no-dots' : ''}`}
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      backButton={
        <QboButton
          onClick={handleBack}
          loading={isBackLoading}
          loadingPosition="start"
          disabled={isBackDisabled}
          startIcon={<ArrowBackIcon />}>
          {backText || t('button.back')}
        </QboButton>
      }
      nextButton={
        <QboButton
          variant="contained"
          disabled={isNextDisabled}
          onClick={handleNext}
          loading={isNextLoading}
          loadingPosition="end"
          endIcon={<ArrowForwardIcon />}>
          {nextText || t('button.next')}
        </QboButton>
      }
    />
  );
}

BottomStepperBlock.propTypes = {
  maxSteps: PropTypes.number,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  backText: PropTypes.string,
  nextText: PropTypes.string,
  noDots: PropTypes.bool,
  sx: PropTypes.object,
  isNextLoading: PropTypes.bool,
  isBackLoading: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  isBackDisabled: PropTypes.bool,
};

BottomStepperBlock.defaultProps = {
  maxSteps: 1,
  onNext: null,
  onBack: null,
  backText: undefined,
  nextText: undefined,
  noDots: true,
  sx: {},
  isNextLoading: false,
  isBackLoading: false,
  isNextDisabled: false,
  isBackDisabled: false,
};
