import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePagination, useCollectionInterval } from '@hooks';
import {
  qboFetchStatusRestoreAsync,
  resetState,
  updateFetching,
  getStatusRestoreAsync,
} from '@features/qboStatusRestore/qboStatusRestoreSlice';
import { constant } from '@config/BaseSetting';
import { features } from '@hooks/usePagination';
import { listRestoreStatus } from '@hooks/useSystemStatus';

const { paginationCache } = constant;

export default function useFetchStatusRestore() {
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { QboStatusRestore, fetchingQboStatusRestore } = useSelector(
    (state) => state.qboStatusRestore
  );

  const {
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    filter,
  } = usePagination(
    QboStatusRestore,
    qboFetchStatusRestoreAsync,
    paginationCache.DASBOARD_BACKUP_REALMS,
    isApplicationReady,
    {},
    [features.search, features.filtering, features.pagination, features.initialize],
    () => {
      // show loading immediately when keyword
      // changed to blank string
      if (keyword.length === 0) dispatch(updateFetching(true));
    }
  );

  let hasRestoreStatuses = false;

  if (hasRestoreStatuses === false) hasRestoreStatuses = QboStatusRestore.value.length > 0;
  if (hasRestoreStatuses === false) hasRestoreStatuses = keyword.length > 0;
  if (hasRestoreStatuses === false) hasRestoreStatuses = filter.length > 0;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useCollectionInterval(
    QboStatusRestore.value, // collection
    (restore) => restore.status === listRestoreStatus.RUNNING, // eligible for interval
    (restore) => restore.status !== listRestoreStatus.RUNNING, // eligible removed from interval
    (restore) => dispatch(getStatusRestoreAsync({ qboRestoreId: restore.maskedId })) // action when interval running
  );

  return {
    QboStatusRestore,
    fetchingQboStatusRestore,
    hasRestoreStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    isApplicationReady,
  };
}
