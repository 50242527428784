import React from 'react';
import { QboDrawerListItem, QboTypography, QboTipsBox, QboConnectButton } from '@ui/Components';
import PropTypes from 'prop-types';
import { useSystemStatus } from '@hooks';
import { t } from 'i18next';
import DomainIcon from '@mui/icons-material/Domain';

export default function ReplicateTargetItemBlock({
  status,
  targetCompanyName,
  targetEmailAddress,
  label,
  isReauthButtonActive,
  onClickReauth,
  oauthTipsInfo,
}) {
  const { isRequireRauth, listReplicateStatus } = useSystemStatus({
    status,
    withIcon: true,
    withInfoIcon: false,
    withStatusAndButton: true,
  });

  return (
    <div className="ReplicateStatusItemBlock__wrapper">
      <QboDrawerListItem icon={<DomainIcon />} label={label}>
        <QboTypography className="DrawerReplicateInfoSection__card-list-value" withTitle>
          {status === listReplicateStatus.AUTHENTICATION_ERROR ? (
            <>
              <span className="DrawerReplicateInfoSection__error-label">
                {t('status_replicate_page.drawer_replicate_info_section.auth_error_text')}
              </span>
              {' - '}
            </>
          ) : null}
          {targetCompanyName}
        </QboTypography>
        <QboTypography withTitle variant="caption">
          {targetEmailAddress}
        </QboTypography>
      </QboDrawerListItem>

      {isRequireRauth(status) && (
        <>
          {oauthTipsInfo && (
            <QboTipsBox
              className="ReplicateStatusItemBlock__auth-tips-wrapper"
              __html={oauthTipsInfo}
            />
          )}
          <QboConnectButton disabled={!isReauthButtonActive} onClick={onClickReauth} />
        </>
      )}
    </div>
  );
}

ReplicateTargetItemBlock.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  targetCompanyName: PropTypes.string,
  targetEmailAddress: PropTypes.string,
  isReauthButtonActive: PropTypes.bool,
  onClickReauth: PropTypes.func,
  oauthTipsInfo: PropTypes.string,
};

ReplicateTargetItemBlock.defaultProps = {
  label: '',
  status: '',
  targetCompanyName: '',
  targetEmailAddress: '',
  isReauthButtonActive: false,
  onClickReauth: () => {},
  oauthTipsInfo: null,
};
