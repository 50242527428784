import React from 'react';
import { QboDrawerListItem, QboTypography, QboLinearProgress } from '@ui/Components';
import PropTypes from 'prop-types';
import { useSystemStatus } from '@hooks';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import { t } from 'i18next';

export default function ReplicateStatusItemBlock({ status, percentage, label }) {
  const { listReplicateStatus, badgeReplicateStatusRender } = useSystemStatus({
    status,
    withIcon: true,
    withInfoIcon: false,
    withStatusAndButton: true,
  });

  const sideBadgeTextRender = () => {
    if (listReplicateStatus.RUNNING === status && percentage) {
      return (
        <QboTypography>
          {t('system_status.completed.percentage_text', { number: percentage })}
        </QboTypography>
      );
    }

    return null;
  };

  return (
    <div className="ReplicateStatusItemBlock__wrapper">
      <QboDrawerListItem
        className="ReplicateStatusItemBlock__badge-wrapper"
        icon={<CloudSyncOutlinedIcon />}
        label={label}>
        <div className="ReplicateStatusItemBlock__content">
          {badgeReplicateStatusRender()}
          {sideBadgeTextRender()}
        </div>

        {listReplicateStatus.RUNNING === status && <QboLinearProgress progress={percentage} />}
      </QboDrawerListItem>
    </div>
  );
}

ReplicateStatusItemBlock.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  percentage: PropTypes.number,
};

ReplicateStatusItemBlock.defaultProps = {
  label: '',
  status: '',
  percentage: 0,
};
