import React from 'react';
import PropTypes from 'prop-types';
import {
  QboCardListItemStatus,
  QboTypography,
  QboHighlighter,
  QboLinearProgress,
  QboBadge,
} from '@ui/Components';
import ReplicateAva from '@images/generals/ReplicateAva.svg';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { useDateTime } from '@hooks';
import useSystemStatus, { listReplicateStatus } from '@hooks/useSystemStatus';
import { t } from 'i18next';

const defaultNull = '-';

/* eslint-disable react/no-danger */
export default function StatusReplicateCardItemBlock({
  QboStatusReplicate,
  searchKeyword,
  onClickRow,
}) {
  const { dateToString, dateFormat, dateDistance, dateTimeNow } = useDateTime();
  const { badgeReplicateStatusRender } = useSystemStatus({
    status: QboStatusReplicate.status,
    withIcon: true,
    withInfoIcon: false,
  });

  let elapsedTime = defaultNull;

  if (
    QboStatusReplicate?.startTime &&
    QboStatusReplicate?.status !== listReplicateStatus.AUTHENTICATION_ERROR
  ) {
    const endTime = QboStatusReplicate?.endTime || dateTimeNow();
    elapsedTime =
      dateDistance(QboStatusReplicate?.startTime, endTime, {
        separator: ' ',
      }) || '1s';
  }

  const replicateInfoHTML = () => {
    const info = [
      t('status_replicate_page.item_card.caption.status_id_label', {
        id: QboStatusReplicate?.id,
      }),
      t('status_replicate_page.item_card.caption.started_at_label', {
        date:
          dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusReplicate?.startTime) || defaultNull,
      }),
      t('status_replicate_page.item_card.caption.elapsed_at_label', { date: elapsedTime }),
    ];

    if (
      QboStatusReplicate?.status === listReplicateStatus.RUNNING &&
      !QboStatusReplicate?.startTime
    ) {
      // calculating
      info.push(`${t('system_status.calculating_text')}...`);
    } else if (QboStatusReplicate?.status === listReplicateStatus.RUNNING) {
      // running info
      info.push(
        t('system_status.completed.percentage_text_all', {
          number: QboStatusReplicate?.percentage,
        })
      );
    } else {
      // fallback
      info.push(
        t('status_replicate_page.item_card.caption.completed_at_label', {
          date:
            dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusReplicate?.endTime) || defaultNull,
        })
      );
    }

    return info.join(' | ');
  };

  return (
    <QboCardListItemStatus
      onClick={() => {
        if (onClickRow) onClickRow(QboStatusReplicate.id);
      }}
      className="StatusCardItemBlock__wrapper StatusCardItemBlock__active"
      justifyContent="center"
      alignItems="center"
      leftContent={<img src={ReplicateAva} alt="DownloadAva" className="Img__fluid" />}
      rightContent={
        <div className="StatusCardItemBlock__right-content">{badgeReplicateStatusRender()}</div>
      }
      buttonRightContent={
        QboStatusReplicate?.status === listReplicateStatus.RUNNING && (
          <QboLinearProgress
            progress={parseInt(QboStatusReplicate?.percentage, 10)}
            className="StatusCardItemBlock__running-progress"
            processing={Boolean(!QboStatusReplicate?.startTime)}
          />
        )
      }>
      <div className="StatusCardItemBlock__title">
        <QboTypography variant="subtitle2" withTitle>
          {searchKeyword !== '' ? (
            <QboHighlighter
              searchWord={searchKeyword}
              sentences={QboStatusReplicate?.companyName}
            />
          ) : (
            QboStatusReplicate?.companyName
          )}
        </QboTypography>
        <ArrowForwardOutlinedIcon />
        <QboTypography variant="subtitle2" withTitle>
          {searchKeyword !== '' ? (
            <QboHighlighter
              searchWord={searchKeyword}
              sentences={QboStatusReplicate?.targetCompanyName}
            />
          ) : (
            QboStatusReplicate?.targetCompanyName
          )}
        </QboTypography>
        <QboTypography variant="subtitle2">({QboStatusReplicate?.environment})</QboTypography>
        {QboStatusReplicate?.hasErrorUrl ? (
          <QboBadge
            className="StatusCardItemBlock__badge-download-info"
            icon={<AttachFileOutlinedIcon />}
            label={t('status_replicate_page.item_card.badge_label')}
            color="info"
          />
        ) : null}
      </div>

      <div
        className="StatusCardItemBlock__info QboCardListItem__info"
        dangerouslySetInnerHTML={{ __html: replicateInfoHTML() }}
      />
    </QboCardListItemStatus>
  );
}

StatusReplicateCardItemBlock.propTypes = {
  QboStatusReplicate: PropTypes.object.isRequired,
  searchKeyword: PropTypes.string,
  onClickRow: PropTypes.func,
};

StatusReplicateCardItemBlock.defaultProps = {
  searchKeyword: '',
  onClickRow: () => {},
};
