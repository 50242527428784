import React from 'react';
import { t } from 'i18next';
import { intuitQboLogo } from '@images/quickbooks';
import { QboCard, QboTypography, QboConnectButton, QboAlert } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { dashboardPath } from '@config/Routes/WebClientRoutes';
import { usePageAlert } from '@hooks';
import useQboOauth from '@pages/sharedHooks/useQboOauth';
import usePageMenu from '@pages/sharedHooks/usePageMenu';

export default function NewQuickbooksPage() {
  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.HOME_MENU);

  const { openOauthWindow, isButtonActive } = useQboOauth();
  const { isAlertOpen, alert, closeAlert } = usePageAlert();

  return (
    <>
      <HeaderPageBlock navigate={dashboardPath} linkNavigateText={t('navigate.back_to_dashboard')}>
        {t('new_quickbooks_page.header.text')}
      </HeaderPageBlock>

      {isAlertOpen && (
        <QboAlert
          type={alert.type}
          headingText={alert.heading}
          onClose={() => {
            closeAlert(false);
          }}>
          <QboTypography wrap>{alert.message}</QboTypography>
        </QboAlert>
      )}

      <QboCard isMainWrapper isCentered className="NewQuickbookPage__wrapper">
        <img src={intuitQboLogo} alt="" className="Img__fluid" />
        <QboTypography>{t('new_quickbooks_page.main.caption')}</QboTypography>
        <QboConnectButton disabled={!isButtonActive} onClick={openOauthWindow} />
      </QboCard>
    </>
  );
}
