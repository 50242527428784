/**
 * Service client Library
 *
 * Service client Library uses Factory Design Pattern
 * the factory will be intialized within ServiceClientFactory Class
 *
 * available client:
 *   - axios
 *
 * eg:
 *   # by defailt the serviceType will be axios
 *   serviceClient = new ServiceClientFactory({}, 'axios').create();
 *
 *   # get the client object
 *   # will return axios
 *   serviceClient.getClient('accessToken', 'this is token')
 *
 *   # execute post request
 *   serviceClient.postRequest({url: 'http://dropsuite.com', parameters: { args: 1 }})
 *
 *   # execute post request
 *   serviceClient.getRequest({url: 'http://dropsuite.com', parameters: { args: 1 }})
 *
 * * */

import ServiceClientFactory from './ServiceClientFactory';

export default ServiceClientFactory;
