import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const AccountService = {
  changePasswordAPI: (accessToken, currentPassword, newPassword, confirmationPassword) => {
    return webClientApi(accessToken).putRequest({
      url: WebApiRoutes.passwordAccountPath(),
      parameters: { currentPassword, newPassword, confirmationPassword },
    });
  },
  fetchStatus2FAApi: (accessToken) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboFetchStatus2FAPath(),
    });
  },
  fetchKey2FAApi: (accessToken) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboFetchKey2FAPath(),
    });
  },
  validateAuthForm2FAAPI: (accessToken, payload = {}) => {
    const parameters = { ...payload };
    delete parameters.type;
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboValidateAuthForm2FAPath({
        type: payload.type,
      }),
      parameters,
    });
  },
};

export default AccountService;
