/**
 * Storage Library
 *
 * Storage Library uses Factory Design Pattern
 * the factory will be intialized within StorageFactory Class
 *
 * available storages:
 *   - localStorage
 *
 * eg:
 *   localStorage = new StorageFactory({}, 'localStorage').create();
 *
 *   # save the data
 *   localStorage.save('accessToken', 'this is token')
 *
 *   # get the data
 *   localStorage.get('accessToken')
 *
 *   # delete or clear the data
 *   localStorage.delete('accessToken')
 *   localStorage.deleteAll()
 * * */

import StorageFactory from './StorageFactory';

export default StorageFactory;
