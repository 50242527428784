import DateTimeError from './DateTimeError';
import DateTimeMoment from './DateTimeMoment';

const momentjs = 'momentjs';

class DateTimeFactory {
  constructor(opts, client = momentjs) {
    this.client = client;
    this.opts = opts;
  }

  create() {
    if (this.client === momentjs) {
      return new DateTimeMoment(this.opts);
    }

    throw new DateTimeError('date time client is not defined.');
  }
}

export default DateTimeFactory;
