/**
 * Routes
 * Parent Class for building url
 * */

class Routes {
  // declare private variables
  // that can not be accessed
  // from outside
  #config = {};

  constructor(baseUrl, pathOnly = false) {
    this.#config = {
      baseUrl,
    };

    this.pathOnly = pathOnly;
  }

  getBaseUrl() {
    return this.#config.baseUrl;
  }

  createUrl(path, params = {}, format = '') {
    let pathUrl = path;
    if (format !== '') pathUrl = `${pathUrl}.${format}`;

    const url = new URL(pathUrl, this.getBaseUrl());
    const paramKeys = Object.keys(params);
    const buildParams = Object.keys(params);

    // inject to url
    if (paramKeys.length > 0) {
      paramKeys.forEach((key) => {
        const keyInPath = `:${key}`;
        const value = params[key];

        if (url.pathname.includes(keyInPath) && params[key]) {
          url.pathname = url.pathname.replace(keyInPath, value);

          // remove they key from array
          // for avoid multiple parameters
          buildParams.splice(buildParams.indexOf(key), 1);
        }
      });
    }
    // add as paramseters
    if (buildParams.length > 0) {
      buildParams.forEach((key) => {
        if (params[key]) url.searchParams.append(key, params[key]);
      });
    }

    if (this.pathOnly) return url.pathname + url.search;
    return url.href;
  }
}

export default Routes;
