import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboRealmsAPI } from '@services/WebApiService';
import { canShowPagination } from '@utilities';

const qboFetchRealmsAsync = createAsyncThunk(
  'QboRealms/fetchQboRealms',
  async ({ pageLink, query, top, skip, order, orderBy, statuses }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboRealmsAPI(accessToken, pageLink, {
      query,
      top,
      skip,
      order,
      orderBy,
      statuses,
    });
    return data;
  }
);

const { pending, fulfilled, rejected } = qboFetchRealmsAsync;

const qboFetchRealmsExtraReducers = {
  [pending]: (state) => {
    state.fetchingQboRealms = true;
  },
  [rejected]: (state) => {
    state.fetchingQboRealms = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboRealms = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.value) {
      const showPagination = canShowPagination(state.QboRealms.showPagination, action);

      state.QboRealms = {
        value: action.payload.value,
        nextLink: action.payload.nextLink,
        prevLink: action.payload.prevLink,
        showPagination,
      };
    }
  },
};

export { qboFetchRealmsAsync, qboFetchRealmsExtraReducers };
