import React from 'react';
import PropTypes from 'prop-types';
import { QboBreadcrumbs, QboLink, QboCardListItem } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { dashboardPath } from '@config/Routes/WebClientRoutes';
import HomeIcon from '@mui/icons-material/Home';
import { Skeleton } from '@mui/material';

export default function DashboardBreadcrumbsBlock({ loading, children, anyChildren }) {
  const { t } = useTranslation();

  return (
    <QboCardListItem>
      <QboBreadcrumbs className="CardBreadcrumbsBlock__wrapper">
        <QboLink href={dashboardPath} withTitle>
          <HomeIcon />
          {t('dashboard_page.header.text')}
        </QboLink>

        {/* children section */}
        {loading && !anyChildren ? <Skeleton width={100} /> : children}
      </QboBreadcrumbs>
    </QboCardListItem>
  );
}

DashboardBreadcrumbsBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  loading: PropTypes.bool,
  anyChildren: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

DashboardBreadcrumbsBlock.defaultProps = {
  children: '',
  loading: false,
  anyChildren: null,
};
