import React from 'react';
import { QboAlert, QboTypography } from '@ui/Components';
import { usePageAlert } from '@hooks';

/* eslint-disable react/no-danger */
export default function AlertPageSection() {
  const { isAlertOpen, alert, closeAlert } = usePageAlert();

  if (!isAlertOpen) return null;

  return (
    <QboAlert
      type={alert.type}
      headingText={alert.heading}
      onClose={() => {
        closeAlert(false);
      }}>
      {alert?.html && alert.message && (
        <p
          dangerouslySetInnerHTML={{
            __html: alert.message,
          }}
        />
      )}

      {!alert?.html && alert.message && <QboTypography wrap>{alert.message}</QboTypography>}
    </QboAlert>
  );
}
