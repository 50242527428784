import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSystemStatus } from '@hooks';
import { Box } from '@mui/material';
import { QboTypography } from '@ui/Components';

const useRealmActionButtons = ({
  status,
  id,
  onClickMoreInfo,
  onClickDownload,
  onClickRestore,
  onClickDeactivate,
  onClickDelete,
  onClickBackupNow,
  onClickReplicate,
}) => {
  const { t } = useTranslation();
  const { listBackupStatus } = useSystemStatus();

  const MORE_INFO = {
    id: 'MORE_INFO',
    label: 'dashboard_page.backup_list_item_block.list_more_text_info',
  };

  const DEACTIVATE = {
    id: 'DEACTIVATE',
    label: 'dashboard_page.backup_list_item_block.list_more_text_deactivated',
  };

  const BACKUP_NOW = {
    id: 'BACKUP_NOW',
    label: 'dashboard_page.backup_list_item_block.list_more_text_backup_now',
  };

  const DELETE = {
    id: 'DELETE',
    label: 'dashboard_page.backup_list_item_block.list_more_text_delete',
  };

  const MORE_BUTTON_OPTIONS = {
    MORE_INFO,
    DEACTIVATE,
    BACKUP_NOW,
    DELETE,
  };

  // More Info Options
  // for show realm details
  const clickMoreInfo = () => {
    if (onClickMoreInfo) onClickMoreInfo(id);
  };

  // Backup Now
  // for running On-Demand backup
  const clickBackupNow = () => {
    if (onClickBackupNow) onClickBackupNow(id);
  };

  // More Info Options
  // for deactivate realm
  const clickDeactivate = () => {
    if (onClickDeactivate) onClickDeactivate(id);
  };

  // More Info Options
  // for deleting realm
  const clickDelete = () => {
    if (onClickDelete) onClickDelete(id);
  };

  // Main Button for download
  const clickDownload = (e) => {
    e.stopPropagation();
    if (onClickDownload) onClickDownload(id);
  };

  // Main Button for Restore
  const clickRestore = (e) => {
    e.stopPropagation();
    if (onClickRestore) onClickRestore(id);
  };

  // Main Button for Restore
  const clickReplicate = (e) => {
    e.stopPropagation();
    if (onClickReplicate) onClickReplicate(id);
  };

  const onlyMoreButton = [
    listBackupStatus.AUTHENTICATION_ERROR,
    listBackupStatus.DEACTIVATED,
  ].includes(status || null);

  const clickMoreButton = (selected) => {
    switch (selected) {
      case MORE_INFO.id:
        clickMoreInfo();
        break;
      case DEACTIVATE.id:
        clickDeactivate();
        break;
      case DELETE.id:
        clickDelete();
        break;
      case BACKUP_NOW.id:
        clickBackupNow();
        break;
      default:
        break;
    }
  };

  const availableButtons = { MORE_INFO };
  if (status !== listBackupStatus.DEACTIVATED) availableButtons.DEACTIVATE = DEACTIVATE;
  if (status === listBackupStatus.SUCCESS) availableButtons.BACKUP_NOW = BACKUP_NOW;

  const moreButtonOptions = Object.keys(availableButtons).map((key) => {
    return [
      MORE_BUTTON_OPTIONS[key].id,
      <Box>
        <QboTypography>{t(MORE_BUTTON_OPTIONS[key].label)}</QboTypography>
      </Box>,
    ];
  });

  return {
    moreButtonOptions,
    clickMoreButton,
    clickDownload,
    clickRestore,
    clickReplicate,
    onlyMoreButton,
  };
};

export default useRealmActionButtons;
