import React from 'react';
import PropTypes from 'prop-types';
import { QboLastBackupText, QboLinearProgress } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { useSystemStatus } from '@hooks';
import useRealmActionBottons from '@pages/DashboardPage/pageHooks/useRealmActionBottons';
import RealmCardActionButtons from './RealmCardActionButtons';

export default function RealmCardItemStatusBlock({
  id,
  status,
  percentage,
  lastBackupAt,
  openActionBar,
  onClickDownload,
  onClickMoreInfo,
  onClickDeactivate,
  onClickBackupNow,
  onClickRestore,
  onClickReplicate,
}) {
  const { listBackupStatus, badgeBackupStatusRender } = useSystemStatus({
    status,
    withInfoIcon: true,
  });

  const { t } = useTranslation();
  const { moreButtonOptions, clickMoreButton, clickDownload, clickRestore, clickReplicate } =
    useRealmActionBottons({
      status,
      id,
      onClickMoreInfo,
      onClickDeactivate,
      onClickDownload,
      onClickBackupNow,
      onClickRestore,
      onClickReplicate,
    });

  if (openActionBar)
    return (
      <RealmCardActionButtons
        onClickMoreOption={clickMoreButton}
        moreOptions={moreButtonOptions}
        onClickDownload={clickDownload}
        onClickRestore={clickRestore}
        onClickReplicate={clickReplicate}
        status={status}
        lastBackupAt={lastBackupAt}
      />
    );

  switch (status) {
    case listBackupStatus.RUNNING:
      return (
        <QboLinearProgress
          progress={percentage}
          label={t('system_status.completed.percentage_text', { number: percentage })}
        />
      );
    case listBackupStatus.DEACTIVATED:
    case listBackupStatus.AUTHENTICATION_ERROR:
      return badgeBackupStatusRender();
    default:
      if (lastBackupAt) return <QboLastBackupText date={lastBackupAt} />;
      return null;
  }
}

RealmCardItemStatusBlock.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  lastBackupAt: PropTypes.string,
  openActionBar: PropTypes.bool,
  percentage: PropTypes.number,
  onClickDownload: PropTypes.func,
  onClickMoreInfo: PropTypes.func,
  onClickDeactivate: PropTypes.func,
  onClickBackupNow: PropTypes.func,
  onClickRestore: PropTypes.func,
  onClickReplicate: PropTypes.func,
};

RealmCardItemStatusBlock.defaultProps = {
  id: null,
  status: null,
  lastBackupAt: null,
  openActionBar: false,
  percentage: 0,
  onClickRestore: () => {},
  onClickDownload: () => {},
  onClickMoreInfo: () => {},
  onClickDeactivate: () => {},
  onClickBackupNow: () => {},
  onClickReplicate: () => {},
};
