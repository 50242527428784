const ErrorResponse = {
  RecordNotFound: 'RecordNotFound',
  GeneralError: 'GeneralError',
  UnprocessableFormat: 'UnprocessableFormat',
  MaxDownloadLimitReached: 'MaxDownloadLimitReached',
  DownloadRequestAlreadyExists: 'DownloadRequestAlreadyExists',
  ThirdPartyServiceError: 'ThirdPartyServiceError',
  MaxRestoreLimitReached: 'MaxRestoreLimitReached',
  RestoreRequestAlreadyExists: 'RestoreRequestAlreadyExists',
  UnauthorizedRecords: 'UnauthorizedRecords',
  RecordAlreadyExists: 'RecordAlreadyExists',
  TargetReplicateSameAsSource: 'TargetReplicateSameAsSource',
  TargetReplicateStillOnProgress: 'TargetReplicateStillOnProgress',
  UserNotAcceptedTermsAndConditions: 'UserNotAcceptedTermsAndConditions',
};

export default ErrorResponse;
