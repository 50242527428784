import * as React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

export default function QboTooltip({ children, className, title, ...rest }) {
  return (
    <Tooltip {...rest} className={`QboTooltip__wrapper ${className}`} title={title}>
      {children}
    </Tooltip>
  );
}

QboTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

QboTooltip.defaultProps = {
  className: '',
  title: '',
};
