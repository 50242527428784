import React from 'react';
import PropTypes from 'prop-types';
import { ListItemButton, ListItemIcon, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import { QboTypography } from '@ui/Components';

export default function QboDrawerMenuItem({
  className,
  path,
  icon,
  text,
  forceActive,
  onClick,
  loading,
}) {
  if (loading) {
    return (
      <div className="QboDrawerMenuItem">
        <ListItemButton>
          <Skeleton
            variant="text"
            width={25}
            height={35}
            className="QboDrawerMenuItem__loading-icon MuiListItemIcon-root"
          />

          <Skeleton variant="text" width="100%" className="QboDrawerMenuItem__loading-label" />
        </ListItemButton>
      </div>
    );
  }

  return (
    <Link
      onClick={onClick}
      to={path}
      className={`QboDrawerMenuItem QboDrawerMenuItem__${
        forceActive ? 'active' : 'inactive'
      } ${className}`}>
      <ListItemButton>
        <ListItemIcon>{icon}</ListItemIcon>
        <QboTypography withTitle>{text}</QboTypography>
      </ListItemButton>
    </Link>
  );
}

QboDrawerMenuItem.propTypes = {
  path: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.string,
  forceActive: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

QboDrawerMenuItem.defaultProps = {
  path: '#menu',
  icon: '',
  text: '',
  forceActive: false,
  className: '',
  onClick: () => {},
  loading: false,
};
