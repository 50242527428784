import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  const serviceClient = new ServiceClientFactory({
    baseUrl: WebApiRoutes.getBaseUrl(),
    accessToken,
  }).create();

  serviceClient.refreshTokenExcludedUrl = [WebApiRoutes.authenticationPath()];
  serviceClient.refreshTokenUrl = WebApiRoutes.refreshTokenPath();

  return serviceClient;
};

const AuthenticationService = {
  authenticateAPI: (ssoToken) => {
    return webClientApi().postRequest({
      url: WebApiRoutes.authenticationPath(),
      parameters: { ssoToken },
    });
  },
  refreshTokenAPI: () => {
    return webClientApi().postRequest({
      url: WebApiRoutes.refreshTokenPath(),
    });
  },
  logoutAPI: (accessToken) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.logoutPath(),
    });
  },
};

export default AuthenticationService;
