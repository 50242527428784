/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable prefer-regex-literals */

export const REQUIRED = 'REQUIRED';
export const MIN_CHAR_LENGTH = 'MIN_CHAR_LENGTH';
export const MAX_CHAR_LENGTH = 'MAX_CHAR_LENGTH';
export const HAS_UPCASE = 'HAS_UPCASE';
export const HAS_LOWCASE = 'HAS_LOWCASE';
export const HAS_NUMBER = 'HAS_NUMBER';
export const HAS_SYMBOL = 'HAS_SYMBOL';
export const MATCH_VALUE = 'MATCH_VALUE';

const listValidations = [
  REQUIRED,
  MIN_CHAR_LENGTH,
  MAX_CHAR_LENGTH,
  HAS_UPCASE,
  HAS_LOWCASE,
  HAS_NUMBER,
  HAS_SYMBOL,
  MATCH_VALUE,
];

export default class FormValidation {
  // validations
  validations = [];

  // value for validation
  value = '';

  // second value for match
  // valudation
  secondValue = '';

  // errors
  errors = [];

  constructor(key, { validations, minCharLength, maxCharLength }) {
    this.key = key;
    this.validations = validations;
    this.minCharLength = minCharLength || 10;
    this.maxCharLength = maxCharLength || 40;
  }

  formAttributes() {
    const errorDetails = {};

    this.validations.forEach((k) => {
      errorDetails[k] = this.value.length > 0 && !this.errors.includes(k);
    });

    return {
      name: this.key,
      value: this.value,
      errors: this.errors,
      hasError: this.errors.length > 0,
      isValid: this.isValid(),
      errorDetails,
    };
  }

  validate() {
    this.resetValidation();

    this.validations.forEach((validate) => {
      if (listValidations.includes(validate)) {
        // to get the name function
        // eg: requiredValidate
        const functName = `${this.#toFuncName(validate)}Validate`;

        try {
          this[functName]();
        } catch (err) {
          console.info(err.message);
        }
      }
    });

    return this.errors;
  }

  resetValidation() {
    this.errors = [];
  }

  resetValue() {
    this.value = '';
    this.errors = [];
  }

  isValid() {
    return this.errors.length === 0;
  }

  // required validation
  requiredValidate() {
    if (!this.value || this.value === '') this.errors.push(REQUIRED);
  }

  // min char length validation
  minCharLengthValidate() {
    if (this.value.length > 0 && this.value.length < this.minCharLength)
      this.errors.push(MIN_CHAR_LENGTH);
  }

  // max char length validation
  maxCharLengthValidate() {
    if (this.value.length > 0 && this.value.length > this.maxCharLength)
      this.errors.push(MIN_CHAR_LENGTH);
  }

  // any upcase in value validation
  hasUpcaseValidate() {
    if (this.value.length > 0) {
      const pattern = new RegExp('^(?=.*[A-Z]).+$');
      if (!pattern.test(this.value)) this.errors.push(HAS_UPCASE);
    }
  }

  // any lowcase in value validation
  hasLowcaseValidate() {
    if (this.value.length > 0) {
      const pattern = new RegExp('^(?=.*[a-z]).+$');
      if (!pattern.test(this.value)) this.errors.push(HAS_LOWCASE);
    }
  }

  // any number in value validation
  hasNumberValidate() {
    if (this.value.length > 0) {
      const pattern = new RegExp('^(?=.*\\d).+$');
      if (!pattern.test(this.value)) this.errors.push(HAS_NUMBER);
    }
  }

  // any symbol in value validation
  hasSymbolValidate() {
    if (this.value.length > 0) {
      const pattern = new RegExp('^(?=.*[[\\]#!$%&()*+,./:;<>=?@^_{|}~-]).+$');
      if (!pattern.test(this.value)) this.errors.push(HAS_SYMBOL);
    }
  }

  matchValueValidate() {
    if (this.value.length > 0 && this.secondValue.length > 0 && this.value !== this.secondValue)
      this.errors.push(MATCH_VALUE);
  }

  // convert upcase to
  // camelcase
  /* eslint-disable class-methods-use-this */
  #toFuncName(text) {
    const newText = text;

    return newText
      .toLowerCase()
      .replaceAll('_', ' ')
      .replace(/\s(.)/g, function (a) {
        return a.toUpperCase();
      })
      .replace(/\s/g, '')
      .replace(/^(.)/, function (b) {
        return b.toLowerCase();
      });
  }
}
