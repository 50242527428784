import StorageFactory from '@libs/Storage';

class DrawerView {
  // key name
  DRAWER_COLLAPSE_OPEN = 'DRAWER_COLLAPSE_OPEN';

  constructor() {
    // initialize storage
    const storageFactory = new StorageFactory({ keyStoragePrefix: 'QBO' }, 'localStorage');
    this.drawerStorage = storageFactory.create();

    if (!this.drawerStorage.get(this.DRAWER_COLLAPSE_OPEN)) {
      this.setClose();
    }
  }

  setOpen() {
    this.drawerStorage.save(this.DRAWER_COLLAPSE_OPEN, true);
  }

  setClose() {
    this.drawerStorage.save(this.DRAWER_COLLAPSE_OPEN, false);
  }

  isOpen() {
    return this.drawerStorage.get(this.DRAWER_COLLAPSE_OPEN) === 'true';
  }
}

export default DrawerView;
