import { createAsyncThunk } from '@reduxjs/toolkit';
import { changePasswordAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';

const changePasswordAsync = createAsyncThunk(
  'Account/changePassword',
  async ({ currentPassword, newPassword, confirmationPassword }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await changePasswordAPI(
      accessToken,
      currentPassword,
      newPassword,
      confirmationPassword
    );
    return data;
  }
);

const { pending, fulfilled, rejected } = changePasswordAsync;

const changePasswordExtraReducers = {
  [pending]: (state) => {
    state.changePassword.submitting = true;
  },
  [rejected]: (state) => {
    state.changePassword.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.changePassword.submitting = false;

    if (action?.payload?.error) {
      state.changePassword.error = action.payload.error;
    } else if (action?.payload?.status === HttpResponse.success) {
      state.changePassword.success = true;
    }
  },
};

export { changePasswordAsync, changePasswordExtraReducers };
