import React from 'react';
import PropTypes from 'prop-types';
import { QboCard, QboCardListItem, QboTabs } from '@ui/Components';

function CardWrapper(child) {
  return (
    <QboCard className="TabsCardBlock__card-wrapper" noPadding isMainWrapper>
      <QboCardListItem>{child}</QboCardListItem>
    </QboCard>
  );
}

export default function TabsCardBlock({ title, children, icon, tabIndex, onChange }) {
  return (
    <QboTabs
      className="TabsCardBlock__wrapper"
      title={title}
      icon={icon}
      iconPosition="start"
      TabsParentWrapper={(child) => CardWrapper(child)}
      tabIndex={tabIndex}
      onChange={onChange}>
      {children}
    </QboTabs>
  );
}

TabsCardBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.array,
  icon: PropTypes.arrayOf(PropTypes.node),
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
};

TabsCardBlock.defaultProps = {
  children: null,
  title: [],
  icon: [],
  tabIndex: 0,
  onChange: () => {},
};
