export default class WindowOpener {
  // `defaultOpts`: options that the window has. if you want to know all the options goto
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/open
  defaultOpts = `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, width=${1000}, height=${654}`;

  constructor(windowName, asPopup = false) {
    this.windowName = windowName;
    this.browser = window.self;
    this.popup = null;

    if (asPopup === true) {
      this.opener = window.opener;
      this.popup = window.self;
    }
  }

  watcher() {
    // if popup is null then let's clean the intervals.
    if (this.popup === null) {
      clearInterval(this.timer);
      this.timer = null;
      // if popup is not null and it is not closed, then let's set the focus on it
    } else if (this.popup !== null && this.popup.closed) {
      clearInterval(this.timer);

      if (this.currentUrl === window.location.href) {
        this.browser.onbeforeunload = undefined;
      }

      this.browser.focus();
      this.timer = null;
      this.popup = null;

      if (this.onCloseCallback) this.onCloseCallback();
    }
  }

  open(url, onCloseCallback, onSubscribePopup, preventCloseText, opts = this.defaultOpts) {
    if (this.popup && !this.popup.closed) {
      this.popup.close();
    }

    this.popup = this.browser.open(url, this.windowName, opts);
    this.onCloseCallback = onCloseCallback;

    if (typeof onSubscribePopup === 'function')
      this.browser.addEventListener('message', onSubscribePopup, false);

    // each two seconds we check if the popup still open or not
    if (!this.timer) this.timer = setInterval(this.watcher.bind(this), 1500);

    if (preventCloseText) {
      this.browser.onbeforeunload = () => {
        return preventCloseText;
      };
    }

    this.currentUrl = window.location.href;
    return this.popup;
  }

  postSuccessMessage(params = {}) {
    if (!this.opener) return false;

    const response = {
      success: true,
      windowName: this.windowName,
      ...params,
    };

    this.opener.postMessage(response);
    return true;
  }

  postFailedMessage(params = {}) {
    if (!this.opener) return false;

    const response = {
      success: false,
      windowName: this.windowName,
      ...params,
    };

    this.opener.postMessage(response);
    return true;
  }

  closePopup() {
    if (!this.popup) return false;

    this.popup.close();
    return true;
  }
}
