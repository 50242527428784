import React from 'react';
import PropTypes from 'prop-types';
import {
  QboCardListItem,
  QboButton,
  QboDropdown,
  QboTypography,
  QboAutocomplete,
  QboTextField,
  QboLink,
} from '@ui/Components';
import {
  SearchOutlined as SearchOutlinedIcon,
  Cancel as CancelIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { fontActiveColor } from '@config/themes/default/variables';

const iconStyles = {
  color: fontActiveColor,
};

const rightPosition = 'right';
const leftPosition = 'left';
export default function SearchCardBlock({
  withSortButton,
  withFilterButton,
  placeholder,
  className,
  compact,
  autocomplete,
  searchKeyword,
  setSearchKeyword,
  selectedSort,
  setSelectedSort,
  listSortOptions,
  options,
  keyGroupBy,
  keyLabelOption,
  loading,
  onClickAllResult,
  onSelectItem,
  setShowDrawer,
  showBadgeFilter,
  sort,
  clearOnEscape,
  clearOnBlur,
  defaultSearchValue,
  forceShowClearIcon,
  rightContent,
  sortFilterPosition,
  ...rest
}) {
  const { t } = useTranslation();

  const renderRightContent = () => {
    if (!rightContent) return null;

    return rightContent;
  };

  const renderSortFilterContent = () => {
    return (
      <>
        {withSortButton && (
          <QboDropdown
            menuItems={listSortOptions}
            onClickItem={(selected) => {
              setSelectedSort(selected);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            leftIcon={<ImportExportIcon sx={iconStyles} />}
            className="QboDropdown__button-filter"
            dropdownClassName="QboMenuItem__sort-variant"
            showBadge={Object.keys(sort).length !== 0}
            variant="sort">
            <QboTypography>{t(selectedSort?.label)}</QboTypography>
          </QboDropdown>
        )}
        {withFilterButton && (
          <QboButton
            onClick={() => setShowDrawer(true)}
            size="large"
            startIcon={<FilterAltIcon />}
            showBadge={showBadgeFilter}
            variant="outlined"
            color="inherit">
            {t('search_block.filter_text_button')}
          </QboButton>
        )}
      </>
    );
  };

  return (
    <QboCardListItem
      className={`SearchBlock__wrapper ${className}`}
      compact={compact}
      justifyContent="center"
      alignItems="center"
      rightContent={
        <>
          {sortFilterPosition === rightPosition ? renderSortFilterContent() : null}
          {renderRightContent()}
        </>
      }>
      {autocomplete && (
        <QboAutocomplete
          {...rest}
          options={options}
          keyGroupBy={keyGroupBy}
          keyLabelOption={keyLabelOption}
          loading={loading}
          placeholder={placeholder}
          onClickLink={onClickAllResult}
          searchResultLabel={t('autocomplete.show_all_text')}
          onSelect={onSelectItem}
          onInputChange={(value) => {
            setSearchKeyword(value);
          }}
          clearOnEscape={clearOnEscape}
          clearOnBlur={clearOnBlur}
          defaultSearchValue={defaultSearchValue}
          forceShowClearIcon={forceShowClearIcon}
        />
      )}

      {!autocomplete && (
        <QboTextField
          {...rest}
          className="SearchBlock__text-field"
          startIcon={<SearchOutlinedIcon />}
          endIcon={
            <QboLink
              onClick={() => {
                setSearchKeyword('');
              }}
              className="SearchBlock__cancel-icon">
              <CancelIcon />
            </QboLink>
          }
          placeholder={placeholder}
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.currentTarget.value);
          }}
        />
      )}
      {sortFilterPosition === leftPosition ? renderSortFilterContent() : null}
    </QboCardListItem>
  );
}

SearchCardBlock.propTypes = {
  withFilterButton: PropTypes.bool,
  withSortButton: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  compact: PropTypes.bool,
  autocomplete: PropTypes.bool,
  searchKeyword: PropTypes.string,
  setSearchKeyword: PropTypes.func,
  selectedSort: PropTypes.object,
  setSelectedSort: PropTypes.func,
  listSortOptions: PropTypes.array,
  loading: PropTypes.bool,
  onClickAllResult: PropTypes.func,
  options: PropTypes.array,
  keyGroupBy: PropTypes.string,
  keyLabelOption: PropTypes.string,
  onSelectItem: PropTypes.func,
  setShowDrawer: PropTypes.func,
  showBadgeFilter: PropTypes.bool,
  sort: PropTypes.object,
  clearOnEscape: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  defaultSearchValue: PropTypes.string,
  forceShowClearIcon: PropTypes.bool,
  rightContent: PropTypes.node,
  sortFilterPosition: PropTypes.string,
};

SearchCardBlock.defaultProps = {
  withFilterButton: true,
  withSortButton: false,
  placeholder: '',
  className: '',
  compact: false,
  autocomplete: false,
  searchKeyword: '',
  setSearchKeyword: () => {},
  selectedSort: {},
  setSelectedSort: () => {},
  listSortOptions: [],
  onClickAllResult: () => {},
  loading: false,
  options: [],
  keyGroupBy: '',
  keyLabelOption: '',
  onSelectItem: () => {},
  setShowDrawer: () => {},
  showBadgeFilter: false,
  sort: {},
  clearOnEscape: true,
  clearOnBlur: true,
  defaultSearchValue: '',
  forceShowClearIcon: false,
  rightContent: null,
  sortFilterPosition: rightPosition,
};
