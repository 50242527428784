import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QboTypography, QboDrawerListItem } from '@ui/Components';
import EventIcon from '@mui/icons-material/Event';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useDateTime, useSystemStatus } from '@hooks';

const defaultNull = '-';

const createCardItem = (icon, label, value) => {
  return (
    <QboDrawerListItem icon={icon} label={label}>
      <QboTypography className="DrawerRealmInfoSection__card-list-value">{value}</QboTypography>
    </QboDrawerListItem>
  );
};

export default function useDrawerReplicateInfo(QboStatusReplicateSelectedItem) {
  const { t } = useTranslation();
  const { dateFormat, dateToString, dateTimeNow, dateDistance } = useDateTime();
  const { listReplicateStatus } = useSystemStatus();
  const [selectedReplicateId, setSelectedReplicateId] = useState(null);
  const { QboStatusReplicate } = useSelector((state) => state.qboStatusReplicate);

  const drawerIsOpen = selectedReplicateId !== null;
  const QboStatusReplicateItem = QboStatusReplicate.value.find((v) => v.id === selectedReplicateId);

  let elapsedTime = defaultNull;

  if (
    QboStatusReplicateSelectedItem?.startTime &&
    QboStatusReplicateSelectedItem?.status !== listReplicateStatus.AUTHENTICATION_ERROR
  ) {
    const endTime = QboStatusReplicateSelectedItem?.endTime || dateTimeNow();
    elapsedTime =
      dateDistance(QboStatusReplicateSelectedItem?.startTime, endTime, {
        separator: ' ',
      }) || '1s';
  }

  const generalInformationPanel = [
    {
      headingText: t('dashboard_page.drawer_realm_info_section.general_info_caption'),
      isFullContent: true,
      children: (
        <>
          {createCardItem(
            <EventIcon />,
            t('status_replicate_page.drawer_replicate_info_section.started_at_label'),
            dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME,
              QboStatusReplicateSelectedItem?.startTime
            ) || defaultNull
          )}
          {createCardItem(
            <HistoryToggleOffIcon />,
            t('status_replicate_page.drawer_replicate_info_section.elapsed_time_label'),
            elapsedTime
          )}
          {createCardItem(
            <EventAvailableIcon />,
            t('status_replicate_page.drawer_replicate_info_section.completed_at_label'),
            dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME,
              QboStatusReplicateSelectedItem?.endTime
            ) || defaultNull
          )}
        </>
      ),
    },
  ];

  const labelInfoItem = () => {
    let label = '';

    if (QboStatusReplicateSelectedItem?.status === listReplicateStatus.RUNNING) {
      label = t('status_replicate_page.drawer_replicate_info_section.item_info_calculating');
    } else {
      label = t('status_replicate_page.drawer_replicate_info_section.item_info_realm_label', {
        company_name: QboStatusReplicateSelectedItem?.companyName,
      });
    }

    return label;
  };

  return {
    drawerIsOpen,
    setOpenDrawer: (id) => {
      setSelectedReplicateId(id);
    },
    closeDrawer: () => {
      setSelectedReplicateId(null);
    },
    QboStatusReplicateItem,
    generalInformationPanel,
    labelInfoItem: labelInfoItem(),
  };
}
