//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchSeatUsageAsync,
  fetchSeatUsageExtraReducers,
} from './serviceActions/fetchSeatUsageAsync';

const initialState = {
  Organization: {
    seats: 0,
  },
  fetchingSeatUsage: true,
  error: null,
};

const organizationSlice = createSlice({
  name: 'Organization',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    ...fetchSeatUsageExtraReducers,
  },
});

export { fetchSeatUsageAsync };
export const { resetState } = organizationSlice.actions;
export default organizationSlice.reducer;
