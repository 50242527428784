import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  QboButton,
  QboRightDrawer,
  QboDrawerListWrapper,
  QboDrawerListItem,
  QboTypography,
  QboDrawerBoxItem,
  QboAccordion,
  QboTipsBox,
} from '@ui/Components';
import RestoreAva from '@images/generals/RestoreAva.svg';
import { getStatusRestoreAsync } from '@features/qboStatusRestore/qboStatusRestoreSlice';
import { useDateTime, useSystemStatus, usePageAlert, useAlertMessage } from '@hooks';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from '@utilities';
import RestoreStatusItemBlock from '@ui/Blocks/Shared/RestoreStatusItemBlock';
import useDrawerRestoreInfo from '@pages/SystemStatusPage/pageHooks/useDrawerRestoreInfo';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Box from '@mui/material/Box';
import useQboOauth, { REACTIVATE } from '@pages/sharedHooks/useQboOauth';
import { restoreStatusPath } from '@config/Routes/WebClientRoutes';
import ErrorResponse from '@libs/ErrorResponse';

const defaultNull = '-';

export default function DrawerRestoreInfoSection({
  QboStatusRestore,
  onClose,
  open,
  setSnackbarOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dateFormat, dateToString } = useDateTime();
  const { generalInformationPanel, labelInfoItem } = useDrawerRestoreInfo(QboStatusRestore);
  const { listDownloadStatus } = useSystemStatus();
  const { updateAlert } = usePageAlert();
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();

  const { openOauthWindow, isButtonActive } = useQboOauth(REACTIVATE, {
    qboRealmId: QboStatusRestore?.qboRealmId,
    errorCallback: (data) => {
      onClose();

      if (data.code === ErrorResponse.UnprocessableFormat) {
        updateAlert(
          getErrorMessage(konstMessage.ERROR_REAUTHORIZED_WRONG_REALM, {
            company_name: QboStatusRestore?.companyName,
          })
        );
      } else {
        updateAlert(
          getErrorMessage(konstMessage.ERROR_REAUTHORIZED_GENERAL_ERROR, {
            company_name: QboStatusRestore?.companyName,
          })
        );
      }

      navigate(restoreStatusPath, { replace: true });
    },
    successCallback: () => {
      onClose();
      dispatch(getStatusRestoreAsync({ qboBackupId: QboStatusRestore?.maskedId }));
      updateAlert(
        getSuccessMessage(konstMessage.SUCCESS_QBO_REAUTHENTICATED, {
          company_name: QboStatusRestore?.companyName,
        })
      );
      navigate(restoreStatusPath, { replace: true });
    },
  });

  const handleCopy = (statusId) => {
    const snackbarState = copyToClipboard(statusId);
    setSnackbarOpen(!!snackbarState);
  };

  return (
    <QboRightDrawer
      title={t('status_restore_page.drawer_restore_info_section.heading_text')}
      onClose={onClose}
      open={open}
      className="DrawerRestoreInfoSection__wrapper">
      <div className="DrawerRestoreInfoSection__content">
        <QboDrawerBoxItem
          icon={<img src={RestoreAva} alt="" className="Img__fluid" />}
          label={QboStatusRestore?.companyName || defaultNull}
          withArrow={false}>
          <div className="DrawerRestoreInfoSection__status-id">
            <QboTypography variant="caption">
              {t('status_backup_page.item_card.caption.status_id_label', {
                id: QboStatusRestore?.id,
              }) || defaultNull}
            </QboTypography>
            <QboButton onClick={() => handleCopy(QboStatusRestore?.id)} startIcon={<CopyIcon />} />
          </div>
        </QboDrawerBoxItem>

        <QboDrawerListWrapper>
          <RestoreStatusItemBlock
            label={t('status_restore_page.drawer_restore_info_section.restore_status_label')}
            status={QboStatusRestore?.status}
            percentage={parseInt(QboStatusRestore?.percentage, 10)}
            isReauthButtonActive={isButtonActive}
            onClickReauth={openOauthWindow}
            oauthTipsInfo={t(
              'dashboard_page.drawer_realm_info_section.reauth_correct_login_desc_html'
            )}
            maskedQboRestoreId={QboStatusRestore?.maskedId}
            hasErrorUrl={QboStatusRestore?.hasErrorUrl}
          />

          {listDownloadStatus.EXPIRED === QboStatusRestore?.status && (
            <QboDrawerListItem
              icon={<TimerOffOutlinedIcon />}
              label={t('status_restore_page.drawer_restore_info_section.expired_at_label')}>
              <QboTypography className="DrawerBackupInfoSection__card-list-value">
                {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusRestore?.expiredTime) ||
                  defaultNull}
              </QboTypography>
            </QboDrawerListItem>
          )}

          <QboDrawerListItem
            icon={<InsertDriveFileOutlinedIcon />}
            label={t('status_restore_page.drawer_restore_info_section.item_text_label')}>
            <QboTypography className="DrawerBackupInfoSection__card-list-value" withTitle>
              {labelInfoItem}
            </QboTypography>
          </QboDrawerListItem>

          <QboDrawerListItem
            icon={<Inventory2OutlinedIcon />}
            label={t('status_restore_page.drawer_restore_info_section.point_in_time_text_label')}>
            <QboTypography className="DrawerBackupInfoSection__card-list-value">
              {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusRestore?.pointInTime) ||
                defaultNull}
            </QboTypography>
          </QboDrawerListItem>
          {QboStatusRestore?.notes ? (
            <Box className="DrawerRestoreInfoSection_tips-box-wrapper">
              <QboTipsBox
                className="DrawerRestoreInfoSection_tips-box-notes"
                __html={t('status_restore_page.drawer_restore_info_section.restore_notes_html', {
                  notes: QboStatusRestore?.notes,
                })}
                variant="info"
                icon={<StickyNote2OutlinedIcon />}
              />
            </Box>
          ) : null}
        </QboDrawerListWrapper>
        <QboAccordion
          defaultExpanded
          collections={[...generalInformationPanel]}
          className="DrawerRestoreInfoSection__accordion-information"
        />
      </div>
    </QboRightDrawer>
  );
}

DrawerRestoreInfoSection.propTypes = {
  QboStatusRestore: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setSnackbarOpen: PropTypes.func,
};

DrawerRestoreInfoSection.defaultProps = {
  QboStatusRestore: {},
  onClose: () => {},
  open: false,
  setSnackbarOpen: () => {},
};
