import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';

export default function QboCardListItemStatus({
  leftContent,
  rightContent,
  buttonRightContent,
  children,
  className,
  loading,
  compact,
  ...rest
}) {
  const renderLeftContent = () => {
    if (!leftContent) return null;
    if (loading) {
      return (
        <Skeleton
          className="QboCardListItemStatus__left-content"
          variant="circular"
          width={40}
          height={40}
        />
      );
    }

    return (
      <Grid item className="QboCardListItemStatus__left-content">
        {leftContent}
      </Grid>
    );
  };

  const renderRightContent = () => {
    if (!rightContent) return null;

    return (
      <Grid item className="QboCardListItemStatus__right-content">
        {rightContent}
      </Grid>
    );
  };

  const renderMainContent = () => {
    return (
      <Grid item className="QboCardListItemStatus__content">
        {loading && (
          <>
            <Skeleton variant="text" width="30%" />
            <Skeleton variant="text" width="10%" height={15} />
          </>
        )}

        {!loading && children}
      </Grid>
    );
  };

  const renderButtonRightContent = () => {
    if (!buttonRightContent) return null;

    return (
      <Grid item className="QboCardListItemStatus__button-right-content">
        {buttonRightContent}
      </Grid>
    );
  };

  const attributes = {
    ...(loading ? { justifyContent: 'center', alignItems: 'center' } : {}),
  };

  return (
    <Grid
      container
      className={`QboCardListItemStatus__wrapper ${
        loading ? 'QboCardListItemStatus__loading' : ''
      } ${compact ? 'QboCardListItemStatus__compact-padding' : ''} ${className}`}
      {...attributes}
      {...rest}>
      {renderLeftContent()}
      <Grid className="QboCardListItemStatus__right-content-wrapper">
        <Grid className="QboCardListItemStatus__top-right-content">
          {renderMainContent()}
          {renderRightContent()}
        </Grid>
        {renderButtonRightContent()}
      </Grid>
    </Grid>
  );
}

QboCardListItemStatus.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  buttonRightContent: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  loading: PropTypes.bool,
  compact: PropTypes.bool,
};

QboCardListItemStatus.defaultProps = {
  leftContent: null,
  rightContent: null,
  buttonRightContent: null,
  className: '',
  children: '',
  loading: false,
  compact: false,
};
