import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  QboButton,
  QboRightDrawer,
  QboDrawerListWrapper,
  QboDrawerListItem,
  QboTypography,
  QboDrawerBoxItem,
  QboAccordion,
  QboTipsBox,
} from '@ui/Components';
import DownloadAvaSVG from '@images/generals/DownloadAva.svg';
import { useDateTime, useSystemStatus } from '@hooks';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from '@utilities';
import DownloadStatusItemBlock from '@ui/Blocks/Shared/DownloadStatusItemBlock';
import useDrawerDownloadInfo from '@pages/SystemStatusPage/pageHooks/useDrawerDownloadInfo';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import Box from '@mui/material/Box';

const defaultNull = '-';

export default function DrawerDownloadInfoSection({
  QboStatusDownload,
  onClose,
  open,
  setSnackbarOpen,
  onClickDownload,
}) {
  const { t } = useTranslation();
  const { dateFormat, dateToString } = useDateTime();
  const { generalInformationPanel, labelInfoItem } = useDrawerDownloadInfo(QboStatusDownload);
  const { listDownloadStatus } = useSystemStatus();

  const handleCopy = (statusId) => {
    const snackbarState = copyToClipboard(statusId);
    setSnackbarOpen(!!snackbarState);
  };

  return (
    <QboRightDrawer
      title={t('status_download_page.drawer_download_info_section.heading_text')}
      onClose={onClose}
      open={open}
      className="DrawerDownloadInfoSection__wrapper">
      <div className="DrawerDownloadInfoSection__content">
        <QboDrawerBoxItem
          icon={<img src={DownloadAvaSVG} alt="" className="Img__fluid" />}
          label={QboStatusDownload?.companyName || defaultNull}
          withArrow={false}>
          <div className="DrawerDownloadInfoSection__status-id">
            <QboTypography variant="caption">
              {t('status_backup_page.item_card.caption.status_id_label', {
                id: QboStatusDownload?.id,
              }) || defaultNull}
            </QboTypography>
            <QboButton onClick={() => handleCopy(QboStatusDownload?.id)} startIcon={<CopyIcon />} />
          </div>
        </QboDrawerBoxItem>

        <QboDrawerListWrapper>
          <DownloadStatusItemBlock
            label={t('status_download_page.drawer_download_info_section.download_status_label')}
            status={QboStatusDownload?.status}
            percentage={parseInt(QboStatusDownload?.percentage, 10)}
            onClickDownload={() => {
              onClose();
              onClickDownload();
            }}
            expireAt={dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME_WITH_SORTH_MONTH,
              QboStatusDownload?.expireTime
            )}
          />

          {listDownloadStatus.EXPIRED === QboStatusDownload?.status && (
            <QboDrawerListItem
              icon={<TimerOffOutlinedIcon />}
              label={t('status_download_page.drawer_download_info_section.expired_at_label')}>
              <QboTypography className="DrawerDownloadInfoSection__card-list-value">
                {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusDownload?.expireTime) ||
                  defaultNull}
              </QboTypography>
            </QboDrawerListItem>
          )}

          <QboDrawerListItem
            icon={<InsertDriveFileOutlinedIcon />}
            label={t('status_download_page.drawer_download_info_section.item_text_label')}>
            <QboTypography className="DrawerDownloadInfoSection__card-list-value" withTitle>
              {labelInfoItem}
            </QboTypography>
          </QboDrawerListItem>

          <QboDrawerListItem
            icon={<Inventory2OutlinedIcon />}
            label={t('status_download_page.drawer_download_info_section.point_in_time_text_label')}>
            <QboTypography className="DrawerDownloadInfoSection__card-list-value">
              {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusDownload?.pointInTime) ||
                defaultNull}
            </QboTypography>
          </QboDrawerListItem>
          <Box className="DrawerDownloadInfoSection_tips-box-wrapper">
            {QboStatusDownload?.notes ? (
              <QboTipsBox
                className="DrawerDownloadInfoSection_tips-box-notes"
                __html={t('status_download_page.drawer_download_info_section.download_notes_html')}
                variant="info"
                icon={<StickyNote2OutlinedIcon />}>
                <p>{QboStatusDownload?.notes}</p>
              </QboTipsBox>
            ) : null}
            <QboTipsBox
              className="DrawerDownloadInfoSection_tips-box-warning"
              __html={t('status_download_page.drawer_download_info_section.download_tips_html')}
              variant="warning"
            />
          </Box>
        </QboDrawerListWrapper>
        <QboAccordion
          defaultExpanded
          collections={[...generalInformationPanel]}
          className="DrawerDownloadInfoSection__accordion-information"
        />
      </div>
    </QboRightDrawer>
  );
}

DrawerDownloadInfoSection.propTypes = {
  QboStatusDownload: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setSnackbarOpen: PropTypes.func,
  onClickDownload: PropTypes.func,
};

DrawerDownloadInfoSection.defaultProps = {
  QboStatusDownload: {},
  onClose: () => {},
  open: false,
  setSnackbarOpen: () => {},
  onClickDownload: () => {},
};
