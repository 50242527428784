import * as deLocale from './locales/de.json';
import * as enLocale from './locales/en.json';
import * as esLocale from './locales/es.json';
import * as frLocale from './locales/fr.json';
import * as itLocale from './locales/it.json';
import * as jaLocale from './locales/ja.json';
import * as nlLocale from './locales/nl.json';
import * as plLocale from './locales/pl.json';
import * as ptLocale from './locales/pt.json';
import * as viLocale from './locales/vi.json';

export const constant = {
  MENU_STORAGE: 'MENU_STORAGE',
  MENUS: {
    HOME_MENU: 'HOME_MENU',
    ADVANCED_SEARCH_MENU: 'ADVANCED_SEARCH_MENU',
    SYSTEM_STATUS_MENU: 'SYSTEM_STATUS_MENU',
    SETTING_MENU: 'SETTING_MENU',
    ACCOUNT_CHANGE_PASSWORD_MENU: 'ACCOUNT_CHANGE_PASSWORD_MENU',
    ACCOUNT_SETUP_2FA_MENU: 'ACCOUNT_SETUP_2FA_MENU',
  },
  paginationCache: {
    DASBOARD_BACKUP_REALMS: 'DASBOARD_BACKUP_REALMS',
    DASBOARD_BACKUP_REALMS_OBJECT: 'DASBOARD_BACKUP_REALMS_OBJECT',
    DASBOARD_BACKUP_REALMS_OBJECT_ITEMS: 'DASBOARD_BACKUP_REALMS_OBJECT_ITEMS',
    STATUS_BACKUPS: 'STATUS_BACKUPS',
  },
  ssoCache: {
    CALLBACK_URL: 'CALLBACK_URL',
    SOURCE: 'SOURCE',
    IMPERSONATE: 'IMPERSONATE',
    EMAIL_ADDRESS: 'EMAIL_ADDRESS',
    NAME_INITIAL: 'NAME_INITIAL',
    FEATURES: 'FEATURES',
    FAVICON: 'FAVICON',
    GENERAL_LOGO: 'GENERAL_LOGO',
  },
};

export const ERROR_CODE = {
  RecordAlreadyExists: 'RecordAlreadyExists',
};

/**
 * Basic Setting Variables Define
 */
const BaseSetting = {
  name: 'QBO-Web-Client',
  displayName: 'QboWebClient',
  appVersion: '1.0.0',
  defaultLanguage: 'en',
  defaultNameInitial: 'Avatar',
  defaultSSOCallbackUrl: process.env.REACT_APP_DEFAULT_SSO_CALLBACK_URL,
  qboWebappApiHost: process.env.REACT_APP_QBO_WEBAPP_API_HOST,
  qboWebappClientHost: process.env.REACT_APP_QBO_WEBAPP_CLIENT_HOST,
  qboSizeDownloadLimit: process.env.REACT_APP_SIZE_DOWNLOAD_LIMIT,
  qboEnableRestoreFeature: process.env.REACT_APP_ENABLE_RESTORE_FEATURE
    ? process.env.REACT_APP_ENABLE_RESTORE_FEATURE === 'true'
    : true,
  qboWindowOpenerName: 'QuickBooksOauth2',
  paginationPerPageNumbers: ['10', '25', '50', '100'],
  paginationPerPageDefault: '10',
  minimumShowPagination: '10',
  suggestionSearchLimit: '15',
  refresTokenRetryThrotle: 700,
  searchTypeDelay: 500,
  allowTypeRequestLength: 2,
  intervalCheckingBackupStatus: 5000,
  passwordSetupSetting: {
    minCharLength: 10,
    maxCharLength: 40,
    hasUpperCase: true,
    hasLowerCase: true,
    hasNumber: true,
    hasSymbol: true,
  },
  constant,
  backendDateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  forceUseLocale: 'en',
  resourcesLocales: {
    de: {
      translation: deLocale,
    },
    en: {
      translation: enLocale,
    },
    es: {
      translation: esLocale,
    },
    fr: {
      translation: frLocale,
    },
    it: {
      translation: itLocale,
    },
    ja: {
      translation: jaLocale,
    },
    nl: {
      translation: nlLocale,
    },
    pl: {
      translation: plLocale,
    },
    pt: {
      translation: ptLocale,
    },
    vi: {
      translation: viLocale,
    },
  },
};

export default BaseSetting;
