import ServiceClientError from './ServiceClientError';
import AxiosServiceClient from './AxiosServiceClient';

class ServiceClientFactory {
  constructor(options, serviceType = 'axios') {
    this.serviceType = serviceType;
    this.options = options;
  }

  create() {
    if (this.serviceType === 'axios') {
      return new AxiosServiceClient(this.options);
    }

    throw new ServiceClientError('serviceType is not defined.');
  }
}

export default ServiceClientFactory;
