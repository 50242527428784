import React from 'react';
import { useSelector } from 'react-redux';
import DashboardSection from '@ui/Sections/DashboardPage/DashboardSection';
import InitialBackupSection from '@ui/Sections/DashboardPage/InitialBackupSection';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboSpinnerProgress } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';

export default function DashboardPage() {
  const { t } = useTranslation();
  const { requireInitialSetup, isInitializing } = useSelector((state) => state.application);

  if (isInitializing) {
    return <QboSpinnerProgress sx={{ height: '70vh' }} />;
  }

  if (requireInitialSetup) {
    return (
      <>
        <AlertPageSection />
        <InitialBackupSection />
      </>
    );
  }

  return (
    <>
      <HeaderPageBlock>{t('dashboard_page.header.text')}</HeaderPageBlock>
      <AlertPageSection />
      <DashboardSection />
    </>
  );
}
