import React from 'react';
import PropTypes from 'prop-types';
import { QboCardWrapper } from '@ui/Components';
import ListingDownloadSection from '@ui/Sections/SystemStatusPage/ListingDownloadSection';
import useFetchStatusDownload from '@pages/SystemStatusPage/pageHooks/useFetchStatusDownload';
import useStatusDownloadFilter, {
  ButtonFilter,
} from '@pages/SystemStatusPage/pageHooks/useStatusDownloadFilter';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import NoDownloadSection from '@ui/Sections/SystemStatusPage/NoDownloadSection';
import ModalStatusDownloadCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalStatusDownloadCardBlock';
import DrawerDownloadInfoSection from '@ui/Sections/SystemStatusPage/DrawerDownloadInfoSection';
import useDrawerDownloadInfo from '@pages/SystemStatusPage/pageHooks/useDrawerDownloadInfo';
import useModalStatusDownload from '@pages/SystemStatusPage/pageHooks/useModalStatusDownload';

export default function StatusDownloadSection({ setSnackbarOpen }) {
  const {
    hasDownloadStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    QboStatusDownload,
    fetchingQboStatusDownload,
  } = useFetchStatusDownload();

  const { drawerIsOpen, setOpenDrawer, closeDrawer, QboStatusDownloadItem } =
    useDrawerDownloadInfo();

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusDownloadFilter(onFilter);

  const { onOpen, showModal, onClose, onClickDownload, filenames, onClickLoadMore, totalFiles } =
    useModalStatusDownload();

  return (
    <>
      {!fetchingQboStatusDownload && !hasDownloadStatuses && <NoDownloadSection />}

      {(fetchingQboStatusDownload || hasDownloadStatuses) && (
        <QboCardWrapper>
          <ListingDownloadSection
            onClickRow={setOpenDrawer}
            loading={fetchingQboStatusDownload}
            collection={QboStatusDownload.value}
            hasNextLink={QboStatusDownload.nextLink}
            hasPrevLink={QboStatusDownload.prevLink}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onChangePageNumber={onChangePageNumber}
            pageNumber={pageNumber}
            showPagination={QboStatusDownload.showPagination}
            onFilter={onFilter}
            searchKeyword={keyword}
            setSearchKeyword={setKeyword}
            displaySearchKeyword={displayKeyword}
            handleOpenDrawer={handleOpenDrawer}
            listBadges={listBadges}
            handleDelete={handleDelete}
            onClickDownload={(statusDownloadId) => onOpen(statusDownloadId)}
          />
        </QboCardWrapper>
      )}

      <DrawerFilterSection
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleFilter={handleFilter}
        ButtonFilter={ButtonFilter}
        filterData={filterData}
      />

      <DrawerDownloadInfoSection
        onClose={closeDrawer}
        QboStatusDownload={QboStatusDownloadItem}
        open={drawerIsOpen}
        setSnackbarOpen={setSnackbarOpen}
        onClickDownload={() => onOpen(QboStatusDownloadItem.maskedId)}
      />

      <ModalStatusDownloadCardBlock
        onClickLoadMore={() => {
          onClickLoadMore();
        }}
        onClickDownload={onClickDownload}
        showModal={showModal}
        onClose={onClose}
        filenames={filenames}
        totalFiles={totalFiles}
      />
    </>
  );
}

StatusDownloadSection.propTypes = {
  setSnackbarOpen: PropTypes.func,
};

StatusDownloadSection.defaultProps = {
  setSnackbarOpen: () => {},
};
