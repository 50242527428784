import BaseSetting from '@config/BaseSetting';
import Routes from './Routes';

/**
 * Contains web api urls
 * */

class WebClientRoutes extends Routes {
  //
  // routes
  // endpoint defined here
  // format: [url_name]Path
  //

  // ref from backend, eg:
  // https://localhost:3001/en/qbo_sso/1657178937/60d1ebad-fdc1-4e62-9802-969447618c45/pgcHT3jbOBYvSmvHE7X2c3gSE9FNGa5-Z2vJOsV3dsc=
  loginSSOPath(params = {}, format = '') {
    return this.createUrl(
      '/:locale/qbo_sso/:timestamp/:authenticationToken/:signature',
      params,
      format
    );
  }

  homePath(params = {}, format = '') {
    return this.createUrl('/', params, format);
  }

  dashboardPath(params = {}, format = '') {
    return this.createUrl('/dashboard', params, format);
  }

  newQuickbooksPath(params = {}, format = '') {
    return this.createUrl('/quickbooks/new', params, format);
  }

  oauthQuickbooksCallbackPath(params = {}, format = '') {
    return this.createUrl('/quickbooks/oauth/callback', params, format);
  }

  confirmQuickbooksPath(params = {}, format = '') {
    return this.createUrl('/quickbooks/confirm', params, format);
  }

  showRealmPath(params = {}, format = '') {
    return this.createUrl('/realms/:id', params, format);
  }

  searchRealmObjectItemPath(params = {}, format = '') {
    return this.createUrl('/realms/:id/search', params, format);
  }

  showRealmObjectPath(params = {}, format = '') {
    return this.createUrl('/realms/:id/:objectName', params, format);
  }

  showRealmObjectContentPath(params = {}, format = '') {
    return this.createUrl('/realms/:id/:objectName/:objectId/contents/:versionId', params, format);
  }

  backupStatusPath(params = {}, format = '') {
    return this.createUrl('/statuses/backup', params, format);
  }

  notFoundPath(params = {}, format = '') {
    return this.createUrl('/404', params, format);
  }

  errorPath(params = {}, format = '') {
    return this.createUrl('/500', params, format);
  }

  settingChangePasswordPath() {
    return this.createUrl('/account-settings/change-password');
  }

  downloadStatusPath(params = {}, format = '') {
    return this.createUrl('/statuses/download', params, format);
  }

  restoreStatusPath(params = {}, format = '') {
    return this.createUrl('/statuses/restore', params, format);
  }

  settingSetup2FAPath() {
    return this.createUrl('/account-settings/setup-2fa');
  }

  replicateStatusPath(params = {}, format = '') {
    return this.createUrl('/statuses/replicate', params, format);
  }
}

const ClientRoutes = new WebClientRoutes(BaseSetting.qboWebappClientHost, true);

export const loginSSOPath = ClientRoutes.loginSSOPath();
export const homePath = ClientRoutes.homePath();
export const dashboardPath = ClientRoutes.dashboardPath();
export const newQuickbooksPath = ClientRoutes.newQuickbooksPath();
export const oauthQuickbooksCallbackPath = ClientRoutes.oauthQuickbooksCallbackPath();
export const confirmQuickbooksPath = ClientRoutes.confirmQuickbooksPath();
export const showRealmPath = ClientRoutes.showRealmPath();
export const searchRealmObjectItemPath = ClientRoutes.searchRealmObjectItemPath();
export const showRealmObjectPath = ClientRoutes.showRealmObjectPath();
export const showRealmObjectContentPath = ClientRoutes.showRealmObjectContentPath();
export const backupStatusPath = ClientRoutes.backupStatusPath();
export const notFoundPath = ClientRoutes.notFoundPath();
export const errorPath = ClientRoutes.errorPath();
export const settingChangePasswordPath = ClientRoutes.settingChangePasswordPath();
export const downloadStatusPath = ClientRoutes.downloadStatusPath();
export const restoreStatusPath = ClientRoutes.restoreStatusPath();
export const settingSetup2FAPath = ClientRoutes.settingSetup2FAPath();
export const replicateStatusPath = ClientRoutes.replicateStatusPath();

export const publicLayouts = [oauthQuickbooksCallbackPath, loginSSOPath];

export default ClientRoutes;
