import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePagination, useCollectionInterval } from '@hooks';
import {
  qboFetchStatusReplicateAsync,
  resetState,
  updateFetching,
  getStatusReplicateAsync,
} from '@features/qboStatusReplicate/qboStatusReplicateSlice';
import { constant } from '@config/BaseSetting';
import { features } from '@hooks/usePagination';
import { listReplicateStatus } from '@hooks/useSystemStatus';

const { paginationCache } = constant;

export default function useFetchStatusReplicate() {
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { QboStatusReplicate, fetchingQboStatusReplicate } = useSelector(
    (state) => state.qboStatusReplicate
  );

  const {
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    filter,
  } = usePagination(
    QboStatusReplicate,
    qboFetchStatusReplicateAsync,
    paginationCache.DASBOARD_BACKUP_REALMS,
    isApplicationReady,
    {},
    [features.search, features.filtering, features.pagination, features.initialize],
    () => {
      // show loading immediately when keyword
      // changed to blank string
      if (keyword.length === 0) dispatch(updateFetching(true));
    }
  );

  let hasReplicateStatuses = false;

  if (hasReplicateStatuses === false) hasReplicateStatuses = QboStatusReplicate.value.length > 0;
  if (hasReplicateStatuses === false) hasReplicateStatuses = keyword.length > 0;
  if (hasReplicateStatuses === false) hasReplicateStatuses = filter.length > 0;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useCollectionInterval(
    QboStatusReplicate.value, // collection
    (replicate) => replicate.status === listReplicateStatus.RUNNING, // eligible for interval
    (replicate) => replicate.status !== listReplicateStatus.RUNNING, // eligible removed from interval
    (replicate) => dispatch(getStatusReplicateAsync({ qboReplicateId: replicate.maskedId })) // action when interval running
  );

  return {
    QboStatusReplicate,
    fetchingQboStatusReplicate,
    hasReplicateStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    isApplicationReady,
  };
}
