//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboFetchRealmsAsync,
  qboFetchRealmsExtraReducers,
} from './serviceActions/fetchQboRealmsAsync';
import {
  getQboRealmStatusAsync,
  getQboRealmStatusExtraReducers,
} from './serviceActions/getQboRealmStatusAsync';
import {
  qboDeactivateRealmAsync,
  qboDeactivateRealmExtraReducers,
} from './serviceActions/deactivateRealmAsync';
import {
  qboBackupNowRealmAsync,
  qboBackupNowRealmExtraReducers,
} from './serviceActions/backupNowAsync';

const initialState = {
  QboRealms: {
    value: [],
    nextLink: null,
    prevLink: null,
    showPagination: false,
  },
  fetchingQboRealms: true,
  generateDownloadStatus: null,
  deactivateRealm: {
    error: null,
    submitting: false,
    success: false,
  },
  error: null,
};

const qboRealmsSlice = createSlice({
  name: 'QboRealms',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    resetDownloadStatus(state) {
      state.generateDownloadStatus = null;
    },
  },
  extraReducers: {
    ...qboFetchRealmsExtraReducers,
    ...getQboRealmStatusExtraReducers,
    ...qboDeactivateRealmExtraReducers,
    ...qboBackupNowRealmExtraReducers,
  },
});

export const { resetState, deactivate, resetDownloadStatus } = qboRealmsSlice.actions;
export {
  qboFetchRealmsAsync,
  getQboRealmStatusAsync,
  qboDeactivateRealmAsync,
  qboBackupNowRealmAsync,
};

export default qboRealmsSlice.reducer;
