import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQboDownloadStatusAPI } from '@services/WebApiService';

const getStatusDownloadAsync = createAsyncThunk(
  'QboStatusDownload/getQboDownloadStatus',
  async ({ qboDownloadId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await getQboDownloadStatusAPI(accessToken, qboDownloadId);
    return data;
  }
);

const { fulfilled } = getStatusDownloadAsync;

const getQboDownloadStatusExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.id) {
      const {
        id,
        companyName,
        status,
        startTime,
        endTime,
        expireTime,
        pointInTime,
        type,
        totalObjects,
        percentage,
        filenames,
        displayName,
      } = action.payload;

      // replace the value from array
      const value = state.QboStatusDownload.value.find((v) => v.id === id);

      if (value) {
        const index = state.QboStatusDownload.value.indexOf(value);
        const currentValueState = state.QboStatusDownload.value[index];

        state.QboStatusDownload.value[index] = {
          ...currentValueState,
          companyName,
          status,
          startTime,
          endTime,
          expireTime,
          pointInTime,
          type,
          totalObjects,
          percentage,
          filenames,
          displayName,
        };
      }
    }
  },
};

export { getStatusDownloadAsync, getQboDownloadStatusExtraReducers };
