import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {
  ErrorRounded as ErrorRoundedIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { QboTypography } from '@ui/Components';
import { bgIconSuccess, bgIconError } from '@config/themes/default/variables';

const error = 'error';
const success = 'success';

export default function QboAlert({
  sx,
  className,
  children,
  type,
  headingText,
  innerRef,
  ...rest
}) {
  const icon = () => {
    if (rest.icon) return <QboTypography>{rest.icon}</QboTypography>;
    if (type === error) {
      return (
        <QboTypography>
          <ErrorRoundedIcon sx={{ color: bgIconError, fontSize: '2.4rem' }} />
        </QboTypography>
      );
    }
    if (type === success) {
      return (
        <QboTypography>
          <CheckCircleIcon sx={{ color: bgIconSuccess, fontSize: '2.4rem' }} />
        </QboTypography>
      );
    }

    return null;
  };

  const styles = {
    marginBottom: '2rem',
  };

  return (
    <Alert
      {...rest}
      icon={icon()}
      severity={type}
      ref={innerRef}
      className={`QboAlert__wrapper ${className} ${!headingText && 'QboAlert__without-heading'}`}
      sx={{ ...sx, ...styles }}>
      {headingText && <AlertTitle>{headingText}</AlertTitle>}
      {children}
    </Alert>
  );
}

QboAlert.propTypes = {
  type: PropTypes.string,
  headingText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  sx: PropTypes.object,
  className: PropTypes.string,
  innerRef: PropTypes.func,
};

QboAlert.defaultProps = {
  type: 'success',
  headingText: undefined,
  children: '',
  sx: {},
  className: '',
  innerRef: () => {},
};
