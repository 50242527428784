//
// All related with the features or states
// should be managed of the wrapper of components
//
// the component only receive the state from redux as props
// and the logic inside the component related to manipulate
// UI and UX only
//

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { QboCardWrapper } from '@ui/Components';
import {
  qboFetchRealmsAsync,
  getQboRealmStatusAsync,
  qboBackupNowRealmAsync,
  resetState as qboRealmsResetState,
} from '@features/qboRealms/qboRealmsSlice';
import {
  fetchSeatUsageAsync,
  resetState as usageSeatResetState,
} from '@features/organization/organizationSlice';
import { usePagination, useSystemStatus, useCollectionInterval } from '@hooks';
import { constant } from '@config/BaseSetting';
import useDrawerRealmInfo from '@pages/DashboardPage/pageHooks/useDrawerRealmInfo';
import useBackupNow from '@pages/sharedHooks/useBackupNow';
import BackupNowConfirmationBlock from '@ui/Blocks/Shared/BackupNowConfirmationBlock';
import useRealmDownload from '@pages/sharedHooks/useRealmDownload';
import ModalDownloadCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalDownloadCardBlock';
import useRealmRestore from '@pages/sharedHooks/useRealmRestore';
import ModalRestoreCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalRestoreCardBlock';
import useRealmReplicate from '@pages/DashboardPage/pageHooks/useRealmReplicate';
import ModalReplicateCardBlock from '@ui/Blocks/DashboardPageBlocks/ModalReplicateCardBlock';
import useDrawerRestoreGuide from '@pages/DashboardPage/pageHooks/useDrawerRestoreGuide';
import DrawerReplicateGuideSection from '@ui/Sections/DashboardPage/DrawerReplicateGuideSection';
import useDrawerReplicateGuide from '@pages/DashboardPage/pageHooks/useDrawerReplicateGuide';
import DrawerRestoreGuideSection from './DrawerRestoreGuideSection';
import DrawerRealmInfoSection from './DrawerRealmInfoSection';
import UsageCardSection from './UsageCardSection';
import BackupsQboSection from './BackupsQboSection';

const { paginationCache } = constant;

export default function DashboardSection() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listBackupStatus } = useSystemStatus();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { fetchingQboRealms, QboRealms } = useSelector((state) => state.qboRealms);
  const { Organization, fetchingSeatUsage } = useSelector((state) => state.organization);
  const { drawerIsOpen, setOpenDrawer, closeDrawer, QboRealm } = useDrawerRealmInfo();
  const {
    onSelectForBackup,
    onConfirmBackupNow,
    openDialogConfirm,
    onCloseConfirmBackupNow,
    companyNameLabel,
    requestingBackupNow,
  } = useBackupNow(qboBackupNowRealmAsync);

  const { drawerRestoreIsOpen, openDrawerRestore, closeDrawerRestore } = useDrawerRestoreGuide();

  const { drawerReplicateIsOpen, openDrawerReplicate, closeDrawerReplicate } =
    useDrawerReplicateGuide();

  useCollectionInterval(
    QboRealms.value, // collection
    (backup) => !backup.status || backup.status === listBackupStatus.RUNNING, // eligible for interval
    (backup) => backup.status && backup.status !== listBackupStatus.RUNNING, // eligible removed from interval
    (backup) => dispatch(getQboRealmStatusAsync({ qboRealmId: backup.id })) // action when interval running
  );

  const {
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onSort,
    onFilter,
    sort,
  } = usePagination(
    QboRealms,
    qboFetchRealmsAsync,
    paginationCache.DASBOARD_BACKUP_REALMS,
    isApplicationReady
  );

  const {
    showModalDateTime,
    showModalConfirmation,
    onOpen,
    onClose,
    onNextButton,
    onBackButton,
    setSelectedRealmId,
    timeValue,
    setTimeValue,
    dateValue,
    setDateValue,
    notes,
    setNotes,
    selectedTime,
    onGenerateLink,
    QboRealm: selectedRealm,
  } = useRealmDownload();

  const {
    showModalRestore,
    onOpen: onOpenModalRestore,
    onClose: onCloseModalRestore,
    timeValue: timeValueRestore,
    setTimeValue: setTimeValueRestore,
    dateValue: dateValueRestore,
    setDateValue: setDateValueRestore,
    selectedTimeRestore,
    onNextButtonRestore,
    showModalConfirmationRestore,
    onBackButtonRestore,
    selectedRestoreData,
    restoreNotes,
    setRestoreNotes,
    restoreType,
    onGenerateRestore,
  } = useRealmRestore();

  const {
    showModalReplicate,
    onOpen: onOpenModalReplicate,
    onClose: onCloseModalReplicate,
    timeValue: timeValueReplicate,
    setTimeValue: setTimeValueReplicate,
    dateValue: dateValueReplicate,
    setDateValue: setDateValueReplicate,
    selectedTimeReplicate,
    onNextButtonReplicate,
    showModalConfirmationReplicate,
    onBackButtonReplicate,
    selectedReplicateData,
    replicateNotes,
    setReplicateNotes,
    onGenerateReplicate,
    environment,
    setEnvironment,
    listEnvironment,
    checkboxConfirmation,
    setCheckboxConfirmation,
    checkboxFollowGuide,
    setCheckboxFollowGuide,
    checkFormIsValid,
    showModalConfirmationValidationReplicate,
    textConfirmation,
    setTextConfirmation,
    responseOAuth,
    setResponseOAuth,
    getErrorMessages,
  } = useRealmReplicate({ onCloseGuide: closeDrawerReplicate });

  useEffect(() => {
    if (isApplicationReady) dispatch(fetchSeatUsageAsync());
  }, [isApplicationReady]);

  useEffect(() => {
    return () => {
      dispatch(usageSeatResetState());
      dispatch(qboRealmsResetState());
    };
  }, []);

  return (
    <QboCardWrapper>
      <UsageCardSection loading={fetchingSeatUsage} seats={Organization.seats} />

      <BackupsQboSection
        loading={fetchingQboRealms}
        collection={QboRealms.value}
        hasNextLink={QboRealms.nextLink}
        hasPrevLink={QboRealms.prevLink}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        onChangePageNumber={onChangePageNumber}
        pageNumber={pageNumber}
        showPagination={QboRealms.showPagination}
        onSort={onSort}
        sort={sort}
        onFilter={onFilter}
        onClickDownload={(selectedId) => {
          setSelectedRealmId(selectedId);
          onOpen();
        }}
        onClickRestore={(selectedId) => {
          onOpenModalRestore(selectedId);
        }}
        onClickReplicate={(selectedId) => {
          onOpenModalReplicate(selectedId);
        }}
        onClickMoreInfo={setOpenDrawer}
        onClickBackupNow={onSelectForBackup}
        searchKeyword={keyword}
        setSearchKeyword={setKeyword}
        displaySearchKeyword={displayKeyword}
      />

      <DrawerRealmInfoSection onClose={closeDrawer} QboRealm={QboRealm} open={drawerIsOpen} />

      <DrawerRestoreGuideSection onClose={closeDrawerRestore} open={drawerRestoreIsOpen} />

      <BackupNowConfirmationBlock
        onClose={onCloseConfirmBackupNow}
        onConfirm={onConfirmBackupNow}
        open={openDialogConfirm}
        companyName={companyNameLabel}
        submitting={requestingBackupNow}
      />
      <ModalDownloadCardBlock
        onClose={() => onClose()}
        onNextButton={() => onNextButton()}
        onBackButton={() => onBackButton()}
        onGenerateLink={() => onGenerateLink()}
        showModalDateTime={showModalDateTime}
        showModalConfirmation={showModalConfirmation}
        selectedTime={selectedTime}
        minDate={selectedRealm?.initialCompletedBackupAt || selectedRealm?.createdAt}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        dateValue={dateValue}
        setDateValue={setDateValue}
        notes={notes}
        setNotes={setNotes}
        itemSelectedText={t('dashboard_page.modal_download.item_value_text', {
          company_name: selectedRealm?.companyName,
        })}
      />
      <ModalRestoreCardBlock
        showModalRestore={showModalRestore}
        onClose={onCloseModalRestore}
        timeValue={timeValueRestore}
        setTimeValue={setTimeValueRestore}
        dateValue={dateValueRestore}
        setDateValue={setDateValueRestore}
        selectedTime={selectedTimeRestore}
        onOpenGuide={openDrawerRestore}
        onNextButton={onNextButtonRestore}
        showModalConfirmation={showModalConfirmationRestore}
        onBackButton={onBackButtonRestore}
        minDate={selectedRestoreData?.initialCompletedBackupAt || selectedRestoreData?.createdAt}
        itemSelectedText={t('dashboard_page.modal_restore.item_value_text', {
          company_name: selectedRestoreData?.companyName,
        })}
        notes={restoreNotes}
        setNotes={setRestoreNotes}
        onGenerateLink={() => onGenerateRestore(restoreType.restoreTypeRealm)}
      />
      <ModalReplicateCardBlock
        showModalReplicate={showModalReplicate}
        onClose={onCloseModalReplicate}
        timeValue={timeValueReplicate}
        setTimeValue={setTimeValueReplicate}
        dateValue={dateValueReplicate}
        setDateValue={setDateValueReplicate}
        selectedTime={selectedTimeReplicate}
        onOpenGuide={openDrawerReplicate}
        onNextButton={onNextButtonReplicate}
        showModalConfirmation={showModalConfirmationReplicate}
        onBackButton={onBackButtonReplicate}
        minDate={
          selectedReplicateData?.initialCompletedBackupAt || selectedReplicateData?.createdAt
        }
        itemSelectedText={t('dashboard_page.modal_restore.item_value_text', {
          company_name: selectedReplicateData?.companyName,
        })}
        notes={replicateNotes}
        setNotes={setReplicateNotes}
        onGenerateLink={() => onGenerateReplicate()}
        environment={environment}
        setEnvironment={setEnvironment}
        listEnvironment={listEnvironment}
        checkboxConfirmation={checkboxConfirmation}
        setCheckboxConfirmation={setCheckboxConfirmation}
        checkboxFollowGuide={checkboxFollowGuide}
        setCheckboxFollowGuide={setCheckboxFollowGuide}
        checkFormIsValid={checkFormIsValid}
        showModalConfirmationValidation={showModalConfirmationValidationReplicate}
        textConfirmation={textConfirmation}
        setTextConfirmation={setTextConfirmation}
        realmId={selectedReplicateData?.id}
        responseOAuth={responseOAuth}
        setResponseOAuth={setResponseOAuth}
        getErrorMessages={getErrorMessages}
      />

      <DrawerReplicateGuideSection onClose={closeDrawerReplicate} open={drawerReplicateIsOpen} />
    </QboCardWrapper>
  );
}
