import React from 'react';
import PropTypes from 'prop-types';
import { Card, Box } from '@mui/material';
import { QboTypography } from '@ui/Components';

export default function QboCard({
  sx,
  className,
  children,
  shadow,
  isMainWrapper,
  isCentered,
  title,
  noPadding,
  titleCard,
  ...rest
}) {
  const styles = {
    backgroundColor: '#fff',
    ...(isMainWrapper ? { padding: '20px 28px 40px' } : {}),
    ...(isCentered
      ? {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '20px 28px 20px 28px',
          gap: '1rem',
          minHeight: '65vh',
        }
      : {}),
    ...(noPadding ? { padding: '0' } : {}),
  };

  return (
    <Box className="QboCard__wrapper">
      {title && <QboTypography className="QboCard__title">{title}</QboTypography>}

      <Card
        className={`Card__default Card__box-shadow-${shadow} ${className}`}
        sx={{
          ...styles,
          ...sx,
        }}
        {...rest}>
        {titleCard && (
          <QboTypography variant="h5" className="QboCard__title-card">
            {titleCard}
          </QboTypography>
        )}
        {children}
      </Card>
    </Box>
  );
}

QboCard.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  shadow: PropTypes.string,
  isMainWrapper: PropTypes.bool,
  isCentered: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  noPadding: PropTypes.bool,
  titleCard: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

QboCard.defaultProps = {
  sx: {},
  children: '',
  shadow: 'depth-10',
  isMainWrapper: false,
  isCentered: false,
  className: '',
  title: null,
  noPadding: false,
  titleCard: null,
};
