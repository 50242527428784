import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { QboTypography } from '@ui/Components';

export default function QboCardHeader({ className, headingText, subtitleText }) {
  return (
    <Box className={`QboCardHeader__wrapper ${className}`}>
      <QboTypography variant="h4">{headingText}</QboTypography>
      <QboTypography className="QboTypography__description">{subtitleText}</QboTypography>
    </Box>
  );
}

QboCardHeader.propTypes = {
  headingText: PropTypes.string,
  subtitleText: PropTypes.string,
  className: PropTypes.string,
};

QboCardHeader.defaultProps = {
  headingText: '',
  subtitleText: '',
  className: '',
};
