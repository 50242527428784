import { useTranslation } from 'react-i18next';
import SystemStatus from '@libs/SystemStatus';
import useStatusFilter, {
  ButtonFilter as ButtonFilterConst,
} from '@pages/sharedHooks/useStatusFilter';

export const ButtonFilter = ButtonFilterConst;

const useStatusReplicateFilter = (onFilter) => {
  const { t } = useTranslation();
  const { listReplicateStatus } = SystemStatus;

  const listFilter = [
    {
      label: 'system_status.completed_text',
      status: listReplicateStatus.SUCCESS,
    },
    {
      label: 'system_status.partial_completed_text',
      status: listReplicateStatus.PARTIALLY_COMPLETED,
    },
    {
      label: 'system_status.running_text',
      status: listReplicateStatus.RUNNING,
    },
    {
      label: 'system_status.authentication_error_text',
      status: listReplicateStatus.AUTHENTICATION_ERROR,
    },
    {
      label: 'system_status.failed_text',
      status: listReplicateStatus.FAILED,
    },
  ];

  const headingText = t('status_replicate_page.search_block.filter_by_replicate_status_text');

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusFilter({ onFilter, listFilter, headingText });

  return {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  };
};

export default useStatusReplicateFilter;
