import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboAlert, QboTypography, QboSnackbar } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import TabsCardBlock from '@ui/Blocks/Shared/CardBlocks/TabsCardBlock';
import { usePageAlert } from '@hooks';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import StatusBackupSection from '@ui/Sections/SystemStatusPage/StatusBackupSection';
import StatusDownloadSection from '@ui/Sections/SystemStatusPage/StatusDownloadSection';
import StatusRestoreSection from '@ui/Sections/SystemStatusPage/StatusRestoreSection';
import StatusReplicateSection from '@ui/Sections/SystemStatusPage/StatusReplicateSection';
import BaseSetting from '@config/BaseSetting';
import useTabsSystemStatus, { ConstantFeature } from './pageHooks/useTabsSystemStatus';

export default function SystemStatusPage() {
  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.SYSTEM_STATUS_MENU);
  const { t } = useTranslation();
  const { isAlertOpen, alert, closeAlert } = usePageAlert();

  const { tabIndex, onChange, snackbarOpen, setSnackbarOpen, titles, icons, activeFeatures } =
    useTabsSystemStatus();

  const renderAlert = () => {
    if (!isAlertOpen) return null;

    return (
      <QboAlert
        type={alert.type}
        headingText={alert.heading}
        onClose={() => {
          closeAlert(false);
        }}>
        <QboTypography wrap>{alert.message}</QboTypography>
      </QboAlert>
    );
  };

  return (
    <>
      <HeaderPageBlock>{t('status_backup_page.header.text')}</HeaderPageBlock>
      {renderAlert()}

      <QboSnackbar
        open={snackbarOpen}
        message={t('snackbar.success_copy')}
        onClose={() => setSnackbarOpen(false)}
      />
      <TabsCardBlock title={titles} icon={icons} tabIndex={tabIndex} onChange={onChange}>
        {activeFeatures.includes(ConstantFeature.BACKUPS) ? (
          <StatusBackupSection setSnackbarOpen={setSnackbarOpen} />
        ) : null}
        {activeFeatures.includes(ConstantFeature.DOWNLOAD) ? (
          <StatusDownloadSection setSnackbarOpen={setSnackbarOpen} />
        ) : null}
        {BaseSetting.qboEnableRestoreFeature && activeFeatures.includes(ConstantFeature.RESTORE) ? (
          <StatusRestoreSection setSnackbarOpen={setSnackbarOpen} />
        ) : null}
        {activeFeatures.includes(ConstantFeature.REPLICATE) ? (
          <StatusReplicateSection setSnackbarOpen={setSnackbarOpen} />
        ) : null}
      </TabsCardBlock>
    </>
  );
}
