import React from 'react';
import PropTypes from 'prop-types';
import { QboModal } from '@ui/Components';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/no-danger */
export default function BackupNowConfirmationBlock({
  onConfirm,
  open,
  onClose,
  companyName,
  submitting,
}) {
  const { t } = useTranslation();

  return (
    <QboModal
      open={open}
      onClose={onClose}
      className="BackupNowConfirmation__wrapper"
      title={t('dashboard_page.modal.backup_now_title')}
      button={{
        text: t('dashboard_page.modal.backup_now_confirm'),
        position: 'right',
        onClick: onConfirm,
        loading: submitting,
      }}
      preventOutsideClick>
      <div
        className="BackupNowConfirmation__body"
        dangerouslySetInnerHTML={{
          __html: t('dashboard_page.modal.backup_now_confirm_html', { company_name: companyName }),
        }}
      />
    </QboModal>
  );
}

BackupNowConfirmationBlock.propTypes = {
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  companyName: PropTypes.string,
  submitting: PropTypes.bool,
};

BackupNowConfirmationBlock.defaultProps = {
  onConfirm: () => {},
  onClose: () => {},
  open: false,
  companyName: '',
  submitting: false,
};
