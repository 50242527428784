import React from 'react';
import PropTypes from 'prop-types';
import {
  QboCardListItemStatus,
  QboTypography,
  QboHighlighter,
  QboLinearProgress,
} from '@ui/Components';
import BackupAvaSVG from '@images/generals/BackupAva.svg';
import { useDateTime } from '@hooks';
import useSystemStatus, { listBackupStatus } from '@hooks/useSystemStatus';
import { t } from 'i18next';

const defaultNull = '-';

/* eslint-disable react/no-danger */
export default function StatusCardItemBlock({ QboStatusBackup, searchKeyword, onClickRow }) {
  const { dateToString, dateFormat, dateDistance, dateTimeNow } = useDateTime();
  const { badgeBackupStatusRender } = useSystemStatus({
    status: QboStatusBackup.status,
    withIcon: true,
    withInfoIcon: QboStatusBackup?.status === listBackupStatus.AUTHENTICATION_ERROR,
  });

  let elapsedTime = defaultNull;

  if (QboStatusBackup?.startTime) {
    const endTime = QboStatusBackup?.endTime || dateTimeNow();
    elapsedTime = dateDistance(QboStatusBackup?.startTime, endTime, {
      separator: ' ',
    });
  }

  const backupInfoHTML = () => {
    const info = [
      t('status_backup_page.item_card.caption.status_id_label', {
        id: QboStatusBackup?.id,
      }),
      t('status_backup_page.item_card.caption.started_at_label', {
        date: dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusBackup?.startTime) || defaultNull,
      }),
      t('status_backup_page.item_card.caption.elapsed_at_label', { date: elapsedTime }),
    ];

    if (QboStatusBackup?.status === listBackupStatus.RUNNING && !QboStatusBackup?.startTime) {
      // calculating
      info.push(`${t('system_status.calculating_text')}...`);
    } else if (QboStatusBackup?.status === listBackupStatus.RUNNING) {
      // running info
      info.push(
        t('system_status.completed.percentage_text', {
          number: QboStatusBackup?.percentage,
        })
      );
    } else {
      // fallback
      info.push(
        t('status_backup_page.item_card.caption.completed_at_label', {
          date: dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusBackup?.endTime) || defaultNull,
        })
      );
    }

    return info.join(' | ');
  };

  return (
    <QboCardListItemStatus
      onClick={() => {
        if (onClickRow) onClickRow(QboStatusBackup.id);
      }}
      className="StatusCardItemBlock__wrapper StatusCardItemBlock__active"
      justifyContent="center"
      alignItems="center"
      leftContent={<img src={BackupAvaSVG} alt="BackupAvaSVG" className="Img__fluid" />}
      rightContent={
        <div className="StatusCardItemBlock__right-content">{badgeBackupStatusRender()}</div>
      }
      buttonRightContent={
        QboStatusBackup?.status === listBackupStatus.RUNNING && (
          <QboLinearProgress
            progress={parseInt(QboStatusBackup?.percentage, 10)}
            className="StatusCardItemBlock__running-progress"
            processing={Boolean(!QboStatusBackup?.startTime)}
          />
        )
      }>
      <div className="StatusCardItemBlock__title">
        <QboTypography variant="subtitle2">
          {searchKeyword !== '' ? (
            <QboHighlighter searchWord={searchKeyword} sentences={QboStatusBackup?.companyName} />
          ) : (
            QboStatusBackup?.companyName
          )}
        </QboTypography>
      </div>

      <div
        className="StatusCardItemBlock__info QboCardListItem__info"
        dangerouslySetInnerHTML={{ __html: backupInfoHTML() }}
      />
    </QboCardListItemStatus>
  );
}

StatusCardItemBlock.propTypes = {
  QboStatusBackup: PropTypes.object.isRequired,
  searchKeyword: PropTypes.string,
  onClickRow: PropTypes.func,
};

StatusCardItemBlock.defaultProps = {
  searchKeyword: '',
  onClickRow: () => {},
};
