import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextField, Paper, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { QboLink, QboTypography, QboHighlighter } from '@ui/Components';
import { SearchOutlined as SearchOutlinedIcon, Cancel as CancelIcon } from '@mui/icons-material';

function PaperComponent({
  children,
  loading,
  linkText,
  onClickLink,
  options,
  searchValue,
  ...rest
}) {
  const { t } = useTranslation();

  if (loading) {
    return (
      <Paper {...rest} className={`QboAutocomplete__popper-wrapper ${rest.className}`}>
        <QboTypography className="QboAutocomplete__loading-text">
          {t('label.loading')}...
        </QboTypography>
      </Paper>
    );
  }

  if (options && options.length === 0) return null;

  return (
    <Paper {...rest} className={`QboAutocomplete__popper-wrapper ${rest.className}`}>
      <div>{children}</div>
      {linkText && searchValue.length > 0 && (
        <QboLink
          onMouseDown={(e) => {
            e.preventDefault();
            onClickLink();
          }}
          className="QboAutocomplete__bottom-link">
          {linkText}
        </QboLink>
      )}
    </Paper>
  );
}

PaperComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClickLink: PropTypes.func,
  linkText: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.array,
  searchValue: PropTypes.string,
};

PaperComponent.defaultProps = {
  children: null,
  linkText: null,
  onClickLink: () => {},
  loading: false,
  options: [],
  searchValue: '',
};

export default function QboAutocomplete({
  keywordFieldRef,
  searchResultLabel,
  onChange,
  onClickLink,
  placeholder,
  loading,
  options,
  keyGroupBy,
  keyLabelOption,
  onSelect,
  onInputChange,
  clearOnEscape,
  clearOnBlur,
  defaultSearchValue,
  forceShowClearIcon,
  ...rest
}) {
  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  return (
    <Autocomplete
      {...rest}
      className={`QboAutocomplete__wrapper ${
        forceShowClearIcon ? 'QboAutocomplete__always-show-icon' : ''
      } `}
      freeSolo
      inputValue={searchValue}
      clearOnEscape={clearOnEscape}
      clearOnBlur={clearOnBlur}
      blurOnSelect
      options={options.sort((a, b) => -b[keyGroupBy].localeCompare(a[keyGroupBy]))}
      groupBy={(option) => option[keyGroupBy]}
      getOptionLabel={(option) => option[keyLabelOption]}
      renderOption={(props, option, state) => {
        return (
          <li {...props} key={option?.id}>
            <QboTypography>
              {state.inputValue !== '' ? (
                <QboHighlighter searchWord={state.inputValue} sentences={option[keyLabelOption]} />
              ) : (
                option[keyLabelOption]
              )}
            </QboTypography>
          </li>
        );
      }}
      clearIcon={<CancelIcon />}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={onChange}
            ref={keywordFieldRef}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              className: `${params.InputProps.className} FormControl__default-wrapper`,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                  if (onClickLink && searchValue.length > 0) onClickLink();
                }
              },
            }}
          />
        );
      }}
      PaperComponent={PaperComponent}
      componentsProps={{
        paper: {
          linkText: searchResultLabel,
          loading,
          onClickLink,
          options,
          searchValue,
        },
      }}
      onChange={(e, value) => {
        onSelect(value);
      }}
      onInputChange={(e, value, reason) => {
        if (reason === 'reset') {
          setSearchValue('');
          onInputChange('');
        } else {
          setSearchValue(value);
          onInputChange(value);
        }
      }}
    />
  );
}

QboAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  keywordFieldRef: PropTypes.object,
  onChange: PropTypes.func,
  searchResultLabel: PropTypes.string,
  onClickLink: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.array,
  keyGroupBy: PropTypes.string,
  keyLabelOption: PropTypes.string,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  clearOnEscape: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  defaultSearchValue: PropTypes.string,
  forceShowClearIcon: PropTypes.bool,
};

QboAutocomplete.defaultProps = {
  placeholder: '',
  keywordFieldRef: null,
  onChange: () => {},
  searchResultLabel: null,
  onClickLink: () => {},
  loading: false,
  options: [],
  keyGroupBy: '',
  keyLabelOption: '',
  onSelect: () => {},
  onInputChange: () => {},
  clearOnEscape: true,
  clearOnBlur: true,
  defaultSearchValue: '',
  forceShowClearIcon: false,
};
