import React from 'react';
import PropTypes from 'prop-types';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function QboRefreshingIcon({ className, ...rest }) {
  return <AutorenewIcon {...rest} className={`${className} animation-rotation`} />;
}

QboRefreshingIcon.propTypes = {
  className: PropTypes.string,
};

QboRefreshingIcon.defaultProps = {
  className: '',
};
