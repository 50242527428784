import { useState } from 'react';

export default function useDrawerRestoreGuide() {
  const [openDrawerRestore, setOpenDrawerRestore] = useState(false);

  return {
    drawerRestoreIsOpen: openDrawerRestore,
    openDrawerRestore: () => {
      setOpenDrawerRestore(!openDrawerRestore);
    },
    closeDrawerRestore: () => {
      setOpenDrawerRestore(false);
    },
  };
}
