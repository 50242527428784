import { useEffect } from 'react';
import BaseSetting from '@config/BaseSetting';

export default function useSearchDelay(
  { actionFunc, resetFunc, onChangeKeyword },
  keyword,
  moreTriggers = [],
  allowNumberChar = BaseSetting.allowTypeRequestLength
) {
  useEffect(() => {
    if (onChangeKeyword) onChangeKeyword();

    const delayID = setTimeout(() => {
      if (keyword.length > allowNumberChar) {
        actionFunc();
      }

      if (keyword.length === 0 && resetFunc) {
        resetFunc();
      }
    }, BaseSetting.searchTypeDelay);

    return () => clearTimeout(delayID);
  }, [keyword, ...moreTriggers]);

  return [];
}
