import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

export default function QboSelectField({
  id,
  label,
  name,
  disabled,
  errorMessage,
  placeholder,
  startIcon,
  endIcon,
  helperText,
  onScroll,
  horizontal,
  options,
  loading,
  value,
  onChange,
  ...rest
}) {
  const inputAttributes = {};
  let error = false;
  const { t } = useTranslation();

  if (name) inputAttributes.name = name;
  if (placeholder) inputAttributes.placeholder = placeholder;
  if (errorMessage) error = true;

  if (startIcon) {
    inputAttributes.startAdornment = <InputAdornment position="start">{startIcon}</InputAdornment>;
  }

  if (endIcon) {
    inputAttributes.endAdornment = <InputAdornment position="end">{endIcon}</InputAdornment>;
  }

  const renderItem = (item, index) => {
    if (Object.keys(item).includes('id')) {
      return (
        <MenuItem key={index} value={item.id}>
          {item.name}
        </MenuItem>
      );
    }

    return (
      <MenuItem key={index} value={item[0]}>
        {item[1] || item[0]}
      </MenuItem>
    );
  };

  return (
    <FormControl
      className={`FormControl__default-wrapper ${
        horizontal ? 'FormControl__horizontal-wrapper' : ''
      }`}
      disabled={disabled}
      error={error}>
      {label && (
        <label className="QboSelectField__label" htmlFor={`#${id}`}>
          {label}
        </label>
      )}

      <Select
        className="QboSelectField__select"
        onChange={onChange}
        value={value}
        MenuProps={{
          PaperProps: {
            onScroll,
          },
          style: {
            maxHeight: '28rem',
          },
        }}
        id={id}
        IconComponent={ExpandMoreIcon}
        {...inputAttributes}
        {...rest}>
        {options.map(renderItem)}

        {loading && (
          <MenuItem key="loading_more" disabled>
            {t('label.loading_more')}...
          </MenuItem>
        )}
      </Select>

      {helperText && !errorMessage && (
        <span className="QboSelectField__helper-text">{helperText}</span>
      )}

      {errorMessage && (
        <span className="QboSelectField__error-text">
          <ErrorIcon /> {errorMessage}
        </span>
      )}
    </FormControl>
  );
}

QboSelectField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  helperText: PropTypes.string,
  onScroll: PropTypes.func,
  onChange: PropTypes.func,
  horizontal: PropTypes.bool,
  options: PropTypes.array,
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

QboSelectField.defaultProps = {
  id: '',
  name: '',
  label: null,
  disabled: false,
  errorMessage: null,
  placeholder: null,
  startIcon: null,
  endIcon: null,
  helperText: null,
  onScroll: () => {},
  onChange: () => {},
  horizontal: false,
  options: [],
  loading: false,
  value: '',
};
