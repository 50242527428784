import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { notFoundPath, errorPath } from '@config/Routes/WebClientRoutes';
import ErrorResponse from '@libs/ErrorResponse';

export default function CatchErrorResponse({ children }) {
  const errorQboObjects = useSelector((state) => state.qboObjects.error);
  const errorQboObjectItems = useSelector((state) => state.qboObjectItems.error);
  const errorCode = errorQboObjects?.code || errorQboObjectItems?.code;

  if (errorCode) {
    switch (errorCode) {
      case ErrorResponse.GeneralError:
        window.location.replace(window.location.origin + errorPath);
        return null;
      case ErrorResponse.RecordNotFound:
        window.location.replace(window.location.origin + notFoundPath);
        return null;
      default:
        return children;
    }
  }

  return children;
}

CatchErrorResponse.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
