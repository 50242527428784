//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboFetchStatusDownloadAsync,
  qboFetchStatusDownloadExtraReducers,
} from './serviceActions/fetchStatusDownloadAsync';
import {
  getQboDownloadStatusExtraReducers,
  getStatusDownloadAsync,
} from './serviceActions/getStatusDownloadAsync';

const initialState = {
  QboStatusDownload: {
    value: [],
    nextLink: null,
    prevLink: null,
    showPagination: false,
  },
  fetchingQboStatusDownload: true,
  error: null,
};

const qboStatusDownloadSlice = createSlice({
  name: 'QboStatusDownload',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
    updateFetching(state, action) {
      state.fetchingQboStatusDownload = action.payload;
    },
  },
  extraReducers: {
    ...qboFetchStatusDownloadExtraReducers,
    ...getQboDownloadStatusExtraReducers,
  },
});

export const { resetState, updateFetching } = qboStatusDownloadSlice.actions;
export { qboFetchStatusDownloadAsync, getStatusDownloadAsync };

export default qboStatusDownloadSlice.reducer;
