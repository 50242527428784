import React from 'react';
import PropTypes from 'prop-types';
import { QboModal, QboTextField, QboTypography } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useAuthForm2FA from '@pages/SettingSetup2FAPage/pageHooks/useAuthForm2FA';

export default function ModalFormAuthenticationCodeBlock({
  showAuthForm,
  onClose,
  onNextButton,
  onBackButton,
  withoutPassword,
  withoutAuth,
  validationError,
  loading,
}) {
  const { t } = useTranslation();
  const { authCode, setAuthCode, password, setPassword, formValidation } = useAuthForm2FA();
  const labelPrefix = withoutPassword ? 'form_auth_code' : 'form_auth_code_with_password';
  const passValidation = formValidation(withoutPassword, withoutAuth);

  const renderBodyModal = () => {
    return (
      <Box className="ModalFormAuthenticationCodeBlock__wrapper">
        <QboTypography noWrap={false} color="primary">
          {t(`account_settings_setup_2fa_page.modal.${labelPrefix}.description`)}
        </QboTypography>
        {!withoutPassword ? (
          <QboTextField
            id="password"
            isPasswordField
            placeholder={t(
              'account_settings_setup_2fa_page.modal.form_auth_code_with_password.form_password_label'
            )}
            label={t(
              'account_settings_setup_2fa_page.modal.form_auth_code_with_password.form_password_label'
            )}
            fullWidth
            withoutErrorIcon
            value={password.value}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === 'Enter' && passValidation) {
                  onNextButton(true, { authCode, password });
                }
              },
            }}
            errorMessage={
              validationError?.password
                ? t(`account_settings_setup_2fa_page.modal.${labelPrefix}.form_password_error`)
                : ''
            }
          />
        ) : null}
        {!withoutAuth ? (
          <QboTextField
            id="auth-code"
            placeholder={t(`account_settings_setup_2fa_page.modal.${labelPrefix}.form_auth_label`)}
            label={t(`account_settings_setup_2fa_page.modal.${labelPrefix}.form_auth_label`)}
            fullWidth
            withoutErrorIcon
            value={authCode.value}
            onChange={(e) => {
              setAuthCode(e.currentTarget.value);
            }}
            inputProps={{
              onKeyDown: (e) => {
                if (e.key === 'Enter' && passValidation) {
                  onNextButton(true, { authCode, password });
                }
              },
            }}
            errorMessage={
              validationError?.authCode
                ? t(`account_settings_setup_2fa_page.modal.${labelPrefix}.form_auth_error`)
                : ''
            }
          />
        ) : null}
      </Box>
    );
  };

  return (
    <QboModal
      onClose={() => onClose()}
      open={showAuthForm}
      title={t(`account_settings_setup_2fa_page.modal.${labelPrefix}.title`)}
      button={[
        {
          text: t('account_settings_setup_2fa_page.modal.form_auth_code.back_button'),
          onClick: () => onBackButton(),
          position: 'right',
          hidden: !withoutPassword,
        },
        {
          text: t(
            `account_settings_setup_2fa_page.modal.${labelPrefix}.${
              !withoutPassword ? 'continue_button' : 'verify_button'
            }`
          ),
          onClick: () => onNextButton(true, { authCode, password }),
          position: 'right',
          disabled: !passValidation,
          loading,
        },
      ]}
      preventOutsideClick>
      {renderBodyModal()}
    </QboModal>
  );
}

ModalFormAuthenticationCodeBlock.propTypes = {
  showAuthForm: PropTypes.bool,
  onClose: PropTypes.func,
  onNextButton: PropTypes.func,
  onBackButton: PropTypes.func,
  withoutPassword: PropTypes.bool,
  withoutAuth: PropTypes.bool,
  validationError: PropTypes.object,
  loading: PropTypes.bool,
};

ModalFormAuthenticationCodeBlock.defaultProps = {
  showAuthForm: false,
  onClose: () => {},
  onNextButton: () => {},
  onBackButton: () => {},
  withoutPassword: false,
  withoutAuth: false,
  validationError: {},
  loading: false,
};
