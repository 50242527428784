import DateTimeError, { throwMessage } from './DateTimeError';

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
class DateTime {
  getClient() {
    throw new DateTimeError(throwMessage('getClient', 'DateTime'));
  }

  toString() {
    throw new DateTimeError(throwMessage('toString', 'DateTime'));
  }

  dateDistance() {
    throw new DateTimeError(throwMessage('dateDiff', 'DateTime'));
  }

  dateTimeNow() {
    throw new DateTimeError(throwMessage('dateTimeNow', 'DateTime'));
  }
}

export default DateTime;
