import React, { useState, useEffect } from 'react';
import { getUrlPathName } from '@utilities';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import BaseSetting from '@config/BaseSetting';
import useAuthParam from '@pages/sharedHooks/useAuthParam';

const BACKUPS = 'Backup';
const DOWNLOAD = 'Download';
const RESTORE = 'Restore';
const REPLICATE = 'Replicate';

export const ConstantFeature = {
  BACKUPS,
  DOWNLOAD,
  RESTORE,
  REPLICATE,
};

const useTabsSystemStatus = () => {
  const { isApplicationReady } = useSelector((state) => state.application);
  const [tabIndex, setTabIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { features } = useAuthParam();

  const titles = [];
  const icons = [];
  const routes = [];

  if (features.includes(BACKUPS)) {
    titles.push(t('button.backups'));
    icons.push(<CloudUploadOutlinedIcon />);
    routes.push(ClientRoutes.backupStatusPath());
  }

  if (features.includes(DOWNLOAD)) {
    titles.push(t('button.download'));
    icons.push(<CloudDownloadOutlinedIcon />);
    routes.push(ClientRoutes.downloadStatusPath());
  }

  if (BaseSetting.qboEnableRestoreFeature && features.includes(RESTORE)) {
    titles.push(t('button.restore'));
    icons.push(<CloudSyncOutlinedIcon />);
    routes.push(ClientRoutes.restoreStatusPath());
  }

  if (features.includes(REPLICATE)) {
    titles.push(t('button.replicate'));
    icons.push(<FileCopyOutlinedIcon />);
    routes.push(ClientRoutes.replicateStatusPath());
  }

  const onChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    if (routes?.length > 0) {
      navigate(routes[newTabIndex]);
    }
  };

  const setTabByRoute = () => {
    const route = getUrlPathName();
    const index = routes?.indexOf(route);
    if (route !== routes[tabIndex]) {
      setTabIndex(index);
    }
  };

  useEffect(() => {
    if (routes?.length > 0) {
      setTabByRoute();
    }
  }, [isApplicationReady]);

  return {
    tabIndex,
    onChange,
    setTabByRoute,
    snackbarOpen,
    setSnackbarOpen,
    titles,
    icons,
    activeFeatures: features,
  };
};

export default useTabsSystemStatus;
