import React from 'react';
import {
  QboDrawerListItem,
  QboTypography,
  QboLinearProgress,
  QboTipsBox,
  QboConnectButton,
  QboButton,
} from '@ui/Components';
import PropTypes from 'prop-types';
import { useSystemStatus } from '@hooks';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import { t } from 'i18next';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import useDownloadErrorMessage, {
  TypeDownload,
} from '@pages/SystemStatusPage/pageHooks/useDownloadErrorMessage';

export default function RestoreStatusItemBlock({
  status,
  percentage,
  label,
  isReauthButtonActive,
  onClickReauth,
  oauthTipsInfo,
  maskedQboRestoreId,
  hasErrorUrl,
}) {
  const { isRequireRauth, listRestoreStatus, badgeRestoreStatusRender } = useSystemStatus({
    status,
    withIcon: true,
    withInfoIcon: false,
    withStatusAndButton: true,
  });
  const { onClickDownload } = useDownloadErrorMessage(TypeDownload.RestoreErrorMessage);

  const sideBadgeTextRender = () => {
    if (listRestoreStatus.RUNNING === status && percentage) {
      return (
        <QboTypography>
          {t('system_status.completed.percentage_text', { number: percentage })}
        </QboTypography>
      );
    }

    return null;
  };

  return (
    <div className="BackupStatusItemBlock__wrapper">
      <QboDrawerListItem
        className="BackupStatusItemBlock__badge-wrapper"
        icon={<CloudSyncOutlinedIcon />}
        label={label}>
        <div className="BackupStatusItemBlock__content">
          {badgeRestoreStatusRender()}
          {sideBadgeTextRender()}
        </div>

        {listRestoreStatus.RUNNING === status && <QboLinearProgress progress={percentage} />}

        {hasErrorUrl && (
          <div className="BackupStatusItemBlock__download-wrapper">
            <QboButton
              variant="outlined"
              onClick={() => {
                onClickDownload(maskedQboRestoreId);
              }}
              startIcon={<CloudDownloadOutlinedIcon />}>
              {t('status_restore_page.drawer_restore_info_section.download_error_message_button')}
            </QboButton>
          </div>
        )}
      </QboDrawerListItem>

      {isRequireRauth(status) && (
        <>
          {oauthTipsInfo && (
            <QboTipsBox
              className="BackupStatusItemBlock__auth-tips-wrapper"
              __html={oauthTipsInfo}
            />
          )}
          <QboConnectButton disabled={!isReauthButtonActive} onClick={onClickReauth} />
        </>
      )}
    </div>
  );
}

RestoreStatusItemBlock.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  percentage: PropTypes.number,
  isReauthButtonActive: PropTypes.bool,
  onClickReauth: PropTypes.func,
  oauthTipsInfo: PropTypes.string,
  maskedQboRestoreId: PropTypes.string,
  hasErrorUrl: PropTypes.bool,
};

RestoreStatusItemBlock.defaultProps = {
  label: '',
  status: '',
  percentage: 0,
  isReauthButtonActive: false,
  onClickReauth: () => {},
  oauthTipsInfo: null,
  maskedQboRestoreId: '',
  hasErrorUrl: false,
};
