import { createAsyncThunk } from '@reduxjs/toolkit';
import { startBackupAPI } from '@services/WebApiService';

const qboStartBackupAsync = createAsyncThunk(
  'QboAuth/startBackup',
  async (qboAuthRequestId, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await startBackupAPI(accessToken, qboAuthRequestId);
    return data;
  }
);

const { pending, fulfilled, rejected } = qboStartBackupAsync;

const qboStartBackupExtraReducers = {
  [pending]: (state) => {
    state.isRequestingBackup = true;
  },
  [rejected]: (state, action) => {
    if (action.error) {
      state.error = action.error;
    }

    state.isRequestingBackup = false;
  },
  [fulfilled]: (state, action) => {
    state.isRequestingBackup = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else {
      state.QboRequestCommit = action.payload;
    }
  },
};

export { qboStartBackupAsync, qboStartBackupExtraReducers };
