import React from 'react';
import PropTypes from 'prop-types';
import { QboCardListItem, QboTypography } from '@ui/Components';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

export default function QboDrawerBoxItem({
  icon,
  label,
  children,
  className,
  onClick,
  withArrow,
  ...rest
}) {
  const newRest = rest;
  if (withArrow) {
    newRest.rightContent = (
      <KeyboardArrowRightOutlinedIcon className="QboDrawerBoxItem__arrow-icon" />
    );
  }

  return (
    <QboCardListItem
      {...newRest}
      onClick={onClick}
      leftContent={icon}
      className={`QboDrawerBoxItem__wrapper ${className} ${
        onClick ? 'QboDrawerBoxItem__with-link' : ''
      }`}>
      {label && <QboTypography variant="subtitle2">{label}</QboTypography>}
      {children}
    </QboCardListItem>
  );
}

QboDrawerBoxItem.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  withArrow: PropTypes.bool,
};

QboDrawerBoxItem.defaultProps = {
  icon: null,
  label: null,
  children: null,
  className: '',
  withArrow: true,
  onClick: null,
};
