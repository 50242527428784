import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useAlertMessage, usePageAlert } from '@hooks';
import useAuthForm2FA from '@pages/SettingSetup2FAPage/pageHooks/useAuthForm2FA';

export default function useDisable2FA() {
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const {
    submittingForm,
    successValidation,
    errorValidation,
    handleSubmitAuthForm,
    resetAuthform2FA,
    type,
    ConstantActionTypes,
    resetType,
  } = useAuthForm2FA();

  const [showDisableConfirmationModal, setShowDisableConfirmationModal] = useState(false);
  const [showDisableAuthFormModal, setDisableShowAuthFormModal] = useState(false);

  const dispatch = useDispatch();

  const resetState = () => {
    setShowDisableConfirmationModal(false);
    setDisableShowAuthFormModal(false);
    dispatch(resetAuthform2FA());
    resetType();
  };

  const onSubmitAuthForm = (params) => {
    const payload = {
      authCode: params.authCode,
      password: params.password,
      type: ConstantActionTypes.ActionTypeDisable,
    };

    handleSubmitAuthForm(payload);
  };

  useEffect(() => {
    if (!submittingForm && type === ConstantActionTypes.ActionTypeDisable) {
      if (successValidation === true) {
        updateSuccessAlert(getSuccessMessage(konstMessage.SUCCESS_DISABLE_2FA));
        resetState();
      } else if (errorValidation.banner) {
        updateErrorAlert(getErrorMessage(konstMessage.ERROR_DISABLE_2FA));
        resetState();
      }
    }
  }, [submittingForm]);

  return {
    showDisableConfirmationModal,
    showDisableAuthFormModal,
    onOpenModalDisable2FA: () => {
      setShowDisableConfirmationModal(true);
    },
    onCloseDisableModal: () => {
      resetState();
    },
    onNextDisable2FAButton: (finish, payload) => {
      if (finish) {
        onSubmitAuthForm(payload);
      } else {
        setShowDisableConfirmationModal(false);
        setDisableShowAuthFormModal(true);
      }
    },
    onBackDisable2FAButton: () => {
      setShowDisableConfirmationModal(true);
      setDisableShowAuthFormModal(false);
    },
    errorValidation,
    loadingDisable: submittingForm,
  };
}
