import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useDrawerBackupInfo() {
  const [selectedBackupId, setSelectedBackupId] = useState(null);
  const { QboStatusBackups } = useSelector((state) => state.qboStatusBackups);

  const drawerIsOpen = selectedBackupId !== null;
  const QboStatusBackup = QboStatusBackups.value.find((v) => v.id === selectedBackupId);

  return {
    drawerIsOpen,
    setOpenDrawer: (id) => {
      setSelectedBackupId(id);
    },
    closeDrawer: () => {
      setSelectedBackupId(null);
    },
    QboStatusBackup,
  };
}
