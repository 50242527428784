import AccountService from './AccountService';
import AuthenticationService from './AuthenticationService';
import QboObjectsService from './QboObjectsService';
import QboRealmsService from './QboRealmsService';
import OrganizationService from './OrganizationService';

/* eslint-disable import/no-unresolved */
export const { authenticateAPI, refreshTokenAPI, logoutAPI } = AuthenticationService;

export const { changePasswordAPI, fetchStatus2FAApi, fetchKey2FAApi, validateAuthForm2FAAPI } =
  AccountService;

export const {
  generateOauthUrlAPI,
  oauthCallbackAPI,
  startBackupAPI,
  fetchQboRealmsAPI,
  fetchQboStatusBackupsAPI,
  getQboBackupStatusAPI,
  getQboRealmStatusAPI,
  oauthReactivateCallbackAPI,
  deactivateCallbackAPI,
  backupNowAPI,
  downloadCallbackAPI,
  downloadDetailCallbackAPI,
  fetchQboStatusDownloadAPI,
  getQboDownloadStatusAPI,
  restoreCallbackAPI,
  restoreDetailCallbackAPI,
  downloadUrlCallbackAPI,
  fetchQboStatusRestoreAPI,
  getQboRestoreStatusAPI,
  downloadErrorMessageUrlCallbackAPI,
  oauthReplicateCallbackAPI,
  oauthReplicateReAuthenticationCallbackAPI,
  replicateCallbackAPI,
  fetchQboStatusReplicateAPI,
  getQboReplicateStatusAPI,
} = QboRealmsService;

export const {
  fetchQboObjectsAPI,
  fetchQboItemsAPI,
  fetchQboObjectContentAPI,
  fetchQboContentVersionsAPI,
} = QboObjectsService;

export const { fetchSeatUsageAPI } = OrganizationService;
