import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setIsDrawerOpen,
  setApplicationReadyStatus,
  isRequireInitialSetupAsync,
} from '@features/application/applicationSlice';
import { authRefreshTokenAsync } from '@features/auth/authSlice';
import { dashboardPath, homePath, publicLayouts } from '@config/Routes/WebClientRoutes';
import useAuthParam from '@pages/sharedHooks/useAuthParam';
import '@config/themes/default';

export default function DefaultApplication() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { impersonate, favicon, generalLogo } = useAuthParam();

  const { isDrawerOpen, isApplicationReady, requireInitialSetup } = useSelector(
    (state) => state.application
  );
  const { accessToken } = useSelector((state) => state.auth);

  const isShowSecondBar = impersonate;

  useEffect(() => {
    if (accessToken && requireInitialSetup !== null) {
      dispatch(setApplicationReadyStatus(true));
    }
  }, [accessToken, requireInitialSetup]);

  useEffect(() => {
    if (!accessToken && !location.pathname.includes('qbo_sso')) dispatch(authRefreshTokenAsync());
  }, []);

  useEffect(() => {
    if (location.pathname === homePath) {
      navigate(dashboardPath, { replace: true });
    }
  }, [location]);

  useEffect(() => {
    if (accessToken && requireInitialSetup === null) {
      dispatch(isRequireInitialSetupAsync());
    }
  }, [accessToken]);

  const handleDrawerOpen = () => {
    dispatch(setIsDrawerOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setIsDrawerOpen(false));
  };

  const isPublicPage = publicLayouts.includes(location.pathname);

  if (favicon) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = favicon;
  }

  return {
    isPublicPage,
    handleDrawerClose,
    handleDrawerOpen,
    accessToken,
    isDrawerOpen,
    isShowSecondBar,
    isApplicationReady,
    logo: generalLogo,
  };
}
