import { useEffect } from 'react';
import { clearAlert, setAlert } from '@features/application/applicationSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const success = 'success';
const error = 'error';

const usePageAlert = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { alert } = useSelector((state) => state.application);

  const closeAlert = () => {
    dispatch(clearAlert());
  };

  const isAlertOpen = () => {
    return alert?.type;
  };

  const updateAlert = (payload) => {
    if (
      !alert ||
      alert?.type !== payload?.type ||
      alert?.message !== payload?.message ||
      alert?.heading !== payload?.heading
    )
      dispatch(setAlert({ ...payload, path: location.pathname }));
  };

  const updateSuccessAlert = (payload) => {
    updateAlert({ ...payload, type: success });
  };

  const updateErrorAlert = (payload) => {
    updateAlert({ ...payload, type: error });
  };

  useEffect(() => {
    // will remove the alert
    // if alert is exist
    // and switch between page
    return () => {
      closeAlert();
    };
  }, []);

  return {
    isAlertOpen: isAlertOpen(),
    alert,
    closeAlert,
    updateAlert,
    updateSuccessAlert,
    updateErrorAlert,
  };
};

export default usePageAlert;
