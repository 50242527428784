import React, { useState } from 'react';
import FilterBlock from '@ui/Blocks/Shared/DrawerBlocks/FilterBlock';
import { pushSpliceEntry } from '@utilities';

const ButtonFilterApply = 'Apply';
const ButtonFilterRemove = 'Remove';

const FilterTypeSelect = 'Select';
const FilterTypeMultiSelect = 'MultiSelect';

const SelectAll = 'SelectAll';

export const ButtonFilter = {
  ButtonFilterApply,
  ButtonFilterRemove,
};

export const FilterType = {
  FilterTypeSelect,
  FilterTypeMultiSelect,
};

export const SelectAllStatus = SelectAll;

const useStatusFilter = (props = {}) => {
  const { onFilter, listFilter, headingText, type = FilterTypeMultiSelect } = props;
  const [filterBy, setFilterBy] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [listBadges, setListBadges] = useState([]);

  const handleOpenDrawer = (state) => {
    if (state) {
      setFilterBy(listBadges);
      if (type === FilterTypeSelect && listBadges.length === 0) setFilterBy([SelectAll]);
    }
    setShowDrawer(state);
  };

  const handleChecked = (checked, setChecked, item) => {
    const newArray = pushSpliceEntry(checked, item);
    if (type === FilterTypeMultiSelect) {
      setChecked(newArray);
    } else if (type === FilterTypeSelect && checked[0] === item) {
      setChecked(newArray);
    } else {
      setChecked([item]);
      return item;
    }
    return newArray;
  };

  const handleFilter = (action) => {
    if (action === ButtonFilterApply) {
      const newArray = [...filterBy];
      if (newArray.includes(SelectAll)) {
        onFilter('');
        setListBadges('');
      } else {
        onFilter(newArray.toString());
        setListBadges(newArray);
      }
      setShowDrawer(false);
    } else {
      onFilter('');
      setFilterBy([]);
      setListBadges([]);
      setShowDrawer(false);
    }
  };

  const handleDelete = (item) => () => {
    const newArray = handleChecked(filterBy, setFilterBy, item);
    onFilter(newArray.toString());
    setListBadges(newArray);
  };

  const renderFilter = (header, filterList, checked, setChecked, ableToReset) => {
    const collections = { header, filterList, checked, setChecked, ableToReset };
    return (
      <FilterBlock collections={collections} handleChecked={handleChecked} filterType={type} />
    );
  };

  const filterData = () => {
    return [
      {
        headingText,
        children: renderFilter(headingText, listFilter, filterBy, setFilterBy, false),
      },
    ];
  };

  return {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges: listFilter?.filter((x) => listBadges?.includes(x?.status)),
    handleDelete,
  };
};

export default useStatusFilter;
