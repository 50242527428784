import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import DefaultApplication from '@layouts/DefaultApplication';
import store from '@store';
import reportWebVitals from '@config/reportWebVitals';
import BaseSetting from '@config/BaseSetting';
import I18n from '@config/I18n';
import ServiceClientInterceptors from '@config/ServiceClientInterceptors';
import CatchErrorResponse from '@config/CatchErrorResponse';
import BuildCacheKeeper from '@config/BuildCacheKeeper';

const container = document.getElementById('root');
const root = createRoot(container);

const qboI18n = new I18n(BaseSetting.defaultLanguage, BaseSetting.resourcesLocales);
qboI18n.setupLocales();

root.render(
  <Provider store={store}>
    <BuildCacheKeeper>
      <ServiceClientInterceptors>
        <CatchErrorResponse>
          <BrowserRouter>
            <DefaultApplication />
          </BrowserRouter>
        </CatchErrorResponse>
      </ServiceClientInterceptors>
    </BuildCacheKeeper>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
