import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandCircleDownOutlined as ExpandCircleDownOutlinedIcon } from '@mui/icons-material';
import { styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { QboTypography } from '@ui/Components';

function QboAccordionItem({
  index,
  isFullContent,
  handleExpanded,
  headingText,
  headingSubtitle,
  children,
  icon,
  expanded,
  ...rest
}) {
  const panelName = `AccordionPanel-${index}`;
  const newRest = rest;
  if (!rest.defaultExpanded) newRest.expanded = expanded === panelName;

  return (
    <Accordion
      {...newRest}
      disabled={!children}
      onChange={handleExpanded(panelName)}
      className={`QboAccordionRoot__wrapper ${
        isFullContent ? 'QboAccordionRoot__full-content' : ''
      } ${!children ? 'QboAccordionRoot__no-children' : ''}`}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        {icon && <div className="QboAccordionRoot__icon">{icon}</div>}

        {(headingText || headingSubtitle) && (
          <div className="QboAccordionRoot__caption">
            {headingText && (
              <QboTypography className="QboAccordionRoot__heading">{headingText}</QboTypography>
            )}
            {headingSubtitle && (
              <QboTypography className="QboAccordionRoot__subtitle">
                {headingSubtitle}
              </QboTypography>
            )}
          </div>
        )}
      </AccordionSummary>
      {children && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  );
}

QboAccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  expanded: PropTypes.string,
  handleExpanded: PropTypes.func.isRequired,
  headingText: PropTypes.string,
  headingSubtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.node,
  isFullContent: PropTypes.bool,
};

QboAccordionItem.defaultProps = {
  expanded: null,
  headingText: null,
  headingSubtitle: null,
  children: null,
  icon: null,
  isFullContent: false,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandCircleDownOutlinedIcon sx={{ fontSize: '2rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function QboAccordion({ collections, onExpanded, className, ...rest }) {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    onExpanded(event, panel);
  };

  return (
    <div className={`QboAccordion__wrapper ${className}`}>
      {collections.map((item, index) => {
        const { isFullContent, headingText, headingSubtitle, icon, children } = item;

        /* eslint-disable react/no-array-index-key */
        return (
          <QboAccordionItem
            {...rest}
            key={index}
            index={index}
            handleExpanded={handleChange}
            expanded={expanded}
            headingText={headingText}
            headingSubtitle={headingSubtitle}
            icon={icon}
            isFullContent={isFullContent}>
            {children}
          </QboAccordionItem>
        );
      })}
    </div>
  );
}

QboAccordion.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onExpanded: PropTypes.func,
  className: PropTypes.string,
};

QboAccordion.defaultProps = {
  onExpanded: () => {},
  className: '',
};
