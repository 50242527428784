import React from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { QboCard, QboCardHeader, QboAccordion } from '@ui/Components';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import BottomStepperBlock from '@ui/Blocks/Shared/BottomStepperBlock';
import { dashboardPath, newQuickbooksPath } from '@config/Routes/WebClientRoutes';
import { qboStartBackupAsync, resetQboRequestCommit } from '@features/qboOauth/qboOauthSlice';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import useConfirmBackup from './pageHooks/useConfirmBackup';

export default function QuickbookConfirmBackupPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.HOME_MENU);

  const { confirmData, QboAuthRequestId, isRequestingBackup, confirmBack } = useConfirmBackup();

  return (
    <>
      <HeaderPageBlock
        onClick={() => {
          confirmBack(() => {
            dispatch(resetQboRequestCommit());
            navigate(dashboardPath);
          });
        }}
        linkNavigateText={t('navigate.back_to_dashboard')}>
        {t('quickbook_backup_confirm_page.header.text')}
      </HeaderPageBlock>

      <AlertPageSection />

      <QboCard
        isMainWrapper
        className="QuickbookConfirmBackupPage__wrapper Page__with-bottom-stepper">
        <QboCardHeader
          headingText={t('quickbook_backup_confirm_page.confirm.header_text')}
          subtitleText={t('quickbook_backup_confirm_page.confirm.header_captions_text')}
        />
        <QboAccordion collections={confirmData} />
      </QboCard>

      <BottomStepperBlock
        nextText={t('quickbook_backup_confirm_page.button.start_backup')}
        onBack={() => {
          confirmBack(() => {
            dispatch(resetQboRequestCommit());
            navigate(newQuickbooksPath, { replace: true });
          });
        }}
        onNext={() => {
          dispatch(qboStartBackupAsync(QboAuthRequestId));
        }}
        isBackDisabled={isRequestingBackup}
        isNextLoading={isRequestingBackup}
      />
    </>
  );
}
