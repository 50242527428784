import React from 'react';
import PropTypes from 'prop-types';
import { QboModal, QboTypography } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export default function ModalReEnable2FABlock({
  showReEnableConfirmationModal,
  onCloseReEnableModal,
  onNextReEnable2FAButton,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <QboModal
      onClose={() => (!loading ? onCloseReEnableModal() : null)}
      open={showReEnableConfirmationModal}
      title={t('account_settings_setup_2fa_page.modal.re_enable_confirmation.title')}
      button={{
        text: t('account_settings_setup_2fa_page.modal.re_enable_confirmation.confirmation_button'),
        onClick: () => onNextReEnable2FAButton(),
        position: 'right',
        loading,
      }}
      preventOutsideClick>
      <Box className="ModalReEnable2FABlock__wrapper">
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.re_enable_confirmation.description_1')}
        </QboTypography>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.re_enable_confirmation.description_2')}
        </QboTypography>
      </Box>
    </QboModal>
  );
}

ModalReEnable2FABlock.propTypes = {
  showReEnableConfirmationModal: PropTypes.bool,
  onCloseReEnableModal: PropTypes.func,
  onNextReEnable2FAButton: PropTypes.func,
  loading: PropTypes.bool,
};

ModalReEnable2FABlock.defaultProps = {
  showReEnableConfirmationModal: false,
  onCloseReEnableModal: () => {},
  onNextReEnable2FAButton: () => {},
  loading: false,
};
