import React from 'react';
import PropTypes from 'prop-types';
import { QboTypography } from '@ui/Components';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

export default function ChangePasswordFormRequirementBlock({ className, listRequirement }) {
  const { t } = useTranslation();

  return (
    <div className={`ChangePasswordFormRequirementBlock__wrapper ${className}`}>
      <QboTypography variant="subtitle2">
        {t('account_settings_change_password_page.form_requirement.heading_text')}
      </QboTypography>

      <QboTypography variant="caption">
        {t('account_settings_change_password_page.form_requirement.caption_text')}
      </QboTypography>

      <ul className="SettingChangePasswordPage__list-requirement">
        {listRequirement.map((item) => {
          return (
            <li key={item.id}>
              <QboTypography color={item.success ? 'success' : 'primary'}>
                {!item.success && <CircleIcon />}
                {item.success && <CheckCircleIcon />}
                {item.desc}
              </QboTypography>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ChangePasswordFormRequirementBlock.propTypes = {
  className: PropTypes.string,
  listRequirement: PropTypes.array,
};

ChangePasswordFormRequirementBlock.defaultProps = {
  className: '',
  listRequirement: [],
};
