import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { QboCardListItem, QboTypography, QboHighlighter } from '@ui/Components';
import IntuitQboLogo from '@images/logos/intuit-qbo-logo.svg';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { useDateTime } from '@hooks';
import RealmCardItemStatusBlock from './RealmCardItemStatusBlock';

export default function RealmCardItemBlock({
  QboRealm,
  searchKeyword,
  onClickMoreInfo,
  onClickBackupNow,
  onClickDownload,
  onClickRestore,
  onClickReplicate,
  onOpenDeactivateModal,
}) {
  const navigate = useNavigate();
  const { dateToString, dateFormat } = useDateTime();
  const [openActionBar, setOpenActionBar] = useState(false);

  return (
    <QboCardListItem
      onClick={(e) => {
        if (!e.target.classList.contains('MuiBackdrop-root') && QboRealm.lastCompletedBackupAt) {
          navigate(ClientRoutes.showRealmPath({ id: QboRealm.id }));
        }
      }}
      onMouseEnter={() => setOpenActionBar(true)}
      onMouseLeave={() => setOpenActionBar(false)}
      className={`BackupListItemBlock__wrapper ${
        QboRealm.lastCompletedBackupAt ? 'BackupListItemBlock__active' : ''
      } ${openActionBar ? 'BackupListItemBlock__hover' : ''}`}
      justifyContent="center"
      alignItems="center"
      leftContent={<img src={IntuitQboLogo} alt="IntuitQboLogo" className="Img__fluid" />}
      rightContent={
        <div className="BackupListItemBlock__right-content">
          <RealmCardItemStatusBlock
            id={QboRealm.id}
            status={QboRealm.status}
            lastBackupAt={dateToString(
              dateFormat.DEFAULT_DATE_TIME,
              QboRealm.lastCompletedBackupAt
            )}
            openActionBar={openActionBar}
            percentage={QboRealm.percentage}
            onClickDownload={() => onClickDownload(QboRealm.id)}
            onClickRestore={() => onClickRestore(QboRealm.id)}
            onClickReplicate={() => onClickReplicate(QboRealm.id)}
            onClickMoreInfo={onClickMoreInfo}
            onClickDeactivate={() => onOpenDeactivateModal(QboRealm.id)}
            onClickBackupNow={() => onClickBackupNow(QboRealm.id)}
            style={{
              position: 'relative',
              zIndex: 1,
            }}
          />
        </div>
      }>
      <QboTypography variant="subtitle2">
        {searchKeyword !== '' ? (
          <QboHighlighter searchWord={searchKeyword} sentences={QboRealm.companyName} />
        ) : (
          QboRealm.companyName
        )}
      </QboTypography>
      <QboTypography className="QboCardListItem__info">{QboRealm.emailAddress}</QboTypography>
    </QboCardListItem>
  );
}

RealmCardItemBlock.propTypes = {
  QboRealm: PropTypes.object.isRequired,
  searchKeyword: PropTypes.string,
  onClickDownload: PropTypes.func,
  onClickRestore: PropTypes.func,
  onClickReplicate: PropTypes.func,
  onClickMoreInfo: PropTypes.func,
  onClickBackupNow: PropTypes.func,
  onOpenDeactivateModal: PropTypes.func,
};

RealmCardItemBlock.defaultProps = {
  searchKeyword: '',
  onClickDownload: () => {},
  onClickRestore: () => {},
  onClickReplicate: () => {},
  onClickMoreInfo: () => {},
  onClickBackupNow: () => {},
  onOpenDeactivateModal: () => {},
};
