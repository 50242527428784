import StorageFactory from '@libs/Storage';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import {
  deFlag,
  enFlag,
  esFlag,
  frFlag,
  itFlag,
  jaFlag,
  nlFlag,
  plFlag,
  ptFlag,
  viFlag,
} from '@images/generals/flags-svg';

class I18n {
  // key name within locale storage
  CONST_ACTIVE_LOCALE = 'ACTIVE_LOCALE';

  /* eslint-disable lines-between-class-members */
  // locale keys
  EN = 'en';
  PT = 'pt';
  JA = 'ja';
  VI = 'vi';
  DE = 'de';
  ES = 'es';
  NL = 'nl';
  FR = 'fr';
  PL = 'pl';
  IT = 'it';

  // locales
  localeOptions = [
    [this.EN, 'English'],
    [this.PT, 'Português'],
    [this.JA, '日本語'],
    [this.VI, 'Tiếng Việt'],
    [this.DE, 'Deutsch'],
    [this.ES, 'Español'],
    [this.NL, 'Nederlands'],
    [this.FR, 'Français'],
    [this.PL, 'Polski'],
    [this.IT, 'Italiano'],
  ];

  // activeLocales
  activeLocales = [this.EN];

  // plugin that used
  client = i18n;

  constructor(defaultLocale, resourcesLocales) {
    this.availableLocales = Object.keys(resourcesLocales);
    this.resourcesLocales = resourcesLocales;
    this.defaultLocale = defaultLocale;

    // initialize storage locale
    const storageFactory = new StorageFactory({ keyStoragePrefix: 'QBO' }, 'localStorage');
    this.i18nStorage = storageFactory.create();
  }

  setupLocales() {
    this.client.use(initReactI18next).init({
      resources: this.resourcesLocales,
      lng: this.getActiveLocale(),
      fallbackLng: this.defaultLocale,
    });
  }

  setLocale(locale) {
    if (this.availableLocales.includes(locale)) {
      this.client.changeLanguage(locale);
      this.i18nStorage.save(this.CONST_ACTIVE_LOCALE, locale);
    }
  }

  getActiveLocale() {
    return this.i18nStorage.get(this.CONST_ACTIVE_LOCALE) || this.defaultLocale;
  }

  getActiveLocaleLabel(localeParam) {
    try {
      const activeLocale = localeParam || this.getActiveLocale();
      return this.localeOptions.find((o) => o[0] === activeLocale)[1];
    } catch (err) {
      return this.localeOptions[0][1];
    }
  }

  /* eslint-disable class-methods-use-this */
  getFlag(locale) {
    if (locale === 'de') {
      return deFlag;
    }
    if (locale === 'es') {
      return esFlag;
    }
    if (locale === 'fr') {
      return frFlag;
    }
    if (locale === 'it') {
      return itFlag;
    }
    if (locale === 'ja') {
      return jaFlag;
    }
    if (locale === 'nl') {
      return nlFlag;
    }
    if (locale === 'pl') {
      return plFlag;
    }
    if (locale === 'pt') {
      return ptFlag;
    }
    if (locale === 'vi') {
      return viFlag;
    }
    return enFlag;
  }
}

export default I18n;
