import { createAsyncThunk } from '@reduxjs/toolkit';
import { replicateCallbackAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';
import ErrorResponse from '@libs/ErrorResponse';

const qboReplicateRealmToolsAsync = createAsyncThunk(
  'QboRealms/replicateRealm',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await replicateCallbackAPI(accessToken, payload);
    return data;
  }
);

const { pending, fulfilled, rejected } = qboReplicateRealmToolsAsync;

const qboReplicateRealmToolsExtraReducers = {
  [pending]: (state) => {
    state.replicate.submitting = true;
    state.replicate.success = false;
    state.replicate.error = null;
  },
  [rejected]: (state) => {
    state.replicate.submitting = false;
    state.replicate.error = ErrorResponse.GeneralError;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.replicate.error = action.payload.error.code;
    } else if (action.payload.status === HttpResponse.success) {
      state.replicate.success = true;
    }
    state.replicate.submitting = false;
  },
};

export { qboReplicateRealmToolsAsync, qboReplicateRealmToolsExtraReducers };
