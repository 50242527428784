import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboObjectContentAPI } from '@services/WebApiService';

const fetchQboContentAsync = createAsyncThunk(
  'QboObjectContent/fetchQboContentAsync',
  async ({ qboRealmId, objectName, objectId, versionId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboObjectContentAPI(
      accessToken,
      qboRealmId,
      objectName,
      objectId,
      versionId
    );

    return data;
  }
);

const { pending, fulfilled, rejected } = fetchQboContentAsync;

const fetchQboContentExtraReducers = {
  [pending]: (state) => {
    state.fetchingQboContent = true;
  },
  [rejected]: (state) => {
    state.fetchingQboContent = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboContent = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload) {
      state.QboObjectContent.raw = action.payload;
    }
  },
};

export { fetchQboContentAsync, fetchQboContentExtraReducers };
