import ServiceClientError, { throwMessage } from './ServiceClientError';

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
class ServiceClient {
  #config = {};

  constructor({ baseUrl, accessToken }) {
    this.#config.baseUrl = baseUrl;
    this.#config.accessToken = accessToken;
  }

  getClient() {
    throw new ServiceClientError(throwMessage('getClient', 'ServiceClient'));
  }

  getConfig() {
    return this.#config;
  }

  postRequest({ url, parameters }) {
    throw new ServiceClientError(throwMessage('postRequest', 'ServiceClient'));
  }

  getRequest({ url, parameters }) {
    throw new ServiceClientError(throwMessage('getRequest', 'ServiceClient'));
  }

  putRequest({ url, parameters }) {
    throw new ServiceClientError(throwMessage('putRequest', 'ServiceClient'));
  }
}

export default ServiceClient;
