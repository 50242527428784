import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboCardCaption } from '@ui/Components';
import ErrorImageSvg from '@images/500.svg';
import usePageMenu from '@pages/sharedHooks/usePageMenu';

export default function ErrorPage() {
  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.HOME_MENU);
  const { t } = useTranslation();

  return (
    <QboCardCaption
      className="ErrorPage__wrapper"
      heading={t('error_page.heading_text')}
      caption={t('error_page.caption_text')}
      imageUrl={ErrorImageSvg}
    />
  );
}
