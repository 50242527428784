import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useDownload = (Collection, fetchFunc, resetState) => {
  const dispatch = useDispatch();

  const { url, submitting } = Collection;

  const handleDownload = (payload = {}) => {
    dispatch(fetchFunc(payload));
  };

  useEffect(() => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      link.click();
      dispatch(resetState());
    }
  }, [submitting]);

  return {
    handleDownload,
  };
};

export default useDownload;
