import React from 'react';
import { useTranslation } from 'react-i18next';
import { foldersMd } from '@images/generals/folders-svg';
import { QboButton, QboCard, QboTypography } from '@ui/Components';
import { AddOutlined as AddOutlinedIcon } from '@mui/icons-material';
import { newQuickbooksPath } from '@config/Routes/WebClientRoutes';

export default function InitialBackupSection() {
  const { t } = useTranslation();

  return (
    <QboCard isMainWrapper isCentered className="InitialBackupSection__wrapper">
      <img src={foldersMd} alt="" className="Img__fluid" />

      <QboTypography variant="h4">{t('dashboard_page.initial_backup.header')}</QboTypography>

      <QboTypography>{t('dashboard_page.caption')}</QboTypography>

      <QboButton
        size="large"
        navigate={newQuickbooksPath}
        variant="contained"
        startIcon={<AddOutlinedIcon />}>
        {t('dashboard_page.button_add')}
      </QboButton>
    </QboCard>
  );
}
