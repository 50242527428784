import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { QboCardListItem, QboPagination, QboTypography } from '@ui/Components';

export default function PaginationCardblock({
  showPagination,
  hasNextLink,
  hasPrevLink,
  onPrevPage,
  onNextPage,
  onChangePageNumber,
  pageNumber,
  paginationLabel,
  className,
  showFooterSelectedItems,
  selectedItems,
}) {
  const { t } = useTranslation();

  if (!showPagination) return null;

  const renderFooterSelectedItems = () => {
    if (!showFooterSelectedItems) return null;

    return (
      <QboTypography variant="caption">
        {t('pagination.footer_checkbox_text_html', { selected: selectedItems })}
      </QboTypography>
    );
  };

  return (
    <QboCardListItem
      className={`PaginationCardblock__wrapper ${className}`}
      justifyContent="center"
      alignItems="center"
      leftContent={renderFooterSelectedItems()}
      rightContent={
        <QboPagination
          hasNextLink={hasNextLink}
          hasPrevLink={hasPrevLink}
          onClickPrevLink={onPrevPage}
          onClickNextLink={onNextPage}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          paginationLabel={paginationLabel}
        />
      }
    />
  );
}

PaginationCardblock.propTypes = {
  hasNextLink: PropTypes.string,
  hasPrevLink: PropTypes.string,
  onPrevPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onChangePageNumber: PropTypes.func,
  pageNumber: PropTypes.string,
  showPagination: PropTypes.bool,
  paginationLabel: PropTypes.string,
  className: PropTypes.string,
  showFooterSelectedItems: PropTypes.bool,
  selectedItems: PropTypes.number,
};

PaginationCardblock.defaultProps = {
  hasNextLink: null,
  hasPrevLink: null,
  onPrevPage: () => {},
  onNextPage: () => {},
  onChangePageNumber: () => {},
  pageNumber: null,
  showPagination: false,
  paginationLabel: '',
  className: '',
  showFooterSelectedItems: false,
  selectedItems: 0,
};
