import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { QboTypography } from '@ui/Components';

export default function QboSimpleTable({ sx, header, contents }) {
  const tableContentRow = (content, index) => {
    return (
      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {Object.keys(content).map((key, indexContent) => {
          const headerColumn = header.find((head) => head.columnName === key);

          /* eslint-disable react/no-array-index-key */
          return (
            <TableCell key={indexContent} align={headerColumn.align}>
              <QboTypography>{content[key]}</QboTypography>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <TableContainer className="QboSimpleTable__wrapper">
      <Table sx={{ ...sx }} aria-label="simple table">
        <TableHead>
          <TableRow className="QboSimpleTable__header">
            {header.map((head, index) => (
              /* eslint-disable react/no-array-index-key */
              <TableCell
                key={index}
                align={head.align}
                className={`QboSimpleTable__cell-${head.size}`}>
                <QboTypography>{head.text}</QboTypography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{contents.map(tableContentRow)}</TableBody>
      </Table>
    </TableContainer>
  );
}

QboSimpleTable.propTypes = {
  sx: PropTypes.object,
  /* header eg: [{text: "number", align: "right", size: "sx", columnName: "number"}] */
  header: PropTypes.arrayOf(PropTypes.object),
  /* header eg: [{number: "1"}, {number: "2"}] */
  contents: PropTypes.arrayOf(PropTypes.object),
};

QboSimpleTable.defaultProps = {
  sx: null,
  header: [],
  contents: [],
};
