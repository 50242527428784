//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  fetchQboContentVersionsAsync,
  fetchQboContentVersionsExtraReducers,
} from './serviceActions/fetchQboContentVersionsAsync';

const initialState = {
  QboContentVersions: {
    companyName: null,
    value: [],
    nextLink: null,
  },
  fetchingQboContentVersions: true,
  fetchingMoreVersions: false,
  error: null,
};

const qboContentVersionsSlice = createSlice({
  name: 'QboObjectContentVersions',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    ...fetchQboContentVersionsExtraReducers,
  },
});

export const { resetState } = qboContentVersionsSlice.actions;
export { fetchQboContentVersionsAsync };
export default qboContentVersionsSlice.reducer;
