import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboRealmsAPI } from '@services/WebApiService';

const isRequireInitialSetupAsync = createAsyncThunk(
  'Application/isRequireInitialSetup',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboRealmsAPI(accessToken, null, { top: 1, skip: 0 });
    return data;
  }
);

const { pending, fulfilled, rejected } = isRequireInitialSetupAsync;

const requireInitialSetupReducers = {
  [pending]: (state) => {
    state.isInitializing = true;
  },
  [rejected]: (state) => {
    state.isInitializing = false;
  },
  [fulfilled]: (state, action) => {
    state.isInitializing = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.value && action.payload.value.length > 0) {
      state.requireInitialSetup = false;
    } else if (action.payload.value && action.payload.value.length === 0) {
      state.requireInitialSetup = true;
    }
  },
};

export { isRequireInitialSetupAsync, requireInitialSetupReducers };
