import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchKey2FAApi } from '@services/WebApiService';

const fetchKey2FAAsync = createAsyncThunk('Account/fetchKey2FA', async (payload, { getState }) => {
  const { accessToken } = getState().auth;
  const data = await fetchKey2FAApi(accessToken);
  return data;
});

const { pending, fulfilled, rejected } = fetchKey2FAAsync;

const fetchKey2FAExtraReducers = {
  [pending]: (state) => {
    state.authKey2FA.submitting = true;
  },
  [rejected]: (state) => {
    state.authKey2FA.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.authKey2FA.submitting = false;
    if (!action.payload.error) {
      state.authKey2FA.qrKey = action.payload.qrKey;
      state.authKey2FA.secretKey = action.payload.secretKey;
    }
  },
};

export { fetchKey2FAAsync, fetchKey2FAExtraReducers };
