//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import {
  qboDownloadRealmToolsAsync,
  qboDownloadRealmToolsExtraReducers,
} from './serviceActions/downloadRealmToolsAsync';

import {
  qboRestoreRealmToolsAsync,
  qboRestoreRealmToolsExtraReducers,
} from './serviceActions/restoreRealmToolsAsync';

import {
  qboDownloadURLToolsAsync,
  qboDownloadURLToolsExtraReducers,
} from './serviceActions/downloadURLToolsAsync';

import {
  qboDownloadErrorMessageURLToolsAsync,
  qboDownloadErrorMessageURLToolsExtraReducers,
} from './serviceActions/downloadErrorMessageURLToolsAsync';

import {
  qboReplicateRealmToolsAsync,
  qboReplicateRealmToolsExtraReducers,
} from './serviceActions/replicateRealmToolsAsync';

const initialState = {
  download: {
    error: null,
    submitting: false,
    success: false,
  },
  downloadUrl: {
    submitting: false,
    url: null,
  },
  downloadErrorMessageUrl: {
    submitting: false,
    url: null,
  },
  restore: {
    error: null,
    submitting: false,
    success: false,
  },
  replicate: {
    error: null,
    submitting: false,
    success: false,
  },
};

const QboRealmToolsSlice = createSlice({
  name: 'QboRealmsTools',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: {
    ...qboDownloadRealmToolsExtraReducers,
    ...qboRestoreRealmToolsExtraReducers,
    ...qboDownloadURLToolsExtraReducers,
    ...qboDownloadErrorMessageURLToolsExtraReducers,
    ...qboReplicateRealmToolsExtraReducers,
  },
});

export const { resetState } = QboRealmToolsSlice.actions;
export {
  qboDownloadRealmToolsAsync,
  qboRestoreRealmToolsAsync,
  qboDownloadURLToolsAsync,
  qboDownloadErrorMessageURLToolsAsync,
  qboReplicateRealmToolsAsync,
};

export default QboRealmToolsSlice.reducer;
