import { createAsyncThunk } from '@reduxjs/toolkit';
import { deactivateCallbackAPI } from '@services/WebApiService';
import SystemStatus from '@libs/SystemStatus';
import HttpResponse from '@libs/HttpResponse';

const { listBackupStatus } = SystemStatus;

const qboDeactivateRealmAsync = createAsyncThunk(
  'QboRealms/deactivateRealm',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await deactivateCallbackAPI(accessToken, payload);
    return data;
  }
);

const { pending, fulfilled, rejected } = qboDeactivateRealmAsync;

const qboDeactivateRealmExtraReducers = {
  [pending]: (state) => {
    state.deactivateRealm.submitting = true;
  },
  [rejected]: (state) => {
    state.deactivateRealm.submitting = false;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
      state.deactivateRealm.error = action.payload.error;
    } else if (action.payload.status === HttpResponse.success) {
      const { realmId, reason } = action.meta.arg;

      // replace the value from array
      const selectedQboRealm = state.QboRealms.value.find((r) => {
        return r.id === realmId;
      });

      if (selectedQboRealm) {
        // build new value
        selectedQboRealm.status = listBackupStatus.DEACTIVATED;
        selectedQboRealm.deactivatedReason = reason;

        // replace new value into
        // existing data
        const { value } = state.QboRealms;
        const index = value.findIndex((v) => v.id === realmId);

        value.splice(index, 1, selectedQboRealm);

        state.QboRealms.value = [...value];
      }
      state.deactivateRealm.success = true;
    }
    state.deactivateRealm.submitting = false;
  },
};

export { qboDeactivateRealmAsync, qboDeactivateRealmExtraReducers };
