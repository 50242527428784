import React from 'react';
import PropTypes from 'prop-types';
import { QboCardWrapper } from '@ui/Components';
import ListingRestoreSection from '@ui/Sections/SystemStatusPage//ListingRestoreSection';
import useFetchStatusRestore from '@pages/SystemStatusPage/pageHooks/useFetchStatusRestore';
import useStatusRestoreFilter, {
  ButtonFilter,
} from '@pages/SystemStatusPage/pageHooks/useStatusRestoreFilter';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import NoRestoreSection from '@ui/Sections/SystemStatusPage/NoRestoreSection';
import DrawerRestoreInfoSection from '@ui/Sections/SystemStatusPage/DrawerRestoreInfoSection';
import useDrawerRestoreInfo from '@pages/SystemStatusPage/pageHooks/useDrawerRestoreInfo';

export default function StatusRestoreSection({ setSnackbarOpen }) {
  const {
    hasRestoreStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    QboStatusRestore,
    fetchingQboStatusRestore,
  } = useFetchStatusRestore();

  const { drawerIsOpen, setOpenDrawer, closeDrawer, QboStatusRestoreItem } = useDrawerRestoreInfo();

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusRestoreFilter(onFilter);

  return (
    <>
      {!fetchingQboStatusRestore && !hasRestoreStatuses && <NoRestoreSection />}

      {(fetchingQboStatusRestore || hasRestoreStatuses) && (
        <QboCardWrapper>
          <ListingRestoreSection
            onClickRow={setOpenDrawer}
            loading={fetchingQboStatusRestore}
            collection={QboStatusRestore.value}
            hasNextLink={QboStatusRestore.nextLink}
            hasPrevLink={QboStatusRestore.prevLink}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onChangePageNumber={onChangePageNumber}
            pageNumber={pageNumber}
            showPagination={QboStatusRestore.showPagination}
            onFilter={onFilter}
            searchKeyword={keyword}
            setSearchKeyword={setKeyword}
            displaySearchKeyword={displayKeyword}
            handleOpenDrawer={handleOpenDrawer}
            listBadges={listBadges}
            handleDelete={handleDelete}
          />
        </QboCardWrapper>
      )}

      <DrawerFilterSection
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleFilter={handleFilter}
        ButtonFilter={ButtonFilter}
        filterData={filterData}
      />

      <DrawerRestoreInfoSection
        onClose={closeDrawer}
        QboStatusRestore={QboStatusRestoreItem}
        open={drawerIsOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
}

StatusRestoreSection.propTypes = {
  setSnackbarOpen: PropTypes.func,
};

StatusRestoreSection.defaultProps = {
  setSnackbarOpen: () => {},
};
