import React from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { QboTypography, QboTooltip } from '@ui/Components';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Badge from '@mui/material/Badge';

function TooltipWrapper({ condition, children, title }) {
  return condition ? (
    <QboTooltip title={title}>
      <span className="QboButton__tooltip">{children}</span>
    </QboTooltip>
  ) : (
    children
  );
}

TooltipWrapper.propTypes = {
  condition: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
};

TooltipWrapper.defaultProps = {
  condition: false,
  children: null,
  title: '',
};

export default function QboButton({
  sx,
  sxText,
  children,
  onClick,
  navigate,
  asDropdown,
  startIcon,
  showBadge,
  hidden,
  className,
  title,
  withTitle,
  disabled,
  loading,
  ...rest
}) {
  const routeNavigate = useNavigate();

  const onClickButton = (e) => {
    if (typeof onClick === 'function') onClick(e);
    if (typeof navigate === 'string') routeNavigate(navigate);
  };

  const styles = {
    textTransform: 'inherit',
  };

  const renderStartIcon = () => {
    if (showBadge) {
      return (
        <Badge
          className="QboButton__badge"
          color="primary"
          variant="dot"
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          {startIcon}
        </Badge>
      );
    }
    return startIcon;
  };

  if (hidden) {
    return null;
  }

  return (
    <TooltipWrapper condition={withTitle} title={title}>
      <LoadingButton
        sx={{
          ...styles,
          ...sx,
        }}
        {...rest}
        onClick={(e) => {
          if (!disabled) onClickButton(e);
        }}
        startIcon={renderStartIcon()}
        className={`QboButton__default ${className} ${loading ? 'QboButton__loading' : ''}`}
        disabled={disabled}
        loading={loading}>
        <QboTypography sx={sxText}>{children}</QboTypography>
        {asDropdown && <KeyboardArrowDownIcon className="QboButton__dropdown-icon" />}
      </LoadingButton>
    </TooltipWrapper>
  );
}

QboButton.propTypes = {
  sx: PropTypes.object,
  sxText: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  navigate: PropTypes.string,
  asDropdown: PropTypes.bool,
  startIcon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  showBadge: PropTypes.bool,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  withTitle: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

QboButton.defaultProps = {
  sx: {},
  sxText: {},
  children: '',
  onClick: undefined,
  navigate: undefined,
  asDropdown: false,
  startIcon: null,
  showBadge: false,
  hidden: false,
  className: '',
  title: '',
  withTitle: false,
  disabled: false,
  loading: false,
};
