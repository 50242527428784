//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import BaseSetting from '@config/BaseSetting';
import i18n from 'i18next';
import DrawerView from '@config/views/DrawerView';
import I18n from '@config/I18n';
import {
  isRequireInitialSetupAsync,
  requireInitialSetupReducers,
} from './serviceActions/requireInitialSetupAsync';

const drawerView = new DrawerView();
const qboI18n = new I18n(BaseSetting.defaultLanguage, BaseSetting.resourcesLocales);

const initialState = {
  locale: i18n.language || BaseSetting.defaultLocale,
  isDrawerOpen: drawerView.isOpen(),
  alert: null,
  requireInitialSetup: null,
  isInitializing: true,
  error: null,
  isApplicationReady: false,
  snackbar: null,
};

const applicationSlice = createSlice({
  name: 'Application',
  initialState,
  reducers: {
    setLocale(state, action) {
      if (qboI18n.availableLocales.includes(action.payload)) {
        // forceUseLocale
        // for forcing uses locale from BaseSetting
        const useLocale = BaseSetting.forceUseLocale || action.payload;

        qboI18n.setLocale(useLocale);
        state.locale = useLocale;
      }
    },
    setIsDrawerOpen(state, action) {
      if (action.payload) {
        drawerView.setOpen();
      } else {
        drawerView.setClose();
      }

      state.isDrawerOpen = action.payload;
    },
    setAlert(state, action) {
      state.alert = {
        type: action.payload.type,
        message: action.payload.message,
        heading: action.payload.heading,
        path: action.payload.path,
        html: action.payload.html,
      };
    },
    clearAlert(state) {
      state.alert = null;
    },
    setSnackbar(state, action) {
      state.snackbar = {
        type: action.payload.type,
        message: action.payload.message,
      };
    },
    clearSnackbar(state) {
      state.snackbar = null;
    },
    setApplicationReadyStatus(state, action) {
      state.isApplicationReady = action.payload;
    },
    setRequireInitialSetup(state, action) {
      state.requireInitialSetup = action.payload;
    },
  },
  extraReducers: {
    ...requireInitialSetupReducers,
  },
});

export const {
  clearAlert,
  setAlert,
  setLocale,
  setIsDrawerOpen,
  setApplicationReadyStatus,
  setRequireInitialSetup,
  setSnackbar,
  clearSnackbar,
} = applicationSlice.actions;
export { isRequireInitialSetupAsync };
export default applicationSlice.reducer;
