import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { usePageNumber, useSearchDelay } from '@hooks';
import { useSearchParams } from 'react-router-dom';
import { trim } from '@utilities';

const search = 'search';
const pagination = 'pagination';
const initialize = 'initialize';
const sorting = 'sorting';
const filtering = 'filtering';
export const features = {
  search,
  pagination,
  initialize,
  sorting,
  filtering,
};

const usePagination = (
  Collection,
  fetchFunc,
  cacheKey,
  isReady,
  fetchParams = {},
  featuresEnable = [search, sorting, filtering, pagination, initialize],
  onChangeKeywordFunc = () => {}
) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const keywordParams = searchParams.get('keyword');
  const [keyword, setKeyword] = useState(keywordParams || '');
  const [displayKeyword, setDisplayKeyword] = useState(keywordParams || '');
  const [hasInitiazedRequest, setHasInitiazedRequest] = useState(
    !featuresEnable.includes(initialize)
  );
  const [sort, setSort] = useState(fetchParams.sort || {});
  const [filter, setFilter] = useState('');
  const [pageNumber, setPageNumber] = usePageNumber(cacheKey);

  const defaultFetchParams = {
    top: pageNumber,
    query: keyword,
    order: sort?.order,
    orderBy: sort?.orderBy,
    statuses: filter,
    ...fetchParams,
  };

  const onPrevPage = () => {
    if (Collection.prevLink && featuresEnable.includes(pagination) && hasInitiazedRequest) {
      dispatch(
        fetchFunc({
          ...defaultFetchParams,
          pageLink: Collection.prevLink,
        })
      );
    }
  };

  const onNextPage = () => {
    if (Collection.nextLink && featuresEnable.includes(pagination) && hasInitiazedRequest) {
      dispatch(
        fetchFunc({
          ...defaultFetchParams,
          pageLink: Collection.nextLink,
        })
      );
    }
  };

  const onChangePageNumber = (number) => {
    if (featuresEnable.includes(pagination) && hasInitiazedRequest) {
      setPageNumber(number);
      dispatch(
        fetchFunc({
          ...defaultFetchParams,
          top: number,
        })
      );
    }
  };

  const fetchAsync = (query = keyword, order = sort, statuses = filter, filterBy = 'statuses') => {
    const parameters = {};
    if (query) parameters.query = query;
    if (order?.order) parameters.order = order.order;
    if (order?.orderBy) parameters.orderBy = order.orderBy;
    parameters[filterBy] = statuses;

    dispatch(
      fetchFunc({
        ...defaultFetchParams,
        ...parameters,
      })
    );
  };

  useSearchDelay(
    {
      actionFunc: () => {
        if (isReady && featuresEnable.includes(search) && hasInitiazedRequest) {
          fetchAsync(trim(keyword));
          setDisplayKeyword(trim(keyword));
        }
      },
      resetFunc: () => {
        if (isReady && featuresEnable.includes(search) && hasInitiazedRequest) {
          fetchAsync('');
          setDisplayKeyword('');
        }
      },
      onChangeKeyword: () => {
        if (onChangeKeywordFunc) onChangeKeywordFunc();
      },
    },
    keyword
  );

  const onSort = (selected) => {
    if (isReady && featuresEnable.includes(sorting)) {
      setSort(selected);
      // to pass 1 parameters in fetchAsync function
      fetchAsync(...Array(1), selected);
    }
  };

  //
  // some of features has intialize request
  // so some features no need below action
  // and will be skipped by skipInitiazeRequest => true
  //

  const onFilter = (statuses, filterBy) => {
    if (isReady && featuresEnable.includes(filtering)) {
      setFilter(statuses);
      // ...Array(2) -> to pass 2 parameters in fetchAsync function
      fetchAsync(...Array(2), statuses, filterBy);
    }
  };

  useEffect(() => {
    if (isReady && featuresEnable.includes(initialize)) {
      dispatch(fetchFunc({ top: pageNumber, query: keyword, ...fetchParams }));
      setHasInitiazedRequest(true);
    }

    return () => {
      setHasInitiazedRequest(!featuresEnable.includes(initialize));
    };
  }, [isReady]);

  return {
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    pageNumber,
    keyword,
    setKeyword,
    displayKeyword,
    filter,
    onSort,
    onFilter,
    sort,
  };
};

export default usePagination;
