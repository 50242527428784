import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDateTime } from '@hooks';
import ClientRoutes, { dashboardPath } from '@config/Routes/WebClientRoutes';
import { QboLink, QboTypography, QboCard, QboSimpleDataTable, QboButton } from '@ui/Components';
import { useNavigate } from 'react-router-dom';
import { pascalToTitleCase } from '@utilities';
import DrawerObjectContentSection from '@ui/Sections/Shared/DrawerObjectContentSection';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import DashboardBreadcrumbsBlocks from '@ui/Blocks/Shared/DashboardBreadcrumbsBlock';
import BackupNowButtonBlock from '@ui/Blocks/Shared/BackupNowButtonBlock';
import AlertPageSection from '@ui/Sections/Shared/AlertPageSection';
import SearchCardBlock from '@ui/Blocks/Shared/CardBlocks/SearchCardBlock';
import PaginationCardBlock from '@ui/Blocks/Shared/CardBlocks/PaginationCardBlock';
import useDrawerContent from '@pages/sharedHooks/useDrawerContent';
import useSearchItemSuggestion from '@pages/sharedHooks/useSearchItemSuggestion';
import { qboBackupNowRealmAsync } from '@features/qboObjectItems/qboObjectItemsSlice';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import useRealmDownload from '@pages/sharedHooks/useRealmDownload';
import ModalDownloadCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalDownloadCardBlock';
import useRealmRestore from '@pages/sharedHooks/useRealmRestore';
import ModalRestoreCardBlock from '@ui/Blocks/Shared/CardBlocks/ModalRestoreCardBlock';
import useDrawerRestoreGuide from '@pages/DashboardPage/pageHooks/useDrawerRestoreGuide';
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import DrawerRestoreGuideSection from '@ui/Sections/DashboardPage/DrawerRestoreGuideSection';
import useStatusItemsFilter, {
  ButtonFilter,
} from '@pages/BackupRealmItemPage/pageHooks/useStatusItemsFilter';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import FilterCardBlock from '@ui/Blocks/Shared/CardBlocks/FilterCardBlock';
import useFetchObjectItems from './pageHooks/useFetchObjectItems';

export default function BackupRealmItemPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActivePageMenu, listMenu } = usePageMenu();
  const { dateFormat, dateToString } = useDateTime();
  const {
    objectName,
    objectId,
    versionId,
    openDrawer,
    setOpenDrawer,
    onOpenDrawer,
    qboRealmId,
    navigateObjectName,
  } = useDrawerContent();

  const {
    QboObjectItems,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableHeaders,
    tableRows,
    loading,
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    pageNumber,
    displayObjectName,
    listIdOnPage,
    onFilter,
  } = useFetchObjectItems();

  const {
    loadingSuggestion,
    searchKeyword,
    keyGroupBy,
    keyLabelOption,
    suggestions,
    setSearchKeyword,
    onSelectItem,
  } = useSearchItemSuggestion(onOpenDrawer);

  setActivePageMenu(listMenu.HOME_MENU);
  const {
    selected,
    setSelected,
    listSortOptions,
    selectedDropdown,
    onDropdownSelect,
    totalSelected,
  } = useCheckboxTable({
    listIdOnPage,
    totalRow: QboObjectItems?.totalRecords,
  });

  const {
    showModalDateTime,
    showModalConfirmation,
    onOpen,
    onClose,
    onNextButton,
    onBackButton,
    setSelectedRealmId,
    timeValue,
    setTimeValue,
    dateValue,
    setDateValue,
    notes,
    setNotes,
    selectedTime,
    onGenerateLink,
    downloadType,
    getMinDate,
  } = useRealmDownload({
    selectedListId: selected,
    objectName,
    companyName: QboObjectItems?.companyName,
  });

  const { drawerRestoreIsOpen, openDrawerRestore, closeDrawerRestore } = useDrawerRestoreGuide();

  const {
    showModalRestore,
    onOpen: onOpenModalRestore,
    onClose: onCloseModalRestore,
    timeValue: timeValueRestore,
    setTimeValue: setTimeValueRestore,
    dateValue: dateValueRestore,
    setDateValue: setDateValueRestore,
    selectedTimeRestore,
    onNextButtonRestore,
    showModalConfirmationRestore,
    onBackButtonRestore,
    selectedRestoreData,
    restoreNotes,
    setRestoreNotes,
    restoreType,
    onGenerateRestore,
  } = useRealmRestore({
    selectedListId: selected,
    objectName,
    companyName: QboObjectItems?.companyName,
  });

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusItemsFilter(onFilter);

  return (
    <div className="BackupRealmItemPage__wrapper">
      <div className="BackupRealmItemPage__header-block">
        <HeaderPageBlock
          loading={loading}
          navigate={dashboardPath}
          linkNavigateText={t('navigate.back_to_dashboard')}
          caption={
            QboObjectItems?.lastCompletedBackupAt
              ? t('backup_realm_page.header.last_backup_label', {
                  date: dateToString(
                    dateFormat.DEFAULT_DATE_TIME,
                    QboObjectItems?.lastCompletedBackupAt
                  ),
                })
              : ' '
          }>
          {QboObjectItems?.companyName}
        </HeaderPageBlock>

        <BackupNowButtonBlock
          id={qboRealmId}
          status={QboObjectItems?.backupStatus}
          action={qboBackupNowRealmAsync}
          companyName={QboObjectItems?.companyName}
          loading={loading}
        />
      </div>

      <AlertPageSection />

      <QboCard noPadding isMainWrapper>
        <DashboardBreadcrumbsBlocks loading={loading} anyChildren={QboObjectItems?.companyName}>
          <QboLink withTitle navigate={ClientRoutes.showRealmPath({ id: qboRealmId })}>
            {QboObjectItems?.companyName}
          </QboLink>
          <QboTypography withTitle>{pascalToTitleCase(displayObjectName)}</QboTypography>
        </DashboardBreadcrumbsBlocks>

        <SearchCardBlock
          className="BackupRealmItemPage__search-wrapper"
          loading={loadingSuggestion}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          keyGroupBy={keyGroupBy}
          keyLabelOption={keyLabelOption}
          options={suggestions}
          compact
          autocomplete
          withFilterButton
          placeholder={t('backup_realm_page.search_block.search_placeholder')}
          onSelectItem={onSelectItem}
          onClickAllResult={() => {
            navigate(
              ClientRoutes.searchRealmObjectItemPath({ id: qboRealmId, keyword: searchKeyword })
            );
          }}
          clearOnEscape={false}
          clearOnBlur={false}
          forceShowClearIcon
          rightContent={
            <div className="BackupRealmItemPage__right-button-search">
              <QboButton
                onClick={() => {
                  setSelectedRealmId(qboRealmId);
                  onOpen();
                }}
                size="large"
                startIcon={<CloudDownloadOutlinedIcon />}
                variant="outlined"
                color="primary"
                disabled={selected.length < 1}>
                {t('button.download')}
              </QboButton>
              <QboButton
                onClick={() => {
                  onOpenModalRestore(qboRealmId);
                }}
                size="large"
                startIcon={<CloudSyncOutlinedIcon />}
                variant="outlined"
                color="primary"
                disabled={selected.length < 1}
                hidden>
                {t('button.restore')}
              </QboButton>
            </div>
          }
          sortFilterPosition="left"
          setShowDrawer={handleOpenDrawer}
          showBadgeFilter={listBadges.length > 0}
        />

        <FilterCardBlock filterData={listBadges} handleDelete={handleDelete} />

        <QboSimpleDataTable
          rows={tableRows}
          header={tableHeaders}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          loading={loading}
          onClickRow={(row) => {
            if (row?.navigate) {
              navigate(row.navigate);
            } else if (row?.lastVersionId) {
              onOpenDrawer(row.lastVersionId, row.objectName, row.maskedId, true);
            }
          }}
          selected={selected}
          setSelected={setSelected}
          listSortOptions={listSortOptions}
          selectedDropdown={selectedDropdown}
          onDropdownSelect={onDropdownSelect}
          withCheckboxSelection
        />

        <PaginationCardBlock
          className="BackupRealmItemPage__wrapper"
          hasNextLink={QboObjectItems.nextLink}
          hasPrevLink={QboObjectItems.prevLink}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          onChangePageNumber={onChangePageNumber}
          pageNumber={pageNumber}
          showPagination={QboObjectItems.showPagination}
          paginationLabel={t('pagination.lists_per_page_label')}
          showFooterSelectedItems={totalSelected > 0}
          selectedItems={totalSelected}
        />
      </QboCard>

      <DrawerObjectContentSection
        versionId={versionId}
        objectName={objectName}
        objectId={objectId}
        qboRealmId={qboRealmId}
        open={openDrawer}
        isNavigateObjectName={navigateObjectName}
        closeDrawer={() => {
          setOpenDrawer(false);
        }}
      />

      <ModalDownloadCardBlock
        onClose={() => onClose()}
        onNextButton={() => onNextButton()}
        onBackButton={() => onBackButton()}
        onGenerateLink={() => onGenerateLink(downloadType.downloadTypeItems)}
        showModalDateTime={showModalDateTime}
        showModalConfirmation={showModalConfirmation}
        selectedTime={selectedTime}
        minDate={getMinDate(QboObjectItems?.value, QboObjectItems?.initialBackupAt)}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
        dateValue={dateValue}
        setDateValue={setDateValue}
        notes={notes}
        setNotes={setNotes}
        itemSelectedText={t('dashboard_page.modal_download.item_value_text_items', {
          row_selected: totalSelected,
        })}
      />

      <ModalRestoreCardBlock
        showModalRestore={showModalRestore}
        onClose={onCloseModalRestore}
        timeValue={timeValueRestore}
        setTimeValue={setTimeValueRestore}
        dateValue={dateValueRestore}
        setDateValue={setDateValueRestore}
        selectedTime={selectedTimeRestore}
        onOpenGuide={openDrawerRestore}
        onNextButton={onNextButtonRestore}
        showModalConfirmation={showModalConfirmationRestore}
        onBackButton={onBackButtonRestore}
        minDate={selectedRestoreData?.createdAt}
        itemSelectedText={t('dashboard_page.modal_restore.item_value_text_items', {
          row_selected: selected?.length,
        })}
        notes={restoreNotes}
        setNotes={setRestoreNotes}
        onGenerateLink={() => onGenerateRestore(restoreType.restoreTypeItems)}
      />

      <DrawerFilterSection
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleFilter={handleFilter}
        ButtonFilter={ButtonFilter}
        filterData={filterData}
      />

      <DrawerRestoreGuideSection onClose={closeDrawerRestore} open={drawerRestoreIsOpen} />
    </div>
  );
}
