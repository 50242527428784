import React from 'react';
import PropTypes from 'prop-types';

export default function QboDrawerListWrapper({ children, className }) {
  return <div className={`QboDrawerListWrapper__wrapper ${className}`}>{children}</div>;
}

QboDrawerListWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

QboDrawerListWrapper.defaultProps = {
  className: '',
};
