class StorageError extends Error {
  constructor(message) {
    super(message);
    this.name = 'StorageError';
  }
}

export const throwMessage = (func, klassName) => {
  return `${func} from ${klassName} is not defined`;
};

export default StorageError;
