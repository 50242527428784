import { useState } from 'react';

export default function useDrawerReplicateGuide() {
  const [openDrawerReplicate, setOpenDrawerReplicate] = useState(false);

  return {
    drawerReplicateIsOpen: openDrawerReplicate,
    openDrawerReplicate: () => {
      setOpenDrawerReplicate(!openDrawerReplicate);
    },
    closeDrawerReplicate: () => {
      setOpenDrawerReplicate(false);
    },
  };
}
