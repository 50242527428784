import { createAsyncThunk } from '@reduxjs/toolkit';
import { validateAuthForm2FAAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';

const validateAuthForm2FAAsync = createAsyncThunk(
  'Account/validateAuthForm2FA',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await validateAuthForm2FAAPI(accessToken, payload);
    return data;
  }
);

const { pending, fulfilled, rejected } = validateAuthForm2FAAsync;

const validateAuthForm2FAExtraReducers = {
  [pending]: (state) => {
    state.authForm2FA.submitting = true;
  },
  [rejected]: (state) => {
    state.authForm2FA.submitting = false;
  },
  [fulfilled]: (state, action) => {
    if (action?.payload?.error) {
      state.authForm2FA.error = action?.payload?.error;
    } else if (action?.payload?.status === HttpResponse.success) {
      state.authForm2FA.success = true;
    }
    state.authForm2FA.submitting = false;
  },
};

export { validateAuthForm2FAAsync, validateAuthForm2FAExtraReducers };
