import React from 'react';
import PropTypes from 'prop-types';
import { defaultButton, disabledButton, activeButton } from '@images/quickbooks';

export default function QboConnectButton({ className, onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      className={`QboConnectButton__wrapper ${className}`}>
      <img src={defaultButton} alt="" className="QboConnectButton__default" />
      <img src={activeButton} alt="" className="QboConnectButton__active" />
      <img src={disabledButton} alt="" className="QboConnectButton__disabled" />
    </button>
  );
}

QboConnectButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

QboConnectButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: '',
};
