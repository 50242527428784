import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Container } from '@mui/material';
import { QboSpinnerProgress } from '@ui/Components';
import NavbarSection from '@layouts/default/NavbarSection';
import SecondBarSection from '@layouts/default/SecondBarSection';
import DrawerSection from '@layouts/default/DrawerSection';
import FooterSection from '@layouts/default/FooterSection';
import Navigation from '@navigation';
import useDefaultSetupApplication from './layoutHooks/useDefaultSetupApplication';
import '@config/themes/default';

const drawerWidth = 240;

const navigationContainer = {
  marginTop: '4rem !important',
  marginBottom: '7rem !important',
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
};

export default function DefaultApplication() {
  const {
    isApplicationReady,
    isDrawerOpen,
    isShowSecondBar,
    handleDrawerOpen,
    handleDrawerClose,
    isPublicPage,
    logo,
  } = useDefaultSetupApplication();
  const mainMarginTop = isShowSecondBar ? '8.5rem' : '5.5rem';

  if (isPublicPage) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navigation />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavbarSection drawerOpen={isDrawerOpen} drawerWidth={drawerWidth} logo={logo} />
      {isShowSecondBar && <SecondBarSection drawerOpen={isDrawerOpen} drawerWidth={drawerWidth} />}
      <DrawerSection
        drawerWidth={drawerWidth}
        drawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Box
        component="main"
        sx={{
          minHeight: `calc(100vh - ${mainMarginTop})`,
          marginTop: mainMarginTop,
        }}
        className={`Box__main Box__main-with-padding ${
          !isApplicationReady ? 'DefaultApplication__not-ready' : ''
        }`}>
        <Container
          className="DefaultApplication__navigation"
          maxWidth="xlg"
          sx={navigationContainer}>
          <Navigation />
        </Container>

        {!isApplicationReady && (
          <QboSpinnerProgress className="DefaultApplication__not-ready-loading" fullWrapper />
        )}
        <FooterSection />
      </Box>
    </Box>
  );
}
