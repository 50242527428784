//
// useCollectionInterval
//
// hook that's used for create bulk interval
// for checking progress of the backup or any request for interval update
//
// to use:
//
// useCollectionInterval(
//   [{id: 1, status: 'running'}, {id: 2, status: 'completed'}], // collection parameters
//   (item) => item.status === 'running', // proof if item can be set with interval
//   (item) => item.status !=== 'running', // proof if item can escape from interval
//   (item) => dispatch(action()), // action when interval is executed
// )
//
// @returns: func -> [clearProgressInterval]
//
// the return func can be used for clear all the interval
//

import { useRef, useEffect, useCallback } from 'react';
import BaseSetting from '@config/BaseSetting';

// building attributes
// and adding to runningIntervalRef
const setBuildProgressInterval = (attributes, key, interval) => {
  let newAttributes = {};

  newAttributes = {
    ...attributes,
    [key]: interval,
  };

  return newAttributes;
};

const useCollectionInterval = (
  collection,
  canSetInterval,
  canRemoveInterval,
  onRunningInterval,
  delay = BaseSetting.intervalCheckingBackupStatus
) => {
  // for checking effect
  const firstCollectionId = collection[0]?.id;

  // as storage identities of
  // interval
  const runningIntervalRef = useRef();

  // create the interval
  // based on collection
  const createBuildInterval = useCallback(() => {
    if (!canSetInterval || !canRemoveInterval || !collection) {
      return false;
    }

    runningIntervalRef.current = runningIntervalRef.current || {};
    let buildProgressInterval = { ...runningIntervalRef.current };

    if (collection.length > 0) {
      collection.forEach((item) => {
        if (canSetInterval(item)) {
          const runningInterval = setInterval(() => {
            onRunningInterval(item);

            if (canRemoveInterval(item) && runningIntervalRef.current[item.id]) {
              clearInterval(runningIntervalRef.current[item.id]);
              delete buildProgressInterval[item.id];
            }
          }, delay);

          buildProgressInterval = setBuildProgressInterval(
            buildProgressInterval,
            item.id,
            runningInterval
          );
        } else if (canRemoveInterval(item) && runningIntervalRef.current[item.id]) {
          clearInterval(runningIntervalRef.current[item.id]);
          delete buildProgressInterval[item.id];
        }
      });

      runningIntervalRef.current = buildProgressInterval;
    }

    return true;
  }, [firstCollectionId, collection]);

  // public method
  // that returned
  const clearProgressInterval = useCallback(() => {
    Object.keys(runningIntervalRef.current).forEach((k) => {
      clearInterval(runningIntervalRef.current[k]);
    });

    return true;
  }, [firstCollectionId, collection]);

  // React cylce to create the
  // bulk interval
  /* eslint-disable */
  useEffect(() => {
    createBuildInterval();

    return () => {
      clearProgressInterval();
    };
  }, [firstCollectionId, collection]);

  return [clearProgressInterval];
};

export default useCollectionInterval;
