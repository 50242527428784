import { createAsyncThunk } from '@reduxjs/toolkit';
import { backupNowAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';
import SystemStatus from '@libs/SystemStatus';

const { listBackupStatus } = SystemStatus;

const qboBackupNowRealmAsync = createAsyncThunk(
  'QboRealms/backupNowRealm',
  async ({ qboRealmId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await backupNowAPI(accessToken, qboRealmId);
    return data;
  }
);

const { fulfilled } = qboBackupNowRealmAsync;

const qboBackupNowRealmExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.status === HttpResponse.success) {
      const { qboRealmId } = action.meta.arg;

      // replace the value from array
      const selectedQboRealm = state.QboRealms.value.find((r) => {
        return r.id === qboRealmId;
      });

      if (selectedQboRealm) {
        // build new value
        selectedQboRealm.status = listBackupStatus.RUNNING;
        selectedQboRealm.percentage = 0;

        // replace new value into
        // existing data
        const { value } = state.QboRealms;
        const index = value.findIndex((v) => v.id === qboRealmId);

        value.splice(index, 1, selectedQboRealm);

        state.QboRealms.value = [...value];
      }
    }
  },
};

export { qboBackupNowRealmAsync, qboBackupNowRealmExtraReducers };
