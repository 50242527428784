import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { QboSpinnerProgress } from '@ui/Components';
import { t } from 'i18next';
import WindowOpener from '@libs/WindowOpener';
import BaseSetting from '@config/BaseSetting';
import {
  oauthCallbackAPI,
  oauthReactivateCallbackAPI,
  oauthReplicateCallbackAPI,
  oauthReplicateReAuthenticationCallbackAPI,
} from '@services/WebApiService';
import { REACTIVATE, REPLICATE, REACTIVATE_REPLICATE } from '@pages/sharedHooks/useQboOauth';

const windowOpener = new WindowOpener(BaseSetting.qboWindowOpenerName, true);
const RESPONSE_ACCESS_DENIED = 'access_denied';

export default function OauthCallbackQuickbooksPage() {
  const { accessToken } = useSelector((state) => state.auth);

  const confirmQboAuth = async (code, realmId, state) => {
    let data = null;

    if (state.includes(REACTIVATE_REPLICATE)) {
      const [, environment, qboReplicateId] = state.split('_');
      data = await oauthReplicateReAuthenticationCallbackAPI(
        accessToken,
        code,
        realmId,
        environment,
        qboReplicateId
      );
    } else if (state.includes(REPLICATE)) {
      const [, environment, maskedSourceQboRealmId] = state.split('_');
      data = await oauthReplicateCallbackAPI(
        accessToken,
        code,
        realmId,
        environment,
        maskedSourceQboRealmId
      );
    } else if (state.includes(REACTIVATE)) {
      const [, qboRealmId] = state.split('_');
      data = await oauthReactivateCallbackAPI(accessToken, qboRealmId, code, realmId);
    } else {
      data = await oauthCallbackAPI(accessToken, code, realmId);
    }

    if (data.error) {
      windowOpener.postFailedMessage(data);
      windowOpener.closePopup();
    } else {
      windowOpener.postSuccessMessage({ confirmResponse: data, state });
      windowOpener.closePopup();
    }
  };

  useEffect(() => {
    try {
      if (accessToken) {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');
        const realmId = url.searchParams.get('realmId');
        const state = url.searchParams.get('state');
        const error = url.searchParams.get('error');

        if (code && realmId && state) {
          confirmQboAuth(code, realmId, state);
        } else if (error === RESPONSE_ACCESS_DENIED) {
          windowOpener.closePopup();
        } else {
          windowOpener.postFailedMessage();
          windowOpener.closePopup();
        }
      }
    } catch (err) {
      windowOpener.postFailedMessage({ error: err.message });
      windowOpener.closePopup();
    }
  }, [accessToken]);

  return <QboSpinnerProgress fullWrapper label={`${t('label.loading')}...`} />;
}
