import React from 'react';
import { useTranslation } from 'react-i18next';
import { QboCardCaption } from '@ui/Components';
import usePageMenu from '@pages/sharedHooks/usePageMenu';

export default function NotFoundPage() {
  const { setActivePageMenu, listMenu } = usePageMenu();
  setActivePageMenu(listMenu.HOME_MENU);

  const { t } = useTranslation();

  return (
    <QboCardCaption
      className="NotFoundPage__wrapper"
      heading={t('not_found_page.heading_text')}
      caption={t('not_found_page.caption_text')}>
      <h1>404</h1>
    </QboCardCaption>
  );
}
