import React from 'react';
import PropTypes from 'prop-types';
import { QboModal, QboQRCode, QboTypography, QboLink } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton } from '@mui/material';
import ModalFormAuthenticationCodeBlock from './ModalFormAuthenticationCodeBlock';

export default function ModalQRCode2FABlock({
  showQRCode,
  showAuthForm,
  onClose,
  onNextButton,
  onBackButton,
  type,
  changeTypeAuth,
  qrKey,
  secretKey,
  loading,
  errorValidation,
  loadingForm,
}) {
  const { t } = useTranslation();

  const renderBodyModal = () => {
    if (type === 'key') {
      return (
        <Box className="ModalKey2FA__wrapper">
          <QboTypography noWrap={false} color="primary">
            {t('account_settings_setup_2fa_page.modal.qr_code_key.paragraph_1')}
          </QboTypography>
          <QboTypography noWrap={false} color="primary">
            {t('account_settings_setup_2fa_page.modal.qr_code_key.paragraph_2')}
          </QboTypography>
          <QboLink className="ModalKey2FA__button-link" onClick={() => changeTypeAuth('qr')}>
            {t('account_settings_setup_2fa_page.modal.qr_code_key.try_scan_text_button')}
          </QboLink>
          <Box className="ModalKey2FA__key-box">
            <QboTypography color="primary" component="div">
              {secretKey?.match(/.{1,4}/g)?.join(' ')}
            </QboTypography>
          </Box>
        </Box>
      );
    }

    return (
      <Box className="ModalQRCode2FA__wrapper">
        <Box className="ModalQRCode2FA__qr-box">
          <QboQRCode value={qrKey} />
        </Box>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.qr_code.description')}
        </QboTypography>
        <QboLink className="ModalQRCode2FA__button-link" onClick={() => changeTypeAuth('key')}>
          {t('account_settings_setup_2fa_page.modal.qr_code.unable_scan_text_button')}
        </QboLink>
      </Box>
    );
  };

  const renderSkeleton = () => {
    if (type === 'key') {
      return (
        <Box className="ModalKey2FA__wrapper">
          <Skeleton variant="rectangular" width="100%" height={60} />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
      );
    }
    return (
      <Box className="ModalQRCode2FA__wrapper">
        <Skeleton variant="rectangular" width={280} height={280} />
        <Skeleton variant="text" width="80%" height={60} />
        <Skeleton variant="text" width="40%" />
      </Box>
    );
  };

  return (
    <>
      <QboModal
        onClose={() => onClose()}
        open={showQRCode}
        title={t('account_settings_setup_2fa_page.modal.qr_code.title')}
        button={{
          text: t('account_settings_setup_2fa_page.modal.qr_code.next_button'),
          onClick: () => onNextButton(),
          position: 'right',
          loading,
        }}
        preventOutsideClick>
        {loading ? renderSkeleton() : renderBodyModal()}
      </QboModal>
      <ModalFormAuthenticationCodeBlock
        showAuthForm={showAuthForm}
        onBackButton={onBackButton}
        onClose={onClose}
        onNextButton={onNextButton}
        validationError={errorValidation}
        withoutPassword
        loading={loadingForm}
      />
    </>
  );
}

ModalQRCode2FABlock.propTypes = {
  showQRCode: PropTypes.bool,
  showAuthForm: PropTypes.bool,
  onClose: PropTypes.func,
  onNextButton: PropTypes.func,
  onBackButton: PropTypes.func,
  changeTypeAuth: PropTypes.func,
  qrKey: PropTypes.string,
  secretKey: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  errorValidation: PropTypes.object,
  loadingForm: PropTypes.bool,
};

ModalQRCode2FABlock.defaultProps = {
  showQRCode: false,
  showAuthForm: false,
  onClose: () => {},
  onNextButton: () => {},
  onBackButton: () => {},
  changeTypeAuth: () => {},
  qrKey: '',
  secretKey: '',
  type: 'qr',
  loading: true,
  errorValidation: {},
  loadingForm: true,
};
