import React from 'react';
import { QboCard, QboCardCaption } from '@ui/Components';
import NoReplicateStatus from '@images/generals/NoReplicateStatus.svg';
import { useTranslation } from 'react-i18next';

export default function NoKeywordMatchSection() {
  const { t } = useTranslation();

  return (
    <QboCard noPadding isMainWrapper>
      <QboCardCaption
        imageUrl={NoReplicateStatus}
        heading={t('system_status_page.no_keyword_match_section.heading_text')}
        caption={t('system_status_page.no_keyword_match_section.caption_text')}
      />
    </QboCard>
  );
}
