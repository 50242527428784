import StorageError, { throwMessage } from './StorageError';

/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
class Storage {
  constructor({ keyStoragePrefix }) {
    this.keyStoragePrefix = keyStoragePrefix;
  }

  storageClient() {
    throw new StorageError(throwMessage('storageClient', 'Storage'));
  }

  save(key, value) {
    throw new StorageError(throwMessage('save', 'Storage'));
  }

  get(key) {
    throw new StorageError(throwMessage('get', 'Storage'));
  }

  destroy(key) {
    throw new StorageError(throwMessage('destroy', 'Storage'));
  }

  static clear(key) {
    throw new StorageError(throwMessage('clear', 'Storage'));
  }

  keyName(key) {
    if (this.keyStoragePrefix) {
      return `${this.keyStoragePrefix}_${key}`;
    }

    return key;
  }
}

export default Storage;
