import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  QboRightDrawer,
  QboAccordion,
  QboDrawerListWrapper,
  QboDrawerListItem,
  QboTypography,
  QboDrawerBoxItem,
  QboTipsBox,
} from '@ui/Components';
import { getQboRealmStatusAsync } from '@features/qboRealms/qboRealmsSlice';
import { useNavigate } from 'react-router-dom';
import ClientRoutes, { dashboardPath } from '@config/Routes/WebClientRoutes';
import useRealmInfo from '@pages/DashboardPage/pageHooks/useRealmInfo';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import IntuitQboLogo from '@images/logos/intuit-qbo-logo.svg';
import BackupStatusItemBlock from '@ui/Blocks/Shared/BackupStatusItemBlock';
import { useDateTime, usePageAlert, useAlertMessage } from '@hooks';
import { listBackupStatus } from '@hooks/useSystemStatus';
import useQboOauth, { REACTIVATE } from '@pages/sharedHooks/useQboOauth';
import Box from '@mui/material/Box';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import ErrorResponse from '@libs/ErrorResponse';

export default function DrawerRealmInfoSection({ QboRealm, onClose, open }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { openOauthWindow, isButtonActive } = useQboOauth(REACTIVATE, {
    qboRealmId: QboRealm?.id,
    errorCallback: (error) => {
      onClose();
      const alertPayload = {
        ...getErrorMessage(konstMessage.ERROR_REACTIVATE, { company_name: QboRealm?.companyName }),
      };

      const alertPayloadIncorrectCompany = {
        ...getErrorMessage(konstMessage.ERROR_REACTIVATE_INCORRECT_COMPANY, {
          company_name: QboRealm?.companyName,
        }),
      };

      if (error?.code === ErrorResponse.UnprocessableFormat) {
        updateErrorAlert(alertPayloadIncorrectCompany);
      } else {
        updateErrorAlert(alertPayload);
      }

      navigate(dashboardPath, { replace: true });
    },
    successCallback: () => {
      onClose();
      dispatch(getQboRealmStatusAsync({ qboRealmId: QboRealm.id }));
      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_REACTIVATE, {
          company_name: QboRealm?.companyName,
        }),
      };

      updateSuccessAlert(alertPayload);
      navigate(dashboardPath, { replace: true });
    },
  });
  const { dateFormat, dateToString } = useDateTime();
  const { generalInformationPanel, backupInformationPanel } = useRealmInfo({ QboRealm });

  return (
    <QboRightDrawer
      title={t('dashboard_page.drawer_realm_info_section.heading_text')}
      onClose={onClose}
      open={open}
      className="DrawerRealmInfoSection__wrapper">
      <div className="DrawerRealmInfoSection__content">
        <QboDrawerBoxItem
          className={
            !QboRealm?.lastCompletedBackupAt ? 'DrawerRealmInfoSection__content-header-disable' : ''
          }
          icon={<img src={IntuitQboLogo} alt="" className="Img__fluid" />}
          label={QboRealm?.companyName || '-'}
          onClick={() => {
            if (QboRealm?.lastCompletedBackupAt) {
              navigate(ClientRoutes.showRealmPath({ id: QboRealm?.id }));
            }
          }}>
          <QboTypography variant="caption">{QboRealm?.emailAddress || '-'}</QboTypography>
        </QboDrawerBoxItem>

        <QboDrawerListWrapper>
          <BackupStatusItemBlock
            label={t('dashboard_page.drawer_realm_info_section.realm_info.current_backup_label')}
            status={QboRealm?.status}
            percentage={QboRealm?.percentage}
            lastCompletedBackupAt={QboRealm?.lastBackupAt}
            isReauthButtonActive={isButtonActive}
            onClickReauth={openOauthWindow}
            oauthTipsInfo={
              QboRealm?.status === listBackupStatus.DEACTIVATED
                ? t('dashboard_page.drawer_realm_info_section.deactivate_description_html')
                : t('dashboard_page.drawer_realm_info_section.reauth_description_html')
            }
          />
          {QboRealm?.deactivatedReason ? (
            <Box className="DrawerRealmInfoSection-tips-box-wrapper">
              <QboTipsBox
                className="DrawerRealmInfoSection_tips-box-reason"
                __html={t('dashboard_page.drawer_realm_info_section.deactivate_reason_html')}
                variant="info"
                icon={<StickyNote2OutlinedIcon />}>
                <p>{QboRealm?.deactivatedReason}</p>
              </QboTipsBox>
            </Box>
          ) : null}
          <QboDrawerListItem
            icon={<CloudDoneOutlinedIcon />}
            label={t('dashboard_page.drawer_realm_info_section.realm_info.last_backup_label')}>
            <QboTypography className="DrawerRealmInfoSection__card-list-value">
              {dateToString(dateFormat.DEFAULT_DATE_TIME, QboRealm?.lastCompletedBackupAt) || '-'}
            </QboTypography>
          </QboDrawerListItem>
        </QboDrawerListWrapper>

        <QboAccordion
          defaultExpanded
          collections={[...generalInformationPanel, ...backupInformationPanel]}
          className="DrawerRealmInfoSection__accordion-information"
        />
      </div>
    </QboRightDrawer>
  );
}

DrawerRealmInfoSection.propTypes = {
  QboRealm: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

DrawerRealmInfoSection.defaultProps = {
  QboRealm: {},
  onClose: () => {},
  open: false,
};
