import React from 'react';
import {
  QboDrawerListItem,
  QboTypography,
  QboLinearProgress,
  QboTipsBox,
  QboConnectButton,
} from '@ui/Components';
import PropTypes from 'prop-types';
import { useSystemStatus, useDateTime } from '@hooks';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { t } from 'i18next';

export default function BackupStatusItemBlock({
  status,
  percentage,
  lastCompletedBackupAt,
  label,
  isReauthButtonActive,
  onClickReauth,
  oauthTipsInfo,
}) {
  const { dateFormat, dateToString } = useDateTime();
  const { isRequireRauth, listBackupStatus, badgeBackupStatusRender } = useSystemStatus({
    status,
    withIcon: true,
    withInfoIcon: false,
  });

  const sideBadgeTextRender = () => {
    if (listBackupStatus.RUNNING === status && percentage) {
      return (
        <QboTypography>
          {t('system_status.completed.percentage_text', { number: percentage })}
        </QboTypography>
      );
    }

    if (lastCompletedBackupAt) {
      return (
        <QboTypography>
          ({dateToString(dateFormat.DEFAULT_DATE_TIME, lastCompletedBackupAt)})
        </QboTypography>
      );
    }

    return null;
  };

  return (
    <div className="BackupStatusItemBlock__wrapper">
      <QboDrawerListItem
        className="BackupStatusItemBlock__badge-wrapper"
        icon={<BackupOutlinedIcon />}
        label={label}>
        <div className="BackupStatusItemBlock__content">
          {badgeBackupStatusRender()}
          {sideBadgeTextRender()}
        </div>

        {listBackupStatus.RUNNING === status && <QboLinearProgress progress={percentage} />}
      </QboDrawerListItem>

      {isRequireRauth(status) && (
        <>
          {oauthTipsInfo && (
            <QboTipsBox
              className="BackupStatusItemBlock__auth-tips-wrapper"
              __html={oauthTipsInfo}
            />
          )}
          <QboConnectButton disabled={!isReauthButtonActive} onClick={onClickReauth} />
        </>
      )}
    </div>
  );
}

BackupStatusItemBlock.propTypes = {
  label: PropTypes.string,
  status: PropTypes.string,
  lastCompletedBackupAt: PropTypes.string,
  percentage: PropTypes.number,
  isReauthButtonActive: PropTypes.bool,
  onClickReauth: PropTypes.func,
  oauthTipsInfo: PropTypes.string,
};

BackupStatusItemBlock.defaultProps = {
  label: '',
  status: '',
  lastCompletedBackupAt: '',
  percentage: 0,
  isReauthButtonActive: false,
  onClickReauth: () => {},
  oauthTipsInfo: null,
};
