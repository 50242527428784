import React from 'react';
import { QboTypography, QboCard, QboLink } from '@ui/Components';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import ClientRoutes from '@config/Routes/WebClientRoutes';

export default function AccountSettingsMenuSection() {
  const { isActiveMenu, listMenu } = usePageMenu({ withRender: true });

  return (
    <QboCard className="AccountSettingsMenuSection__wrapper">
      <QboTypography variant="caption" color="mute">
        SETTING MENU
      </QboTypography>

      <div className="AccountSettingsMenuSection__list-account-menu">
        <QboLink
          className={
            isActiveMenu(listMenu.ACCOUNT_CHANGE_PASSWORD_MENU)
              ? 'AccountSettingsMenuSection__active-account-menu'
              : ''
          }
          navigate={ClientRoutes.settingChangePasswordPath()}>
          <HttpsOutlinedIcon /> Change Password
        </QboLink>
        <QboLink
          className={
            isActiveMenu(listMenu.ACCOUNT_SETUP_2FA_MENU)
              ? 'AccountSettingsMenuSection__active-account-menu'
              : ''
          }
          navigate={ClientRoutes.settingSetup2FAPath()}>
          <ShieldOutlinedIcon /> Two-factor Authentication
        </QboLink>
      </div>
    </QboCard>
  );
}
