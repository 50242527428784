import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { settingChangePasswordPath } from '@config/Routes/WebClientRoutes';
import usePageMenu from '@pages/sharedHooks/usePageMenu';
import useLogout from './useLogout';

const LOGOUT = 'LOGOUT';
const ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS';

export default function useAccountMenu() {
  const { logoutAction } = useLogout();
  const { t } = useTranslation();
  const { setActivePageMenu, listMenu } = usePageMenu();

  const selectedMenuAccount = (selected) => {
    switch (selected) {
      case LOGOUT:
        logoutAction();
        break;
      default:
        break;
    }
  };

  const accountMenuItems = [
    [
      ACCOUNT_SETTINGS,
      t('navbar.account_menu.account_settings'),
      {
        divider: true,
        component: Link,
        to: settingChangePasswordPath,
        onClick: () => {
          setActivePageMenu(listMenu.ACCOUNT_CHANGE_PASSWORD_MENU);
        },
      },
    ],
    [LOGOUT, t('navbar.account_menu.logout'), { color: 'danger' }],
  ];

  return { selectedMenuAccount, accountMenuItems };
}
