import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { QboTypography, QboAlert } from '@ui/Components';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <QboAlert innerRef={ref} {...props} />;
});

const renderMessage = (message) => {
  if (message) {
    return (
      <div className="QboSnackbar__initial-text">
        <QboTypography>{message}</QboTypography>
      </div>
    );
  }

  return null;
};

export default function QboSnackbar({ sx, className, open, message, onClose, type, ...rest }) {
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Snackbar
      {...rest}
      className="QboSnackbar__wrapper"
      open={open}
      TransitionComponent={TransitionUp}
      autoHideDuration={3000}
      onClose={handleClose}
      key={TransitionUp?.name}>
      {type === '' ? (
        renderMessage(message)
      ) : (
        <Alert
          className="QboSnackbar__alert"
          type={type}
          onClose={() => {
            handleClose();
          }}>
          <QboTypography>{message}</QboTypography>
        </Alert>
      )}
    </Snackbar>
  );
}

QboSnackbar.propTypes = {
  sx: PropTypes.object,
  className: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.string,
};

QboSnackbar.defaultProps = {
  sx: {},
  className: '',
  open: false,
  message: '',
  onClose: () => {},
  type: '',
};
