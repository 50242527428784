//
// create slice
// as centeralize between actions and reducers
//
// serviceActions module contains reducers and actions that
// created by createAsyncThunk from redux-toolkit
//
// for standard actions, that require other business logic
// can be put inside extraActions module
//
// export default will be reducers it self that processed by store
// other exports will contains actions that's dispatched from ReactComponent
//

import { createSlice } from '@reduxjs/toolkit';
import { authRequestAsync, authExtraReducers } from './serviceActions/authAsync';
import { authLogoutAsync, authLogoutExtraReducers } from './serviceActions/authLogoutAsync';
import {
  authRefreshTokenAsync,
  authRefreshTokenExtraReducers,
} from './serviceActions/authRefreshTokenAsync';

const initialState = {
  isUserSignedIn: false,
  isAuthenticating: false,
  isLogouting: false,
  accessToken: undefined,
  error: null,
  emailAddress: null,
  nameInitial: null,
  fullName: null,
  organizationName: null,
  features: [],
  favicon: null,
  generalLogo: null,
};

const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
  },
  extraReducers: {
    ...authExtraReducers,
    ...authLogoutExtraReducers,
    ...authRefreshTokenExtraReducers,
  },
});

export { authRequestAsync, authLogoutAsync, authRefreshTokenAsync };
export const { setAccessToken } = authSlice.actions;
export default authSlice.reducer;
