import { createAsyncThunk } from '@reduxjs/toolkit';
import { downloadCallbackAPI, downloadDetailCallbackAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';
import ErrorResponse from '@libs/ErrorResponse';

const qboDownloadRealmToolsAsync = createAsyncThunk(
  'QboRealms/downloadRealm',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    let data;

    if (payload.type) {
      data = await downloadDetailCallbackAPI(accessToken, payload);
    } else {
      data = await downloadCallbackAPI(accessToken, payload);
    }
    return data;
  }
);

const { pending, fulfilled, rejected } = qboDownloadRealmToolsAsync;

const qboDownloadRealmToolsExtraReducers = {
  [pending]: (state) => {
    state.download.submitting = true;
    state.download.success = false;
    state.download.error = null;
  },
  [rejected]: (state) => {
    state.download.submitting = false;
    state.download.error = ErrorResponse.GeneralError;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.download.error = action.payload.error.code;
    } else if (action.payload.status === HttpResponse.success) {
      state.download.success = true;
    }
    state.download.submitting = false;
  },
};

export { qboDownloadRealmToolsAsync, qboDownloadRealmToolsExtraReducers };
