/* eslint-disable import/no-relative-packages */
/* eslint-disable no-restricted-syntax */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import packageJson from '../../package.json';

const buildDateGreaterThan = (latestVersionDate, currentVersionDate) => {
  const momLatestDateTime = moment(latestVersionDate);
  const momCurrentDateTime = moment(currentVersionDate);
  if (momLatestDateTime.isAfter(momCurrentDateTime)) return true;
  return false;
};

export default function BuildCacheKeeper({ children }) {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  const refreshCacheAndReload = () => {
    if (window.caches) {
      // Service worker cache should be cleared with caches.delete()
      window.caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }

    // delete browser cache and hard reload
    window.location.reload(true);
  };

  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;

        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);

        if (shouldForceRefresh) {
          setIsLatestBuildDate(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuildDate(true);
        }
      });
  }, []);

  if (!isLatestBuildDate) return null;
  return children;
}

BuildCacheKeeper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
