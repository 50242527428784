import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QboTypography, QboDrawerListItem } from '@ui/Components';
import EventIcon from '@mui/icons-material/Event';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useDateTime, useSystemStatus } from '@hooks';
import useRealmRestore from '@pages/sharedHooks/useRealmRestore';

const defaultNull = '-';

const createCardItem = (icon, label, value) => {
  return (
    <QboDrawerListItem icon={icon} label={label}>
      <QboTypography className="DrawerRealmInfoSection__card-list-value">{value}</QboTypography>
    </QboDrawerListItem>
  );
};

export default function useDrawerRestoreInfo(QboStatusRestoreSelectedItem) {
  const { t } = useTranslation();
  const { dateFormat, dateToString, dateTimeNow, dateDistance } = useDateTime();
  const { ConstantQBOPayloadType } = useRealmRestore();
  const { listRestoreStatus } = useSystemStatus();
  const [selectedRestoreId, setSelectedRestoreId] = useState(null);
  const { QboStatusRestore } = useSelector((state) => state.qboStatusRestore);

  const drawerIsOpen = selectedRestoreId !== null;
  const QboStatusRestoreItem = QboStatusRestore.value.find((v) => v.id === selectedRestoreId);

  let elapsedTime = defaultNull;

  if (QboStatusRestoreSelectedItem?.startTime) {
    const endTime = QboStatusRestoreSelectedItem?.endTime || dateTimeNow();
    elapsedTime =
      dateDistance(QboStatusRestoreSelectedItem?.startTime, endTime, {
        separator: ' ',
      }) || '1s';
  }

  const generalInformationPanel = [
    {
      headingText: t('dashboard_page.drawer_realm_info_section.general_info_caption'),
      isFullContent: true,
      children: (
        <>
          {createCardItem(
            <EventIcon />,
            t('status_restore_page.drawer_restore_info_section.started_at_label'),
            dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME,
              QboStatusRestoreSelectedItem?.startTime
            ) || defaultNull
          )}
          {createCardItem(
            <HistoryToggleOffIcon />,
            t('status_restore_page.drawer_restore_info_section.elapsed_time_label'),
            elapsedTime
          )}
          {createCardItem(
            <EventAvailableIcon />,
            t('status_restore_page.drawer_restore_info_section.completed_at_label'),
            dateToString(
              dateFormat.DEFAULT_FULL_DATE_TIME,
              QboStatusRestoreSelectedItem?.endTime
            ) || defaultNull
          )}
        </>
      ),
    },
  ];

  const labelInfoItem = () => {
    let label = '';

    if (QboStatusRestoreSelectedItem?.status === listRestoreStatus.RUNNING) {
      label = t('status_restore_page.drawer_restore_info_section.item_info_calculating');
    } else if (ConstantQBOPayloadType?.TypeRealm === QboStatusRestoreSelectedItem?.type) {
      label = t('status_restore_page.drawer_restore_info_section.item_info_realm_label', {
        company_name: QboStatusRestoreSelectedItem?.displayName,
      });
    } else if (ConstantQBOPayloadType?.TypeObject === QboStatusRestoreSelectedItem?.type) {
      label = t('status_restore_page.drawer_restore_info_section.item_info_object_label', {
        row_selected: QboStatusRestoreSelectedItem?.totalObjects,
      });
    } else if (ConstantQBOPayloadType?.TypeItems === QboStatusRestoreSelectedItem?.type) {
      if (QboStatusRestoreSelectedItem?.displayName) {
        label = t('status_restore_page.drawer_restore_info_section.item_info_items_label', {
          object_name: QboStatusRestoreSelectedItem?.displayName,
        });
      } else {
        label = t('status_restore_page.drawer_restore_info_section.item_info_items_search_label');
      }
    } else {
      label = QboStatusRestoreSelectedItem?.displayName;
    }

    return label;
  };

  return {
    drawerIsOpen,
    setOpenDrawer: (id) => {
      setSelectedRestoreId(id);
    },
    closeDrawer: () => {
      setSelectedRestoreId(null);
    },
    QboStatusRestoreItem,
    generalInformationPanel,
    labelInfoItem: labelInfoItem(),
  };
}
