import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshTokenAPI } from '@services/WebApiService';

const authRefreshTokenAsync = createAsyncThunk('Auth/refreshTokenRequest', async () => {
  const data = await refreshTokenAPI();
  return data;
});

const { pending, fulfilled } = authRefreshTokenAsync;

const authRefreshTokenExtraReducers = {
  [pending]: (state) => {
    state.isAuthenticating = true;
  },
  [fulfilled]: (state, action) => {
    if (action?.payload?.accessToken) {
      state.accessToken = action.payload.accessToken;
      state.isAuthenticating = false;
      state.isUserSignedIn = true;
    } else {
      state.isAuthenticating = false;
      state.isUserSignedIn = false;
    }
  },
};

export { authRefreshTokenAsync, authRefreshTokenExtraReducers };
