import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export default function QboFormGroup({ children, className, ...rest }) {
  return (
    <Box {...rest} className={`QboFormGroup__wrapper ${className}`}>
      {children}
    </Box>
  );
}

QboFormGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

QboFormGroup.defaultProps = {
  className: '',
};
