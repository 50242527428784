import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  QboModal,
  QboTypography,
  QboDatePicker,
  QboTimePicker,
  QboTextField,
  QboTipsBox,
} from '@ui/Components';
import Box from '@mui/material/Box';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useDateTime } from '@hooks';
import { QboModalListItem, QboModalCardList } from '@ui/Components/QboModal';

export default function ModalRestoreCardBlock({
  onClose,
  onNextButton,
  onBackButton,
  onGenerateLink,
  onOpenGuide,
  showModalRestore,
  showModalConfirmation,
  selectedTime,
  timeValue,
  setTimeValue,
  dateValue,
  setDateValue,
  notes,
  setNotes,
  minDate,
  itemSelectedText,
  withoutSelectDate,
  infoTips,
  children,
}) {
  const { t } = useTranslation();
  const { stringToDate, addDateTime } = useDateTime();
  const minDateFormatted = minDate ? stringToDate(minDate) : {};
  const addedMinDate = minDateFormatted ? addDateTime(minDateFormatted, 2, 'minutes') : {};
  addedMinDate?.second(0);

  const renderBodyModal = (type = '') => {
    if (type === 'confirmation') {
      return (
        <Box className="ModalConfirmationRestore__wrapper">
          <Box className="ModalConfirmationRestore__preview-title">
            <QboTypography>
              {t('dashboard_page.modal_restore.helper_modal_confirmation')}
            </QboTypography>
          </Box>
          <QboModalCardList>
            <QboModalListItem
              icon={<InsertDriveFileOutlinedIcon />}
              name={t('dashboard_page.modal_restore.item_text_label')}
              description1={itemSelectedText}
            />
            <QboModalListItem
              icon={<Inventory2OutlinedIcon />}
              name={t('dashboard_page.modal_restore.point_in_time_text_label')}
              description1={selectedTime}
            />
          </QboModalCardList>
          <Box className="ModalConfirmationRestore__notes">
            <div className="ModalConfirmationRestore__notes-label">
              <QboTypography>{t('dashboard_page.modal_restore.notes_text_label')}</QboTypography>
              <QboTypography>{t('dashboard_page.modal_restore.optional_text_label')}</QboTypography>
            </div>
            <QboTextField
              className="ModalConfirmationRestore__text-field"
              placeholder={t('dashboard_page.modal_restore.placeholder_notes')}
              value={notes}
              onChange={(e) => {
                setNotes(e.currentTarget.value);
              }}
              multiline
              rows={4}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          {!withoutSelectDate ? (
            <QboTipsBox
              className="ModalConfirmationRestore_tips-box-notes"
              __html={t('dashboard_page.modal_restore.tips_box_modal_confirmation_html')}
              variant="primary"
            />
          ) : (
            <>
              {infoTips ? (
                <QboTipsBox
                  className="ModalConfirmationRestore_tips-box-notes-item-version"
                  __html={infoTips}
                  variant="primary"
                />
              ) : null}
              <QboTipsBox
                className="ModalConfirmationRestore_tips-box-guides"
                __html={t('dashboard_page.modal_restore.tips_box_modal_restore_item_version_html')}
                variant="info"
                icon={<EmojiObjectsOutlinedIcon />}
                showButton
                classNameButton="ModalConfirmationRestore_tips-box-button"
                textButton={t('dashboard_page.modal_restore.tips_box_button_guide')}
                endIconButton={<ArrowUpwardIcon />}
                onClick={onOpenGuide}
              />
            </>
          )}
        </Box>
      );
    }

    return (
      <Box className="ModalRestore__wrapper">
        <Box className="ModalRestore__date-time">
          <QboDatePicker
            value={dateValue}
            onChange={setDateValue}
            placeholder="DD/MM/YYYY"
            minDate={minDateFormatted}
          />
          <QboTimePicker
            value={timeValue}
            onChange={setTimeValue}
            placeholder="HH:MM"
            minTime={minDateFormatted}
          />
        </Box>
        <QboTypography>{t('dashboard_page.modal_restore.helper_modal_date_time')}</QboTypography>
        <QboTipsBox
          className="ModalRestore_tips-box-notes"
          __html={t('dashboard_page.modal_restore.tips_box_modal_restore_html')}
          variant="info"
          icon={<EmojiObjectsOutlinedIcon />}
          showButton
          classNameButton="ModalRestore_tips-box-button"
          textButton={t('dashboard_page.modal_restore.tips_box_button_guide')}
          endIconButton={<ArrowUpwardIcon />}
          onClick={onOpenGuide}
        />
      </Box>
    );
  };

  return (
    <>
      <QboModal
        onClose={() => onClose()}
        open={showModalRestore}
        title={t('dashboard_page.modal_restore.title_modal_restore')}
        button={{
          text: t('dashboard_page.modal_restore.next_text_button'),
          onClick: () => onNextButton(),
          disabled: !!(selectedTime === '' || selectedTime === null),
        }}
        preventOutsideClick>
        {renderBodyModal()}
        {children}
      </QboModal>
      <QboModal
        onClose={() => onClose()}
        open={showModalConfirmation}
        title={t('dashboard_page.modal_restore.title_modal_confirmation')}
        button={
          !withoutSelectDate
            ? [
                {
                  text: t('dashboard_page.modal_restore.back_text_button'),
                  onClick: () => onBackButton(),
                },
                {
                  text: t('dashboard_page.modal_restore.restore_text_button'),
                  onClick: () => onGenerateLink(),
                },
              ]
            : {
                text: t('dashboard_page.modal_restore.restore_text_button'),
                onClick: () => onGenerateLink(),
                position: 'right',
                disabled: !(notes?.length >= 1),
              }
        }
        preventOutsideClick>
        {renderBodyModal('confirmation')}
        {children}
      </QboModal>
    </>
  );
}

ModalRestoreCardBlock.propTypes = {
  onClose: PropTypes.func,
  onNextButton: PropTypes.func,
  onBackButton: PropTypes.func,
  onGenerateLink: PropTypes.func,
  onOpenGuide: PropTypes.func,
  showModalRestore: PropTypes.bool,
  showModalConfirmation: PropTypes.bool,
  selectedTime: PropTypes.string,
  minDate: PropTypes.string,
  dateValue: PropTypes.object,
  setDateValue: PropTypes.func,
  timeValue: PropTypes.object,
  setTimeValue: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  itemSelectedText: PropTypes.string,
  withoutSelectDate: PropTypes.bool,
  infoTips: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ModalRestoreCardBlock.defaultProps = {
  onClose: () => {},
  onNextButton: () => {},
  onBackButton: () => {},
  onGenerateLink: () => {},
  onOpenGuide: () => {},
  showModalRestore: false,
  showModalConfirmation: false,
  selectedTime: '',
  minDate: '',
  dateValue: {},
  setDateValue: () => {},
  timeValue: {},
  setTimeValue: () => {},
  notes: '',
  setNotes: () => {},
  itemSelectedText: '',
  withoutSelectDate: false,
  infoTips: '',
  children: null,
};
