import * as React from 'react';

import PropTypes from 'prop-types';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDateTime } from '@hooks';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import { QboTextField } from '@ui/Components';

export default function QboDatePicker({ placeholder, value, onChange, minDate, ...rest }) {
  const { dateTimeNow } = useDateTime();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...rest}
        className={`QboDatePicker__default ${value ? 'QboDatePicker__with-value' : ''}`}
        value={value}
        maxDate={dateTimeNow()}
        minDate={minDate}
        mask="__-__-____"
        inputFormat="DD-MM-YYYY"
        onChange={(newValue) => {
          onChange(newValue);
        }}
        components={{
          OpenPickerIcon: CalendarMonthRoundedIcon,
        }}
        renderInput={(params) => {
          const { InputProps } = params;
          const attr = params;
          delete attr.InputProps;
          return <QboTextField {...attr} endAdornment={InputProps.endAdornment} />;
        }}
        inputProps={{
          placeholder,
          readOnly: true,
        }}
      />
    </LocalizationProvider>
  );
}

QboDatePicker.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  minDate: PropTypes.object,
};

QboDatePicker.defaultProps = {
  placeholder: '',
  value: {},
  onChange: () => {},
  minDate: {},
};
