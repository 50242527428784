import { useTranslation } from 'react-i18next';
import SystemStatus from '@libs/SystemStatus';
import useStatusFilter, {
  ButtonFilter as ButtonFilterConst,
} from '@pages/sharedHooks/useStatusFilter';

export const ButtonFilter = ButtonFilterConst;

const useStatusDownloadFilter = (onFilter) => {
  const { t } = useTranslation();
  const { listDownloadStatus } = SystemStatus;

  const listFilter = [
    {
      label: 'system_status.completed_text',
      status: listDownloadStatus.SUCCESS,
    },
    {
      label: 'system_status.expired_text_filter',
      status: listDownloadStatus.EXPIRED,
    },
    {
      label: 'system_status.generating_text',
      status: listDownloadStatus.RUNNING,
    },
    {
      label: 'system_status.no_data_text',
      status: listDownloadStatus.NO_DATA,
    },
  ];

  const headingText = t('status_download_page.search_block.filter_by_download_status_text');

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusFilter({ onFilter, listFilter, headingText });

  return {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  };
};

export default useStatusDownloadFilter;
