import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQboStatusReplicateAPI } from '@services/WebApiService';
import { canShowPagination } from '@utilities';

const qboFetchStatusReplicateAsync = createAsyncThunk(
  'QboStatusReplicate/fetchStatusReplicate',
  async ({ pageLink, query, top, skip, statuses }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchQboStatusReplicateAPI(accessToken, pageLink, {
      query,
      top,
      skip,
      statuses,
    });
    return data;
  }
);

const { pending, fulfilled, rejected } = qboFetchStatusReplicateAsync;

const qboFetchStatusReplicateExtraReducers = {
  [pending]: (state) => {
    state.fetchingQboStatusReplicate = true;
  },
  [rejected]: (state) => {
    state.fetchingQboStatusReplicate = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingQboStatusReplicate = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.value) {
      const showPagination = canShowPagination(state.QboStatusReplicate.showPagination, action);

      state.QboStatusReplicate = {
        value: action.payload.value,
        nextLink: action.payload.nextLink,
        prevLink: action.payload.prevLink,
        showPagination,
      };
    }
  },
};

export { qboFetchStatusReplicateAsync, qboFetchStatusReplicateExtraReducers };
