import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  QboButton,
  QboRightDrawer,
  QboDrawerListWrapper,
  QboDrawerListItem,
  QboTypography,
  QboDrawerBoxItem,
  QboAccordion,
  QboTipsBox,
} from '@ui/Components';
import ReplicateAva from '@images/generals/ReplicateAva.svg';
import { getStatusReplicateAsync } from '@features/qboStatusReplicate/qboStatusReplicateSlice';
import { useDateTime, usePageAlert, useAlertMessage } from '@hooks';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from '@utilities';
import ReplicateStatusItemBlock from '@ui/Blocks/Shared/ReplicateStatusItemBlock';
import ReplicateTargetItemBlock from '@ui/Blocks/Shared/ReplicateTargetItemBlock';
import useDrawerReplicateInfo from '@pages/SystemStatusPage/pageHooks/useDrawerReplicateInfo';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import Box from '@mui/material/Box';
import useQboOauth, { REACTIVATE_REPLICATE } from '@pages/sharedHooks/useQboOauth';
import { replicateStatusPath } from '@config/Routes/WebClientRoutes';

const defaultNull = '-';

export default function DrawerReplicateInfoSection({
  QboStatusReplicate,
  onClose,
  open,
  setSnackbarOpen,
  onClickDownload,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dateFormat, dateToString } = useDateTime();
  const { generalInformationPanel, labelInfoItem } = useDrawerReplicateInfo(QboStatusReplicate);
  const { updateAlert } = usePageAlert();
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();

  const { openOauthWindow, isButtonActive } = useQboOauth(REACTIVATE_REPLICATE, {
    qboReplicateId: QboStatusReplicate?.maskedId,
    environment: QboStatusReplicate?.environment,
    errorCallback: () => {
      onClose();
      updateAlert(
        getErrorMessage(konstMessage.ERROR_REACTIVATE_REPLICATE, {
          target_company_name: QboStatusReplicate?.targetCompanyName,
          environment: QboStatusReplicate?.environment,
        })
      );
      navigate(replicateStatusPath, { replace: true });
    },
    successCallback: () => {
      onClose();
      dispatch(getStatusReplicateAsync({ qboReplicateId: QboStatusReplicate?.maskedId }));
      updateAlert(
        getSuccessMessage(konstMessage.SUCCESS_REACTIVATE_REPLICATE, {
          target_company_name: QboStatusReplicate?.targetCompanyName,
          environment: QboStatusReplicate?.environment,
          source_company_name: QboStatusReplicate?.companyName,
        })
      );
      navigate(replicateStatusPath, { replace: true });
    },
  });

  const handleCopy = (statusId) => {
    const snackbarState = copyToClipboard(statusId);
    setSnackbarOpen(!!snackbarState);
  };

  return (
    <QboRightDrawer
      title={t('status_replicate_page.drawer_replicate_info_section.heading_text')}
      onClose={onClose}
      open={open}
      className="DrawerReplicateInfoSection__wrapper">
      <div className="DrawerReplicateInfoSection__content">
        <QboDrawerBoxItem
          icon={<img src={ReplicateAva} alt="" className="Img__fluid" />}
          label={QboStatusReplicate?.companyName || defaultNull}
          withArrow={false}>
          <div className="DrawerReplicateInfoSection__status-id">
            <QboTypography variant="caption">
              {t('status_backup_page.item_card.caption.status_id_label', {
                id: QboStatusReplicate?.id,
              }) || defaultNull}
            </QboTypography>
            <QboButton
              onClick={() => handleCopy(QboStatusReplicate?.id)}
              startIcon={<CopyIcon />}
            />
          </div>
        </QboDrawerBoxItem>

        <QboDrawerListWrapper>
          <ReplicateStatusItemBlock
            label={t('status_replicate_page.drawer_replicate_info_section.replicate_status_label')}
            status={QboStatusReplicate?.status}
            percentage={parseInt(QboStatusReplicate?.percentage, 10)}
          />

          {QboStatusReplicate?.hasErrorUrl && QboStatusReplicate?.attachmentFileName ? (
            <QboDrawerListItem
              icon={<AttachFileOutlinedIcon />}
              label={t('status_replicate_page.drawer_replicate_info_section.item_text_label')}>
              <QboTipsBox
                className="DrawerReplicateInfoSection_download-wrapper"
                __html={t(
                  'status_replicate_page.drawer_replicate_info_section.item_info_download_attachment'
                )}
                variant="info"
                showButton
                classNameButton="DrawerReplicateInfoSection_download-button"
                textButton={QboStatusReplicate?.attachmentFileName}
                endIconButton={<FileDownloadOutlinedIcon />}
                onClick={() => {
                  onClickDownload(QboStatusReplicate?.maskedId);
                }}
                withTitle
              />
            </QboDrawerListItem>
          ) : null}

          <QboDrawerListItem
            icon={<InsertDriveFileOutlinedIcon />}
            label={t('status_replicate_page.drawer_replicate_info_section.item_text_label')}>
            <QboTypography className="DrawerReplicateInfoSection__card-list-value" withTitle>
              {labelInfoItem}
            </QboTypography>
          </QboDrawerListItem>

          <QboDrawerListItem
            icon={<Inventory2OutlinedIcon />}
            label={t(
              'status_replicate_page.drawer_replicate_info_section.point_in_time_text_label'
            )}>
            <QboTypography className="DrawerReplicateInfoSection__card-list-value">
              {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusReplicate?.pointInTime) ||
                defaultNull}
            </QboTypography>
          </QboDrawerListItem>

          <ReplicateTargetItemBlock
            label={t('status_replicate_page.drawer_replicate_info_section.replicate_to_text_label')}
            status={QboStatusReplicate?.status}
            targetCompanyName={QboStatusReplicate.targetCompanyName}
            targetEmailAddress={QboStatusReplicate.targetEmailAddress}
            isReauthButtonActive={isButtonActive}
            onClickReauth={openOauthWindow}
            oauthTipsInfo={t(
              'status_replicate_page.drawer_replicate_info_section.reauth_description_html'
            )}
          />

          <QboDrawerListItem
            icon={<StorageRoundedIcon />}
            label={t('status_replicate_page.drawer_replicate_info_section.environment_text_label')}>
            <QboTypography className="DrawerReplicateInfoSection__card-list-value">
              {QboStatusReplicate.environment}
            </QboTypography>
          </QboDrawerListItem>

          {QboStatusReplicate?.notes ? (
            <Box className="DrawerReplicateInfoSection_tips-box-wrapper">
              <QboTipsBox
                className="DrawerReplicateInfoSection_tips-box-notes"
                __html={t(
                  'status_replicate_page.drawer_replicate_info_section.replicate_notes_html',
                  {
                    notes: QboStatusReplicate?.notes,
                  }
                )}
                variant="info"
                icon={<StickyNote2OutlinedIcon />}
              />
            </Box>
          ) : null}
        </QboDrawerListWrapper>
        <QboAccordion
          defaultExpanded
          collections={[...generalInformationPanel]}
          className="DrawerReplicateInfoSection__accordion-information"
        />
      </div>
    </QboRightDrawer>
  );
}

DrawerReplicateInfoSection.propTypes = {
  QboStatusReplicate: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setSnackbarOpen: PropTypes.func,
  onClickDownload: PropTypes.func,
};

DrawerReplicateInfoSection.defaultProps = {
  QboStatusReplicate: {},
  onClose: () => {},
  open: false,
  setSnackbarOpen: () => {},
  onClickDownload: () => {},
};
