import Storage from './Storage';

class LocalStorage extends Storage {
  /* eslint-disable class-methods-use-this */
  storageClient() {
    return localStorage;
  }

  save(key, value) {
    const keyName = this.keyName(key);
    this.storageClient().removeItem(keyName);
    this.storageClient().setItem(keyName, value);
    return true;
  }

  get(key) {
    const keyName = this.keyName(key);
    return this.storageClient().getItem(keyName);
  }

  destroy(key) {
    const keyName = this.keyName(key);
    this.storageClient().removeItem(keyName);
    return true;
  }

  static clear() {
    localStorage.clear();
    return true;
  }
}

export default LocalStorage;
