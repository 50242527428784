import React from 'react';
import PropTypes from 'prop-types';
import { QboCardWrapper } from '@ui/Components';
import ListingReplicateSection from '@ui/Sections/SystemStatusPage/ListingReplicateSection';
import useFetchStatusReplicate from '@pages/SystemStatusPage/pageHooks/useFetchStatusReplicate';
import useStatusReplicateFilter, {
  ButtonFilter,
} from '@pages/SystemStatusPage/pageHooks/useStatusReplicateFilter';
import DrawerFilterSection from '@ui/Sections/Shared/DrawerFilterSection';
import NoReplicateSection from '@ui/Sections/SystemStatusPage/NoReplicateSection';
import NoKeywordMatchSection from '@ui/Sections/SystemStatusPage/NoKeywordMatchSection';
import DrawerReplicateInfoSection from '@ui/Sections/SystemStatusPage/DrawerReplicateInfoSection';
import useDrawerReplicateInfo from '@pages/SystemStatusPage/pageHooks/useDrawerReplicateInfo';
import useDownloadErrorMessage, {
  TypeDownload,
} from '@pages/SystemStatusPage/pageHooks/useDownloadErrorMessage';

export default function StatusReplicateSection({ setSnackbarOpen }) {
  const {
    hasReplicateStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    QboStatusReplicate,
    fetchingQboStatusReplicate,
  } = useFetchStatusReplicate();

  const { drawerIsOpen, setOpenDrawer, closeDrawer, QboStatusReplicateItem } =
    useDrawerReplicateInfo();

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusReplicateFilter(onFilter);

  const { onClickDownload } = useDownloadErrorMessage(TypeDownload.ReplicateErrorMessage);

  return (
    <>
      {!fetchingQboStatusReplicate && !hasReplicateStatuses && !keyword ? (
        <NoReplicateSection />
      ) : null}
      {!fetchingQboStatusReplicate && !hasReplicateStatuses && keyword?.length > 0 ? (
        <NoKeywordMatchSection />
      ) : null}

      {(fetchingQboStatusReplicate || hasReplicateStatuses) && (
        <QboCardWrapper>
          <ListingReplicateSection
            onClickRow={setOpenDrawer}
            loading={fetchingQboStatusReplicate}
            collection={QboStatusReplicate.value}
            hasNextLink={QboStatusReplicate.nextLink}
            hasPrevLink={QboStatusReplicate.prevLink}
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            onChangePageNumber={onChangePageNumber}
            pageNumber={pageNumber}
            showPagination={QboStatusReplicate.showPagination}
            onFilter={onFilter}
            searchKeyword={keyword}
            setSearchKeyword={setKeyword}
            displaySearchKeyword={displayKeyword}
            handleOpenDrawer={handleOpenDrawer}
            listBadges={listBadges}
            handleDelete={handleDelete}
          />
        </QboCardWrapper>
      )}

      <DrawerFilterSection
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        handleFilter={handleFilter}
        ButtonFilter={ButtonFilter}
        filterData={filterData}
      />

      <DrawerReplicateInfoSection
        onClose={closeDrawer}
        QboStatusReplicate={QboStatusReplicateItem}
        open={drawerIsOpen}
        setSnackbarOpen={setSnackbarOpen}
        onClickDownload={onClickDownload}
      />
    </>
  );
}

StatusReplicateSection.propTypes = {
  setSnackbarOpen: PropTypes.func,
};

StatusReplicateSection.defaultProps = {
  setSnackbarOpen: () => {},
};
