import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { QboTypography } from '@ui/Components';

const circularProgress = () => {
  return (
    <div style={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#F2F4F6',
        }}
        size={56}
        thickness={4}
        value={100}
        data-testid="progress"
      />

      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#1C63D5',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={56}
        thickness={4}
      />
    </div>
  );
};

export default function QboSpinnerProgress({ sx, fullWrapper, label, className }) {
  return (
    <div
      style={sx}
      className={`QboSpinnerProgress__wrapper ${className} ${
        fullWrapper ? 'QboSpinnerProgress__full-wrapper' : ''
      }`}>
      {circularProgress()}
      {label && <QboTypography>{label}</QboTypography>}
    </div>
  );
}

QboSpinnerProgress.propTypes = {
  fullWrapper: PropTypes.bool,
  label: PropTypes.string,
  sx: PropTypes.object,
  className: PropTypes.string,
};

QboSpinnerProgress.defaultProps = {
  fullWrapper: true,
  label: null,
  sx: {},
  className: '',
};
