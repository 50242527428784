import React from 'react';
import { QboCard, QboCardCaption } from '@ui/Components';
import NoDownloadStatus from '@images/generals/NoDownloadStatus.svg';
import { useTranslation } from 'react-i18next';

export default function NoDownloadSection() {
  const { t } = useTranslation();

  return (
    <QboCard noPadding isMainWrapper>
      <QboCardCaption
        imageUrl={NoDownloadStatus}
        heading={t('status_download_page.no_download_section.heading_text')}
        caption={t('status_download_page.no_download_section.caption_text')}
      />
    </QboCard>
  );
}
