import { createAsyncThunk } from '@reduxjs/toolkit';
import { backupNowAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';
import SystemStatus from '@libs/SystemStatus';

const { listBackupStatus } = SystemStatus;

const qboBackupNowRealmAsync = createAsyncThunk(
  'QboObjects/backupNowRealm',
  async ({ qboRealmId }, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await backupNowAPI(accessToken, qboRealmId);
    return data;
  }
);

const { fulfilled } = qboBackupNowRealmAsync;

const qboBackupNowRealmExtraReducers = {
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload.status === HttpResponse.success) {
      state.QboObjects.backupStatus = listBackupStatus.RUNNING;
    }
  },
};

export { qboBackupNowRealmAsync, qboBackupNowRealmExtraReducers };
