import { createAsyncThunk } from '@reduxjs/toolkit';
import { logoutAPI } from '@services/WebApiService';

const authLogoutAsync = createAsyncThunk('Auth/logoutRequest', async (payload, { getState }) => {
  const { accessToken } = getState().auth;
  const response = await logoutAPI(accessToken);
  return response.data;
});

const { pending, fulfilled, rejected } = authLogoutAsync;

const authLogoutExtraReducers = {
  [pending]: (state) => {
    state.isLogouting = true;
  },
  [fulfilled]: (state) => {
    state.isUserSignedIn = false;
    state.isLogouting = false;
    state.accessToken = undefined;
  },
  [rejected]: (state) => {
    state.isUserSignedIn = false;
    state.isLogouting = false;
    state.accessToken = undefined;
  },
};

export { authLogoutAsync, authLogoutExtraReducers };
