/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboRightDrawer } from '@ui/Components';
import ReplicateGuideSection from './ReplicateGuideSection';

export default function DrawerReplicateGuideSection({ onClose, open }) {
  const { t } = useTranslation();

  return (
    <QboRightDrawer
      className="DrawerReplicateGuideSection__wrapper"
      title={t('dashboard_page.drawer_replicate_guide_section.heading_text')}
      onClose={onClose}
      open={open}
      showOnTop
      variant="persistent"
      hideBackdrop>
      <ReplicateGuideSection />
    </QboRightDrawer>
  );
}

DrawerReplicateGuideSection.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

DrawerReplicateGuideSection.defaultProps = {
  onClose: () => {},
  open: false,
};
