import { useEffect } from 'react';
import { clearSnackbar, setSnackbar } from '@features/application/applicationSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const success = 'success';
const error = 'error';

const usePageSnackbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { snackbar } = useSelector((state) => state.application);

  const closeSnackbar = () => {
    dispatch(clearSnackbar());
  };

  const isSnackbarOpen = () => {
    return !!snackbar?.message;
  };

  const updateSnackbar = (payload) => {
    if (
      !snackbar ||
      snackbar?.type !== payload?.type ||
      snackbar?.message !== payload?.message ||
      snackbar?.heading !== payload?.heading
    )
      dispatch(setSnackbar({ ...payload, path: location.pathname }));
  };

  const updateSuccessSnackbar = (payload) => {
    updateSnackbar({ ...payload, type: success });
  };

  const updateErrorSnackbar = (payload) => {
    updateSnackbar({ ...payload, type: error });
  };

  useEffect(() => {
    // will remove the alert
    // if alert is exist
    // and switch between page
    return () => {
      closeSnackbar();
    };
  }, []);

  return {
    isSnackbarOpen: isSnackbarOpen(),
    snackbar,
    closeSnackbar,
    updateSnackbar,
    updateSuccessSnackbar,
    updateErrorSnackbar,
  };
};

export default usePageSnackbar;
