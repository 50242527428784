import React from 'react';
import PropTypes from 'prop-types';
import {
  QboCardListItemStatus,
  QboTypography,
  QboHighlighter,
  QboLinearProgress,
} from '@ui/Components';
import DownloadAva from '@images/generals/DownloadAva.svg';
import { useDateTime } from '@hooks';
import useSystemStatus, { listDownloadStatus } from '@hooks/useSystemStatus';
import { t } from 'i18next';

const defaultNull = '-';

/* eslint-disable react/no-danger */
export default function StatusDownloadCardItemBlock({
  QboStatusDownload,
  searchKeyword,
  onClickRow,
  onClickDownload,
}) {
  const { dateToString, dateFormat, dateDistance, dateTimeNow } = useDateTime();
  const { badgeDownloadStatusRender } = useSystemStatus({
    status: QboStatusDownload.status,
    withIcon: true,
    withInfoIcon: false,
    onClickDownload: listDownloadStatus.SUCCESS ? onClickDownload : () => {},
  });

  let elapsedTime = defaultNull;

  if (QboStatusDownload?.startTime) {
    const endTime = QboStatusDownload?.endTime || dateTimeNow();
    elapsedTime =
      dateDistance(QboStatusDownload?.startTime, endTime, {
        separator: ' ',
      }) || '1s';
  }

  const backupInfoHTML = () => {
    const info = [
      t('status_backup_page.item_card.caption.status_id_label', {
        id: QboStatusDownload?.id,
      }),
      t('status_backup_page.item_card.caption.started_at_label', {
        date:
          dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusDownload?.startTime) || defaultNull,
      }),
    ];

    if (QboStatusDownload?.status !== listDownloadStatus.EXPIRED) {
      // elapsed_at_label if not expired
      info.push(
        t('status_backup_page.item_card.caption.elapsed_at_label', {
          date: elapsedTime || defaultNull,
        })
      );
    }

    if (QboStatusDownload?.status === listDownloadStatus.RUNNING && !QboStatusDownload?.startTime) {
      // calculating
      info.push(`${t('system_status.calculating_text')}...`);
    } else if (QboStatusDownload?.status === listDownloadStatus.RUNNING) {
      // running info
      info.push(
        t('system_status.completed.percentage_text_all', {
          number: QboStatusDownload?.percentage,
        })
      );
    } else {
      // fallback
      info.push(
        t('status_backup_page.item_card.caption.generated_at_label', {
          date:
            dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusDownload?.endTime) || defaultNull,
        })
      );
    }

    if (QboStatusDownload?.status === listDownloadStatus.EXPIRED) {
      // expired
      info.push(
        t('status_backup_page.item_card.caption.expired_at_label', {
          date:
            dateToString(dateFormat.DEFAULT_DATE_TIME, QboStatusDownload?.expireTime) ||
            defaultNull,
        })
      );
    }

    return info.join(' | ');
  };

  return (
    <QboCardListItemStatus
      onClick={() => {
        if (onClickRow) onClickRow(QboStatusDownload.id);
      }}
      className="StatusCardItemBlock__wrapper StatusCardItemBlock__active"
      justifyContent="center"
      alignItems="center"
      leftContent={<img src={DownloadAva} alt="DownloadAva" className="Img__fluid" />}
      rightContent={
        <div className="StatusCardItemBlock__right-content">{badgeDownloadStatusRender()}</div>
      }
      buttonRightContent={
        QboStatusDownload?.status === listDownloadStatus.RUNNING && (
          <QboLinearProgress
            progress={parseInt(QboStatusDownload?.percentage, 10)}
            className="StatusCardItemBlock__running-progress"
            processing={Boolean(!QboStatusDownload?.startTime)}
          />
        )
      }>
      <div className="StatusCardItemBlock__title">
        <QboTypography variant="subtitle2">
          {searchKeyword !== '' ? (
            <QboHighlighter searchWord={searchKeyword} sentences={QboStatusDownload?.companyName} />
          ) : (
            QboStatusDownload?.companyName
          )}
        </QboTypography>
      </div>

      <div
        className="StatusCardItemBlock__info QboCardListItem__info"
        dangerouslySetInnerHTML={{ __html: backupInfoHTML() }}
      />
    </QboCardListItemStatus>
  );
}

StatusDownloadCardItemBlock.propTypes = {
  QboStatusDownload: PropTypes.object.isRequired,
  searchKeyword: PropTypes.string,
  onClickRow: PropTypes.func,
  onClickDownload: PropTypes.func,
};

StatusDownloadCardItemBlock.defaultProps = {
  searchKeyword: '',
  onClickRow: () => {},
  onClickDownload: () => {},
};
