import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* eslint-disable react/no-danger */
export default function QboLastBackupText({ date }) {
  const { t } = useTranslation();

  return (
    <p
      className="QboLastBackupText__wrapper"
      dangerouslySetInnerHTML={{ __html: t('label.last_backup_text_date_html', { date }) }}
    />
  );
}

QboLastBackupText.propTypes = {
  date: PropTypes.string.isRequired,
};
