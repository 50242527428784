import { useDispatch, useSelector } from 'react-redux';
import { changePasswordAsync, resetChangePassword } from '@features/account/accountSlice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlertMessage, usePageAlert } from '@hooks';
import useChangePasswordValidation from './useChangePasswordValidation';

export default function useChangePasswordForm() {
  const { t } = useTranslation();
  const { changePassword } = useSelector((state) => state.account);
  const { success, error, submitting } = changePassword;
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const {
    currentPasswordAttributes,
    newPasswordAttributes,
    confirmationPasswordAttributes,
    isValid,
    validateAll,
    resetForm,
  } = useChangePasswordValidation();

  const dispatch = useDispatch();

  const listRequirement = [
    {
      id: 'minChar',
      success:
        newPasswordAttributes?.errorDetails?.MIN_CHAR_LENGTH &&
        newPasswordAttributes?.errorDetails?.MIN_CHAR_LENGTH,
      desc: t('account_settings_change_password_page.form_requirement.requirement_length_text'),
    },
    {
      id: 'hasUpperCase',
      success: newPasswordAttributes?.errorDetails?.HAS_UPCASE,
      desc: t('account_settings_change_password_page.form_requirement.requirement_uppercase_text'),
    },
    {
      id: 'hasLowerCase',
      success: newPasswordAttributes?.errorDetails?.HAS_LOWCASE,
      desc: t('account_settings_change_password_page.form_requirement.requirement_lowercase_text'),
    },
    {
      id: 'hasNumber',
      success: newPasswordAttributes?.errorDetails?.HAS_NUMBER,
      desc: t('account_settings_change_password_page.form_requirement.requirement_number_text'),
    },
    {
      id: 'hasSymbol',
      success: newPasswordAttributes?.errorDetails?.HAS_SYMBOL,
      desc: t('account_settings_change_password_page.form_requirement.requirement_symbol_text'),
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();

    if (validateAll() && !submitting) {
      dispatch(
        changePasswordAsync({
          currentPassword: currentPasswordAttributes.value,
          newPassword: newPasswordAttributes.value,
          confirmationPassword: confirmationPasswordAttributes.value,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetChangePassword());
    };
  }, []);

  useEffect(() => {
    if (success === true) {
      updateSuccessAlert(getSuccessMessage(konstMessage.SUCCESS_CHANGE_PASSWORD));
      dispatch(resetChangePassword());
      resetForm();
    } else if (error) {
      updateErrorAlert(getErrorMessage(konstMessage.ERROR_CHANGE_PASSWORD_INCORRECT));
    }
  }, [success, error]);

  return {
    listRequirement,
    currentPasswordAttributes,
    newPasswordAttributes,
    confirmationPasswordAttributes,
    onSubmit,
    isValid,
    submitting,
  };
}
