import React from 'react';
import { useTranslation } from 'react-i18next';
import SystemStatus from '@libs/SystemStatus';
import { QboTypography } from '@ui/Components';
import { Box } from '@mui/material';
import useStatusFilter, {
  ButtonFilter as ButtonFilterConst,
} from '@pages/sharedHooks/useStatusFilter';

const CreatedAtColumn = 'CreatedAt';
const CompanyNameColumn = 'CompanyName';

export const ButtonFilter = ButtonFilterConst;

const useRealmsOperation = (onFilter) => {
  const { t } = useTranslation();
  const { listBackupStatus } = SystemStatus;

  const listSort = [
    {
      label: 'dashboard_page.search_block.sort_by_text_title',
      order: '',
      orderBy: '',
    },
    {
      label: 'dashboard_page.search_block.sort_by_text_newest_oldest',
      order: 'desc',
      orderBy: CreatedAtColumn,
    },
    {
      label: 'dashboard_page.search_block.sort_by_text_oldest_newest',
      order: 'asc',
      orderBy: CreatedAtColumn,
    },
    {
      label: 'dashboard_page.search_block.sort_by_text_name_A_Z',
      order: 'asc',
      orderBy: CompanyNameColumn,
    },
    {
      label: 'dashboard_page.search_block.sort_by_text_name_Z_A',
      order: 'desc',
      orderBy: CompanyNameColumn,
    },
  ];

  const listFilter = [
    {
      label: 'system_status.completed_text',
      status: listBackupStatus.SUCCESS,
    },
    {
      label: 'system_status.running_text',
      status: listBackupStatus.RUNNING,
    },
    {
      label: 'system_status.authentication_error_text',
      status: listBackupStatus.AUTHENTICATION_ERROR,
    },
    {
      label: 'system_status.deactivated_text',
      status: listBackupStatus.DEACTIVATED,
    },
  ];

  const headingText = t('dashboard_page.search_block.filter_by_backup_status_text');

  const listSortOptions = listSort.map((item) => {
    return [
      item,
      <Box>
        <QboTypography>{t(item.label)}</QboTypography>
      </Box>,
    ];
  });

  const {
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  } = useStatusFilter({ onFilter, listFilter, headingText });

  return {
    listSort,
    listSortOptions,
    filterData,
    showDrawer,
    setShowDrawer,
    handleFilter,
    handleOpenDrawer,
    listBadges,
    handleDelete,
  };
};

export default useRealmsOperation;
