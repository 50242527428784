import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAlertMessage, usePageAlert, useDateTime } from '@hooks';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { qboReplicateRealmToolsAsync } from '@features/QboRealmTools/QboRealmToolsSlice';
import ErrorResponse from '@libs/ErrorResponse';

const PRODUCTION = 'Production';
const SANDBOX = 'Sandbox';

export const listEnvironment = [
  {
    label: 'dashboard_page.modal_replicate.environment_type.production',
    value: PRODUCTION,
  },
  {
    label: 'dashboard_page.modal_replicate.environment_type.sandbox',
    value: SANDBOX,
  },
];

const useRealmReplicate = (props) => {
  const dispatch = useDispatch();
  const { onCloseGuide } = props;
  const { dateToString, dateFormat, mergeDateAndTime, toISOFormat } = useDateTime();
  const { QboRealms } = useSelector((state) => state.qboRealms);
  const { error, submitting, success } = useSelector((state) => state.qboRealmsTools.replicate);

  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();

  const [selectedRealmId, setSelectedRealmId] = useState(null);
  const [showModalReplicate, setShowModalReplicate] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [showModalConfirmationReplicate, setShowModalConfirmationReplicate] = useState(false);
  const [showModalConfirmationValidationReplicate, setShowModalConfirmationValidationReplicate] =
    useState(false);
  const selectedReplicateData = QboRealms.value.find((r) => r.id === selectedRealmId);
  const [notes, setNotes] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [environment, setEnvironment] = useState(listEnvironment[0]);
  const [checkboxConfirmation, setCheckboxConfirmation] = useState(false);
  const [checkboxFollowGuide, setCheckboxFollowGuide] = useState(false);
  const [textConfirmation, setTextConfirmation] = useState('');
  const [responseOAuth, setResponseOAuth] = useState({});

  const getErrorMessages = (errorCode) => {
    let message = '';
    if (errorCode === ErrorResponse.TargetReplicateSameAsSource) {
      message = 'dashboard_page.modal_replicate.error_message_same_as_company';
    } else if (errorCode === ErrorResponse.TargetReplicateStillOnProgress) {
      message = 'dashboard_page.modal_replicate.error_message_on_progress';
    } else {
      message = 'dashboard_page.modal_replicate.error_message_general';
    }

    return message;
  };

  const checkFormIsValid = () => {
    return (
      dateValue &&
      timeValue &&
      environment?.label &&
      checkboxConfirmation &&
      checkboxFollowGuide &&
      responseOAuth?.success === true
    );
  };

  const getTime = (date = dateValue, time = timeValue) => {
    if (date && time) {
      const dateTime = mergeDateAndTime(date, time);
      setSelectedTime(toISOFormat(dateTime));
      return dateTime;
    }
    return '';
  };

  const resetState = () => {
    setSelectedRealmId(null);
    setShowModalReplicate(false);
    setShowModalConfirmationReplicate(false);
    setShowModalConfirmationValidationReplicate(false);
    setSelectedRealmId({});
    setDateValue(null);
    setTimeValue(null);
    setSelectedTime(null);
    setNotes('');
    setCheckboxConfirmation(false);
    setCheckboxFollowGuide(false);
    setTextConfirmation('');
    setResponseOAuth({});
    onCloseGuide();
  };

  const onGenerateLink = () => {
    const payload = {
      pointInTime: selectedTime,
      notes,
      maskedSourceQboRealmId: selectedRealmId,
      maskedTargetQboAuthRequestId: responseOAuth?.maskedQboAuthRequestId,
      type: 'Realm',
      confirmationText: textConfirmation,
      environment: environment?.value,
      confirmationEmptyCompany: checkboxConfirmation,
      confirmationFollowGuide: checkboxFollowGuide,
    };
    dispatch(qboReplicateRealmToolsAsync(payload));
    resetState();
    setRequesting(true);
    return '';
  };

  useEffect(() => {
    if (success && requesting) {
      setRequesting(false);

      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_REPLICATE, {
          path: ClientRoutes.replicateStatusPath(),
        }),
        html: true,
      };

      updateSuccessAlert(alertPayload);
    } else if (error && requesting) {
      let alertPayload = {};

      if (error === ErrorResponse.TargetReplicateSameAsSource) {
        alertPayload = {
          ...getErrorMessage(konstMessage.ERROR_REPLICATE_SAME_COMPANY, {}),
        };
      } else if (error === ErrorResponse.TargetReplicateStillOnProgress) {
        alertPayload = {
          ...getErrorMessage(konstMessage.ERROR_REPLICATE_ON_PROGRESS, {}),
        };
      } else if (error === ErrorResponse.UserNotAcceptedTermsAndConditions) {
        alertPayload = {
          ...getErrorMessage(konstMessage.ERROR_REPLICATE_SUBMISSION_INCOMPLETE, {}),
        };
      } else {
        alertPayload = {
          ...getErrorMessage(konstMessage.ERROR_REPLICATE, {}),
        };
      }

      updateErrorAlert(alertPayload);
    }
  }, [submitting]);

  return {
    showModalReplicate,
    onOpen: (realmId) => {
      setSelectedRealmId(realmId);
      setShowModalReplicate(true);
    },
    onClose: () => {
      resetState();
    },
    timeValue,
    setTimeValue: (value) => {
      setTimeValue(value);
      getTime(...Array(1), value);
    },
    dateValue,
    setDateValue: (value) => {
      setDateValue(value);
      getTime(value);
    },
    selectedTimeReplicate: dateToString(
      dateFormat.DEFAULT_FULL_DATE_TIME_WITHOUT_DAY_NAME,
      selectedTime
    ),
    onNextButtonReplicate: (validation) => {
      setShowModalReplicate(false);
      if (validation) {
        setShowModalConfirmationReplicate(false);
        setShowModalConfirmationValidationReplicate(true);
      } else {
        setShowModalConfirmationReplicate(true);
      }
    },
    showModalConfirmationReplicate,
    onBackButtonReplicate: (validation) => {
      setShowModalConfirmationValidationReplicate(false);
      if (validation) {
        setShowModalConfirmationReplicate(true);
      } else {
        setShowModalConfirmationReplicate(false);
        setShowModalReplicate(true);
      }
    },
    selectedReplicateData,
    replicateNotes: notes,
    setReplicateNotes: setNotes,
    onGenerateReplicate: onGenerateLink,
    environment,
    setEnvironment: (selected) => {
      setEnvironment(selected);
    },
    checkboxConfirmation,
    setCheckboxConfirmation: (event) => {
      setCheckboxConfirmation(event.target.checked);
    },
    checkboxFollowGuide,
    setCheckboxFollowGuide: (event) => {
      setCheckboxFollowGuide(event.target.checked);
    },
    checkFormIsValid: checkFormIsValid(),
    showModalConfirmationValidationReplicate,
    textConfirmation,
    setTextConfirmation,
    responseOAuth,
    setResponseOAuth,
    getErrorMessages,
  };
};

export default useRealmReplicate;
