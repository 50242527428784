import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { QboButton, QboCard, QboCardListItem } from '@ui/Components';
import { Add as AddIcon } from '@mui/icons-material';
import UsageIconSvg from '@images/generals/DashboardPage-UsageIcon.svg';
import { newQuickbooksPath } from '@config/Routes/WebClientRoutes';
import { Skeleton } from '@mui/material';

/* eslint-disable react/no-danger */
export default function UsageCardSection({ seats, loading }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <QboCard
      noPadding
      isMainWrapper
      className="DashboardUsageCard__wrapper"
      title={t('dashboard_page.usage_card.title')}>
      <QboCardListItem
        justifyContent="center"
        alignItems="center"
        leftContent={<img src={UsageIconSvg} alt="DashboardUsage" className="Img__fluid" />}
        rightContent={
          <QboButton
            size="large"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => navigate(newQuickbooksPath)}>
            {t('dashboard_page.button_add')}
          </QboButton>
        }>
        {loading && <Skeleton variant="text" width={200} height={30} />}

        {!loading && (
          <h2
            dangerouslySetInnerHTML={{
              __html: t('dashboard_page.usage_card.usage_html', {
                current_number: seats,
              }),
            }}
          />
        )}
      </QboCardListItem>
    </QboCard>
  );
}

UsageCardSection.propTypes = {
  seats: PropTypes.number,
  loading: PropTypes.bool,
};

UsageCardSection.defaultProps = {
  seats: 0,
  loading: false,
};
