import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { qboFetchObjectItemsAsync, resetState } from '@features/qboObjectItems/qboObjectItemsSlice';
import { QboLink, QboHighlighter, QboTypography } from '@ui/Components';
import { constant } from '@config/BaseSetting';
import { pascalToTitleCase } from '@utilities';
import {
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  InsertPageBreak as InsertPageBreakIcon,
} from '@mui/icons-material';
import { useDateTime } from '@hooks';
import usePagination, { features } from '@hooks/usePagination';

const fileIconColumn = 'fileIcon';
const itemNameColumn = 'itemName';
const updatedAtColumn = 'updatedAt';
const arrowIconColumn = 'arrowIcon';
const objectNameColumn = 'displayObjectName';
const deletedStatusColumn = 'deletedStatus';

const defaultOrder = 'asc';
const defaultOrderColumn = 'itemName';

const createHeader = (id, label, columnName, orderable, size, align) => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: id === 1,
    align,
    allowColspan: id === 1,
    noPadding: columnName === fileIconColumn,
  };
};

const { paginationCache } = constant;

const useSearchObjectItems = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { dateToString, dateFormat } = useDateTime();
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderColumn);
  const [hasInitiazedRequest, setHasInitiazedRequest] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { QboObjectItems, fetchingQboObjectItems } = useSelector((state) => state.qboObjectItems);

  const hasPageInitialized = isApplicationReady && hasInitiazedRequest;

  const {
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    pageNumber,
    keyword,
    setKeyword,
    displayKeyword,
    onFilter,
  } = usePagination(
    QboObjectItems,
    qboFetchObjectItemsAsync,
    paginationCache.DASBOARD_BACKUP_REALMS_OBJECT_ITEMS,
    hasPageInitialized,
    {
      qboRealmId: id,
      order,
      orderBy,
    },
    [features.pagination, features.search, features.filtering]
  );

  /* eslint-disable react/no-danger */
  const searchTableCaption = (__html) => {
    return {
      fileIcon: <div dangerouslySetInnerHTML={{ __html }} />,
      itemName: '{{colspan}}',
      displayObjectName: '{{colspan}}',
      updatedAt: '{{colspan}}',
      arrowIcon: '{{colspan}}',
      deletedStatus: '{{colspan}}',
      colspan: 6,
      active: true,
      component: 'th',
      isLink: false,
      summaryRow: true,
    };
  };

  const createData = (itemName, objectName, updatedAt, lastVersionId, maskedId, deletedItem) => {
    return {
      id: maskedId,
      fileIcon: deletedItem ? (
        <InsertPageBreakIcon className="QboTable__icon" />
      ) : (
        <InsertDriveFileOutlinedIcon className="QboTable__icon" />
      ),
      isLink: true,
      itemName: (
        <QboTypography variant="subtitle2" color="secondary" withTitle>
          <QboHighlighter searchWord={displayKeyword} sentences={itemName} />
        </QboTypography>
      ),
      objectName,
      maskedId,
      displayObjectName: (
        <QboTypography color="primary" withTitle>
          {pascalToTitleCase(objectName)}
        </QboTypography>
      ),
      lastVersionId,
      updatedAt: (
        <QboTypography color="primary" withTitle>
          {dateToString(dateFormat.DEFAULT_DATE_TIME, updatedAt)}
        </QboTypography>
      ),
      arrowIcon: (
        <QboLink className="QboTableCell__icon">
          <KeyboardArrowRightIcon />
        </QboLink>
      ),
      deletedStatus: deletedItem ? (
        <QboTypography withTitle color="primary">
          {t('backup_realm_object_page.table.deleted_column')}
        </QboTypography>
      ) : null,
      colspan: null,
    };
  };

  const tableHeaders = [
    createHeader(1, null, fileIconColumn, false, 'xxs', 'left'),
    createHeader(
      3,
      t('backup_realm_object_page.table.item_name_column'),
      itemNameColumn,
      true,
      'md',
      'left'
    ),
    createHeader(
      4,
      t('backup_realm_object_page.table.object_name_column'),
      objectNameColumn,
      false,
      'md',
      'left'
    ),
    createHeader(
      5,
      t('backup_realm_object_page.table.updated_at_column'),
      updatedAtColumn,
      true,
      'auto',
      'left'
    ),
    createHeader(6, null, deletedStatusColumn, false, 's', 'left'),
    createHeader(7, null, arrowIconColumn, false, 'icon', 'right'),
  ];

  const tableRows = QboObjectItems.value.map((item) =>
    createData(
      item.itemName,
      item.objectName,
      item.updatedAt,
      item.lastVersionId,
      item.maskedId,
      item.isDeletedByIntuit
    )
  );

  // add table caption
  if (displayKeyword && displayKeyword !== '') {
    tableRows.unshift(
      searchTableCaption(
        t('search_realm_object_item_page.table.search_result_caption', {
          keyword: displayKeyword,
        })
      )
    );
  }

  const qboFetchSearchItems = () => {
    dispatch(
      qboFetchObjectItemsAsync({
        qboRealmId: id,
        order,
        orderBy,
        top: pageNumber,
        query: keyword,
      })
    );
  };

  useEffect(() => {
    if (isApplicationReady) {
      qboFetchSearchItems();
      setHasInitiazedRequest(true);
    }

    return () => {
      setHasInitiazedRequest(false);
      dispatch(resetState());
    };
  }, [isApplicationReady]);

  useEffect(() => {
    if (hasPageInitialized) {
      dispatch(
        qboFetchObjectItemsAsync({
          qboRealmId: id,
          order,
          orderBy,
          top: pageNumber,
          query: keyword,
        })
      );
    }
  }, [order, orderBy]);

  const replaceSearchParams = (param) => {
    const encoded = encodeURI(param);
    const encodedParam = encoded?.replaceAll('%20', '+');
    window.history.replaceState(null, null, `?keyword=${encodedParam}`);
  };

  return {
    QboObjectItems,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableHeaders,
    tableRows,
    loading: fetchingQboObjectItems,
    qboRealmId: id,
    searchKeyword: keyword,
    searchResultLabel: displayKeyword,
    setSearchKeyword: setKeyword,
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    pageNumber,
    open,
    setOpen,
    replaceSearchParams,
    listIdOnPage: tableRows.map((x) => x.id).filter((x) => x !== undefined),
    onFilter,
  };
};

export default useSearchObjectItems;
