import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const OrganizationService = {
  fetchSeatUsageAPI: (accessToken) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.orgSeatUsagePath(),
    });
  },
};

export default OrganizationService;
