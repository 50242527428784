import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { qboDownloadRealmToolsAsync } from '@features/QboRealmTools/QboRealmToolsSlice';
import { useAlertMessage, usePageAlert, useDateTime, usePageSnackbar } from '@hooks';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useCheckboxTable from '@pages/sharedHooks/useCheckboxTable';
import { pushSpliceEntry } from '@utilities';
import ErrorResponse from '@libs/ErrorResponse';

const downloadTypeRealm = 'realm';
const downloadTypeObject = 'objects';
const downloadTypeItems = 'items';
const downloadTypeItemVersion = 'itemVersions';

const downloadType = {
  downloadTypeRealm,
  downloadTypeObject,
  downloadTypeItems,
  downloadTypeItemVersion,
};

const ConstantQBOPayloadType = {
  TypeRealm: 'Realm',
  TypeObject: 'Object',
  TypeItems: 'Item',
  TypeItemVersion: 'ItemVersion',
};

const typeNotifConst = 'snackbar';

const useRealmDownload = (
  props = {
    selectedListId: [],
    objectName: '',
    searchKeyword: '',
  }
) => {
  const dispatch = useDispatch();
  const { selectedListId, objectName, searchKeyword, companyName } = props;
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { updateSuccessAlert, updateErrorAlert } = usePageAlert();
  const { updateSuccessSnackbar, updateErrorSnackbar } = usePageSnackbar();
  const { dateToString, dateFormat, mergeDateAndTime, toISOFormat, sortArrayDate } = useDateTime();

  const [selectedRealmId, setSelectedRealmId] = useState({});
  const [showModalDateTime, setShowModalDateTime] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [timeValue, setTimeValue] = useState(null);
  const [notes, setNotes] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [typeNotif, setTypeNotif] = useState('');
  const [selectedItemVersion, setSelectedItemVersion] = useState({});

  const {
    error: downloadError,
    submitting,
    success: downloadSuccess,
  } = useSelector((state) => state.qboRealmsTools.download);
  const { QboRealms } = useSelector((state) => state.qboRealms);
  const { QboObjects } = useSelector((state) => state.qboObjects);
  const QboRealm = QboRealms.value.find((r) => r.id === selectedRealmId);
  const selectedCompanyName = companyName || QboRealm?.companyName;
  const { selectAllConst } = useCheckboxTable();

  const getTime = (date = dateValue, time = timeValue) => {
    if (date && time) {
      const dateTime = mergeDateAndTime(date, time);
      setSelectedTime(toISOFormat(dateTime));
      return dateTime;
    }
    return '';
  };

  const getMinDate = (arrayDate = [], defInitialBackupAt = '') => {
    let minDate = '';
    if (!selectedListId.includes(selectAllConst) && selectedListId?.length === 1) {
      const selectedArray = arrayDate.find((r) =>
        defInitialBackupAt ? r.maskedId === selectedListId[0] : r.objectName === selectedListId[0]
      );
      minDate = selectedArray?.initialBackupAt;
    } else {
      const sortedArray = sortArrayDate(
        arrayDate
          .filter((x) => {
            if (selectedListId.includes(selectAllConst)) {
              if (selectedListId?.length === 1) {
                return true;
              }
              return !selectedListId.includes(x.objectName);
            }
            return selectedListId.includes(x.objectName);
          })
          .map((x) => x?.initialBackupAt)
      );
      minDate = defInitialBackupAt || sortedArray[0];
    }
    return minDate;
  };

  const resetState = () => {
    setShowModalDateTime(false);
    setShowModalConfirmation(false);
    setDateValue(null);
    setTimeValue(null);
    setSelectedTime(null);
    setNotes('');
  };

  const actionDownloadRealm = async () => {
    const payload = {
      realmId: selectedRealmId,
      pointInTime: selectedTime,
      notes,
    };
    dispatch(qboDownloadRealmToolsAsync(payload));
    setRequesting(true);
    resetState();
  };

  const actionDownloadObject = async () => {
    const isSelectAll = selectedListId.includes(selectAllConst);
    const objectId = isSelectAll ? pushSpliceEntry(selectedListId, selectAllConst) : selectedListId;
    const payload = {
      type: downloadTypeObject,
      realmId: selectedRealmId,
      selectAll: isSelectAll,
      pointInTime: selectedTime,
      notes,
      ids: [],
    };
    if (isSelectAll) {
      payload.excludeIds = objectId;
    } else {
      payload.ids = objectId;
    }
    dispatch(qboDownloadRealmToolsAsync(payload));
    setRequesting(true);
    resetState();
  };

  const actionDownloadItems = async () => {
    const isSelectAll = selectedListId.includes(selectAllConst);
    const itemId = isSelectAll ? pushSpliceEntry(selectedListId, selectAllConst) : selectedListId;
    const payload = {
      type: downloadTypeItems,
      realmId: selectedRealmId,
      selectAll: isSelectAll,
      pointInTime: selectedTime,
      notes,
      objectName,
      keyword: searchKeyword,
      ids: [],
    };
    if (isSelectAll) {
      payload.excludeIds = itemId;
    } else {
      payload.ids = itemId;
    }
    dispatch(qboDownloadRealmToolsAsync(payload));
    setRequesting(true);
    resetState();
  };

  const actionDownloadItemVersion = async () => {
    const payload = {
      realmId: selectedRealmId,
      type: downloadTypeItemVersion,
      ids: [selectedItemVersion?.id],
      objectName,
      pointInTime: selectedTime,
      notes,
    };
    dispatch(qboDownloadRealmToolsAsync(payload));
    setRequesting(true);
    setTypeNotif(typeNotifConst);
    resetState();
  };

  const onGenerateLink = (type = downloadTypeRealm) => {
    switch (type) {
      case downloadTypeRealm:
        actionDownloadRealm();
        break;
      case downloadTypeObject:
        actionDownloadObject();
        break;
      case downloadTypeItems:
        actionDownloadItems();
        break;
      case downloadTypeItemVersion:
        actionDownloadItemVersion();
        break;
      default:
        actionDownloadRealm();
    }
    return '';
  };

  useEffect(() => {
    if (downloadSuccess && requesting) {
      setRequesting(false);

      const alertPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_DOWNLOAD, {
          path: ClientRoutes.downloadStatusPath({ keyword: selectedCompanyName }),
        }),
        html: true,
      };

      const bannerPayload = {
        ...getSuccessMessage(konstMessage.SUCCESS_BANNER_DOWNLOAD, {}),
      };

      if (typeNotif === typeNotifConst) {
        updateSuccessSnackbar(bannerPayload);
      } else {
        updateSuccessAlert(alertPayload);
      }
    } else if (downloadError && requesting) {
      setRequesting(false);

      let alertPayload = {};
      let bannerPayload = {};

      switch (downloadError) {
        case ErrorResponse.DownloadRequestAlreadyExists:
          alertPayload = {
            ...getErrorMessage(konstMessage.ERROR_DOWNLOAD_ALREADY_EXISTS, {
              path: ClientRoutes.downloadStatusPath({ keyword: selectedCompanyName }),
            }),
            html: true,
          };
          bannerPayload = {
            ...getErrorMessage(konstMessage.ERROR_BANNER_DOWNLOAD_ALREADY_EXISTS, {}),
          };
          break;
        case ErrorResponse.MaxDownloadLimitReached:
          alertPayload = { ...getErrorMessage(konstMessage.ERROR_DOWNLOAD_LIMITED_REACHED, {}) };
          bannerPayload = {
            ...getErrorMessage(konstMessage.ERROR_BANNER_DOWNLOAD_LIMITED_REACHED, {}),
          };
          break;
        default:
          alertPayload = { ...getErrorMessage(konstMessage.ERROR_DOWNLOAD_GENERAL, {}) };
          bannerPayload = { ...getErrorMessage(konstMessage.ERROR_BANNER_DOWNLOAD_GENERAL, {}) };
      }

      if (typeNotif === typeNotifConst) {
        updateErrorSnackbar(bannerPayload);
      } else {
        updateErrorAlert(alertPayload);
      }
    }
  }, [submitting]);

  return {
    showModalDateTime,
    showModalConfirmation,
    onClose: () => resetState(),
    onOpen: () => {
      setShowModalDateTime(true);
    },
    onNextButton: () => {
      setShowModalDateTime(false);
      setShowModalConfirmation(true);
    },
    onBackButton: () => {
      setShowModalDateTime(true);
      setShowModalConfirmation(false);
    },
    selectedRealmId,
    setSelectedRealmId,
    timeValue,
    setTimeValue: (value) => {
      setTimeValue(value);
      getTime(...Array(1), value);
    },
    dateValue,
    setDateValue: (value) => {
      setDateValue(value);
      getTime(value);
    },
    notes,
    setNotes,
    getTime,
    selectedTime: dateToString(dateFormat.DEFAULT_FULL_DATE_TIME_WITHOUT_DAY_NAME, selectedTime),
    setSelectedItemVersion: (value) => {
      setSelectedItemVersion(value);
      const newDate = value?.backupAt;
      setSelectedTime(newDate);
    },
    onGenerateLink,
    QboRealm,
    QboObjects,
    downloadType,
    ConstantQBOPayloadType,
    getMinDate,
  };
};

export default useRealmDownload;
