import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { qboFetchSearchItemsAsync, resetState } from '@features/qboSearchItems/qboSearchItemsSlice';
import { pascalToTitleCase } from '@utilities';
import { useSearchDelay } from '@hooks';
import BaseSetting from '@config/BaseSetting';

const keyGroupBy = 'displayObjectName';
const keyLabelOption = 'itemName';

const useSearchItemSuggestion = (onSelectItem) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const { QboSearchItems, fetchingQboSearchItems } = useSelector((state) => state.qboSearchItems);

  const qboFetchSearchItems = (q) => {
    dispatch(
      qboFetchSearchItemsAsync({
        qboRealmId: id,
        top: BaseSetting.suggestionSearchLimit,
        query: searchKeyword || q,
      })
    );
  };

  const suggestions = QboSearchItems.value.map((v) => {
    return {
      ...v,
      [keyGroupBy]: pascalToTitleCase(v.objectName).toUpperCase(),
    };
  });

  const selectItem = (item) => {
    if (onSelectItem && item)
      onSelectItem(item.lastVersionId, item.objectName, item.maskedId, false, true);
    setSearchKeyword('');
  };

  useSearchDelay(
    {
      actionFunc: () => {
        if (isApplicationReady) {
          qboFetchSearchItems(searchKeyword);
        }
      },
      resetFunc: () => {
        if (isApplicationReady) {
          qboFetchSearchItems();
        }
      },
    },
    searchKeyword,
    [isApplicationReady],
    0
  );

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (!fetchingQboSearchItems && loading) setLoading(false);
  }, [fetchingQboSearchItems]);

  useEffect(() => {
    if (!loading) setLoading(true);
  }, [searchKeyword]);

  const onSetSearchKeyword = (q) => {
    setSearchKeyword(q);
  };

  return {
    QboSearchItems,
    loadingSuggestion: loading,
    qboRealmId: id,
    searchKeyword,
    keyGroupBy,
    keyLabelOption,
    suggestions,
    onSelectItem: selectItem,
    setSearchKeyword: onSetSearchKeyword,
  };
};

export default useSearchItemSuggestion;
