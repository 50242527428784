import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QboDropdown, QboTypography, QboButton } from '@ui/Components';
import useAuthParam from '@pages/sharedHooks/useAuthParam';
import { ConstantFeature } from '@pages/SystemStatusPage/pageHooks/useTabsSystemStatus';

export default function RealmCardActionButtons({
  onClickMoreOption,
  onClickReplicate,
  onClickRestore,
  onClickDownload,
  moreOptions,
  status,
  lastBackupAt,
}) {
  const { t } = useTranslation();
  const disabled = !lastBackupAt;
  const { features } = useAuthParam();

  return (
    <div className="BackupListItemRightBlock__buttons-wrapper fade-in">
      {status && (
        <>
          <QboButton
            onClick={onClickReplicate}
            variant="outlined"
            disabled={disabled}
            withTitle={disabled}
            title={t('dashboard_page.backup_list_item_block.tooltips_button_text', {
              action: t('dashboard_page.backup_list_item_block.replicate_text_button'),
            })}
            hidden={!features.includes(ConstantFeature.REPLICATE)}>
            {t('dashboard_page.backup_list_item_block.replicate_text_button')}
          </QboButton>
          <QboButton onClick={onClickRestore} variant="outlined" hidden>
            {t('dashboard_page.backup_list_item_block.restore_text_button')}
          </QboButton>
          <QboButton
            onClick={onClickDownload}
            variant="outlined"
            disabled={disabled}
            withTitle={disabled}
            title={t('dashboard_page.backup_list_item_block.tooltips_button_text', {
              action: t('dashboard_page.backup_list_item_block.download_text_button'),
            })}
            hidden={!features.includes(ConstantFeature.DOWNLOAD)}>
            {t('dashboard_page.backup_list_item_block.download_text_button')}
          </QboButton>
        </>
      )}

      <QboDropdown
        menuItems={moreOptions}
        onClickItem={onClickMoreOption}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className="QboDropdown__button"
        dropdownClassName="QboMenuItem__with-danger-color">
        <QboTypography>{t('dashboard_page.backup_list_item_block.more_text_button')}</QboTypography>
      </QboDropdown>
    </div>
  );
}

RealmCardActionButtons.propTypes = {
  onClickDownload: PropTypes.func,
  onClickRestore: PropTypes.func,
  onClickReplicate: PropTypes.func,
  onClickMoreOption: PropTypes.func,
  moreOptions: PropTypes.array,
  status: PropTypes.string,
  lastBackupAt: PropTypes.string,
};

RealmCardActionButtons.defaultProps = {
  onClickDownload: () => {},
  onClickRestore: () => {},
  onClickReplicate: () => {},
  onClickMoreOption: () => {},
  moreOptions: [],
  status: '',
  lastBackupAt: '',
};
