//
// @params:
// keys: [String], list: [String], [Array[String]]
//
// eg:
// ['objectName'], ['satu', 'dua', 'tiga']
// ['number', 'objectName'], ['satu', 'dua', 'tiga']
// ['listing', 'objectName'], [ ['1', 'array'], ['2', 'dua'], ['3', 'tiga'] ]
// ['number', 'listing', 'objectName'], [ ['1', 'array'], ['2', 'dua'], ['3', 'tiga'] ]
//

const CONST_NUMBER = 'number';

export const arrayListToObject = (keys = [], list = []) => {
  if (!keys || !list) return [];
  if (keys.length === 0 || list.length === 0) return [];

  const objectEntries = [];

  list.forEach((l, listIndex) => {
    const entries = [];
    let anyNumberKey = false;

    if (typeof l !== 'object') {
      keys.forEach((k) => {
        if (k === CONST_NUMBER) {
          entries.push([k, listIndex + 1]);
        } else {
          entries.push([k, l]);
        }
      });
    } else {
      keys.forEach((k, keyIndex) => {
        if (k === CONST_NUMBER) {
          entries.push([k, listIndex + 1]);
          anyNumberKey = true;
        } else {
          const ensureKeyIndex = anyNumberKey ? keyIndex - 1 : keyIndex;
          entries.push([k, l[ensureKeyIndex]]);
        }
      });
    }

    objectEntries.push(Object.fromEntries(entries));
  });

  return objectEntries;
};

/* eslint-disable no-param-reassign */
export const flattenAttributes = (attributes) => {
  const flattenHelper = (currentObject, newAttributes, previousKeyName) => {
    const objectKeys = Object.keys(currentObject);

    objectKeys.forEach((key) => {
      const value = currentObject[key];

      if ((value && ![Object, Array].includes(value.constructor)) || !value) {
        let injectValue = value;

        if (injectValue === false) injectValue = 'false';
        if (injectValue === true) injectValue = 'true';
        if ([undefined, null].includes(injectValue)) injectValue = 'null';
        if (injectValue && injectValue.constructor !== String) injectValue = injectValue.toString();

        if (previousKeyName === null || previousKeyName === '') {
          newAttributes[key] = injectValue;
        } else if (key === null || key === '') {
          newAttributes[previousKeyName] = injectValue;
        } else {
          newAttributes[`${previousKeyName} - ${key}`] = injectValue;
        }
      } else if (previousKeyName === null || previousKeyName === '') {
        flattenHelper(value, newAttributes, key);
      } else {
        flattenHelper(value, newAttributes, `${previousKeyName} - ${key}`);
      }
    });
  };

  const newAttributes = {};
  flattenHelper(attributes, newAttributes, '');

  return newAttributes;
};

/* eslint-disable no-console */
export const asyncFlattenAttributes = (attributes) => {
  return new Promise((resolve) => {
    try {
      resolve(flattenAttributes(attributes));
    } catch (err) {
      console.error(err.message);
      resolve({});
    }
  });
};

// This function aims to push data into an array based on parameters,
// if the data already exists then the data in the array will be deleted
// and if there is no data it will be inputted.
export const pushSpliceEntry = (array, change) => {
  if (!Array.isArray(array)) return [];

  const newArray = [...array];
  const arrayPosition = array.indexOf(change);
  if (!newArray.includes(change)) {
    newArray.push(change);
  } else if (arrayPosition !== -1) {
    newArray.splice(arrayPosition, 1);
  }
  return newArray;
};

export const sliceArrayWithArray = (array, change) => {
  if (!Array.isArray(array)) return [];
  if (!Array.isArray(change)) return [];

  // eslint-disable-next-line func-names
  const newArray = array.filter(function (e) {
    return this.indexOf(e) < 0;
  }, change);
  return newArray;
};

export const loadMoreArray = (array, limit, skip) => {
  if (!Array.isArray(array)) return [];
  const newArray = [...array];
  return newArray.splice(0, limit + limit * skip);
};
