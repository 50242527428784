import { createAsyncThunk } from '@reduxjs/toolkit';
import { restoreCallbackAPI, restoreDetailCallbackAPI } from '@services/WebApiService';
import HttpResponse from '@libs/HttpResponse';
import ErrorResponse from '@libs/ErrorResponse';

const qboRestoreRealmToolsAsync = createAsyncThunk(
  'QboRealms/restoreRealm',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    let data;

    if (payload.type) {
      data = await restoreDetailCallbackAPI(accessToken, payload);
    } else {
      data = await restoreCallbackAPI(accessToken, payload);
    }
    return data;
  }
);

const { pending, fulfilled, rejected } = qboRestoreRealmToolsAsync;

const qboRestoreRealmToolsExtraReducers = {
  [pending]: (state) => {
    state.restore.submitting = true;
    state.restore.success = false;
    state.restore.error = null;
  },
  [rejected]: (state) => {
    state.restore.submitting = false;
    state.restore.error = ErrorResponse.GeneralError;
  },
  [fulfilled]: (state, action) => {
    if (action.payload.error) {
      state.restore.error = action.payload.error.code;
    } else if (action.payload.status === HttpResponse.success) {
      state.restore.success = true;
    }
    state.restore.submitting = false;
  },
};

export { qboRestoreRealmToolsAsync, qboRestoreRealmToolsExtraReducers };
