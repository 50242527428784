import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  QboModal,
  QboTypography,
  QboDatePicker,
  QboTimePicker,
  QboTextField,
} from '@ui/Components';
import Box from '@mui/material/Box';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useDateTime } from '@hooks';
import { QboModalListItem, QboModalCardList } from '@ui/Components/QboModal';

export default function ModalDownloadCardBlock({
  onClose,
  onNextButton,
  onBackButton,
  onGenerateLink,
  showModalDateTime,
  showModalConfirmation,
  selectedTime,
  timeValue,
  setTimeValue,
  dateValue,
  setDateValue,
  notes,
  setNotes,
  minDate,
  itemSelectedText,
  withoutSelectDate,
}) {
  const { t } = useTranslation();
  const { stringToDate, addDateTime } = useDateTime();
  const minDateFormatted = minDate ? stringToDate(minDate) : {};
  const addedMinDate = minDateFormatted ? addDateTime(minDateFormatted, 2, 'minutes') : {};
  addedMinDate?.second(0);

  const renderBodyModal = (type = '') => {
    if (type === 'confirmation') {
      return (
        <Box className="ModalConfirmation__wrapper">
          <Box className="ModalConfirmation__preview-title">
            <QboTypography>
              {t('dashboard_page.modal_download.helper_modal_confirmation')}
            </QboTypography>
          </Box>
          <QboModalCardList>
            <QboModalListItem
              icon={<InsertDriveFileOutlinedIcon />}
              name={t('dashboard_page.modal_download.item_text_label')}
              description1={itemSelectedText}
            />
            <QboModalListItem
              icon={<Inventory2OutlinedIcon />}
              name={t('dashboard_page.modal_download.point_in_time_text_label')}
              description1={selectedTime}
            />
          </QboModalCardList>
          <Box className="ModalConfirmation__notes">
            <div className="ModalConfirmation__notes-label">
              <QboTypography>{t('dashboard_page.modal_download.notes_text_label')}</QboTypography>
              <QboTypography>
                {t('dashboard_page.modal_download.optional_text_label')}
              </QboTypography>
            </div>
            <QboTextField
              className="ModalConfirmation__text-field"
              placeholder={t('dashboard_page.modal_download.placeholder_notes')}
              value={notes}
              onChange={(e) => {
                setNotes(e.currentTarget.value);
              }}
              multiline
              rows={4}
              inputProps={{ maxLength: 255 }}
            />
          </Box>
          <Box className="ModalConfirmation__description">
            <QboTypography>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('dashboard_page.modal_download.description_modal_confirmation_html'),
                }}
              />
            </QboTypography>
          </Box>
        </Box>
      );
    }

    return (
      <Box className="ModalDateTime__wrapper">
        <Box className="ModalDateTime__date-time">
          <QboDatePicker
            value={dateValue}
            onChange={setDateValue}
            placeholder="DD/MM/YYYY"
            minDate={addedMinDate}
          />
          <QboTimePicker
            value={timeValue}
            onChange={setTimeValue}
            placeholder="HH:MM"
            minTime={addedMinDate}
            valueFromDate={dateValue}
          />
        </Box>
        <QboTypography>{t('dashboard_page.modal_download.helper_modal_date_time')}</QboTypography>
        <Box className="ModalDateTime__description">
          <QboTypography>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('dashboard_page.modal_download.description_modal_date_time_html'),
              }}
            />
          </QboTypography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <QboModal
        onClose={() => onClose()}
        open={showModalDateTime}
        title={t('dashboard_page.modal_download.title_modal_date_time')}
        button={{
          text: t('dashboard_page.modal_download.next_text_button'),
          onClick: () => onNextButton(),
          disabled: !!(selectedTime === '' || selectedTime === null),
        }}
        preventOutsideClick>
        {renderBodyModal()}
      </QboModal>
      <QboModal
        onClose={() => onClose()}
        open={showModalConfirmation}
        title={t('dashboard_page.modal_download.title_modal_confirmation')}
        button={
          !withoutSelectDate
            ? [
                {
                  text: t('dashboard_page.modal_download.back_text_button'),
                  onClick: () => onBackButton(),
                },
                {
                  text: t('dashboard_page.modal_download.generate_link_text_button'),
                  onClick: () => onGenerateLink(),
                },
              ]
            : {
                text: t('dashboard_page.modal_download.generate_link_text_button'),
                onClick: () => onGenerateLink(),
                position: 'right',
              }
        }
        preventOutsideClick>
        {renderBodyModal('confirmation')}
      </QboModal>
    </>
  );
}

ModalDownloadCardBlock.propTypes = {
  onClose: PropTypes.func,
  onNextButton: PropTypes.func,
  onBackButton: PropTypes.func,
  onGenerateLink: PropTypes.func,
  showModalDateTime: PropTypes.bool,
  showModalConfirmation: PropTypes.bool,
  selectedTime: PropTypes.string,
  minDate: PropTypes.string,
  dateValue: PropTypes.object,
  setDateValue: PropTypes.func,
  timeValue: PropTypes.object,
  setTimeValue: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  itemSelectedText: PropTypes.string,
  withoutSelectDate: PropTypes.bool,
};

ModalDownloadCardBlock.defaultProps = {
  onClose: () => {},
  onNextButton: () => {},
  onBackButton: () => {},
  onGenerateLink: () => {},
  showModalDateTime: false,
  showModalConfirmation: false,
  selectedTime: '',
  minDate: '',
  dateValue: {},
  setDateValue: () => {},
  timeValue: {},
  setTimeValue: () => {},
  notes: '',
  setNotes: () => {},
  itemSelectedText: '',
  withoutSelectDate: false,
};
