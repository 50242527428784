import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStatus2FAApi } from '@services/WebApiService';

const fetchStatus2FAAsync = createAsyncThunk(
  'Account/fetchStatus2FA',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchStatus2FAApi(accessToken);
    return data;
  }
);

const { pending, fulfilled, rejected } = fetchStatus2FAAsync;

const fetchStatus2FAExtraReducers = {
  [pending]: (state) => {
    state.status2FA.submitting = true;
  },
  [rejected]: (state) => {
    state.status2FA.submitting = false;
  },
  [fulfilled]: (state, action) => {
    state.status2FA.submitting = false;
    if (!action.payload.error) {
      const { hasSetup2FA, hasEnable2FA, verifiedDate } = action.payload;
      state.status2FA.hasSetup2FA = hasSetup2FA;
      state.status2FA.hasEnable2FA = hasEnable2FA;
      state.status2FA.verifiedDate = verifiedDate;
    }
  },
};

export { fetchStatus2FAAsync, fetchStatus2FAExtraReducers };
