import WebApiRoutes from '@config/Routes/WebApiRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const QboRealmService = {
  generateOauthUrlAPI: (accessToken, payload = {}) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboGenerateOauthPath(payload),
    });
  },
  oauthCallbackAPI: (accessToken, code, realmId) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboOauthCallbackPath(),
      parameters: { code, realmId },
    });
  },
  oauthReactivateCallbackAPI: (accessToken, qboRealmId, code, realmId) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboReactivateCallbackPath({ qboRealmId }),
      parameters: { code, realmId },
    });
  },
  deactivateCallbackAPI: (accessToken, payload) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboDeactivateCallbackPath({ realmId: payload.realmId }),
      parameters: { deactivateReason: payload.reason },
    });
  },
  startBackupAPI: (accessToken, qboAuthRequestId) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboStartBackupPath(),
      parameters: { qboAuthRequestId },
    });
  },
  fetchQboRealmsAPI: (accessToken, pageLink, payload) => {
    const { query, top, skip, order, orderBy, statuses } = payload;
    const url =
      pageLink || WebApiRoutes.qboFetchRealmsPath({ top, skip, query, order, orderBy, statuses });
    return webClientApi(accessToken).getRequest({ url });
  },
  getQboRealmStatusAPI: (accessToken, qboRealmId) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboRealmBackupStatusPath({ qboRealmId }),
    });
  },
  fetchQboStatusBackupsAPI: (accessToken, pageLink, payload) => {
    const { query, top, skip, statuses } = payload;
    const url = pageLink || WebApiRoutes.qboFetchStatusBackupsPath({ top, skip, query, statuses });
    return webClientApi(accessToken).getRequest({ url });
  },
  getQboBackupStatusAPI: (accessToken, qboBackupId) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboBackupStatusPath({ qboBackupId }),
    });
  },
  backupNowAPI: (accessToken, qboRealmId) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboBackupNowPath({ qboRealmId }),
    });
  },
  downloadCallbackAPI: (accessToken, payload = {}) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboDownloadCallbackPath({ qboRealmId: payload.realmId }),
      parameters: payload,
    });
  },
  downloadDetailCallbackAPI: (accessToken, payload = {}) => {
    const parameters = { ...payload };
    delete parameters.type;
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboDownloadDetailtCallbackPath({
        qboRealmId: payload.realmId,
        type: payload.type,
      }),
      parameters,
    });
  },
  fetchQboStatusDownloadAPI: (accessToken, pageLink, payload) => {
    const { query, top, skip, statuses } = payload;
    const url = pageLink || WebApiRoutes.qboFetchStatusDownloadPath({ top, skip, query, statuses });
    return webClientApi(accessToken).getRequest({ url });
  },
  getQboDownloadStatusAPI: (accessToken, qboDownloadId) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboDownloadStatusPath({ qboDownloadId }),
    });
  },
  downloadUrlCallbackAPI: (accessToken, payload = {}) => {
    const { qboDownloadId, indexNumber } = payload;
    const url = WebApiRoutes.qboDownloadUrlPath({ qboDownloadId, indexNumber });
    return webClientApi(accessToken).getRequest({ url });
  },
  restoreCallbackAPI: (accessToken, payload = {}) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboRestoreCallbackPath({ qboRealmId: payload.realmId }),
      parameters: payload,
    });
  },
  restoreDetailCallbackAPI: (accessToken, payload = {}) => {
    const parameters = { ...payload };
    delete parameters.type;
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboRestoreDetailtCallbackPath({
        qboRealmId: payload.realmId,
        type: payload.type,
      }),
      parameters,
    });
  },
  fetchQboStatusRestoreAPI: (accessToken, pageLink, payload) => {
    const { query, top, skip, statuses } = payload;
    const url = pageLink || WebApiRoutes.qboFetchStatusRestorePath({ top, skip, query, statuses });
    return webClientApi(accessToken).getRequest({ url });
  },
  getQboRestoreStatusAPI: (accessToken, qboRestoreId) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboRestoreStatusPath({ qboRestoreId }),
    });
  },
  downloadErrorMessageUrlCallbackAPI: (accessToken, payload = {}) => {
    const { type, maskedQboId } = payload;
    const url = WebApiRoutes.qboDownloadErrorMessageUrlPath({ type, maskedQboId });
    return webClientApi(accessToken).getRequest({ url });
  },
  oauthReplicateCallbackAPI: (accessToken, code, realmId, environment, maskedSourceQboRealmId) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboOauthReplicateCallbackPath(),
      parameters: { code, realmId, environment, maskedSourceQboRealmId },
    });
  },
  oauthReplicateReAuthenticationCallbackAPI: (
    accessToken,
    code,
    realmId,
    environment,
    qboReplicateId
  ) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboOauthReplicateReAuthenticationCallbackPath({ qboReplicateId }),
      parameters: { code, realmId, environment },
    });
  },
  replicateCallbackAPI: (accessToken, payload = {}) => {
    return webClientApi(accessToken).postRequest({
      url: WebApiRoutes.qboReplicateCallbackPath(),
      parameters: payload,
    });
  },
  fetchQboStatusReplicateAPI: (accessToken, pageLink, payload) => {
    const { query, top, skip, statuses } = payload;
    const url =
      pageLink || WebApiRoutes.qboFetchStatusReplicatePath({ top, skip, query, statuses });
    return webClientApi(accessToken).getRequest({ url });
  },
  getQboReplicateStatusAPI: (accessToken, qboReplicateId) => {
    return webClientApi(accessToken).getRequest({
      url: WebApiRoutes.qboReplicateStatusPath({ qboReplicateId }),
    });
  },
};

export default QboRealmService;
