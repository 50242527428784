export default function replaceUrl(url) {
  window.history.replaceState(null, null, url);
}

export function copyToClipboard(text) {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  if ('clipboardData' in navigator) {
    return window.clipboardData.setData(text);
  }
  return document.execCommand('copy', true, text);
}

export function getUrlPathName() {
  return window.location.pathname;
}
