import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  QboButton,
  QboRightDrawer,
  QboDrawerListWrapper,
  QboDrawerListItem,
  QboTypography,
  QboDrawerBoxItem,
} from '@ui/Components';
import { useDispatch } from 'react-redux';
import { getStatusBackupAsync } from '@features/qboStatusBackups/serviceActions/getStatusBackupAsync';
import EventIcon from '@mui/icons-material/Event';
import { backupStatusPath } from '@config/Routes/WebClientRoutes';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import BackupAvaSVG from '@images/generals/BackupAva.svg';
import BackupStatusItemBlock from '@ui/Blocks/Shared/BackupStatusItemBlock';
import { useDateTime, usePageAlert, useAlertMessage } from '@hooks';
import { useNavigate } from 'react-router-dom';
import useQboOauth, { REACTIVATE } from '@pages/sharedHooks/useQboOauth';
import ErrorResponse from '@libs/ErrorResponse';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import { copyToClipboard } from '@utilities';

const defaultNull = '-';

export default function DrawerBackupInfoSection({
  QboStatusBackup,
  onClose,
  open,
  setSnackbarOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateAlert } = usePageAlert();
  const { getSuccessMessage, getErrorMessage, konstMessage } = useAlertMessage();
  const { openOauthWindow, isButtonActive } = useQboOauth(REACTIVATE, {
    qboRealmId: QboStatusBackup?.qboRealmId,
    errorCallback: (data) => {
      onClose();

      if (data.code === ErrorResponse.UnprocessableFormat) {
        updateAlert(
          getErrorMessage(konstMessage.ERROR_REAUTHORIZED_WRONG_REALM, {
            company_name: QboStatusBackup?.companyName,
          })
        );
      } else {
        updateAlert(
          getErrorMessage(konstMessage.ERROR_REAUTHORIZED_GENERAL_ERROR, {
            company_name: QboStatusBackup?.companyName,
          })
        );
      }

      navigate(backupStatusPath, { replace: true });
    },
    successCallback: () => {
      onClose();
      dispatch(getStatusBackupAsync({ qboBackupId: QboStatusBackup?.maskedId }));
      updateAlert(
        getSuccessMessage(konstMessage.SUCCESS_QBO_REAUTHENTICATED, {
          company_name: QboStatusBackup?.companyName,
        })
      );
      navigate(backupStatusPath, { replace: true });
    },
  });
  const { dateFormat, dateToString, dateTimeNow, dateDistance } = useDateTime();

  let elapsedTime = defaultNull;

  if (QboStatusBackup?.startTime) {
    const endTime = QboStatusBackup?.endTime || dateTimeNow();
    elapsedTime = dateDistance(QboStatusBackup?.startTime, endTime, {
      separator: ' ',
    });
  }

  const handleCopy = (statusId) => {
    const snackbarState = copyToClipboard(statusId);
    setSnackbarOpen(!!snackbarState);
  };

  return (
    <QboRightDrawer
      title={t('status_backup_page.drawer_backup_info_section.heading_text')}
      onClose={onClose}
      open={open}
      className="DrawerBackupInfoSection__wrapper">
      <div className="DrawerBackupInfoSection__content">
        <QboDrawerBoxItem
          icon={<img src={BackupAvaSVG} alt="" className="Img__fluid" />}
          label={QboStatusBackup?.companyName || defaultNull}
          withArrow={false}>
          <div className="DrawerBackupInfoSection__status-id">
            <QboTypography variant="caption">
              {t('status_backup_page.item_card.caption.status_id_label', {
                id: QboStatusBackup?.id,
              }) || defaultNull}
            </QboTypography>
            <QboButton onClick={() => handleCopy(QboStatusBackup?.id)} startIcon={<CopyIcon />} />
          </div>
        </QboDrawerBoxItem>

        <QboDrawerListWrapper>
          <BackupStatusItemBlock
            label={t('status_backup_page.drawer_backup_info_section.backup_status_label')}
            status={QboStatusBackup?.status}
            percentage={parseInt(QboStatusBackup?.percentage, 10)}
            isReauthButtonActive={isButtonActive}
            onClickReauth={openOauthWindow}
            oauthTipsInfo={t(
              'dashboard_page.drawer_realm_info_section.reauth_correct_login_desc_html'
            )}
          />

          <QboDrawerListItem
            icon={<EventIcon />}
            label={t('status_backup_page.drawer_backup_info_section.started_at_label')}>
            <QboTypography className="DrawerBackupInfoSection__card-list-value">
              {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusBackup?.startTime) ||
                defaultNull}
            </QboTypography>
          </QboDrawerListItem>

          <QboDrawerListItem
            icon={<HistoryToggleOffIcon />}
            label={t('status_backup_page.drawer_backup_info_section.elapsed_time_label')}>
            <QboTypography className="DrawerBackupInfoSection__card-list-value">
              {elapsedTime}
            </QboTypography>
          </QboDrawerListItem>

          <QboDrawerListItem
            icon={<EventAvailableIcon />}
            label={t('status_backup_page.drawer_backup_info_section.completed_at_label')}>
            <QboTypography className="DrawerBackupInfoSection__card-list-value">
              {dateToString(dateFormat.DEFAULT_FULL_DATE_TIME, QboStatusBackup?.endTime) ||
                defaultNull}
            </QboTypography>
          </QboDrawerListItem>
        </QboDrawerListWrapper>
      </div>
    </QboRightDrawer>
  );
}

DrawerBackupInfoSection.propTypes = {
  QboStatusBackup: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  setSnackbarOpen: PropTypes.func,
};

DrawerBackupInfoSection.defaultProps = {
  QboStatusBackup: {},
  onClose: () => {},
  open: false,
  setSnackbarOpen: () => {},
};
