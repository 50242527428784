import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePagination, useCollectionInterval } from '@hooks';
import {
  qboFetchStatusDownloadAsync,
  resetState,
  updateFetching,
  getStatusDownloadAsync,
} from '@features/qboStatusDowload/qboStatusDownloadSlice';
import { constant } from '@config/BaseSetting';
import { features } from '@hooks/usePagination';
import { listDownloadStatus } from '@hooks/useSystemStatus';

const { paginationCache } = constant;

export default function useFetchStatusDownload() {
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { QboStatusDownload, fetchingQboStatusDownload } = useSelector(
    (state) => state.qboStatusDownload
  );

  const {
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    filter,
  } = usePagination(
    QboStatusDownload,
    qboFetchStatusDownloadAsync,
    paginationCache.DASBOARD_BACKUP_REALMS,
    isApplicationReady,
    {},
    [features.search, features.filtering, features.pagination, features.initialize],
    () => {
      // show loading immediately when keyword
      // changed to blank string
      if (keyword.length === 0) dispatch(updateFetching(true));
    }
  );

  let hasDownloadStatuses = false;

  if (hasDownloadStatuses === false) hasDownloadStatuses = QboStatusDownload.value.length > 0;
  if (hasDownloadStatuses === false) hasDownloadStatuses = keyword.length > 0;
  if (hasDownloadStatuses === false) hasDownloadStatuses = filter.length > 0;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useCollectionInterval(
    QboStatusDownload.value, // collection
    (download) => download.status === listDownloadStatus.RUNNING, // eligible for interval
    (download) => download.status !== listDownloadStatus.RUNNING, // eligible removed from interval
    (download) => dispatch(getStatusDownloadAsync({ qboDownloadId: download.maskedId })) // action when interval running
  );

  return {
    QboStatusDownload,
    fetchingQboStatusDownload,
    hasDownloadStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    isApplicationReady,
  };
}
