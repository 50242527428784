import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSeatUsageAPI } from '@services/WebApiService';

const fetchSeatUsageAsync = createAsyncThunk(
  'Organization/fetchSeatUsageAsync',
  async (payload, { getState }) => {
    const { accessToken } = getState().auth;
    const data = await fetchSeatUsageAPI(accessToken);
    return data;
  }
);

const { pending, fulfilled, rejected } = fetchSeatUsageAsync;

const fetchSeatUsageExtraReducers = {
  [pending]: (state) => {
    state.fetchingSeatUsage = true;
  },
  [rejected]: (state) => {
    state.fetchingSeatUsage = false;
  },
  [fulfilled]: (state, action) => {
    state.fetchingSeatUsage = false;

    if (action.payload.error) {
      state.error = action.payload.error;
    } else if (action.payload?.seats) {
      state.Organization.seats = action.payload.seats;
    }
  },
};

export { fetchSeatUsageAsync, fetchSeatUsageExtraReducers };
