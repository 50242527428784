import React from 'react';
import PropTypes from 'prop-types';
import { QboModal, QboTypography } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ModalFormAuthenticationCodeBlock from './ModalFormAuthenticationCodeBlock';

export default function ModalReset2FABlock({
  showResetConfirmationModal,
  showResetAuthFormModal,
  onCloseResetModal,
  onNextReset2FAButton,
  onBackReset2FAButton,
  errorValidation,
  loadingForm,
}) {
  const { t } = useTranslation();

  const renderBodyModal = () => {
    return (
      <Box className="ModalReset2FABlock__wrapper">
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.reset_confirmation.description_1')}
        </QboTypography>
        <QboTypography noWrap={false} color="primary">
          {t('account_settings_setup_2fa_page.modal.reset_confirmation.description_2')}
        </QboTypography>
      </Box>
    );
  };

  return (
    <>
      <QboModal
        onClose={() => onCloseResetModal()}
        open={showResetConfirmationModal}
        title={t('account_settings_setup_2fa_page.modal.reset_confirmation.title')}
        button={{
          text: t('account_settings_setup_2fa_page.modal.reset_confirmation.confirmation_button'),
          onClick: () => onNextReset2FAButton(),
          position: 'right',
        }}
        preventOutsideClick>
        {renderBodyModal()}
      </QboModal>
      <ModalFormAuthenticationCodeBlock
        showAuthForm={showResetAuthFormModal}
        onBackButton={onBackReset2FAButton}
        onClose={onCloseResetModal}
        onNextButton={onNextReset2FAButton}
        withoutAuth
        validationError={errorValidation}
        loading={loadingForm}
      />
    </>
  );
}

ModalReset2FABlock.propTypes = {
  showResetConfirmationModal: PropTypes.bool,
  showResetAuthFormModal: PropTypes.bool,
  onCloseResetModal: PropTypes.func,
  onNextReset2FAButton: PropTypes.func,
  onBackReset2FAButton: PropTypes.func,
  errorValidation: PropTypes.object,
  loadingForm: PropTypes.bool,
};

ModalReset2FABlock.defaultProps = {
  showResetConfirmationModal: false,
  showResetAuthFormModal: false,
  onCloseResetModal: () => {},
  onNextReset2FAButton: () => {},
  onBackReset2FAButton: () => {},
  errorValidation: {},
  loadingForm: false,
};
