import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { qboFetchObjectItemsAsync, resetState } from '@features/qboObjectItems/qboObjectItemsSlice';
import { QboLink, QboTypography } from '@ui/Components';
import { pascalToTitleCase } from '@utilities';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import {
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
  InsertPageBreak as InsertPageBreakIcon,
} from '@mui/icons-material';
import { useDateTime } from '@hooks';
import usePagination, { features } from '@hooks/usePagination';
import { constant } from '@config/BaseSetting';

const { paginationCache } = constant;

const fileIconColumn = 'fileIcon';
const itemNameColumn = 'itemName';
const updatedAtColumn = 'updatedAt';
const arrowIconColumn = 'arrowIcon';
const deletedStatusColumn = 'deletedStatus';
const defaultOrder = 'asc';
const defaultOrderColumn = 'itemName';

const createHeader = (id, label, columnName, orderable, size, align) => {
  return {
    id,
    label,
    columnName,
    orderable,
    size,
    noDivider: id === 1,
    align,
    allowColspan: id === 3,
    noPadding: columnName === fileIconColumn,
  };
};

const useFetchObjectItems = () => {
  const { t } = useTranslation();
  const { id, objectName } = useParams();
  const { dateToString, dateFormat } = useDateTime();
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderColumn);
  const [hasInitiazedRequest, setHasInitiazedRequest] = useState(false);
  const { QboObjectItems, fetchingQboObjectItems } = useSelector((state) => state.qboObjectItems);

  const hasPageInitialized = isApplicationReady && hasInitiazedRequest;

  const { onPrevPage, onNextPage, onChangePageNumber, pageNumber, onFilter } = usePagination(
    QboObjectItems,
    qboFetchObjectItemsAsync,
    paginationCache.DASBOARD_BACKUP_REALMS_OBJECT_ITEMS,
    hasPageInitialized,
    {
      qboRealmId: id,
      objectName,
      order,
      orderBy,
    },
    [features.pagination, features.filtering]
  );

  const createBackLinkRow = (objectBackName) => {
    return {
      fileIcon: <DriveFolderUploadIcon className="QboTable__icon" />,
      isLink: true,
      itemName: objectBackName,
      lastVersionId: '{{colspan}}',
      updatedAt: '{{colspan}}',
      arrowIcon: '{{colspan}}',
      deletedStatus: '{{colspan}}',
      colspan: 5,
      active: true,
      component: 'th',
      navigate: ClientRoutes.showRealmPath({ id }),
      summaryRow: true,
    };
  };

  const createData = (itemName, updatedAt, lastVersionId, maskedId, rowObjectName, deletedItem) => {
    return {
      id: maskedId,
      fileIcon: deletedItem ? (
        <InsertPageBreakIcon className="QboTable__icon" />
      ) : (
        <InsertDriveFileOutlinedIcon className="QboTable__icon" />
      ),
      isLink: true,
      itemName: (
        <QboTypography withTitle variant="subtitle2" color="secondary">
          {itemName}
        </QboTypography>
      ),
      lastVersionId,
      maskedId,
      objectName: rowObjectName,
      updatedAt: (
        <QboTypography withTitle color="primary">
          {dateToString(dateFormat.DEFAULT_DATE_TIME, updatedAt)}
        </QboTypography>
      ),
      arrowIcon: (
        <QboLink className="QboTableCell__icon">
          <KeyboardArrowRightIcon />
        </QboLink>
      ),
      deletedStatus: deletedItem ? (
        <QboTypography withTitle color="primary">
          {t('backup_realm_object_page.table.deleted_column')}
        </QboTypography>
      ) : null,
      colspan: null,
    };
  };

  const tableHeaders = [
    createHeader(1, null, fileIconColumn, false, 'xxs', 'center'),
    createHeader(
      3,
      t('backup_realm_object_page.table.item_name_column'),
      itemNameColumn,
      true,
      'md',
      'left'
    ),
    createHeader(
      4,
      t('backup_realm_object_page.table.updated_at_column'),
      updatedAtColumn,
      true,
      'auto',
      'left'
    ),
    createHeader(5, null, deletedStatusColumn, false, 'md', 'left'),
    createHeader(6, null, arrowIconColumn, false, 'icon', 'right'),
  ];

  const tableRows = QboObjectItems.value.map((item) =>
    createData(
      item.itemName,
      item.updatedAt,
      item.lastVersionId,
      item.maskedId,
      item.objectName,
      item.isDeletedByIntuit
    )
  );

  // add row link
  // to return back to object
  tableRows.unshift(createBackLinkRow(`${pascalToTitleCase(objectName)}...`));

  const dispatchAction = () => {
    dispatch(
      qboFetchObjectItemsAsync({
        qboRealmId: id,
        order,
        orderBy,
        objectName,
        top: pageNumber,
      })
    );
  };

  useEffect(() => {
    if (isApplicationReady) {
      dispatchAction();
      setHasInitiazedRequest(true);
    }

    return () => {
      dispatch(resetState());
      setHasInitiazedRequest(false);
    };
  }, [isApplicationReady]);

  useEffect(() => {
    if (hasPageInitialized) dispatchAction();
  }, [order, orderBy, objectName]);

  return {
    QboObjectItems,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    tableHeaders,
    tableRows,
    loading: fetchingQboObjectItems,
    qboRealmId: id,
    displayObjectName: objectName,
    onPrevPage,
    onNextPage,
    onChangePageNumber,
    pageNumber,
    listIdOnPage: tableRows.map((x) => x.id).filter((x) => x !== undefined),
    onFilter,
  };
};

export default useFetchObjectItems;
