import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePagination, useCollectionInterval } from '@hooks';
import {
  qboFetchStatusBackupsAsync,
  getStatusBackupAsync,
  resetState,
  updateFetching,
} from '@features/qboStatusBackups/qboStatusBackupsSlice';
import { constant } from '@config/BaseSetting';
import { features } from '@hooks/usePagination';
import { listBackupStatus } from '@hooks/useSystemStatus';

const { paginationCache } = constant;

export default function useFetchStatusBackups() {
  const dispatch = useDispatch();
  const { isApplicationReady } = useSelector((state) => state.application);
  const { QboStatusBackups, fetchingQboStatusBackups } = useSelector(
    (state) => state.qboStatusBackups
  );

  const {
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    filter,
  } = usePagination(
    QboStatusBackups,
    qboFetchStatusBackupsAsync,
    paginationCache.DASBOARD_BACKUP_REALMS,
    isApplicationReady,
    {},
    [features.search, features.filtering, features.pagination, features.initialize],
    () => {
      // show loading immediately when keyword
      // changed to blank string
      if (keyword.length === 0) dispatch(updateFetching(true));
    }
  );

  let hasBackupStatuses = false;

  if (hasBackupStatuses === false) hasBackupStatuses = QboStatusBackups.value.length > 0;
  if (hasBackupStatuses === false) hasBackupStatuses = keyword.length > 0;
  if (hasBackupStatuses === false) hasBackupStatuses = filter.length > 0;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useCollectionInterval(
    QboStatusBackups.value, // collection
    (backup) => backup.status === listBackupStatus.RUNNING, // eligible for interval
    (backup) => backup.status !== listBackupStatus.RUNNING, // eligible removed from interval
    (backup) => dispatch(getStatusBackupAsync({ qboBackupId: backup.maskedId })) // action when interval running
  );

  return {
    QboStatusBackups,
    fetchingQboStatusBackups,
    hasBackupStatuses,
    onPrevPage,
    onNextPage,
    displayKeyword,
    keyword,
    setKeyword,
    onChangePageNumber,
    pageNumber,
    onFilter,
    isApplicationReady,
  };
}
